<template>
  <section>
    <b-modal
      ref="agentPercentage"
      id="agentPercentage"
      hide-footer
      header-bg-variant="info"
      header-text-variant="light"
      title="Cadastre o percentual de cada agente dentro do produto conforme indicado na FDS ou FISPQ"
      size="xl"
      @show="showModal()"
    >
      <b-overlay
        :show="lookingForProduct"
        rounded
        opacity="0.6"
        spinner-small
        no-center
        spinner-variant="primary"
        class="d-inline-block w-100"
        style="min-height: 100px"
      >
        <section class="modal-font-family">
          <p>
            <strong>Caso a FDS ou FISPQ indique um percentual de agente no produto que esteja em uma faixa (exemplo: 5 a 25%),
              por gentileza, preencha o maior percentual dessa faixa para o respectivo agente na tabela abaixo.
            </strong>
          </p>

          <table class="agentPercentageTable">
            <thead>
              <tr>
                <th style='width: 50%'>Cas: Agente</th>

                <th style='width: 50%'>% do agente no produto</th>
              </tr>
            </thead>

            <tbody class="table-bordered" v-for="item in product.legalSearches">
              <tr>
                <td>
                  {{ item.name }}
                </td>

                <td>
                  <base-input-text
                    v-model="item.percentage"
                    :min="0"
                    :max="100"
                    placeholder="Porcentagem"
                    type="number"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <section class="d-flex justify-content-between mt-4">
            <base-button @click="closeModal()" class="w-50 mr-3 btn-cancel">
              <span>Cancelar</span>
            </base-button>

            <b-overlay
              :show="loading"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block w-50"
            >
              <base-button
                class="w-100 btn-add d-flex align-items-center justify-content-center"
                @click="add()"
              >
                <span class="mr-2"> Atualizar </span>
              </base-button>
            </b-overlay>
          </section>
        </section>

        <template v-slot:overlay>
          <div class="text-center loading-spinner">
            <b-spinner
              style="width: 2rem; height: 2rem"
              variant="primary"
              label="Large Spinner"
            ></b-spinner>
            <h6 class="text-primary">Buscando empresa...</h6>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

export default {
  name: 'AgentPercentage',
  components: {
    VueBootstrapTypeahead,
  },
  props: {
    productId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      lookingForProduct: false,
      loading: false,
      product: {},
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    },
    ...mapGetters('legalSearch', {
      selectedAgents: 'selectedAgents',
      summary: 'summary',
    }),
  },
  methods: {
    ...mapActions('companyLegalConsultation', {
      handleUpdateAgentsCompanyLegalConsultation:
        'handleUpdateAgentsCompanyLegalConsultation',
      handleUpdatePercentageCompanyLegalConsultation:
        'handleUpdatePercentageCompanyLegalConsultation',
    }),
    ...mapActions('productLegalConsultation', {
      handleGetProductLegalConsultation: 'handleGetProductLegalConsultation',
      handleUpdatePercentageProductLegalConsultation:
        'handleUpdatePercentageProductLegalConsultation',
    }),
    ...mapActions('legalSearch', {
      handUpdatePercentage: 'handUpdatePercentage',
    }),
    async showModal() {
      try {
        this.lookingForProduct = true
        const { data } = await this.handleGetProductLegalConsultation(
          this.productId
        )
        this.product = data.product
        this.lookingForProduct = false
      } catch (e) {}
    },
    async closeModal() {
      try {
        this.$refs.agentPercentage.hide()
      } catch (e) {}
    },

    async add() {
      try {
        this.loading = true

        const legalSearches = this.product.legalSearches.map((legalSearch) => {
          return {
            _id: legalSearch._id,
            percentage: legalSearch.percentage
              ? Number(legalSearch.percentage)
              : null,
            productId: this.product._id,
          }
        })

        const { data, status } = await this.handUpdatePercentage({
          legalSearches,
          user: this.user.id,
          companyId: this.product.company,
        })

        const { data: dataCompany, status: statusCompany } =
          await this.handleUpdatePercentageCompanyLegalConsultation({
            legalSearches,
            user: this.user.id,
            companyId: this.product.company,
          })

        const { data: dataProduct, status: statusProduct } =
          await this.handleUpdatePercentageProductLegalConsultation({
            legalSearches,
            user: this.user.id,
            companyId: this.product.company,
          })

        this.loading = false

        this.$emit('update-agents')
        await this.closeModal()

        this.$bvToast.toast(
          `Porcentagem dos agentes atualizadas com sucesso.`,
          {
            title: 'Atenção',
            variant: 'success',
            toaster: 'b-toaster-top-center',
            autoHideDelay: 6000,
          }
        )
      } catch (e) {}
    },
  },
}
</script>

<style lang='scss' scoped>
.agentPercentageTable {
  width: 100%;
}

.agentPercentageTable td {
  padding: 8px;
  border: 1px solid #ddd;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}

.agentPercentageTable th {
  background: var(--sapphire-blue);
  border: 1px solid #ddd;
  padding: 8px;
  color: var(--white);
}

.btn-cancel {
  background: transparent !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: var(--navy-blue);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.btn-add {
  background: var(--navy-blue) !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}
</style>
