<template>
  <div class='mt-1'>
    <b-modal
      ref='measurementsModal'
      id='measurementsModal'
      hide-footer
      hide-header
      header-bg-variant='info'
      header-text-variant='light'
      size='xl'
      @show='showModal()'
      @hide="$emit('update')"
    >
      <b-modal
        ref='measurementsDeleteModal'
        id='measurementsDeleteModal'
        hide-footer
        hide-header
        header-bg-variant='info'
        header-text-variant='light'
        size='xl'
      >
        <section class='modal-font-family' v-if='currentMeasurementDelete && currentMeasurementDelete._id'>
          <section class='d-flex justify-content-between align-items-start w-100 mb-3'>
            <section>
              <p class='title'>Deseja realmente excluir a medição abaixo?</p>
            </section>
            <section>
              <b-icon
                style='margin-top: -8px'
                @click='closeMeasurementsDeleteModal()'
                class='cursor-pointer'
                font-scale='2.5'
                icon='x'
                color='#022245'
              >
              </b-icon>
            </section>
          </section>

          <section
            class='measurement mb-3 d-flex justify-content-between'
          >
            <section>
              <span class='label'>Data</span> <br />
              <span class='measurement-text'>{{ format(new Date(currentMeasurementDelete.date), 'dd/MM/yyyy')
                }}</span>
            </section>

            <section>
              <span class='label'>Resultado</span><br />
              <span class='measurement-text'>{{ currentMeasurementDelete.result }}</span>
            </section>

            <section>
              <span class='label'>Unidade de medida</span><br />
              <span class='measurement-text'>{{ currentMeasurementDelete.unitMeasurement }}</span>
            </section>

            <section>
              <span class='label'>Agente</span><br />
              <span class='measurement-text'>{{ currentMeasurementDelete.agent }}</span>
            </section>

            <section>
              <span class='label'>Relatório</span>
              <a :href='currentMeasurementDelete.fileUrl' class='measurement-link' target='_blank'>
                <p class='measurement-text'>{{ currentMeasurementDelete.fileName }}</p>
              </a>
            </section>
          </section>

          <section class='d-flex justify-content-between mt-4'>
            <base-button
              class='w-50 mr-3 btn-cancel'
              @click='closeMeasurementsDeleteModal()'
            >
              <span>Cancelar</span>
            </base-button>

            <b-overlay
              :show='loading'
              rounded
              opacity='0.6'
              spinner-small
              spinner-variant='primary'
              class='d-inline-block w-50'
            >
              <base-button
                class='w-100 btn-add d-flex align-items-center justify-content-center'
                @click='deleteMeasurement()'
              >
                <span > Confirmar exclusão </span>
              </base-button>
            </b-overlay>
          </section>
        </section>
      </b-modal>

      <b-overlay
        :show='lookingMeasurements'
        rounded
        opacity='0.6'
        spinner-small
        spinner-variant='primary'
        class='d-inline-block w-100'
        style='min-height: 250px'
      >
        <section class='modal-font-family'>
          <section class='d-flex justify-content-between align-items-start w-100 mb-3'>
            <section>
              <p class='title'>Adição de medidas realizadas</p>
              <p class='sub-title'>Preencha os dados solicitados para adicionar uma nova medição</p>
            </section>
            <section>
              <b-icon
                style='margin-top: -8px'
                @click='closeModal()'
                class='cursor-pointer'
                font-scale='2.5'
                icon='x'
                color='#022245'
              >
              </b-icon>
            </section>
          </section>

          <section class=''>
            <p class='text-quantity-measurements'>
              Há <span class='quantity-measurements'>{{measurements.length}} </span> {{measurements.length > 1 ? 'medições inseridas' : 'medição inserida'}}
            </p>
          </section>
          <section style='max-height: 300px; overflow-y: auto; padding-right: 5px;'>
            <section
              class='measurement mb-3 d-flex justify-content-between'
              v-for='measurement in measurements'
              :key='measurement._id'
            >
              <section>
                <span class='label'>Data</span> <br />
                <span class='measurement-text'>{{ format(new Date(measurement.date), 'dd/MM/yyyy') }}</span>
              </section>

              <section>
                <span class='label'>Resultado</span><br />
                <span class='measurement-text'>{{ measurement.result }}</span>
              </section>

              <section>
                <span class='label'>Unidade de medida</span><br />
                <span class='measurement-text'>{{ measurement.unitMeasurement }}</span>
              </section>

              <section>
                <span class='label'>Agente</span><br />
                <span class='measurement-text'>{{ measurement.agent }}</span>
              </section>

              <section>
                <span class='label'>Relatório</span>
                <a :href='measurement.fileUrl' class='measurement-link' target='_blank'>
                  <p class='measurement-text'>{{ measurement.fileName }}</p>
                </a>
              </section>

              <section class='measurement-buttons d-flex justify-content-between'>
                <button
                  class='measurement-button-edit mr-3'
                  @click='editMeasurement(measurement)'
                >
                  <svg width='20' height='20' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M10 0C10.2549 0.000282707 10.5 0.0978791 10.6854 0.272848C10.8707 0.447818 10.9822 0.686953 10.9972 0.941395C11.0121 1.19584 10.9293 1.44638 10.7657 1.64183C10.6021 1.83729 10.3701 1.9629 10.117 1.993L10 2H2V16H16V8C16.0003 7.74512 16.0979 7.49997 16.2728 7.31463C16.4478 7.1293 16.687 7.01776 16.9414 7.00283C17.1958 6.98789 17.4464 7.07067 17.6418 7.23426C17.8373 7.39785 17.9629 7.6299 17.993 7.883L18 8V16C18.0002 16.5046 17.8096 16.9906 17.4665 17.3605C17.1234 17.7305 16.6532 17.9572 16.15 17.995L16 18H2C1.49542 18.0002 1.00943 17.8096 0.639452 17.4665C0.269471 17.1234 0.0428434 16.6532 0.00500021 16.15L1.00268e-07 16V2C-0.000159579 1.49542 0.190406 1.00943 0.533497 0.639452C0.876588 0.269471 1.34684 0.0428433 1.85 0.00500011L2 0H10ZM16.243 0.343C16.423 0.163652 16.6644 0.0595265 16.9184 0.0517719C17.1723 0.0440173 17.4197 0.133215 17.6103 0.301249C17.8008 0.469282 17.9203 0.703552 17.9444 0.956475C17.9685 1.2094 17.8954 1.46201 17.74 1.663L17.657 1.758L7.757 11.657C7.57704 11.8363 7.33557 11.9405 7.08162 11.9482C6.82767 11.956 6.58029 11.8668 6.38972 11.6988C6.19916 11.5307 6.07969 11.2964 6.0556 11.0435C6.03151 10.7906 6.10459 10.538 6.26 10.337L6.343 10.243L16.243 0.343Z'
                      fill='#022245' />
                  </svg>
                </button>

                <button
                  class='measurement-button-remove'
                  @click='showModalDeleteMeasurement(measurement)'
                >
                  <svg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path fill-rule='evenodd' clip-rule='evenodd'
                          d='M6.94286 1.75C6.87466 1.75 6.80925 1.77634 6.76103 1.82322C6.71281 1.87011 6.68571 1.9337 6.68571 2V4.25H11.3143V2C11.3143 1.9337 11.2872 1.87011 11.239 1.82322C11.1907 1.77634 11.1253 1.75 11.0571 1.75H6.94286ZM12.8571 4.25V2C12.8571 1.53587 12.6675 1.09075 12.3299 0.762563C11.9924 0.434375 11.5345 0.25 11.0571 0.25H6.94286C6.46547 0.25 6.00763 0.434375 5.67006 0.762563C5.3325 1.09075 5.14286 1.53587 5.14286 2V4.25H1.80901C1.80359 4.24994 1.79816 4.24994 1.79271 4.25H0.771429C0.34538 4.25 0 4.58579 0 5C0 5.41421 0.34538 5.75 0.771429 5.75H1.09018L2.05735 17.0337C2.06637 17.7508 2.36332 18.4368 2.88561 18.9445C3.41607 19.4603 4.13553 19.75 4.88571 19.75H13.1143C13.8645 19.75 14.5839 19.4603 15.1144 18.9445C15.6367 18.4368 15.9336 17.7508 15.9426 17.0337L16.9098 5.75H17.2286C17.6546 5.75 18 5.41421 18 5C18 4.58579 17.6546 4.25 17.2286 4.25H16.2073C16.2018 4.24994 16.1964 4.24994 16.191 4.25H12.8571ZM2.63839 5.75L3.59733 16.9377C3.59911 16.9584 3.6 16.9792 3.6 17C3.6 17.3315 3.73546 17.6495 3.97658 17.8839C4.21769 18.1183 4.54472 18.25 4.88571 18.25H13.1143C13.4553 18.25 13.7823 18.1183 14.0234 17.8839C14.2645 17.6495 14.4 17.3315 14.4 17C14.4 16.9792 14.4009 16.9584 14.4027 16.9377L15.3616 5.75H2.63839ZM6.94286 8.25C7.36891 8.25 7.71429 8.58579 7.71429 9V15C7.71429 15.4142 7.36891 15.75 6.94286 15.75C6.51681 15.75 6.17143 15.4142 6.17143 15V9C6.17143 8.58579 6.51681 8.25 6.94286 8.25ZM11.0571 8.25C11.4832 8.25 11.8286 8.58579 11.8286 9V15C11.8286 15.4142 11.4832 15.75 11.0571 15.75C10.6311 15.75 10.2857 15.4142 10.2857 15V9C10.2857 8.58579 10.6311 8.25 11.0571 8.25Z'
                          fill='#CD0000' />
                  </svg>
                </button>
              </section>
            </section>
          </section>

          <section>
            <hr />
          </section>

          <section
            v-if='showAddMeasurement'
            class='add-measurement w-100 '
          >
            <section class='d-flex justify-content-end'>
              <section>
                <b-icon
                  style='margin-top: -8px'
                  @click='showAddMeasurement = false'
                  class='cursor-pointer'
                  font-scale='2'
                  icon='x'
                  color='#022245'
                >
                </b-icon>
              </section>
            </section>
            <hr/>

            <section class='d-flex justify-content-between'>
              <section class='add-measurement-date'>
                <label class='label'>Data</label>
                <b-form-input
                  v-model='measurement.date'
                  class='height-input'
                  type='date'
                ></b-form-input>
              </section>

              <section class='add-measurement-result'>
                <section>
                  <label class='label'>Resultado</label>
                </section>
                <section class='d-flex w-100'>
                  <b-form-input
                    v-model='measurement.result'
                    placeholder='Resultado da medição'
                    class='height-input result'
                    type='text'
                  ></b-form-input>

                  <select
                    class='height-input unit'
                    v-model='measurement.unitMeasurement'
                  >
                    <option :value='null' selected>Unidade</option>
                    <option value='ppm'>ppm</option>
                    <option value='mg/m³'>mg/m³</option>
                  </select>
                </section>
              </section>

              <section class='add-measurement-agent'>
                <section>
                  <label class='label'>Agente</label>
                </section>
                <select
                  class='height-input w-100'
                  v-model='measurement.agent'
                >
                  <option :value='null' selected>Selecione</option>
                  <option
                    v-for='legalSearch in legalSearches'
                    :value='legalSearch.name'
                    :key='legalSearch.name'
                  >
                    {{ legalSearch.name }}
                  </option>
                </select>
              </section>

              <section class='add-measurement-file'>
                <label class='label'>Relatório</label>
                <input type='file' id='file' @change='onChange' hidden />
                <label class='file-product d-flex align-items-center justify-content-center' for='file'>
                  <svg class='ml-3 mr-2' width='18' height='18' viewBox='0 0 10 18' fill='none'
                       xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M3.12969 6.00003V12.743C3.13748 13.2361 3.33882 13.7063 3.69026 14.0523C4.0417 14.3982 4.51507 14.5921 5.0082 14.5921C5.50134 14.5921 5.9747 14.3982 6.32615 14.0523C6.67759 13.7063 6.87893 13.2361 6.88672 12.743L6.89281 3.90472C6.89792 3.48328 6.81933 3.06502 6.66159 2.67418C6.50385 2.28334 6.2701 1.9277 5.97389 1.62787C5.67768 1.32804 5.3249 1.09 4.93601 0.927526C4.54711 0.765056 4.12983 0.681396 3.70836 0.681396C3.28689 0.681396 2.86961 0.765056 2.48071 0.927526C2.09182 1.09 1.73904 1.32804 1.44283 1.62787C1.14661 1.9277 0.912864 2.28334 0.755124 2.67418C0.597384 3.06502 0.518793 3.48328 0.523906 3.90472V12.8025C0.51532 13.3959 0.624765 13.985 0.84588 14.5356C1.067 15.0863 1.39537 15.5875 1.81191 16.0101C2.22845 16.4327 2.72484 16.7683 3.27223 16.9973C3.81962 17.2264 4.40709 17.3443 5.00047 17.3443C5.59385 17.3443 6.18132 17.2264 6.72871 16.9973C7.2761 16.7683 7.77249 16.4327 8.18903 16.0101C8.60557 15.5875 8.93394 15.0863 9.15506 14.5356C9.37617 13.985 9.48562 13.3959 9.47703 12.8025V4.48785'
                      stroke='#808080' stroke-miterlimit='10' stroke-linecap='round' />
                  </svg>

                  <span v-if='measurement.fileName && !file.name' class='measurement-link'>{{ measurement.fileName
                    }}</span>
                  <span v-if='!measurement.fileName && !file.name'>Inserir anexo</span>
                  <strong v-if='file && file.name'>{{ file.name }}</strong>
                </label>
              </section>
            </section>

            <section class='mt-3'>
              <section class='mt-4'>
                <b-alert show variant='danger' :class='{ hidden: !error }'>
                  {{ error }}<br />
                </b-alert>
              </section>
              <section>
                <b-overlay
                  :show='loading'
                  rounded
                  opacity='0.6'
                  spinner-small
                  spinner-variant='primary'
                  class='d-inline-block w-100'
                >
                  <base-button
                    v-if='measurement && measurement._id'
                    class='w-100 btn-create-measurement'
                    @click='update()'
                  >
                    <span> Atualizar medição </span>
                  </base-button>

                  <base-button
                    v-else
                    class='w-100 btn-create-measurement'
                    @click='create()'
                  >
                    <span> Salvar nova medição </span>
                  </base-button>
                </b-overlay>

                <base-button
                  v-if='measurement && measurement._id'
                  class='w-100 btn-delete-measurement mt-2'
                  @click='showModalDeleteMeasurement(currentMeasurement)'
                >
                  <span> Excluir medição </span>
                </base-button>
              </section>
            </section>
          </section>

          <section v-else>
            <base-button
              class='w-100 btn-add-measurement d-flex align-items-center justify-content-center'
              @click='addMeasurement()'
            >
              <span class='mr-4'> Clique para adicionar uma nova medição </span>

              <svg width='18' height='18' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2ZM10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0ZM15 9H11V5H9V9H5V11H9V15H11V11H15V9Z'
                  fill='#262626' />
              </svg>
            </base-button>
          </section>

          <section class='d-flex justify-content-between mt-4'>
            <base-button
              class='w-25 mr-3 btn-cancel'
              @click='closeModal()'
            >
              <span>Cancelar</span>
            </base-button>

            <b-overlay
              :show='loading'
              rounded
              opacity='0.6'
              spinner-small
              spinner-variant='primary'
              class='d-inline-block w-100'
            >
              <base-button
                class='w-100 btn-add d-flex align-items-center justify-content-center'
                @click='finishAddingMeasurements()'
              >
                <span class='mr-2'> Concluir adição de medidas </span>
              </base-button>
            </b-overlay>
          </section>
        </section>

        <template v-slot:overlay>
          <div class='text-center loading-spinner'>
            <b-spinner
              style='width: 2rem; height: 2rem'
              variant='primary'
              label='Large Spinner'
            ></b-spinner>
            <h6 class='text-primary'>Buscando medições...</h6>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { format } from 'date-fns'
import api from '@/utils/api'
import axios from 'axios'

export default {
  name: 'Measurements',
  components: {},
  props: {
    productId: {
      type: String,
      required: false,
      default: null
    },
    legalSearches: {
      type: Array,
      required: false,
      default: []
    }
  },
  data() {
    return {
      loading: false,
      measuredUnits: ['mg/m³', 'ppm'],
      file: {
        name: ''
      },
      showAddMeasurement: false,
      currentMeasurement: null,
      currentMeasurementDelete: null,
      lookingMeasurements: false,
      measurements: [],
      measurement: {
        productId: null,
        userId: null,
        date: null,
        result: null,
        unitMeasurement: null,
        agent: null,
        fileUrl: null,
        fileName: null
      }
    }
  },
  async mounted() {
    try {

    } catch (e) {
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    },
    error() {
      return this.$store.getters['access/error']
    }
  },
  methods: {
    format,
    ...mapActions('measurement', {
      handleCreate: 'handleCreate',
      handleGetMeasurements: 'handleGetMeasurements',
      handleGetMeasurement: 'handleGetMeasurement',
      handleUpdade: 'handleUpdade',
      handleDelete: 'handleDelete'
    }),

    async showModal() {
      try {
        this.reset()
        await this.getMeasurements()
        await this.$store.dispatch('access/updateError', '')
      } catch (e) {

      }
    },

    reset() {
      this.measurement = {
        productId: null,
        userId: null,
        date: null,
        result: null,
        unitMeasurement: null,
        agent: null,
        fileUrl: null,
        fileName: null
      }
      //this.measurements = []
      this.currentMeasurement = null
      this.currentMeasurementDelete = null
      this.loading = null
      this.showAddMeasurement = false
      this.file = {
        name: ''
      }
    },

    onChange(event) {
      const files = Array.from(event.target.files)
      this.file = files[0]
    },

    addMeasurement() {
      this.reset()
      this.showAddMeasurement = true
      this.currentMeasurement = null
    },

    showModalDeleteMeasurement(measurement) {
      try {
        this.currentMeasurementDelete = measurement
        this.$bvModal.show('measurementsDeleteModal')
      } catch (e) {

      }
    },

    async deleteMeasurement() {
      try {
        this.loading = true
        const { data, status } = await this.handleDelete(this.currentMeasurementDelete._id)
        this.loading = false
        let message = data.message
        let variant = 'success'
        if (status !== 200) {
          message = data.errors
          variant = 'danger'
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.showAddMeasurement = false
        this.reset()
        this.closeMeasurementsDeleteModal()
        await this.getMeasurements()
      } catch (e) {

      }
    },

    async editMeasurement(measurement) {
      try {
        this.showAddMeasurement = true
        this.currentMeasurement = measurement
        await this.get(measurement._id)
      } catch (e) {

      }
    },

    async upload() {
      try {
        if (this.file && this.file.size) {
          const { data: responseSignedUrl } = await api.get('uploads/signed-url', {
            params: {
              fileType: this.file.type
            }
          })

          if (responseSignedUrl.error) {
            return responseSignedUrl
          }

          const { url: signedUrl } = responseSignedUrl
          const url = signedUrl.split('?')[0]

          const options = {
            headers: {
              'Content-Type': this.file.type
            }
          }

          await axios.put(signedUrl, this.file, options)
          return url
        }
      } catch (e) {
        console.error(e)
      }
    },

    async create() {
      try {
        await this.$store.dispatch('access/updateError', '')
        if (!this.measurement.date || !this.measurement.result || !this.measurement.unitMeasurement || !this.measurement.agent || !this.file || !this.file.name) {
          await this.$store.dispatch('access/updateError', 'Preencha todos os campos corretamente')
          return
        }
        this.loading = true
        const fileUrl = await this.upload()
        this.measurement.productId = this.productId
        this.measurement.userId = this.user.id
        this.measurement.fileUrl = fileUrl
        this.measurement.fileName = this.file.name
        const { data, status } = await this.handleCreate(this.measurement)
        this.loading = false
        let message = data.message
        let variant = 'success'
        if (status !== 201) {
          message = data.errors
          variant = 'danger'
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.showAddMeasurement = false
        this.reset()
        await this.getMeasurements()
      } catch (e) {
      }
    },

    async update() {
      try {
        try {
          this.loading = true
          await this.$store.dispatch('access/updateError', '')
          if (!this.measurement.date || !this.measurement.result || !this.measurement.unitMeasurement || !this.measurement.agent) {
            await this.$store.dispatch('access/updateError', 'Preencha todos os campos corretamente')
            return
          }
          if (this.file && this.file.name) {
            this.measurement.fileUrl = await this.upload()
            this.measurement.fileName = this.file.name
          }
          const { data, status } = await this.handleUpdade(this.measurement)
          this.loading = false
          let message = data.message
          let variant = 'success'
          if (status !== 200) {
            message = data.errors
            variant = 'danger'
          }
          this.$bvToast.toast(message, {
            title: 'Atenção',
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000
          })
          this.showAddMeasurement = false
          this.reset()
          await this.getMeasurements()
        } catch (e) {
        }
      } catch (e) {
      }
    },

    async get(measurementId) {
      try {
        this.lookingMeasurements = true
        const { data, status } = await this.handleGetMeasurement(measurementId)
        const { measurement } = data
        this.measurement = measurement
        this.measurement.date = this.format(new Date(this.measurement.date), 'yyyy-MM-dd')
        this.lookingMeasurements = false
      } catch (e) {
      }
    },

    async getMeasurements() {
      try {
        this.lookingMeasurements = true
        const { data, status } = await this.handleGetMeasurements(this.productId)
        const { measurements } = data
        this.measurements = measurements
        this.lookingMeasurements = false
      } catch (e) {
      }
    },

    closeModal() {
      this.$bvModal.hide('measurementsModal')
    },
    closeMeasurementsDeleteModal() {
      this.$bvModal.hide('measurementsDeleteModal')
    },
    async finishAddingMeasurements() {
      if(!this.showAddMeasurement){
        this.closeModal();
        return
      }
      if (this.measurement && this.measurement._id) {
        if (!this.measurement.date || !this.measurement.result || !this.measurement.unitMeasurement || !this.measurement.agent) {
          await this.$store.dispatch('access/updateError', 'Preencha todos os campos corretamente')
          return
        }
        await this.update()
        this.closeModal();
      } else {
        if (!this.measurement.date || !this.measurement.result || !this.measurement.unitMeasurement || !this.measurement.agent || !this.file || !this.file.name) {
          await this.$store.dispatch('access/updateError', 'Preencha todos os campos corretamente')
          return
        }
        await this.create()
        this.closeModal();
      }
    }
  },
  created() {
  }
}
</script>
<style lang='scss' scoped>
.title {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: var(--navy-blue);
  margin: 0;
}

.sub-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #808080;
  margin-top: 10px;
}

.btn-cancel {
  background: transparent !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: var(--navy-blue);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.btn-add {
  background: var(--navy-blue) !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.measurement-link {
  text-decoration: underline;
  color: var(--navy-blue)
}

.measurement {
  border: 1px solid #808080;
  border-radius: 8px;
  padding: 10px;

  .measurement-text {
    font-size: 14px;
    font-weight: 400;
    color: var(--navy-blue)
  }

  .measurement-buttons {
    .measurement-button-edit {
      background: transparent;
      border: 1px solid #808080;
      border-radius: 6px;
      width: 48px;
      height: 48px;

      &:hover {
        border: 1px solid var(--navy-blue);
        background: var(--navy-blue) !important;

        svg {
          path {
            fill: #FFFFFF !important;
          }
        }
      }
    }

    .measurement-button-remove {
      background: transparent;
      border: 1px solid #808080;
      border-radius: 6px;
      width: 48px;
      height: 48px;

      &:hover {
        border: 1px solid #CD0000;
        background: #CD0000 !important;

        svg {
          path {
            fill: #FFFFFF !important;
          }
        }
      }
    }
  }
}

.btn-add-measurement {
  background: #E6E9ED !important;
  border-radius: 6.25px !important;

  span {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: var(--navy-blue);
  }
}

.label {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: var(--navy-blue);
}

.text-quantity-measurements{
  font-weight: 700;
  line-height: 24px;
  color: var(--navy-blue);
}
.quantity-measurements{
  color: #04A8F3;
}

.add-measurement {
  padding: 15px;
  border: 1px solid #808080;
  border-radius: 8px;

  .height-input {
    height: 41px;
    border-radius: 6.25px;
    border: 1px solid #808080;
  }

  .add-measurement-date {
    width: 15%;
    min-width: 160px;
    margin-right: 15px;
  }

  .add-measurement-result {
    margin-right: 15px;
    width: 40%;
    min-width: 250px;

    .result {
      width: 65%;
      margin-right: 7px;
    }

    .unit {
      width: 35%;
    }
  }


  .add-measurement-agent {
    margin-right: 15px;
    width: 15%;
    min-width: 250px;
  }

  .add-measurement-file {
    width: 30%;
    min-width: 200px;
  }

  .btn-create-measurement {
    background: #262626 !important;
    border-radius: 6.25px !important;

    span {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      color: #FFFFFF;
    }
  }
  .btn-delete-measurement {
    background: transparent !important;
    border-radius: 6.25px !important;
    border: 1px solid #CD0000;
    span {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: #CD0000;
    }
    &:hover{
      background: #CD0000 !important;
      span{
        color: #FFFFFF;
      }
    }
  }
}

.file-product {
  border-radius: 6.25px;
  border: 1px dashed #808080;
  width: 100%;
  height: 41px;
  cursor: pointer;

  span {
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
}


@media only screen and (max-width: 1000px) {
  .add-measurement {
    flex-wrap: wrap;

    .add-measurement-date {
      margin-right: 0;
      width: 100%;
    }

    .add-measurement-result {
      margin-right: 0;
      width: 100%;
    }

    .add-measurement-file {
      width: 100%;
    }
  }
}
</style>
