export const optionsVentilationIdentified = [
  {
    model: 'ventilationIdentified',
    img: 'ho-facil-blue.png',
    key: 1,
    value: 'Caso nenhuma das outras opções seja condizente com sua condição de ventilação instalada'
  },
  {
    model: 'ventilationIdentified',
    img: 'ho-facil-blue.png',
    key: 2,
    value: 'Ventilação geral com menos de 3 a 6 trocas de ar por hora'
  },
  {
    model: 'ventilationIdentified',
    img: 'ho-facil-blue.png',
    key: 3,
    value:
      'Boa ventilação geral com menos de 6 a 12 trocas de ar por hora'
  },
  {
    model: 'ventilationIdentified',
    img: 'ho-facil-blue.png',
    key: 4,
    value:
      'Boa ventilação geral com exaustão local nos pontos de emissão'
  },
  {
    model: 'ventilationIdentified',
    img: 'ho-facil-blue.png',
    key: 5,
    value: 'Captura no ponto de emissão com contenção'
  },
  {
    model: 'ventilationIdentified',
    img: 'ho-facil-blue.png',
    key: 6,
    value: 'Enclausuramento do agente (uma barreira de proteção)'
  },
  {
    model: 'ventilationIdentified',
    img: 'ho-facil-blue.png',
    key: 7,
    value: 'Enclausuramento primário e secundário (duas barreiras de proteção)'
  }
]