<template>
  <section>
    <b-modal
      ref='company'
      id='company'
      hide-footer
      header-bg-variant='info'
      header-text-variant='light'
      title='Cadastrar empresa'
      size='lg'
      @show='showModal()'
    >
      <section class='modal-font-family'>
        <section>
          <label>Nome</label>
          <base-input-text
            v-model='name'
            placeholder='Digite o nome da empresa'
            class='label-input-modal'
            type='text'
          />
        </section>

        <section class='mt-3'>
          <label>Descrição da empresa</label>
          <base-text-area
            v-model='description'
            class='custom-textarea'
            placeholder='Digite a descrição da empresa'
            type='text'
          />
        </section>

        <section>
          <p class='mt-3' v-if='this.selectedAgents.length !== this.summary.length'>
            <strong class='text-danger'>Atenção: </strong>
            Como não foi selecionado todos o agentes, os agentes não selecionados serão excluídos.
          </p>
        </section>

        <section class='d-flex justify-content-between mt-4'>
          <base-button
            class='w-50 mr-3 btn-cancel'
            @click='hideModalAddCompany()'
          >
            <span>Cancelar</span>
          </base-button>

          <b-overlay
            :show='loading'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='d-inline-block w-50'
          >
            <base-button
              :disabled="disabled"
              class='w-100 btn-add d-flex align-items-center justify-content-center'
              @click='add()'
            >
            <span class='mr-2'>
              Adicionar
            </span>
            </base-button>
          </b-overlay>
        </section>
      </section>
    </b-modal>
  </section>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from '@/utils/validators'
import useVuelidate from '@vuelidate/core'

export default {
  name: 'Company',
  props: {},
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      loading: false,
      name: null,
      description: null,
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    },
    disabled() {
      return !(this.name && this.description)
    },
    ...mapGetters('legalSearch', {
      selectedAgents: 'selectedAgents',
      summary: 'summary',
    })
  },
  methods: {
    ...mapActions('companyLegalConsultation', {
      handleCreateCompanyLegalConsultation: 'handleCreateCompanyLegalConsultation',
      handleMarkAsLastImportedCompanyLegalConsultation: 'handleMarkAsLastImportedCompanyLegalConsultation',
      handleGetLastImportedCompanyLegalConsultation: 'handleGetLastImportedCompanyLegalConsultation',
    }),
    ...mapActions('freeModeAgents', {
      handleCreateFreeModeAgents: 'handleCreateFreeModeAgents',
    }),
    showModal() {
      this.loading = false
      this.name = ''
      this.description = ''
    },

    async add() {
      this.loading = true
      await this.handleCreateFreeModeAgents(
        {
          user: this.user.id,
          agents: []
        }
      )

      const { data, status } = await this.handleCreateCompanyLegalConsultation(
        {
          name: this.name,
          description: this.description,
          user: this.user.id,
          setSelectedAllAgents: this.selectedAgents.length === this.summary.length,
          agents: this.selectedAgents,
        }
      )

      if (this.selectedAgents.length !== this.summary.length) {
        await this.$store.dispatch('legalSearch/notRemove', this.selectedAgents)
      }

      await this.handleMarkAsLastImportedCompanyLegalConsultation(
        {
          user: this.user.id,
          companyLegalConsultationId: data.company._id
        }
      )
      await this.handleGetLastImportedCompanyLegalConsultation(this.user.id)

      this.$bvToast.toast(`Empresa cadastrada com sucesso.`, {
        title: 'Atenção',
        variant: 'success',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 6000,
      });
      this.loading = false
      this.hideModalAddCompany()
      await this.load()
      this.$emit('clear-items-remove')
    },

    hideModalAddCompany() {
      this.$bvModal.hide('company')
      this.$bvModal.hide('registerProductOrImport')
    },

    async load() {
      await this.$store.dispatch('legalSearch/load', this.user.id)
    }
  }
}
</script>

<style lang='scss' scoped>
.custom-textarea {
}

.file-product {
  border-radius: 6.25px;
  border: 1px dashed #808080;
  width: 100%;
  height: 40px;
  cursor: pointer;

  span {
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
}

.btn-cancel {
  background: transparent !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: var(--navy-blue);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.btn-add {
  background: var(--navy-blue) !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: #FFFFFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}
</style>
