<template>
  <div class='d-flex justify-content-start align-items-center w-100'>
    <div>
      <base-button
        class='btn'
        @click='save()'
      >
        <img width='35' height='35' src='../../../../assets/images/Edit.svg'>

        <span class='ml-2'>
            Ver e/ou editar os <br />objetivos da análise
          </span>
      </base-button>
    </div>

    <div class='ml-3'>
      <b-popover
        target='popoverQuantitativeAssessmentInformation'
        triggers='hover'
        placement='auto'
      >
        Clique para ver as informações da Avaliação Quantitativa
      </b-popover>

      <button
        @click='showQuantitativeAssessmentInformation()'
        class='btn-obs'
        id='popoverQuantitativeAssessmentInformation'
      >
        <img width='30' height='30' src='../../../../assets/images/ObsWhite.svg' alt=''>

        <strong class='ml-3'>Atenção!</strong>
      </button>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { mapGetters } from 'vuex'
import Buttons from '@/components/LegalSearch/components/Buttons.vue'

export default {
  name: 'SummaryObjectivesAnalysis',
  directives: { mask },
  components: { Buttons },
  computed: {
    ...mapGetters('objectivesAnalysis', {
      getObjectivesAnalysis: 'getObjectivesAnalysis'
    })
  },
  data() {
    return {
      component: ''

    }
  },
  methods: {

    save() {
      this.$emit('setComponent', { component: 'ObjectivesAnalysis', showResult: false })
    },
    showQuantitativeAssessmentInformation() {
      this.$bvModal.show('quantitativeAssessmentInformation')
    }
  },
  props: ['agent', 'prevInteractions'],
  watch: {}
}
</script>
<style lang='scss' scoped>
.btn-obs {
  border-radius: 6px;
  background: #04A8F3;
  border: 1px solid #04A8F3;
  min-height: 61px;
  min-width: 61px;
  padding: 0 15px;

  strong {
    color: #FFFFFF
  }
}

.btn {
  background-color: #CBD63E;
  color: #022245;
  font-weight: 600;
  min-height: 61px !important;
  min-width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 24px;

  i {
    color: #022245;
    margin-right: 6px;
  }

  img {
    margin-right: 6px;
  }
}

.divider {
  width: 1px;
  height: 70px;
  background: #C0C0C0;
}


.inputs {
  .label_input {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
  }

  .btn_ver_avaliacao {
    width: 320px;
    height: 48px;
  }
}

</style>
