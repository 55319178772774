<template>
  <div class="mt-1">
    <!--<b-modal ref='alert' scrollable hide-footer title='Aviso'>
      <p>A impressao do arquivo em excel esta sendo processada,
        clique no botão abaixo para acessar a página de downloads.</p>
      <router-link :to="{ name: 'RelatoriosConsultaLegal' }">
        <base-button>
         <span class="label">Acessar</span>
        </base-button>
      </router-link>
    </b-modal> -->

    <InsalubrityModal :agent="selectedAgent" @save="saveSelectedActivities" />

    <OrientationVideos
      :orientationVideos="orientationVideos"
      :agent="agent"
      @clear="orientationVideos = []"
    />

    <AnalysisToolModal
      :protecaoEficazProps='protecaoEficazProps'
      :functionalityProps='functionalityProps'
      :exposicaoPermanenteProps='exposicaoPermanenteProps'
      :userOrProduct='userOrProduct'
      :disabled='disabledAnalysisTool'
      @updatePermanentExhibition='updatePermanentExhibition'
      @updateEffectiveprotection='updateEffectiveprotection'
    />

    <ProductAdd @clear-items-remove='load'></ProductAdd>
    <!-- <router-link :to="{ name: 'PrintInsalubrity' }" v-if="allowed">
      <span class="icon"><i class="fas fa-print"></i></span>
      <span class="label">Versão para impressão</span>
    </router-link>-->
    {{ '\t' }}
    <!--<div class="imprimir" @click="$emit('open-survey'), generateReport()" v-if="allowed">
      <span class="icon"><i  class="fas fa-print"></i></span>
      <span class="label mx-1">Versão para impressão Excel</span>
    </div>-->

    <table class="text-center table b-table table-bordered custom-table">
      <thead class='sticky'>
        <tr >
          <th colspan='10' class='container-tab-buttons' >
            <tab-buttons/>
          </th>
        </tr>

        <tr class='headerTable '>
          <th class="width-10-percent">Produto</th>
          <th class="width-20-percent">CAS: Agente</th>
          <th class="width-10-percent min-width-160-px" >Detalhes do Agente</th>
          <th class="width-10-percent">Lista de Atividades</th>
          <th class="width-10-percent">Forma de avaliação</th>
          <th class="width-10-percent">Fundamentação</th>
          <th class="width-10-percent">Limites de tolerância</th>
          <th class="width-10-percent">Insalubridade</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="item in legalConsultationAgentsGroupedByProduct">
          <template
            v-for="legalSearch in item.legalSearches"
          >
            <template>
              <tr :key="legalSearch.legalSearchId">
                <td
                  v-if='legalSearch.index < 1 || item.product._id === `-`'
                  :rowspan="rowspan(item, legalSearch)"
                >
                  <section v-if='legalSearch.product && legalSearch.product._id' class='d-flex justify-content-around'>
                    <!--Exposição Permanente-->
                    <button
                      :class="legalSearch.product.effectiveProtection && legalSearch.product.effectiveProtection._id && analysisPermanentExposureAndEffectiveProtection.interactedEffectiveProtectionQuestions(legalSearch.product.effectiveProtection) ? 'mb-3 btn-filled-analysis-tools' : 'mb-3 btn-unpopulated-analysis-tools'"
                      @click='showEffectiveProtectionOrPermanentExhibition(legalSearch.product._id, 3)'
                    >
                      <img
                        v-if='legalSearch.product.effectiveProtection && legalSearch.product.effectiveProtection._id && analysisPermanentExposureAndEffectiveProtection.interactedEffectiveProtectionQuestions(legalSearch.product.effectiveProtection)'
                        width='35'
                        height='35'
                        alt='Proteção Eficaz'
                        title='Proteção Eficaz'
                        src='../../assets/images/exposicaoPermanenteAzul.svg'
                        class='fa fa-download'
                      />
                      <img
                        v-else
                        width='35'
                        height='35'
                        alt='Proteção Eficaz'
                        title='Proteção Eficaz'
                        src='../../assets/images/exposicaoPermanenteVermelho.svg'
                        class='fa fa-download'
                      />
                    </button>

                    <!--Proteção Eficaz-->
                    <!--<button
                      :class="legalSearch.product.permanentExhibition && legalSearch.product.permanentExhibition._id && analysisPermanentExposureAndEffectiveProtection.interactedPermanentExposureQuestions(legalSearch.product.permanentExhibition) ? 'mb-3 btn-filled-analysis-tools' : 'mb-3 btn-unpopulated-analysis-tools'"
                      @click='showEffectiveProtectionOrPermanentExhibition(legalSearch.product._id, 2)'
                    >
                      <img
                        v-if='legalSearch.product.permanentExhibition && legalSearch.product.permanentExhibition._id && analysisPermanentExposureAndEffectiveProtection.interactedPermanentExposureQuestions(legalSearch.product.permanentExhibition)'
                        width='35'
                        height='35'
                        alt='Exposição Permanente'
                        title='Exposição Permanente'
                        src='../../assets/images/protecaoEficazAzul.svg'
                        class='fa fa-download'
                      />
                      <img
                        v-else
                        width='35'
                        height='35'
                        alt='Exposição Permanente'
                        title='Exposição Permanente'
                        src='../../assets/images/protecaoEficazVermelho.svg'
                        class='fa fa-download'
                      />
                    </button>-->
                  </section>

                  <section v-else class='px-2'>
                    <p
                      v-if='importedCompany && importedCompany._id'
                      class='word-break add-agent-prouct'
                      @click="addAAgentProduct(legalSearch.legalSearchId)"
                    >
                      Adicionar à <br/> produto
                    </p>

                    <p
                      v-else
                      class='word-break '
                    >
                      -
                    </p>

                    <b-tooltip
                      :target="`product_id_${legalSearch.legalSearchId}`"
                      placement="auto"
                      triggers="hover"
                    >
                    <span>
                      É necessário que este agente esteja como constituinte de um produto para que
                      seja possível interagir com as ferramentas de análise da aba LTCAT
                    </span>
                    </b-tooltip>
                    <b-icon
                      v-if='importedCompany && importedCompany._id'
                      :id="`product_id_${legalSearch.legalSearchId}`"
                      class='ml-1'
                      font-scale="1.5"
                      icon="info-circle-fill"
                      variant="info"
                    >
                    </b-icon>
                  </section>

                  <span v-if='legalSearch.product && legalSearch.product.name'> {{ legalSearch.product.name}}</span>

                  <section class='mt-2' v-if='legalSearch.product && legalSearch.product._id'>
                    <b-tooltip
                      v-if="synergisticRiskProducts && synergisticRiskProducts.length > 0 && !!synergisticRiskProducts.find((x) => x.product === legalSearch.product._id)"
                      :target="`${legalSearch.cas}_${legalSearch.legalSearchId}_${legalSearch.product._id}_synergisticRiskProducts`"
                      custom-class="custom-tooltip"
                      placement="auto"
                      triggers="hover"
                    >
                      <strong>Este produto contém agentes químicos que afetam de forma acumulativa o mesmo órgão ou sistema alvo:</strong> <br/>
                      <ul class='phrases mt-2'>
                        <li
                          v-for='phrase in synergisticRiskProducts.find((x) => x.product === legalSearch.product._id).phrases'
                          :key='phrase'
                        >
                          {{phrase}}
                        </li>
                      </ul>
                    </b-tooltip>

                    <button
                      v-if="synergisticRiskProducts && synergisticRiskProducts.length > 0 && !!synergisticRiskProducts.find((x) => x.product === legalSearch.product._id)"
                      class='btn-synergistic-risk'
                      :id="`${legalSearch.cas}_${legalSearch.legalSearchId}_${legalSearch.product._id}_synergisticRiskProducts`"
                    >
                      <svg width="20" height="20" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.034 8.5645L10.6878 1.2175C10.4057 0.93712 10.0242 0.779739 9.62651 0.779739C9.22882 0.779739 8.8473 0.93712 8.56526 1.2175L1.21901 8.5645C0.93863 8.84654 0.78125 9.22806 0.78125 9.62575C0.78125 10.0234 0.93863 10.405 1.21901 10.687L8.56601 18.0333C9.14952 18.6168 10.1035 18.6168 10.6885 18.0333L18.0355 10.6863C18.3151 10.4041 18.4719 10.023 18.4719 9.62575C18.4719 9.22855 18.3151 8.84741 18.0355 8.56525L18.034 8.5645ZM9.02876 4.735C9.19376 4.55725 9.40376 4.468 9.66176 4.468C9.91976 4.468 10.1298 4.5565 10.2948 4.73275C10.4598 4.909 10.5423 5.13025 10.5423 5.3965C10.5423 5.626 10.4673 6.39625 10.315 7.708C10.165 9.0205 10.03 10.288 9.91751 11.512H9.37976C9.27851 10.2873 9.15476 9.01975 9.00476 7.708C8.85626 6.3955 8.78126 5.626 8.78126 5.3965C8.78126 5.134 8.86376 4.915 9.02876 4.7365V4.735ZM10.3263 14.35C10.1403 14.53 9.91901 14.62 9.66176 14.62C9.40451 14.62 9.18326 14.53 8.99801 14.35C8.81276 14.1685 8.72051 13.9495 8.72051 13.6923C8.72051 13.435 8.81276 13.2138 8.99801 13.0285C9.18401 12.8425 9.40526 12.7495 9.66251 12.7495C9.91976 12.7495 10.141 12.8425 10.3263 13.0285C10.5138 13.2138 10.606 13.435 10.606 13.6923C10.606 13.9495 10.513 14.1685 10.327 14.3493L10.3263 14.35Z" fill="#022245"/>
                      </svg>
                    </button>
                  </section>
                </td>

                <td :rowspan="model.getActivity(legalSearch) ? 3 : 2">
                  <b-tooltip
                    :target="`${legalSearch.cas}_${legalSearch.legalSearchId}`"
                    custom-class="custom-tooltip"
                    placement="auto"
                    triggers="hover"
                  >
                    <section
                      v-if='(legalSearch.attachment11 && legalSearch.attachment11.group) || legalSearch.nameAttachment11'
                    >
                      <span
                        class='p-2 text-white'
                        style='font-size: 15px'
                      >
                      Este agente consta na NR 15 com o seguinte nome:
                        {{ legalSearch.attachment11 && legalSearch.attachment11.group
                            ? legalSearch.attachment11.group
                            : legalSearch.nameAttachment11 ? legalSearch.nameAttachment11: '-'
                        }}
                      </span> <br/><br/>
                    </section>

                    <section
                      class='p-2'
                      v-if='legalSearch.acgih && legalSearch.agent'
                    >
                      <span
                        class='text-white'
                        style='font-size: 15px'
                      >
                        Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                        {{ legalSearch.agent ? legalSearch.agent : '-' }}
                      </span>
                      <br/>
                      <br/>
                    </section>
                    <a
                      :href="`https://pubchem.ncbi.nlm.nih.gov/#query=${legalSearch.cas}`"
                      target='_blank'
                      class='link-pubchem-cas ml-2'
                    >
                      <span>
                        Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{legalSearch.cas}}
                      </span>
                    </a>
                  </b-tooltip>

                  <a
                    href="#"
                    class='hover_links word-break '
                    :id="`${legalSearch.cas}_${legalSearch.legalSearchId}`"
                  >
                    <span class='word-break'>{{ model.getsearchId(legalSearch) }}</span>

                    <b-icon
                      style='margin-bottom: -1px'
                      class='ml-1'
                      font-scale="1.2"
                      icon="info-circle-fill"
                      variant="info"
                    >
                    </b-icon>
                  </a>

                  <section v-if='legalSearch.videosOrientacaoAbaLaudoInsalubridade && legalSearch.videosOrientacaoAbaLaudoInsalubridade.length > 0'>
                    <button
                      class='btn-orientations mt-2'
                      @click='showModalOrientationVideos(model.getsearchId(legalSearch), legalSearch.videosOrientacaoAbaLaudoInsalubridade)'
                    >
                      <span>
                        Ver orientações <br/> especificas
                      </span>
                    </button>
                  </section>
                </td>
              </tr>
            </template>

            <template v-if="model.getActivity(legalSearch)">
              <tr :key='`row_1_${legalSearch.cas}_${legalSearch.index}_${item.product._id}`'>
                <template v-for="field in model.tableFields">
                  <td
                    :key="`field_${field.key}`"
                    :class="`field_${field.key}`"
                  >
                    <template v-if="field.key === 'activities'">
                      <a
                        class='hover_links'
                        href="#"
                        v-b-tooltip.lefttop.hover.html
                        :title="model.getValue(field.key, legalSearch)"
                        v-if="model.getValue(field.key, legalSearch)"
                        @click="displayActivities(legalSearch)"
                      >
                        <span style='' class='d-flex align-items-center justify-content-center'>
                          Selecionadas
                          <b-icon
                            class='ml-1'
                            font-scale="1.2"
                            icon="info-circle-fill"
                            variant="info"
                          >
                          </b-icon>
                        </span>
                      </a>
                      <a href="#" v-else>-</a>
                    </template>

                    <template v-else>
                      <span style='font-size: 15px' v-html="model.getValue(field.key, legalSearch)"/>
                    </template>

                    <template v-if="field.key === 'agentDetails'">
                      <section>
                        <b-tooltip
                          :target="`taxonomy_descrption_${legalSearch.cas}_${legalSearch.legalSearchId}`"
                          custom-class="custom-tooltip"
                          placement="auto"
                          triggers="hover"
                        >
                          <div v-if="legalSearch.taxonomy && legalSearch.taxonomy.agentDescription">
                            <strong class='text-white mb-4'>Descrição do agente</strong>
                            <br/>
                            <span class='text-white mt-4'>{{legalSearch.taxonomy.agentDescription}}</span>
                            <br/>
                            <taxonomy :taxonomy="legalSearch.taxonomy"/>
                          </div>

                          <div v-else>
                            <span>
                              Por hora não temos informações sobre a taxonomia e descrição deste agente químico. Estamos trabalhando constantemente na atualização e inclusão de novas informações e no futuro este agente poderá apresentar mais informações.
                            </span>
                          </div>
                        </b-tooltip>

                        <a
                          class='hover_links px-1 text-center'
                          href="#"
                          :id="`taxonomy_descrption_${legalSearch.cas}_${legalSearch.legalSearchId}`"
                        >
                          <span style='' class='d-flex align-items-center justify-content-center'>
                            Ver descrição

                            <b-icon
                              class='ml-1'
                              font-scale="1.2"
                              icon="info-circle-fill"
                              variant="info"
                            >
                            </b-icon>
                          </span>
                        </a>
                      </section>

                      <section style='margin-top: -15px'>
                        <b-tooltip
                          :target="`harmfulness_${legalSearch.cas}_${legalSearch.legalSearchId}`"
                          custom-class="custom-tooltip"
                          placement="auto"
                          triggers="hover"
                        >
                          <span v-html='getPhraseList(legalSearch)'></span>
                        </b-tooltip>

                        <a
                          class='hover_links'
                          href="#"
                          :id="`harmfulness_${legalSearch.cas}_${legalSearch.legalSearchId}`"
                        >
                          <span style='' class='d-flex align-items-center justify-content-center'>
                            Ver nocividade

                            <b-icon
                              class='ml-1'
                              font-scale="1.2"
                              icon="info-circle-fill"
                              variant="info"
                            >
                            </b-icon>
                          </span>
                        </a>
                      </section>
                    </template>

                    <template v-if="field.key === 'base'">
                      <b-tooltip
                        v-if="getGroup(model.getBase(legalSearch))"
                        :target="`base${legalSearch.cas}_${legalSearch.legalSearchId}`"
                        custom-class="custom-tooltip"
                        placement="auto"
                        triggers="hover"
                      >
                        <span class='text-white'>{{getGroup(model.getBase(legalSearch))}}</span>
                      </b-tooltip>

                      <a
                        class='hover_links'
                        href="#"
                        :id="`base${legalSearch.cas}_${legalSearch.legalSearchId}`"
                      >
                        <span >
                          {{ model.getBase(legalSearch)}}

                          <b-icon
                            v-if="getGroup(model.getBase(legalSearch))"
                            class='ml-1'
                            font-scale="1.2"
                            icon="info-circle-fill"
                            variant="info"
                          >
                          </b-icon>
                        </span>
                      </a>
                    </template>

                    <template v-if="field.key === 'toleranceLimits'" :id="`toleranceLimits${legalSearch.cas}`">
                      <span v-html='toleranceLimits(legalSearch)'> </span>
                    </template>
                  </td>
                </template>
              </tr>

              <tr :key='`row_2_${legalSearch.cas}_${legalSearch.index}_${item.product._id}`'>
                <td colspan='9' >
                  <p class='title-observation'>
                    Esta é uma frase de orientação para facilitar o seu entendimento durante a análise do enquadramento.
                    Ela não é uma conclusão de laudo técnico.
                  </p>
                  <a
                    class=''
                    href='#'
                    @click='showEffectiveProtectionOrPermanentExhibition(legalSearch.product._id, 3)'
                    v-if="legalSearch.product && legalSearch.product._id && legalSearch.product.effectiveProtection && legalSearch.product.effectiveProtection._id && !analysisPermanentExposureAndEffectiveProtection.interactedEffectiveProtectionQuestions(legalSearch.product.effectiveProtection)"
                  >
                    <p
                      class='pointer text-expandir '
                      style='text-align: center!important;'
                    >
                      {{ analysisPermanentExposureAndEffectiveProtection.textDefault() }}
                    </p>
                  </a>

                  <section
                    class='d-flex align-content-start justify-content-between py-4 px-3'
                    style='background: #04A8F31A; border-radius: 6px'
                  >
                    <section
                      class='d-flex'
                    >
                      <p
                        class='observation transition'
                      >
                      <span
                        class='transition'
                        v-html="model.getValue('ps', legalSearch).replaceAll(model.textDefault(), '').substring(0, legalSearch.showCompleteObservationSentence ? model.getValue('ps', legalSearch).replaceAll(model.textDefault(), '').length : 200)"
                      >
                      </span>
                        <span
                          class='transition'
                          v-if='legalSearch.showCompleteObservationSentence'
                          v-html='analysisPermanentExposureAndEffectiveProtection.frasePersonalizadaFerramentaAnalise(legalSearch)'
                        ></span>

                        <strong
                          v-if='!legalSearch.showCompleteObservationSentence'
                          class='pl-1 cursor-pointer text-expandir'
                          @click="legalSearch.showCompleteObservationSentence = true"
                        >
                          Clique para expandir ...
                        </strong>
                      </p>
                    </section>

                    <section class='pl-3'>
                      <b-icon
                        style='margin-top: -5px'
                        class='ml-1 mr-1 transition cursor-pointer'
                        :class="legalSearch.showCompleteObservationSentence ? 'rotate ' : ''"
                        font-scale="1.6"
                        icon="chevron-down"
                        @click="legalSearch.showCompleteObservationSentence = !legalSearch.showCompleteObservationSentence"
                      >
                      </b-icon>
                    </section>
                  </section>
                </td>
              </tr>
            </template>

            <template v-else>
              <tr>
                <td colspan="9" class="selectable-row">
                  <a href="#" @click="displayActivities(legalSearch)">
                    <span class='selectable-row-text'>Agente necessita de determinação de atividade, selecione aqui</span>
                  </a>
                </td>
              </tr>
            </template>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import Insalubrity from '@/models/Insalubrity'
import AnalysisPermanentExposureAndEffectiveProtection from '@/models/AnalysisPermanentExposureAndEffectiveProtection'
import InsalubrityModal from '@/components/LegalSearch/InsalubrityModal'
import Taxonomy from '@/components/LegalSearch/components/Taxonomy'
import { mapActions, mapGetters } from 'vuex'
import eventBus from '../../utils/eventBus'
import TabButtons from '@/components/LegalSearch/components/TabButtons.vue'
import AnalysisToolModal from '@/components/LegalSearch/AnalysisToolModal.vue'
import ProductAdd from '@/components/LegalSearch/Modal/ProductAdd.vue'
import OrientationVideos from '@/components/LegalSearch/Modal/OrientationVideos.vue'

export default {
  data() {
    return {
      generateImpression: false,
      show: true,
      allowed: true,
      loading: false,
      dontHavePlan: false,
      model: Insalubrity,
      analysisPermanentExposureAndEffectiveProtection: AnalysisPermanentExposureAndEffectiveProtection,
      selectedAgent: {},
      scrollBarPosition: 0,
      listOfCASExceptionsLimitsTolerance:[
        '14808-60-7', '14464-46-1', '1317-95-9'
      ],
      protecaoEficazProps: null,
      orientationVideos: [],
      agent: "",
      functionalityProps: 1,
      exposicaoPermanenteProps: null,
      userOrProduct: null,
      disabledAnalysisTool: false,
    }
  },
  components: {
    OrientationVideos,
    ProductAdd,
    AnalysisToolModal,
    TabButtons,
    InsalubrityModal,
    Taxonomy,
  },
  computed: {
    xlsxLink() {
      return `${process.env.VUE_APP_BASE_URL}/xlsx/${this.user.id}/insalubrity`
    },
    summary() {
      return this.$store.getters['legalSearch/summary']
    },
    synergisticRiskProducts() {
      return this.$store.getters['legalSearch/synergisticRiskProducts']
    },
    user() {
      return this.$store.getters['user/current']
    },
    importedCompany() {
      return this.$store.getters['companyLegalConsultation/importedCompany']
    },
    ...mapGetters('rationaleGroups', {
      getGroupFundamentationUnhealthy: 'getGroupFundamentationUnhealthy'
    }),
    ...mapGetters('phraseListH', {
      getListOfPhrasesControlBanding: 'getListOfPhrasesControlBanding',
      getListOfPrecautionaryPhrasesControlBanding: 'getListOfPrecautionaryPhrasesControlBanding',
    }),
    ...mapGetters('legalSearch', {
      legalConsultationAgentsGroupedByProduct: 'legalConsultationAgentsGroupedByProduct',
    }),
    ...mapGetters('permanentExhibition', {
      exposicaoPermanente: 'exposicaoPermanente',
    }),
    ...mapGetters('effectiveProtection', {
      protecaoEficaz: 'protecaoEficaz'
    })
  },
  methods: {
    ...mapActions('reportsDownloads', {
      handleCreateReportDownloads: 'handleCreateReportDownloads',
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    ...mapActions('event', {
      handlGetEventActive: 'handlGetEventActive',
    }),
    ...mapActions('legalSearch', {
      exportingExcel: 'exportingExcel',
      setSlectedAgents: 'setSlectedAgents',
    }),
    ...mapActions('permanentExhibition', {
      handleCreatePermanentExhibition: 'handleCreatePermanentExhibition',
      handleUpdatePermanentExhibition: 'handleUpdatePermanentExhibition',
      handleGetPermanentExhibition: 'handleGetPermanentExhibition',
      handleGetByProductPermanentExhibition: 'handleGetByProductPermanentExhibition',
      handleReset: 'handleReset',
    }),
    ...mapActions('effectiveProtection', {
      handleCreateEffectiveprotection: 'handleCreateEffectiveprotection',
      handleUpdateEffectiveprotection: 'handleUpdateEffectiveprotection',
      handleGetEffectiveprotection: 'handleGetEffectiveprotection',
      handleGetByProductEffectiveprotection: 'handleGetByProductEffectiveprotection',
      handleReset: 'handleReset'
    }),
    showModalOrientationVideos(agent, orientationVideos){
      this.orientationVideos = orientationVideos
      this.agent = agent
      setTimeout(() => {
        this.$bvModal.show('orientationVideos')
      },100)
    },
    callGlobalCustomEvent(event) {
      eventBus.$emit(event)
    },
    addAAgentProduct(legalSearchId){
      console.log(legalSearchId)
      this.setSlectedAgents([legalSearchId])
      this.callGlobalCustomEvent('clearItems')
      this.$emit('select', legalSearchId)
      this.callGlobalCustomEvent('showModalAddProduct')
    },
    async showEffectiveProtectionOrPermanentExhibition(productId, functionality) {
      this.productId = productId;
      this.functionalityProps = functionality;
      const { effectiveProtection } = await this.handleGetByProductEffectiveprotection(this.productId)
      this.protecaoEficazProps = effectiveProtection
      const { permanentExhibition } = await this.handleGetByProductPermanentExhibition(this.productId)
      this.exposicaoPermanenteProps = permanentExhibition
      this.userOrProduct = 'product';
      setTimeout(() => {
        this.$bvModal.show('effective-protection-modal')
      },100)
    },

    async updatePermanentExhibition(exposicaoPermanente) {
      this.disabledAnalysisTool = true;
      if (exposicaoPermanente._id) {
        exposicaoPermanente.tab = 'Laudo de Insalubridade'
        await this.handleUpdatePermanentExhibition(exposicaoPermanente)
      } else {
        exposicaoPermanente.user = this.user.id
        exposicaoPermanente.tab = 'Laudo de Insalubridade'
        if(this.userOrProduct === 'product') {
          exposicaoPermanente.product = this.productId
        }
        await this.handleCreatePermanentExhibition(exposicaoPermanente)
        if (this.userOrProduct === 'product') {
          const { permanentExhibition } = await this.handleGetByProductPermanentExhibition(this.productId)
          this.exposicaoPermanenteProps = permanentExhibition
        } else {
          const { permanentExhibition } = await this.handleGetPermanentExhibition(this.user.id)
          this.exposicaoPermanenteProps = permanentExhibition
        }
      }
      this.disabledAnalysisTool = false;
    },

    async updateEffectiveprotection(protecaoEficaz) {
      this.disabledAnalysisTool = true;
      if (protecaoEficaz._id) {
        protecaoEficaz.tab = 'Laudo de Insalubridade'
        await this.handleUpdateEffectiveprotection(protecaoEficaz)
      } else {
        protecaoEficaz.user = this.user.id
        protecaoEficaz.tab = 'Laudo de Insalubridade'
        if(this.userOrProduct === 'product') {
          protecaoEficaz.product = this.productId
        }
        await this.handleCreateEffectiveprotection(protecaoEficaz)
        if (this.userOrProduct === 'product') {
          const { effectiveProtection } = await this.handleGetByProductEffectiveprotection(this.productId)
          this.protecaoEficazProps = effectiveProtection
        } else {
          const { effectiveProtection } = await this.handleGetEffectiveprotection(this.user.id)
          this.protecaoEficazProps = effectiveProtection
        }
      }
      this.disabledAnalysisTool = false;
    },

    async showEffectiveProtectionModal() {
      const { effectiveProtection } = await this.handleGetEffectiveprotection(this.user.id)
      this.protecaoEficazProps = effectiveProtection
      const { permanentExhibition } = await this.handleGetPermanentExhibition(this.user.id)
      this.exposicaoPermanenteProps = permanentExhibition
      this.userOrProduct = 'user';
      setTimeout(() => {
        this.$bvModal.show('effective-protection-modal')
      },100)
    },
    rowspan(legalConsultationAgentsGroupedByProduct, legalSearch){
      if(legalConsultationAgentsGroupedByProduct.product._id === `-`){
        return this.model.getActivity(legalSearch) ? 3 : 2
      } else {
        const notActivity = legalConsultationAgentsGroupedByProduct.legalSearches.filter((legalSearch) => !this.model.getActivity(legalSearch)).length
        return (legalConsultationAgentsGroupedByProduct.legalSearches.length * 3) - notActivity
      }
    },
    styleWidth(field){
      switch (field.key) {
        case 'ps':
        case 'searchId':
          return 'max-width : 150px'
        default:
          return 'width : 120px'
      }
    },
    async generateReport(){
      await this.exportingExcel(true);
      const payload = {
        user: this.user.id,
        type: "insalubrity",
        format: 'xlsx',
        job: 'legalConsultationReports',
        parameters: {}
      }
      const { data, status } = await this.handleCreateReportDownloads(payload)

      let message = data.message
      let variant = 'danger'
      await this.exportingExcel(false);
      if(status === 201){
        message = data.message;
        variant = 'success';
        this.$refs['alert'].show()
        return
      }
      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
      });
    },

    displayActivities(item) {
      this.selectedAgent = item
      this.$bvModal.show('activities-modal')
      this.scrollBarPosition = document.documentElement.scrollTop;
    },
    async load() {
      this.setSlectedAgents([])
      await this.$store.dispatch('legalSearch/load', this.user.id)
    },
    async saveSelectedActivities(agent) {
      await this.$store.dispatch('legalSearch/saveActivities', agent)
      await this.$store.dispatch('legalSearch/load', this.user.id)
      this.$bvModal.hide('activities-modal')
      window.scroll(0, this.scrollBarPosition)

      if (this.importedCompany && this.importedCompany._id) {
        this.$bvToast.toast(`As informações da empresa foram atualizadas.`, {
          title: 'Atenção',
          variant: 'success',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 6000,
        });
      }
    },

    getGroup(group) {
      const descriptionGroup = this.getGroupFundamentationUnhealthy.find((x) => x.group === group)
      return descriptionGroup ? descriptionGroup.reasoning : ''
    },

    toleranceLimits(object) {
      let catalog = undefined
      if (object.attachment1) {
        catalog = 'attachment1'
      } else if (object.attachment2) {
        catalog = 'attachment2'
      } else if (object.attachment3) {
        catalog = 'attachment3'
      } else if (object.attachment8) {
        catalog = 'attachment8'
      } else if (object.attachment11) {
        catalog = 'attachment11'
      } else if (object.attachment14) {
        catalog = 'attachment14'
      } else if (object.attachment12) {
        catalog = 'attachment12'
      } else if (object.attachment13) {
        catalog = 'attachment13'
      } else if (object.nr15Unclassified) {
        catalog = 'nr15Unclassified'
      }

      let skinAbsorption = ''
      let mgm = '';
      let  ppm = ''
      let  ceil = ''

      if (object.limits && object.limits.skinAbsorption) {
        skinAbsorption = object.limits.skinAbsorption
      } else {
        skinAbsorption = object[catalog]
          ? object[catalog].skinAbsorption
            ? object[catalog].skinAbsorption
            : '-'
          : '-'
      }

      if (object.limits && object.limits.limit1) {
        mgm = object.limits.limit1
      } else {
        mgm = object[catalog]
        ? object[catalog].limit1
          ? object[catalog].limit1
          : '-'
        : '-'
      }

      if (object.limits && object.limits.limit2) {
        ppm = object.limits.limit2
      } else {
        ppm =  object[catalog]
          ? object[catalog]
            ? object[catalog].limit2
              ? object[catalog].limit2
              : '-'
            : '-'
          : '-'
      }

      if (object.limits && object.limits.ceil) {
        ceil = object.limits.ceil
      } else {
        ceil = object[catalog]
          ? object[catalog]
            ? object[catalog].ceil
              ? object[catalog].ceil
              : '-'
            : '-'
          : '-'
      }

      if(!this.listOfCASExceptionsLimitsTolerance.includes(object.cas)) {
        if(skinAbsorption !== '-'){
          const [value] = skinAbsorption.split(' ')
          skinAbsorption = value
        }

        if (mgm !== '-') {
          const [value] = mgm.split(' ')
          mgm = value
        }

        if (ppm !== '-') {
          const [value] = ppm.split(' ')
          ppm = value
        }

        if(ceil !== '-'){
          const [value] = ceil.split(' ')
          ceil = value
        }
      }
        return `Pele: ${skinAbsorption}
                <br/>mg/m³: ${mgm}
                <br/>ppm: ${ppm}
                <br/>Teto: ${ceil}`
    },

    getPhraseList(agent) {
      if(agent.controlBanding && agent.controlBanding.dangerPhrases && agent.controlBanding.dangerPhrases.length > 0){
        let list = ``
        agent.controlBanding.dangerPhrases.forEach((x) => {
          this.getListOfPhrasesControlBanding.forEach((y) => {
            if(y[x]) {
              list += ` ${x}: <span class='mb-2'>${y[x]}</span><br/>`
            }
          })
        })
        return list
      }
      return `Não encontramos nenhuma frase de risco ou de nocividade para este agente, porém isso não quer dizer que ele não seja nocivo ou que não apresente riscos ao trabalhador. Trabalhamos constantemente buscando informações de agentes químicos e é possível que no futuro tenhamos mais informações sobre a nocividade deste agente químico. Recomendamos que seja analisada a FISPQ do produto que contém este agente e considere a nocividade ou riscos ao trabalhador conforme a FISPQ`
    },
  },
  async mounted() {
    try {
      eventBus.$on('generateReportInsalubrity', async () => {
        await this.generateReport()
      })
      await this.handlGetEventActive();
    } catch (err) {
      this.dontHavePlan = false;
      this.loading = false;
      console.log(err)
    }
  },
  beforeDestroy() {
    eventBus.$off('generateReportInsalubrity')
  },
  created() {
    const breadcrumb = {
      tab: 'insalubrity',
      page: 'Consulta Legal',
      subPage: {
        name: 'Consulta Legal',
        active: false
      },
      tabs: [
        {
          name: 'Laudo de insalubridade',
          active: true
        }
      ]
    }
    this.setBreadcrumb(breadcrumb)
  },
}
</script>

<style scoped>
.rotate {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.transition {
  color: var(--navy-blue);
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  -ms-transition: -ms-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
}
.text-expandir{
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
  color: #04A8F3;
}
.hover_links{
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #04A8F3;
}
.sticky {
  position: sticky;
  top: 10px;
  z-index: 10;
}
.container-tab-buttons{
  padding: 0 0 5px 0!important;
}
.headerTable{
  vertical-align: middle;
  background: #94D5F3;
  padding: 10px;
  top: 60px;
}

.width-10-percent{
  width: 10%!important;
}
.min-width-160-px{
  min-width: 160px!important;
}
.width-20-percent{
  width: 20%!important;
}
.imprimir {
  color: var(--blue);
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
}
.imprimir:hover {
  text-decoration: underline
}
.loading{
  width: 1.5rem;
  height: 1.5rem;
}
.selectable-row {
  vertical-align: middle;
  background: rgba(4, 168, 243, 0.1)
}
.selectable-row-text{
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #022245!important;
}
td {
  vertical-align: middle;
}
.field_ps {
  text-align: left;
  font-size: 12px;
}
.title-observation{
  color: #262626;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 10px!important;
  font-weight: bold;
  text-align: center;
}

.observation {
  font-size: 14px;
  line-height: 1.2;
  margin: -5px!important;
  text-align: left;
  padding-left: 10px;
}
.btn-filled-analysis-tools{
  border: 1px solid #022245;
  background: transparent!important;
  padding: 2px;
  border-radius: 3px;
}
.btn-unpopulated-analysis-tools{
  border: 1px solid #FF0000;
  background: transparent!important;
  padding: 2px;
  border-radius: 3px;
}
.btn-synergistic-risk{
  width: 32px!important;
  height: 32px!important;
  background: #CBD63E!important;
  border: 1px solid #022245;
  border-radius: 2px;
}
.phrases{
  list-style: inside;
  margin-left: 20px;
  li{
    list-style-position: outside;
  }
}
</style>
