<template>
  <b-modal
    id='surveySatisfaction'
    size='lg'
    ref='surveySatisfaction'
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
  >
    <section class='modal-font-family p-2'>
      <section class='mb-4'>
        <p class='survey-title'>
          Pesquisa de Satisfação
        </p>
      </section>

      <section>
        <p class='survey-sub-title'>
          Em uma escala de 1 a 5, como você avalia a nossa plataforma?
        </p>
      </section>

      <section class='scales mb-2 w-100'>
        <section v-for='(star, i) in starRatingSatisfactionSurvey' :key='i'>
          <section class="iconStar" @click='selectNote(star)'>
            <b-icon class="h1 mb-1" font-scale="4" :icon="star.selected ? star.selectedIcon : star.iconNotDelected" color="#FFD700"></b-icon>
            <p class="label">{{star.text}}</p>
          </section>
        </section>
      </section>

      <section>
        <base-text-area
          block
          v-model='v$.survey.body.justification.$model'
          label='Me conte o porquê você me deu essa nota?'
        />
      </section>

      <section class='float-right mt-3'>
        <b-overlay
          :show='loading'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
        >
          <base-button
            class='btn_export_modal'
            @click='submitSurvey()'
          >
            <span class='label'>Enviar</span>
          </base-button>
        </b-overlay>
      </section>
    </section>
  </b-modal>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@/utils/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SurveySatisfaction',
  props: {
    isOpen: { type: Boolean, default: false },
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data(){
    return {
      loading: false,
      starRatingSatisfactionSurvey: [
        {
          selected: false,
          selectedIcon: 'star-fill',
          iconNotDelected: 'star',
          text: 'Péssimo',
          note: 1,
        },
        {
          selected: false,
          selectedIcon: 'star-fill',
          iconNotDelected: 'star',
          text: 'Ruim',
          note: 2,
        },
        {
          selected: false,
          selectedIcon: 'star-fill',
          iconNotDelected: 'star',
          text: 'Regular',
          note: 3,
        },
        {
          selected: false,
          selectedIcon: 'star-fill',
          iconNotDelected: 'star',
          text: 'Bom',
          note: 4,
        },
        {
          selected: false,
          selectedIcon: 'star-fill',
          iconNotDelected: 'star',
          text: 'Ótimo',
          note: 5,
        }
      ],
    }
  },
  created(){
    this.survey.body = {
      satisfaction: null,
      justification: null,
    }
  },
  validations: {
    survey: {
      type: { required },
      body: {
        satisfaction: { required },
        justification: { },
      },
    }
  },
  computed: {
    ...mapGetters('surveys', {
      survey: 'getSurvey',
    }),
  },
  methods: {
    ...mapActions('surveys', {
      handleCreateSurvey: 'handleCreateSurvey',
      resetSurvey: 'resetSurvey',
    }),
    selectNote(item){
      this.starRatingSatisfactionSurvey.forEach((x) => x.selected = false)
      this.starRatingSatisfactionSurvey.find((x) => x.note === item.note).selected = true
      this.survey.body.satisfaction = item.note
    },
    closeModal() {
      this.$refs.surveySatisfaction.hide()
    },
    async submitSurvey(){
      if(!this.survey.body.justification){
        this.$bvToast.toast(`O campo "Me conte o porquê você me deu essa nota?", precisa ser preenchido!`, {
          title: 'Atenção',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 7000,
        });
        return
      }
      this.loading = true;
      this.survey.type = 'satisfaction-surveys';
      const response = await this.handleCreateSurvey();
      this.loading = true;
      let message = '';
      let variant = '';

      if(response.message){
        const sessionId = localStorage.getItem("sessionId");
        const user = (await this.$api.get(`users/${sessionId}`)).data;
        await this.$store.dispatch('user/updateUser', user);
        message = "Pesquisa enviada com sucesso";
        variant = 'success';
      } if(response.errors){
        message = "Erro ao enviar pesquisa";
        variant = 'danger';
      }

      this.closeModal();

      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
      });

      await this.resetSurvey();
    }
  }
}
</script>

<style lang='scss' scoped>
.survey-title {
  color: #262626;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.survey-sub-title {
  color: #262626;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.text-would-recommend {
  color: #262626;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 125% */
}

.scales {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  .iconStar {
    cursor: pointer;
    .label {
      text-align: center;
      align-self: center;
      color: #FFFFFF !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
    }
  }
}

.selected-scale {
  border: 3px solid var(--navy-blue);
}
</style>
