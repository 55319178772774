<template>
  <div class='mt-1'>
    <b-modal
      ref='alert'
      scrollable
      hide-footer
      title='Atenção'
      header-bg-variant='info'
      header-text-variant='light'
    >
      <section class='modal-font-family'>
        <p>
          A impressão do arquivo em Excel esta sendo processada,
          clique no botão abaixo para acessar a página de downloads.
        </p>

        <router-link class='float-right router-link-text-decoration' :to="{ name: 'RelatoriosConsultaLegal' }">
          <base-button class='btn_export_modal'>
            <span class="label">Acessar</span>
          </base-button>
        </router-link>
      </section>
    </b-modal>

    <div>
      <!--<a class="imprimir" :href="xlsxLink">
        <span class="icon"><i class="fas fa-print"></i></span>
        <span class="label">Versão para impressão Excel</span>
      </a>-->
      <component v-bind:is='component' />
    </div>
  </div>
</template>

<script>
import Acigh from '@/models/Acgih'
import { mapActions, mapGetters } from 'vuex'
import eventBus from '../../../utils/eventBus'
import PcmsoTable from '@/components/LegalSearch/Pcmso/PcmsoTable'

export default {
  name: 'Pcmso',
  data() {
    return {
      generateImpression: false,
      modalOpen: false,
      loading: false,
      showResult: false,
      allowed: false,
      dontHavePlan: false,
      model: Acigh,
      selectedAgent: {},
      scrollBarPosition: 0,
      component: 'PcmsoTable'
    }
  },
  components: {
    PcmsoTable
  },
  computed: {
    xlsxLink() {
      return `${process.env.VUE_APP_BASE_URL}/xlsx/${this.user.id}/pcmso`
    },
    summary() {
      return this.$store.getters['legalSearch/summary']
    },
    summaryMeta() {
      return this.$store.getters['legalSearch/summaryMeta']
    },
    user() {
      return this.$store.getters['user/current']
    }
  },
  methods: {
    ...mapActions('reportsDownloads', {
      handleCreateReportDownloads: 'handleCreateReportDownloads'
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb'
    }),
    ...mapActions('event', {
      handlGetEventActive: 'handlGetEventActive'
    }),
    ...mapActions('legalSearch', {
      exportingExcel: 'exportingExcel'
    }),
    async generateReport() {
      await this.exportingExcel(true)
      const payload = {
        user: this.user.id,
        type: 'pcmso',
        format: 'xlsx',
        job: 'legalConsultationReports',
        parameters: {
          selectedFilters: this.summaryMeta,
        }
      }
      const { data, status } = await this.handleCreateReportDownloads(payload)

      let message = data.message
      let variant = 'danger'
      await this.exportingExcel(false)
      if (status === 201) {
        message = data.message
        variant = 'success'
        this.$refs['alert'].show()
        return
      }
      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })

    }
  },
  async mounted() {
    try {
      eventBus.$on('generateReportPCMSO', async () => {
        await this.generateReport()
      })
      await this.handlGetEventActive()
    } catch (err) {
      this.loading = false
      this.dontHavePlan = false
    }
  },
  beforeDestroy() {
    eventBus.$off('generateReportPCMSO')
  },
  created() {
    const breadcrumb = {
      tab: 'pcmso',
      page: 'Consulta Legal',
      subPage: {
        name: 'Consulta Legal',
        active: false
      },
      tabs: [
        {
          name: 'PCMSO',
          active: true
        }
      ]
    }
    this.setBreadcrumb(breadcrumb)
  }
}
</script>
<style>
.tooltip-inner {
  text-align: left;
  max-width: 400px !important;
  width: 400px !important;
}
</style>
<style lang='scss' scoped>
.pulse {
  width: max-content;
  text-transform: uppercase;
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  background-color: #ffa500;
  box-shadow: 0 0 0 0 #ffa500;
  color: var(--white);
  font-weight: 600;
  padding: 8.5px 7px;
  border-radius: 4px;
  font-size: 14px;
  letter-spacing: 1px;

  &:hover {
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    animation: none;
  }
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}

.obs {
  display: flex;
  align-items: center;
  justify-content: start;
  background: rgba(4, 168, 243, 0.4);
  border-radius: 6.25px;
  padding: 10px;
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
}

.component {
  background: #FFFFFF;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 10px;
  min-height: 90px;
  margin-top: -10px;
}

.btn_export {
  background-color: #CBD63E;
  color: #022245;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 24px;

  i {
    color: #022245;
    margin-right: 6px;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }
}

.headerTable {
  vertical-align: middle;
  background: #94D5F3;
  padding: 10px;
  position: sticky;
  top: 83px;
  z-index: 10;
}

.imprimir {
  color: var(--blue);
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
}

.imprimir:hover {
  text-decoration: underline
}

.loading {
  width: 1.5rem;
  height: 1.5rem;
}

.selectable-row {
  background-color: aliceblue;
  font-weight: bold;
  vertical-align: middle;
}

td {
  vertical-align: middle;
}

.field_ps {
  text-align: left;
  font-size: 12px;
}

.table tr:nth-child(2) th {
  top: 173px;
}
</style>
