<template>
  <b-modal
    id="ltcat-modal"
    size="md"
    header-bg-variant="primary"
    header-text-variant="light"
    title="Escolha as atividades realizadas"
    ref="modal"
    hide-footer
  >
    <form>
      <b-form-group>
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="selectedActivities"
          :options="activities"
          name="flavour-1"
          stacked
        ></b-form-checkbox-group> </b-form-group
      ><br />
      <b-input-group>
        <b-button variant="success" @click="save" block>
          <i class="fa fa-save"></i>Salvar
        </b-button>
      </b-input-group>
    </form>
  </b-modal>
</template>

<script>
export default {
  computed: {
    activities() {
      if (this.agent.decree3048 && this.agent.decree3048.activities) {
        let activities = []
        this.agent.decree3048.activities.forEach((group) => {
          activities = activities.concat(group.activities)
        })
        activities = activities.concat(['O trabalhador executa uma atividade diferente das opções previstas em norma para este agente químico'])
        return activities
      } else return ['O trabalhador executa uma atividade diferente das opções previstas em norma para este agente químico']
    },
  },
  data() {
    return {
      selectedActivities: [],
    }
  },
  methods: {
    save() {
      this.$emit(
        'save',
        Object.assign(this.agent, {
          selectedActivities: this.selectedActivities,
          isSpecificCase: true,
        })
      )
    },
  },
  props: ['agent', 'prevActivities'],
  watch: {
    agent() {
      this.selectedActivities = this.agent.activities
        ? this.agent.activitiesDecree
        : []
    },
  },
}
</script>
