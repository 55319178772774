export default {
  getInteractions(object) {
    if (object.acgih) {
      if (object.acgihInfo && object.acgihInfo.limits.length > 1)
        return 'all-interactions'
      if (
        (object.acgih && object.acgih.interactions && object.acgihInfo) ||
        object.acgih.interactions.length <= 1
      )
        return 'one-interaction'
      else return 'select-interaction'
    } else {
      return 'none-interaction'
    }
  },
  getValue(key, object, index) {
    const catalogInfo = 'acgihInfo'
    const catalog = 'acgih'
    if (!object) return ''

    switch (key) {
      case 'searchId':
        return ''
      case 'interactions':
        if (object[catalogInfo]) {
          if (
            object[catalogInfo] &&
            object[catalogInfo].interactions === 'Todas as opções anteriores'
          ) {
            const tooltip = `${object[catalogInfo].limits[index].interactions}`
            return `${tooltip}`
          } else if (object[catalogInfo].interactions) {
            const tooltip = `${object[catalogInfo].interactions}`
            return `${tooltip}`
          }
        }
        if (object[catalog]) {
          if (object[catalog].interactions) {
            if (object[catalog].interactions[0].interactions !== '') {
              const tooltip = `${object[catalog].interactions[0].interactions}`
              return `${tooltip}`
            }
          }
        }
        return '-'
      case 'lt_twa':
        if (object[catalogInfo])
          if (object[catalogInfo].interactions === 'Todas as opções anteriores')
            return object[catalogInfo].limits[index].lt_twa
          else if (object[catalogInfo].limits)
            if (object[catalogInfo].limits.lt_twa)
              return object[catalogInfo].limits.lt_twa

        if (object[catalog])
          if (object[catalog].interactions)
            if (object[catalog].interactions[0].lt_twa)
              if (object[catalog].interactions[0].lt_twa)
                return object[catalog].interactions[0].lt_twa
        return '-'
      case 'lt_stel':
        if (object[catalogInfo])
          if (object[catalogInfo].interactions === 'Todas as opções anteriores')
            return object[catalogInfo].limits[index].lt_stel
          else if (object[catalogInfo].limits)
            if (object[catalogInfo].limits.lt_stel)
              return object[catalogInfo].limits.lt_stel

        if (object[catalog])
          if (object[catalog].interactions)
            if (object[catalog].interactions[0].lt_stel)
              if (object[catalog].interactions[0].lt_stel)
                return object[catalog].interactions[0].lt_stel
        return '-'
      case 'notations':
        if (object[catalogInfo]) {
          if (
            object[catalogInfo].interactions === 'Todas as opções anteriores'
          ) {
            if (object[catalogInfo].limits[index].notations.length >= 2) {
              return object[catalogInfo].limits[index].notations.join(';<br/>')
            } else {
              if (object[catalogInfo].limits[index].notations.toString()) {
                return object[catalogInfo].limits[index].notations.toString()
              }
            }
          } else if (object[catalogInfo].limits) {
            if (object[catalogInfo].limits.notations.length >= 2) {
              return object[catalogInfo].limits.notations.join(';<br/>')
            } else {
              if (object[catalogInfo].limits.notations.toString()) {
                return object[catalogInfo].limits.notations.toString()
              }
            }
          }
        }

        if (object[catalog]) {
          if (object[catalog].interactions) {
            if (object[catalog].interactions[0].notations.length >= 2) {
              return object[catalog].interactions[0].notations.join(';<br/>')
            } else {
              if (object[catalog].interactions[0].notations.toString()) {
                return object[catalog].interactions[0].notations.toString()
              }
            }
          }
        }

        return '-'
      case 'teto_stel':
        if (object[catalogInfo])
          if (object[catalogInfo].interactions === 'Todas as opções anteriores')
            return object[catalogInfo].limits[index].teto_stel
          else if (object[catalogInfo].limits)
            if (object[catalogInfo].limits.teto_stel)
              return object[catalogInfo].limits.teto_stel

        if (object[catalog])
          if (object[catalog].interactions)
            if (object[catalog].interactions[0].teto_stel)
              if (object[catalog].interactions[0].teto_stel)
                return object[catalog].interactions[0].teto_stel
        return '-'
      case 'base_do_tlv':
        if (object[catalogInfo]) {
          if (
            object[catalogInfo].interactions === 'Todas as opções anteriores'
          ) {
            if (object[catalogInfo].limits[index].base_do_tlv.length >= 2) {
              return object[catalogInfo].limits[index].base_do_tlv.join(
                ';<br/>'
              )
            } else {
              if (object[catalogInfo].limits[index].base_do_tlv.toString()) {
                return object[catalogInfo].limits[index].base_do_tlv.toString()
              }
            }
          } else if (object[catalogInfo].limits) {
            if (object[catalogInfo].limits.base_do_tlv.length >= 2) {
              return object[catalogInfo].limits.base_do_tlv.join(';<br/>')
            } else {
              if (object[catalogInfo].limits.base_do_tlv.toString()) {
                return object[catalogInfo].limits.base_do_tlv.toString()
              }
            }
          }
        }

        if (object[catalog]) {
          if (object[catalog].interactions) {
            if (object[catalog].interactions[0].base_do_tlv) {
              if (object[catalog].interactions[0].base_do_tlv.length >= 2) {
                return object[catalog].interactions[0].base_do_tlv.join(
                  ';<br/>'
                )
              } else {
                if (object[catalog].interactions[0].base_do_tlv.toString()) {
                  return object[catalog].interactions[0].base_do_tlv.toString()
                }
              }
            }
          }
        }
        return '-'
      case 'esocial':
        return object.table24
          ? object.table24.code
            ? object.table24.code
            : 'Não'
          : 'Não'
      case 'product':
        return '-'
      default:
        return '-'
    }
  },
  getsearchId(object){
    const casAgente = object.name || object.searchId || object.searchMatched
    const [cas, agente] = casAgente.split(':')

    if(!agente.trim() && object.synonyms && object.synonyms.length > 0){
      return `${object.cas}: ${object.synonyms.find((x) => x.length > 0)}`
    }
    return casAgente
  },
  tableFields: [
    { key: 'product', value: 'Produto' },
    { key: 'searchId', value: 'CAS: Agente' },
    { key: 'interactions', value: 'Fração de coleta' },
    { key: 'lt_twa', value: 'TWA' },
    { key: 'lt_stel', value: 'STEL' },
    { key: 'teto_stel', value: 'Teto' },
    { key: 'notations', value: 'Notações' },
    { key: 'base_do_tlv', value: 'Base do TLV' },
    /*{ key: 'esocial', value: 'ESOCIAL - Tabela 24' }*/
  ]
}
