<template>
  <div>
    <div class='tree'>
      <ul>
        <li>
          <div class='d-flex'>
            <div>
              <span class=''>Reino</span>
              <div class='risk' :style="'height: '+heightDescriptionKingdom+'px'"></div>
            </div>
            <a href='#' class='pl-2' id='descriptionKingdom'>
              {{ taxonomy.Kingdom }} - {{ taxonomy.descriptionKingdom }}
            </a>
          </div>
          <ul class='ml-5'>
            <li>
              <div class='d-flex'>
                <div>
                  <span>Superclasse</span>
                  <div class='risk' :style="'height: '+heightSuperClass+'px'"></div>
                </div>
                <a href='#' class='pl-2' id='superclassDescription'>
                  {{ taxonomy.superClass }} - {{ taxonomy.superclassDescription }}
                </a>
              </div>
              <ul class='ml-5'>
                <li>
                  <div class='d-flex'>
                    <div>
                      <span>Classe</span>
                      <div class='risk' :style="'height: '+heightClassDescription+'px'"></div>
                    </div>
                    <a href='#' class='pl-2' id='classDescription'>
                      {{ taxonomy.class }} - {{ taxonomy.classDescription }}
                    </a>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <div class='tree' v-if='getPhrasesTaxonomy(taxonomy)'>
        <ul>
          <li>
            <div class='d-flex'>
              <div >
                <span style='width: 150px'>Grupos químicos relacionados</span>
                <div class='risk' :style="'height: '+heighPhrasesTaxonomy+'px'"></div>
              </div>
              <a href='#' class='pl-2 w-75' id='phrasesTaxonomy'>
                  {{ getPhrasesTaxonomy(taxonomy) }}
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Taxonomy',
  data() {
    return {
      heightDescriptionKingdom: 0,
      heightSuperClass: 0,
      heightClassDescription: 0,
      heighPhrasesTaxonomy: 0,
    }
  },
  props: {
    taxonomy: { type: Object, default: null }
  },
  methods: {
    getPhrasesTaxonomy(taxonomy) {
      const taxonomyPhrases = new Set()
      if (taxonomy.listOfAlternativeRelatives && taxonomy.listOfAlternativeRelatives.length > 0) {
        taxonomy.listOfAlternativeRelatives.forEach((listOfAlternativeRelatives) => {
          taxonomyPhrases.add(listOfAlternativeRelatives)
        })
      }
      if (taxonomy.listOfSubstituents && taxonomy.listOfSubstituents.length > 0) {
        taxonomy.listOfSubstituents.forEach((listOfSubstituents) => {
          taxonomyPhrases.add(listOfSubstituents)
        })
      }
      if (taxonomy.listOfAncestors && taxonomy.listOfAncestors.length > 0) {
        taxonomy.listOfAncestors.forEach((listOfAncestors) => {
          taxonomyPhrases.add(listOfAncestors)
        })
      }
      return Array.from(taxonomyPhrases).join(', ')
    }
  },
  mounted() {
    this.heightDescriptionKingdom = document.getElementById('descriptionKingdom').clientHeight - 25
    this.heightSuperClass = document.getElementById('superclassDescription').clientHeight - 25
    this.heightClassDescription = document.getElementById('classDescription').clientHeight - 25
    this.heighPhrasesTaxonomy = document.getElementById('phrasesTaxonomy').clientHeight - 25
  }
}
</script>

<style lang='scss' scoped>
.tree > ul > li::before,
.tree > ul > li::after {
  border: 0;
}

.tree li {
  list-style-type: none;
  margin: 0;
  padding: 10px 5px 0 0;
  position: relative;
}

.tree li span {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border: 1px solid #999;
  border-radius: 5px;
  display: inline-block;
  padding: 3px 8px;
  text-decoration: none;
}

.risk {
  background: #999;
  width: 1px;
  display: flex;
  margin-left: 23px;
}

.tree li::before,
li::after {
  content: '';
  left: -25px;
  position: absolute;
  right: auto;
}

.tree li:last-child::before {
  height: 30px;
}

.tree li::before {
  border-left: 1px solid #999;
  bottom: 50px;
  height: 100%;
  top: 0;
  width: 1px;
}


.tree li::after {
  border-top: 1px solid #999;
  height: 20px;
  top: 25px;
  width: 25px;
}
</style>
