<template>
  <div>
    <b-modal
      id='invitationLaboratory'
      ref='invitationLaboratory'
      size='lg'
      hide-footer
      hide-header
      @show='showModal'
      @hidden='hiddenModal'
    >
      <invitation-laboratory
        @close='$refs.invitationLaboratory.hide()'
      >

      </invitation-laboratory>
    </b-modal>

    <div class='d-flex w-100 justify-content-between align-content-end'>
      <div class='buttons' v-for='(lab, i) in getLaboratories' :key='i'>
        <button
          @click='selectLaboratory(lab)'
          :class="lab._id === selectedLaboratory._id ? 'selected_laboratory': 'selected_not_laboratory'"
        >
          <!--<span>Laboratório: {{ lab.name }}</span>-->
          <span>Laboratório: Teste</span>
        </button>
      </div>
      <div>
        <button
          :class="modalSendInvitationShown? 'invite_button_open_modal':''"
          class='invite_button'
          @click='invitationLaboratoryModal()'
        >
          <img width='22' height='22' class='mr-2' src='../../../../assets/images/plus.svg' alt='' />
          <span>Clique para convidar seu laboratório preferido</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core'
import InvitationLaboratory from '@/components/LegalSearch/QuantitativeAssessment/components/InvitationLaboratory'

export default {
  name: 'Laboratories',
  directives: { mask },
  components: {
    InvitationLaboratory
  },
  computed: {
    ...mapGetters('objectivesAnalysis', {
      getObjectivesAnalysis: 'getObjectivesAnalysis',
      getLaboratories: 'getLaboratories'
    })
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      selectedLaboratory: {},
      modalSendInvitationShown: false
    }
  },
  validations() {
    return {}
  },
  async mounted() {
    await this.init()
  },
  methods: {
    ...mapActions('objectivesAnalysis', {
      handleGetObjectivesAnalysis: 'handleGetObjectivesAnalysis',
      handleLaboratories: 'handleLaboratories',
      handleUpdateLaboratoryObjectivesAnalysis: 'handleUpdateLaboratoryObjectivesAnalysis'
    }),
    showModal() {
      this.modalSendInvitationShown = true
    },
    hiddenModal() {
      this.modalSendInvitationShown = false
    },
    invitationLaboratoryModal() {
      this.$refs.invitationLaboratory.show()
    },
    async init() {
      await this.handleLaboratories()
      if (this.getObjectivesAnalysis && this.getObjectivesAnalysis.laboratory) {
        this.selectedLaboratory = this.getObjectivesAnalysis.laboratory
      } else {
        const [laboratory] = this.getLaboratories
        await this.selectLaboratory(laboratory)
        this.selectedLaboratory = laboratory
      }
    },
    async selectLaboratory(laboratory) {
      try {
        await this.handleUpdateLaboratoryObjectivesAnalysis(laboratory._id)
        await this.handleGetObjectivesAnalysis()
        await this.init()
      } catch (e) {

      }
    }
  },
  props: {},
  watch: {}
}
</script>
<style lang='scss' scoped>
.buttons {
  display: inline-block;

  border-bottom: 1px solid #022245;

  button {
    background: transparent;
    padding-right: 20px;
    padding-bottom: 18px;
  }

}

.selected_not_laboratory {
  border-top: none;
  border-right: none;
  border-bottom: none;
  padding-bottom: 10px;
  border-left: none;

}

.selected_laboratory {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #022245;
  border-top: none;
  border-right: none;
  border-bottom: 3px solid #022245;
  border-left: none;
  padding-bottom: 10px;
  border-radius: 2px;
}

.invite_button {
  background: #CBD63E;
  border: 2px solid #CBD63E;
  padding: 8px 12px;
  border-radius: 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #022245;
}

.invite_button_open_modal {
  border: 2px solid #022245;
  background: transparent;
}
</style>
