<template>
  <div class='mt-4'>
    <quantitative-assessment-modal
      @save='saveSelectedActivities'
      :agent='selectedAgent'
      :laboratory='getObjectivesAnalysis.laboratory.name'
      :selectedEvaluationForm='selectedEvaluationForm'
    >
    </quantitative-assessment-modal>

    <edit-vase-modal
      @save='saveSelectedActivities'
      :evaluationForm='agentEditFlow'
    >
    </edit-vase-modal>

    <edit-volume-modal
      @save='saveSelectedActivities'
      :evaluationForm='agentEditFlow'
      :laboratory='getObjectivesAnalysis.laboratory.name'
    >
    </edit-volume-modal>

    <OrientationVideos
      :orientationVideos="orientationVideos"
      :agent="agent"
      @clear="orientationVideos = []"
    />

    <table class='text-center table b-table table-bordered custom-table'>
      <thead class='sticky '>
        <tr >
          <th colspan='9' class='container-tab-buttons' >
            <tab-buttons/>
          </th>
        </tr>
        <tr class='headerTable'>
          <th class='width-10-percent'>Produto</th>
          <th class='width-20-percent'>CAS: Agente</th>
          <th class='width-10-percent'>Necessário para atender</th>
          <th class='width-10-percent'>Metodologia</th>
          <th class='width-10-percent'>Tipo de Avaliação</th>
          <th class='width-10-percent'>Vazão (L/min)</th>
          <th class='width-10-percent'>Volume (L)</th>
          <th class='width-10-percent'>Defina sua vazão e volume</th>
          <th class='width-10-percent'>Planejamento Sugerido</th>
        </tr>
      </thead>

      <tbody>
      <template v-for="product in legalConsultationAgentsGroupedByProduct">
        <template
          v-for="item in product.legalSearches"
        >
          <tr :key="item.legalSearchId">
            <td
              v-if='item.index < 1 || product.product._id === `-`'
              :rowspan="rowspan(product, item)"
            >
              <span> {{item.product ? item.product.name : '-'}}</span>
            </td>
          </tr>

          <tr :key='`${item.legalSearchId}-${product.product._id}-${item.index}`'>
            <!--Quando o agente não tem informações de nenhum laboratório selecionado pelo usuário.-->
            <template
              v-if='!item[getObjectivesAnalysis.laboratory.name] ||
              item[getObjectivesAnalysis.laboratory.name].length <= 0'
            >
              <td>
                <template>
                  <b-tooltip
                    :target="`${item.cas}-${item.index}`"
                    custom-class="custom-tooltip"
                    placement='auto'
                    triggers='hover'
                  >
                    <section
                      v-if='(item.attachment11 && item.attachment11.group) || item.nameAttachment11'
                    >
                      <span
                        class='p-2 text-white'
                        style='font-size: 15px'
                      >
                      Este agente consta na NR 15 com o seguinte nome:
                        {{ item.attachment11 && item.attachment11.group
                        ? item.attachment11.group
                        : item.nameAttachment11 ? item.nameAttachment11: '-'
                        }}
                      </span> <br/><br/>
                    </section>

                    <section
                      class='p-2'
                      v-if='item.acgih && item.agent'
                    >
                      <span
                        class='text-white'
                        style='font-size: 15px'
                      >
                        Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                        {{ item.agent ? item.agent : '-' }}
                      </span>
                      <br/>
                      <br/>
                    </section>

                    <a
                      :href='`https://pubchem.ncbi.nlm.nih.gov/#query=${item.cas}`'
                      target='_blank'
                      class='link-pubchem-cas ml-2'
                    >
                      <span>
                        Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{item.cas}}
                      </span>
                    </a>
                  </b-tooltip>

                  <a
                    href='#'
                    class='hover_links word-break'
                    :id="`${item.cas}-${item.index}`"
                  >
                    <span class='word-break'>{{ item.name || item.searchId || item.searchMatched }}</span>

                    <b-icon
                      style='margin-bottom: -1px'
                      class='ml-1'
                      font-scale="1.2"
                      icon="info-circle-fill"
                      variant="info"
                    >
                    </b-icon>
                  </a>

                  <section v-if='item.videosOrientacaoAbaAvaliacaoQuantitativa && item.videosOrientacaoAbaAvaliacaoQuantitativa.length > 0'>
                    <button
                      class='btn-orientations mt-2'
                      @click='showModalOrientationVideos(model.getsearchId(item), item.videosOrientacaoAbaLaudoInsalubridade)'
                    >
                      <span>
                        Ver orientações <br/> especificas
                      </span>
                    </button>
                  </section>
                </template>
              </td>

              <td colspan='8' style='font-size: 15px'>
                <p>O agente não possui informações para a avaliação quantitativa para este laboratório.</p>
              </td>
            </template>

            <!--Quando o agente já tem as informação do tipo de avaliação selecionada do laboratório selecionado pelo usuário.-->
            <template
              v-else-if="item[`evaluationFormUsed${getObjectivesAnalysis.laboratory.name}`] &&
              item[`evaluationFormUsed${getObjectivesAnalysis.laboratory.name}`]['laboratory'] &&
              item[`evaluationFormUsed${getObjectivesAnalysis.laboratory.name}`]['laboratory'].name &&
              item[`evaluationFormUsed${getObjectivesAnalysis.laboratory.name}`]['laboratory'].name === getObjectivesAnalysis.laboratory.name"
            >
              <td>
                <template>
                  <b-tooltip
                    :target='item.cas'
                    custom-class="custom-tooltip"
                    placement='auto'
                    triggers='hover'
                  >
                    <section
                      v-if='(item.attachment11 && item.attachment11.group) || item.nameAttachment11'
                    >
                      <span
                        class='p-2 text-white'
                        style='font-size: 15px'
                      >
                      Este agente consta na NR 15 com o seguinte nome:
                        {{ item.attachment11 && item.attachment11.group
                        ? item.attachment11.group
                        : item.nameAttachment11 ? item.nameAttachment11: '-'
                        }}
                      </span> <br/><br/>
                    </section>

                    <section
                      class='p-2'
                      v-if='item.acgih && item.agent'
                    >
                      <span
                        class='text-white'
                        style='font-size: 15px'
                      >
                        Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                        {{ item.agent ? item.agent : '-' }}
                      </span>
                      <br/>
                      <br/>
                    </section>

                    <a
                      :href='`https://pubchem.ncbi.nlm.nih.gov/#query=${item.cas}`'
                      target='_blank'
                      class='link-pubchem-cas ml-2'
                    >
                      <span>
                        Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{item.cas}}
                      </span>
                    </a>
                  </b-tooltip>

                  <a
                    href='#'
                    class='hover_links word-break'
                    :id='item.cas'
                  >
                    <span class='word-break'>{{ item.name || item.searchId || item.searchMatched }}</span>

                    <b-icon
                      style='margin-bottom: -1px'
                      class='ml-1'
                      font-scale="1.2"
                      icon="info-circle-fill"
                      variant="info"
                    >
                    </b-icon>
                  </a>

                  <section v-if='item.videosOrientacaoAbaAvaliacaoQuantitativa && item.videosOrientacaoAbaAvaliacaoQuantitativa.length > 0'>
                    <button
                      class='btn-orientations mt-2'
                      @click='showModalOrientationVideos(model.getsearchId(item), item.videosOrientacaoAbaLaudoInsalubridade)'
                    >
                      <span>
                        Ver orientações <br/> especificas
                      </span>
                    </button>
                  </section>
                </template>
              </td>
              <template>
                <td
                  v-for='field in model.tableFields'
                  :key='`field_${field.key}`'
                  :class='`field_${field.key}`'
                >
                  <template v-if="field.key === 'evaluationForm'">
                    <b-tooltip
                      :target="`evaluationForm_${item.cas}`"
                      placement="lefttop"
                      triggers="hover"
                    >
                      <span class='text-white'>Clique aqui pra editar o tipo de avaliação</span>
                    </b-tooltip>

                    <p
                      @click='displayActivities(item)'
                      class='hover_links'
                      href='#'
                      :id="`evaluationForm_${item.cas}`"
                      v-if='!model.justAformEvaluation(item, getObjectivesAnalysis.laboratory.name)'
                    >
                      <span
                        style='font-size: 15px'
                      >
                        {{model.seekEvaluationForm(field.key, item, getObjectivesAnalysis)}}
                      </span>

                      <b-icon
                        class='ml-1'
                        font-scale="1.2"
                        icon="info-circle-fill"
                        variant="info"
                      >
                      </b-icon>
                    </p>

                    <p
                      v-else
                      style='font-size: 15px'
                      v-html='model.seekEvaluationForm(field.key, item, getObjectivesAnalysis)'
                    >
                    </p>
                  </template>

                  <template v-else-if="field.key === 'defineVolumeFlow'">
                    <div class='ml-1 d-flex align-items-center justify-content-center flex-nowrap' style='font-size: 15px'>
                      <p
                        v-if='model.returnFlowEnteredByUser(item, getObjectivesAnalysis) && model.returnVolumeEnteredByUser(item, getObjectivesAnalysis)'
                      >
                        {{ model.returnFlowEnteredByUser(item, getObjectivesAnalysis) }}
                        <br />
                        {{ model.returnVolumeEnteredByUser(item, getObjectivesAnalysis) }}
                      </p>

                      <p
                        v-else-if='model.returnFlowEnteredByUser(item, getObjectivesAnalysis)'
                      >
                        {{ model.returnFlowEnteredByUser(item, getObjectivesAnalysis) }}
                        <br />
                        {{ model.returnMaximumVolume(item, getObjectivesAnalysis) }}
                      </p>

                      <p
                        v-else-if='model.returnVolumeEnteredByUser(item, getObjectivesAnalysis)'
                      >
                        Vazão: Calculada e mostrada ao lado L/min
                        <br />
                        {{ model.returnVolumeEnteredByUser(item, getObjectivesAnalysis) }}
                      </p>

                      <p>
                        <i
                          @click='editVolumeEvaluationFormFlow(item)'
                          v-b-tooltip.lefttop.hover
                          class='fas fa-edit icon fa-lg'
                          aria-hidden='true'
                          title='Clique aqui para editar vazão e volume.'
                        ></i>
                      </p>
                    </div>
                  </template>

                  <template v-else-if="field.key === 'suggestedPlanning'">
                    <div class='d-flex flex-column justify-content-center '>
                      <p
                        class='text-center'
                        style='font-size: 15px'
                        v-html='model.planejamento_de_amostragem(item[`evaluationFormUsed${getObjectivesAnalysis.laboratory.name}`], getObjectivesAnalysis)'
                      >
                      </p>
                    </div>
                  </template>

                  <template v-else-if="field.key === 'necessarioParaAtender'">
                    <div class='d-flex flex-column justify-content-center '>
                      <p
                        style='font-size: 15px'
                        v-for="necessarioParaAtender in model.seekEvaluationForm('necessarioParaAtender', item, getObjectivesAnalysis)"
                        :key="`${necessarioParaAtender.id}_2`"
                      >
                        <img
                          width='15'
                          height='14'
                          v-bind:src="require(`../../../../assets/images/${necessarioParaAtender.img}.svg`)"
                          alt=''
                        />
                        {{necessarioParaAtender.text}}
                      </p>
                    </div>
                  </template>

                  <template v-else>
                    <p
                      style='font-size: 15px'
                      v-html='model.seekEvaluationForm(field.key, item, getObjectivesAnalysis)'>
                    </p>
                  </template>
                </td>
              </template>
            </template>

            <!--Quando o agente tem apenas um tipo de avaliação do laboratório selecionado pelo usuário.-->
            <template v-else-if='model.justAformEvaluation(item, getObjectivesAnalysis.laboratory.name)'>
              <td>
                <template>
                  <b-tooltip
                    :target='item.cas'
                    custom-class="custom-tooltip"
                    placement='auto'
                    triggers='hover'
                  >
                    <section
                      v-if='(item.attachment11 && item.attachment11.group) || item.nameAttachment11'
                    >
                      <span
                        class='p-2 text-white'
                        style='font-size: 15px'
                      >
                      Este agente consta na NR 15 com o seguinte nome:
                        {{ item.attachment11 && item.attachment11.group
                        ? item.attachment11.group
                        : item.nameAttachment11 ? item.nameAttachment11: '-'
                        }}
                      </span> <br/><br/>
                    </section>

                    <section
                      class='p-2'
                      v-if='item.acgih && item.agent'
                    >
                      <span
                        class='text-white'
                        style='font-size: 15px'
                      >
                        Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                        {{ item.agent ? item.agent : '-' }}
                      </span>
                      <br/>
                      <br/>
                    </section>

                    <a
                      :href='`https://pubchem.ncbi.nlm.nih.gov/#query=${item.cas}`'
                      target='_blank'
                      class='link-pubchem-cas ml-2'
                    >
                      <span>
                        Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{item.cas}}
                      </span>
                    </a>
                  </b-tooltip>

                  <a
                    href='#'
                    class='hover_links word-break'
                    :id='item.cas'
                  >
                    <span class='word-break'>{{ item.name || item.searchId || item.searchMatched }}</span>

                    <b-icon
                      style='margin-bottom: -1px'
                      class='ml-1'
                      font-scale="1.2"
                      icon="info-circle-fill"
                      variant="info"
                    >
                    </b-icon>
                  </a>

                  <section v-if='item.videosOrientacaoAbaAvaliacaoQuantitativa && item.videosOrientacaoAbaAvaliacaoQuantitativa.length > 0'>
                    <button
                      class='btn-orientations mt-2'
                      @click='showModalOrientationVideos(model.getsearchId(item), item.videosOrientacaoAbaLaudoInsalubridade)'
                    >
                      <span>
                        Ver orientações <br/> especificas
                      </span>
                    </button>
                  </section>
                </template>
              </td>
              <template>
                <td
                  v-for='field in model.tableFields'
                  :key='`field_${field.key}`'
                  :class='`field_${field.key}`'
                >
                  <template v-if="field.key === 'suggestedPlanning'">
                    <div class='d-flex flex-column justify-content-center text-center'>
                      <p
                        class='text-center'
                        style='font-size: 15px'
                        v-html='model.planejamento_de_amostragem(item[getObjectivesAnalysis.laboratory.name][0], getObjectivesAnalysis)'
                      >
                      </p>

                    </div>
                  </template>

                  <template v-else-if="field.key === 'defineVolumeFlow'">
                    <div class='ml-1 d-flex align-items-start justify-content-center' style='font-size: 15px'>
                      <p
                        v-if='model.returnFlowEnteredByUser(item, getObjectivesAnalysis) && model.returnVolumeEnteredByUser(item, getObjectivesAnalysis)'
                      >
                        {{ model.returnFlowEnteredByUser(item, getObjectivesAnalysis) }}
                        <br />
                        {{ model.returnVolumeEnteredByUser(item, getObjectivesAnalysis) }}
                      </p>

                      <p
                        v-else-if='model.returnFlowEnteredByUser(item, getObjectivesAnalysis)'
                      >
                        {{ model.returnFlowEnteredByUser(item, getObjectivesAnalysis) }}
                        <br />
                        {{ model.returnMaximumVolume(item, getObjectivesAnalysis) }}
                      </p>

                      <p
                        v-else-if='model.returnVolumeEnteredByUser(item, getObjectivesAnalysis)'
                      >
                        Vazão: Calculada e mostrada ao lado L/min
                        <br />
                        {{ model.returnVolumeEnteredByUser(item, getObjectivesAnalysis) }}
                      </p>
                      <p>
                        <i
                          @click='editVolumeFirstEvaluationForm(item)'
                          v-b-tooltip.lefttop.hover
                          class='fas fa-edit icon fa-lg'
                          aria-hidden='true'
                          title='Clique aqui para editar vazão e volume.'
                        ></i>
                      </p>
                    </div>
                  </template>

                  <template v-else-if="field.key === 'necessarioParaAtender'">
                    <div class='d-flex flex-column justify-content-center '>
                      <p
                        style='font-size: 15px'
                        v-for="necessarioParaAtender in model.seekFirstEvaluationForm('necessarioParaAtender', item, getObjectivesAnalysis)"
                        :key="`${necessarioParaAtender.id}_2w`"
                      >
                        <img
                          width='15'
                          height='14'
                          v-bind:src="require(`../../../../assets/images/${necessarioParaAtender.img}.svg`)"
                          alt=''
                        />
                        {{necessarioParaAtender.text}}
                      </p>
                    </div>
                  </template>

                  <template v-else>
                    <p
                      style='font-size: 15px'
                      v-html='model.seekFirstEvaluationForm(field.key, item, getObjectivesAnalysis)'
                    >
                    </p>
                  </template>
                </td>
              </template>
            </template>

            <!--Quando o agente tem mais de um tipo de avaliação do laboratório selecionado pelo usuário.-->
            <template v-else-if='model.verifyAgentHasMultipleRatings(item, getObjectivesAnalysis.laboratory.name)'>
              <td>
                <template>
                  <b-tooltip
                    :target='item.cas'
                    custom-class="custom-tooltip"
                    placement='auto'
                    triggers='hover'
                  >
                    <section
                      v-if='(item.attachment11 && item.attachment11.group) || item.nameAttachment11'
                    >
                      <span
                        class='p-2 text-white'
                        style='font-size: 15px'
                      >
                      Este agente consta na NR 15 com o seguinte nome:
                        {{ item.attachment11 && item.attachment11.group
                        ? item.attachment11.group
                        : item.nameAttachment11 ? item.nameAttachment11: '-'
                        }}
                      </span> <br/><br/>
                    </section>

                    <section
                      class='p-2'
                      v-if='item.acgih && item.agent'
                    >
                      <span
                        class='text-white'
                        style='font-size: 15px'
                      >
                        Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                        {{ item.agent ? item.agent : '-' }}
                      </span>
                      <br/>
                      <br/>
                    </section>

                    <a
                      :href='`https://pubchem.ncbi.nlm.nih.gov/#query=${item.cas}`'
                      target='_blank'
                      class='link-pubchem-cas ml-2'
                    >
                      <span>
                        Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{item.cas}}
                      </span>
                    </a>
                  </b-tooltip>

                  <a
                    href='#'
                    class='hover_links word-break'
                    :id='item.cas'
                  >
                    <span class='word-break'>{{ item.name || item.searchId || item.searchMatched }}</span>

                    <b-icon
                      style='margin-bottom: -1px'
                      class='ml-1'
                      font-scale="1.2"
                      icon="info-circle-fill"
                      variant="info"
                    >
                    </b-icon>
                  </a>

                  <section v-if='item.videosOrientacaoAbaAvaliacaoQuantitativa && item.videosOrientacaoAbaAvaliacaoQuantitativa.length > 0'>
                    <button
                      class='btn-orientations mt-2'
                      @click='showModalOrientationVideos(model.getsearchId(item), item.videosOrientacaoAbaLaudoInsalubridade)'
                    >
                      <span>
                        Ver orientações <br/> especificas
                      </span>
                    </button>
                  </section>
                </template>
              </td>
              <template>
                <td>
                  <template>
                    <p
                      style='font-size: 15px'
                      v-for="necessarioParaAtender in model.seekEvaluationForm('necessarioParaAtender', item, getObjectivesAnalysis)"
                      :key="`${necessarioParaAtender.id}_6`"
                    >
                      <img
                        width='15'
                        height='14'
                        v-bind:src="require(`../../../../assets/images/${necessarioParaAtender.img}.svg`)"
                        alt=''
                      />
                      {{necessarioParaAtender.text}}
                    </p>
                  </template>
                </td>

                <td colspan='6' class=''>
                  <div @click='displayActivities(item)'>
                    <p class='selectable-row-text pt-2'>Agente necessita de forma de avaliação, selecione aqui</p>
                  </div>
                </td>
              </template>
            </template>

            <!--Quando o agente não tem nenhuma da opções descritas acima-->
            <template v-else>
              <td>
                <template>
                  <b-tooltip
                    :target='item.cas'
                    custom-class="custom-tooltip"
                    placement='auto'
                    triggers='hover'
                  >
                    <section
                      v-if='(item.attachment11 && item.attachment11.group) || item.nameAttachment11'
                    >
                      <span
                        class='p-2 text-white'
                        style='font-size: 15px'
                      >
                      Este agente consta na NR 15 com o seguinte nome:
                        {{ item.attachment11 && item.attachment11.group
                        ? item.attachment11.group
                        : item.nameAttachment11 ? item.nameAttachment11: '-'
                        }}
                      </span> <br/><br/>
                    </section>

                    <section
                      class='p-2'
                      v-if='item.acgih && item.agent'
                    >
                      <span
                        class='text-white'
                        style='font-size: 15px'
                      >
                        Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                        {{ item.agent ? item.agent : '-' }}
                      </span>
                      <br/>
                      <br/>
                    </section>

                    <a
                      :href='`https://pubchem.ncbi.nlm.nih.gov/#query=${item.cas}`'
                      target='_blank'
                      class='link-pubchem-cas ml-2'
                    >
                      <span>
                        Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{item.cas}}
                      </span>
                    </a>
                  </b-tooltip>

                  <a
                    href='#'
                    class='hover_links word-break'
                    :id='item.cas'
                  >
                    <span class='word-break'>{{ item.name || item.searchId || item.searchMatched }}</span>

                    <b-icon
                      style='margin-bottom: -1px'
                      class='ml-1'
                      font-scale="1.2"
                      icon="info-circle-fill"
                      variant="info"
                    >
                    </b-icon>
                  </a>

                  <section v-if='item.videosOrientacaoAbaAvaliacaoQuantitativa && item.videosOrientacaoAbaAvaliacaoQuantitativa.length > 0'>
                    <button
                      class='btn-orientations mt-2'
                      @click='showModalOrientationVideos(model.getsearchId(item), item.videosOrientacaoAbaLaudoInsalubridade)'
                    >
                      <span>
                        Ver orientações <br/> especificas
                      </span>
                    </button>
                  </section>
                </template>
              </td>
              <td colspan='8' class=''>
                <p>-</p>
              </td>
            </template>
          </tr>
        </template>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import QuantitativeAssessment from '@/models/QuantitativeAssessment'
import QuantitativeAssessmentModal
  from '@/components/LegalSearch/QuantitativeAssessment/components/QuantitativeAssessmentModal'
import EditVaseModal from '@/components/LegalSearch/QuantitativeAssessment/components/EditVaseModal'
import EditVolumeModal from '@/components/LegalSearch/QuantitativeAssessment/components/EditVolumeModal'
import { mapActions, mapGetters } from 'vuex'
import eventBus from '../../../../utils/eventBus'
import TabButtons from '@/components/LegalSearch/components/TabButtons.vue'
import OrientationVideos from '@/components/LegalSearch/Modal/OrientationVideos.vue'

export default {
  data() {
    return {
      generateImpression: false,
      agentEnabledAgentInformationEception: {},
      model: QuantitativeAssessment,
      selectedAgent: {},
      selectedEvaluationForm: {},
      agentEditFlow: {},
      legalSearchId: null,
      scrollBarPosition: 0,
      message: 0,
      orientationVideos: [],
      agent: "",
    }
  },
  components: {
    OrientationVideos,
    TabButtons,
    QuantitativeAssessmentModal,
    EditVaseModal,
    EditVolumeModal
  },
  computed: {
    summary() {
      return this.$store.getters['legalSearch/summary']
    },
    legalConsultationAgentsGroupedByProduct() {
      return this.$store.getters['legalSearch/legalConsultationAgentsGroupedByProduct']
    },
    user() {
      return this.$store.getters['user/current']
    },
    importedCompany() {
      return this.$store.getters['companyLegalConsultation/importedCompany']
    },
    ...mapGetters('rationaleGroups', {
      getGroupFundamentationUnhealthy: 'getGroupFundamentationUnhealthy'
    }),
    ...mapGetters('phraseListH', {
      getListOfPhrasesControlBanding: 'getListOfPhrasesControlBanding',
      getListOfPrecautionaryPhrasesControlBanding: 'getListOfPrecautionaryPhrasesControlBanding'
    }),
    ...mapGetters('objectivesAnalysis', {
      getObjectivesAnalysis: 'getObjectivesAnalysis'
    })
  },
  methods: {
    ...mapActions('reportsDownloads', {
      handleCreateReportDownloads: 'handleCreateReportDownloads'
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb'
    }),
    ...mapActions('event', {
      handlGetEventActive: 'handlGetEventActive'
    }),
    ...mapActions('legalSearch', {
      exportingExcel: 'exportingExcel'
    }),

    showModalOrientationVideos(agent, orientationVideos){
      this.orientationVideos = orientationVideos
      this.agent = agent
      setTimeout(() => {
        this.$bvModal.show('orientationVideos')
      },100)
    },

    rowspan(legalConsultationAgentsGroupedByProduct, legalSearch){
      if(legalConsultationAgentsGroupedByProduct.product._id === `-`){
        return 2
      } else {
        return (legalConsultationAgentsGroupedByProduct.legalSearches.length * 2)
      }

    },
    displayActivities(item) {
      this.selectedAgent = {}
      this.selectedEvaluationForm = {}
      this.selectedAgent = item
      this.legalSearchId = item.legalSearchId
      this.selectedEvaluationForm =
        item[`evaluationFormUsed${this.getObjectivesAnalysis.laboratory.name}`] &&
        item[`evaluationFormUsed${this.getObjectivesAnalysis.laboratory.name}`]['evaluationForm'] ?
          item[`evaluationFormUsed${this.getObjectivesAnalysis.laboratory.name}`]['evaluationForm'] : null
      this.scrollBarPosition = document.documentElement.scrollTop
      setTimeout(() => {
        this.$bvModal.show('quantitativeAssessment-modal')
      }, 100)
      this.message = 'Tipo de Avaliação atualizado com sucesso.'
    },

    async saveSelectedActivities(evaluationForm) {
      const agent = this.summary.find((x) => x.legalSearchId === this.legalSearchId)[`evaluationFormUsed${this.getObjectivesAnalysis.laboratory.name}`]

      if (agent) {
        if (agent.hasOwnProperty('enabledAgentInformationReportInsalubrity')) {
          evaluationForm.enabledAgentInformationReportInsalubrity = agent.enabledAgentInformationReportInsalubrity
        }

        if (agent.hasOwnProperty('enabledAgentInformationACGIH')) {
          evaluationForm.enabledAgentInformationACGIH = agent.enabledAgentInformationACGIH
        }

        if (agent.hasOwnProperty('enabledAgentInformationLTCAT')) {
          evaluationForm.enabledAgentInformationLTCAT = agent.enabledAgentInformationLTCAT
        }

        if (this.importedCompany && this.importedCompany._id) {
          this.$bvToast.toast(`As informações da empresa foram atualizadas.`, {
            title: 'Atenção',
            variant: 'success',
            toaster: 'b-toaster-top-center',
            autoHideDelay: 6000,
          });
        }
      }

      const body = {
        legalSearchId: this.legalSearchId,
        evaluationForm,
        laboratory: this.getObjectivesAnalysis.laboratory.name
      }

      this.$bvModal.hide('quantitativeAssessment-modal')
      this.$bvModal.hide('edit-vase-modal')
      this.$bvModal.hide('edit-volume-modal')
      await this.$store.dispatch('legalSearch/saveEvaluationForm', body)
      await this.$store.dispatch('legalSearch/load', this.user.id)
      this.legalSearchId = null
      window.scroll(0, this.scrollBarPosition)
      this.$bvToast.toast(this.message, {
        title: 'Aviso',
        variant: 'success',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
    },

    editFlowFirstEvaluationForm(agent) {
      this.legalSearchId = agent.legalSearchId
      const [evaluationForm] = agent[this.getObjectivesAnalysis.laboratory.name]
      this.showModalEditFlowDingleEvaluationForm(evaluationForm)
    },

    async showModalEditFlowDingleEvaluationForm(evaluationForm) {
      this.agentEditFlow = {}
      this.agentEditFlow = evaluationForm
      this.$bvModal.show('edit-vase-modal')
      this.scrollBarPosition = document.documentElement.scrollTop
      this.message = 'Vazão atualizada com sucesso.'
    },

    editVolumeFirstEvaluationForm(agent) {
      this.legalSearchId = agent.legalSearchId
      const [evaluationForm] = agent[this.getObjectivesAnalysis.laboratory.name]
      this.showModalEditVolumeDingleEvaluationForm(evaluationForm)
    },

    editVolumeEvaluationFormFlow(agent) {
      this.legalSearchId = agent.legalSearchId
      const evaluationForm = agent[`evaluationFormUsed${this.getObjectivesAnalysis.laboratory.name}`]
      this.showModalEditVolumeDingleEvaluationForm(evaluationForm)
    },

    async showModalEditVolumeDingleEvaluationForm(evaluationForm) {
      this.agentEditFlow = {}
      evaluationForm.legalSearchId = this.legalSearchId
      this.agentEditFlow = evaluationForm
      this.$bvModal.show('edit-volume-modal')
      this.scrollBarPosition = document.documentElement.scrollTop
      this.message = 'Vazão e volume atualizado com sucesso.'
    },

    enabledAgentInformationEceptionFirstEvaluationForm(agent) {
      this.legalSearchId = agent.legalSearchId
      let [evaluationForm] = agent[this.getObjectivesAnalysis.laboratory.name]
      evaluationForm.enabledAgentInformationReportInsalubrity = true
      evaluationForm.enabledAgentInformationACGIH = true
      evaluationForm.enabledAgentInformationLTCAT = true
      this.scrollBarPosition = document.documentElement.scrollTop
      this.saveSelectedActivities(evaluationForm)
    },

    enabledAgentInformationEceptionEvaluationForm(agent) {
      this.legalSearchId = agent.legalSearchId
      let evaluationForm = agent[`evaluationFormUsed${this.getObjectivesAnalysis.laboratory.name}`]
      if (!evaluationForm) {
        evaluationForm = {}
      }
      evaluationForm.enabledAgentInformationReportInsalubrity = true
      evaluationForm.enabledAgentInformationACGIH = true
      evaluationForm.enabledAgentInformationLTCAT = true
      this.scrollBarPosition = document.documentElement.scrollTop
      this.saveSelectedActivities(evaluationForm)
    }
  },
  async mounted() {
    const { iAgree } = this.getObjectivesAnalysis
    if (!iAgree) {
      this.$bvModal.show('quantitativeAssessmentInformation')
    }
  },
  beforeDestroy() {
    eventBus.$off('generateReportInsalubrity')
  },
  created() {
  }
}
</script>

<style scoped>

.icon {
  cursor: pointer;
  margin-left: 7px;
}

.hover_links {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #04A8F3;
  cursor: pointer;
}

.hover_links:hover {
  text-decoration: underline;
}
.sticky {
  position: sticky;
  top: 10px;
  z-index: 10;
}
.container-tab-buttons{
  padding: 0 0 5px 0!important;
}
.headerTable {
  vertical-align: middle;
  background: #94D5F3;
  padding: 10px;
  top: 50px;
}

.width-20-percent {
  width: 20% !important;
}

.width-10-percent {
  width: 10% !important;
}

.imprimir {
  color: var(--blue);
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
}

.imprimir:hover {
  text-decoration: underline
}

.loading {
  width: 1.5rem;
  height: 1.5rem;
}

.selectable-row {
  vertical-align: middle;
  background: rgba(4, 168, 243, 0.1)
}

.selectable-row-text {
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #022245 !important;
  text-decoration: underline
}

.message_not_evaluate {
  font-size: 15px;
  margin-bottom: 0.1em;
}

.selectable-row-text:hover {
  text-decoration: underline
}

td {
  vertical-align: middle;
}

.field_ps {
  text-align: left;
  font-size: 12px;
}
</style>
