<template>
  <section class='d-flex flex-wrap align-items-end'>
    <section class='tabs-inventory d-flex justify-content-between w-100'>
      <button
        class='btn-summary'
        :class="tabView === 'Summary' ? 'active' : 'button-left'"
        :style="tabView === 'Summary' ? 'border-radius: 0 8px 8px 0!important;' : ''"
        @click="changeView('Summary', 'CLICOU_ABA_SELECAO_DE_AGENTES', 'Seleção de agentes')"
      >
        Seleção de agentes
      </button>

      <button
        class='btn-acgih-ltcat-pcmso'
        v-if='importedCompany && importedCompany._id'
        :class="tabView === 'Gro' ? 'active' : 'button'"
        @click="changeView('Gro', 'CLICOU_ABA_GRO', 'GRO')"
      >
        GRO
      </button>

      <button
        class='btn-insalubrity'
        :class="tabView === 'Insalubrity' ? 'active' : 'button'"
        @click="changeView('Insalubrity', 'CLICOU_ABA_LAUDO_DE_INSALUBRIDADE', 'Laudo de insalubridade')"
      >
        Laudo de insalubridade
      </button>


      <button
        class='btn-acgih-ltcat-pcmso'
        :class="tabView === 'Acigh' ? 'active' : 'button'"
        @click="changeView('Acigh', 'CLICOU_ABA_ACGIH', 'ACGIH')"
      >
        ACGIH
      </button>

      <button
        class='btn-acgih-ltcat-pcmso'
        :class="tabView === 'Ltcat' ? 'active' : 'button'"
        @click="changeView('Ltcat', 'CLICOU_ABA_LTCAT', 'LTCAT')"
      >
        LTCAT
      </button>

      <button
        class='btn-quantitativeAssessment'
        :class="tabView === 'QuantitativeAssessment' ? 'active' : 'button'"
        @click="changeView('QuantitativeAssessment', 'CLICOU_ABA_AVALIACAO_QUANTITATIVA', 'quantitativeAssessment')"
      >
        Avaliação Quantitativa
      </button>

      <button
        class='btn-acgih-ltcat-pcmso'
        :class="tabView === 'Pcmso' ? 'active' : 'button-right'"
        :style="tabView === 'Summary' ? 'border-radius: 8px 0 0 8px' : ''"
        @click="changeView('Pcmso', 'CLICOU_ABA_PCMSO', 'Pcmso')"
      >
        PCMSO
      </button>
    </section>

    <section class='w-100 mb-1 '>
      <filter-agents-legal-consultation></filter-agents-legal-consultation>
    </section>

    <section v-if='selectedAgents.length > 0' class='d-flex justify-content-between w-100 collapse'>
      <section class=''>
        <base-button
          class='my-2'
          outlineDanger
          @click="callGlobalCustomEvent('analyzeAgentsDelete')"
        >
          <i class="fa fa-trash"></i>
          <span class='ml-2'>
          Apagar selecionados
        </span>
        </base-button>
      </section>

      <section class='d-flex' v-if="releaseCompanyMode(user)">
        <b-overlay
          v-if='importedCompany'
          :show='disableProduct'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class='d-inline-block w-100'
        >
          <base-button
            :disabled='selectedMoreThanOneProduct'
            @click="callGlobalCustomEvent('showModalAddProduct')"
            class='my-2 mr-2'
            outlinePrimaryNavyBlue

          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <g clip-path="url(#clip0_1728_1149)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1.66675V7.08341C10.0001 7.39415 10.1158 7.69374 10.3247 7.92376C10.5336 8.15379 10.8207 8.29775 11.13 8.32758L11.25 8.33341H16.6667V16.6667C16.6668 17.0872 16.508 17.4922 16.2221 17.8005C15.9362 18.1089 15.5443 18.2977 15.125 18.3292L15 18.3334H5.00004C4.57956 18.3335 4.17457 18.1747 3.86625 17.8888C3.55793 17.6029 3.36908 17.211 3.33754 16.7917L3.33337 16.6667V3.33341C3.33324 2.91293 3.49205 2.50794 3.77795 2.19962C4.06386 1.89131 4.45574 1.70245 4.87504 1.67091L5.00004 1.66675H10ZM10 9.58341C9.79593 9.58344 9.59893 9.65838 9.4464 9.79401C9.29387 9.92964 9.19642 10.1165 9.17254 10.3192L9.16671 10.4167V11.6667H7.91671C7.70431 11.667 7.50001 11.7483 7.34557 11.8941C7.19112 12.0399 7.09818 12.2392 7.08573 12.4512C7.07328 12.6633 7.14227 12.8721 7.27859 13.0349C7.41492 13.1978 7.60829 13.3025 7.81921 13.3276L7.91671 13.3334H9.16671V14.5834C9.16694 14.7958 9.24827 15.0001 9.39408 15.1546C9.53989 15.309 9.73917 15.4019 9.9512 15.4144C10.1632 15.4268 10.372 15.3579 10.5349 15.2215C10.6978 15.0852 10.8025 14.8918 10.8275 14.6809L10.8334 14.5834V13.3334H12.0834C12.2958 13.3332 12.5001 13.2518 12.6545 13.106C12.809 12.9602 12.9019 12.761 12.9144 12.5489C12.9268 12.3369 12.8578 12.1281 12.7215 11.9652C12.5852 11.8023 12.3918 11.6977 12.1809 11.6726L12.0834 11.6667H10.8334V10.4167C10.8334 10.1957 10.7456 9.98377 10.5893 9.82749C10.433 9.67121 10.2211 9.58341 10 9.58341ZM11.6667 1.70258C11.9362 1.75979 12.1873 1.88291 12.3975 2.06091L12.5 2.15508L16.1784 5.83341C16.3736 6.02851 16.5174 6.26904 16.5967 6.53341L16.63 6.66675H11.6667V1.70258Z" fill="#022245"/>
              </g>
              <defs>
                <clipPath id="clip0_1728_1149">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <span class='ml-2'>
              {{textBtn}}
            </span>
          </base-button>
        </b-overlay>

        <base-button
          class='my-2'
          @click='showModalAddCompany()'
          v-else
          outlinePrimaryNavyBlue
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M17.5 5.83333V15.8333C17.5 16.2917 17.3367 16.6842 17.01 17.0108C16.6833 17.3375 16.2911 17.5006 15.8333 17.5H4.16667C3.70833 17.5 3.31583 17.3367 2.98917 17.01C2.6625 16.6833 2.49945 16.2911 2.5 15.8333V4.16667C2.5 3.70833 2.66333 3.31583 2.99 2.98917C3.31667 2.6625 3.70889 2.49945 4.16667 2.5H14.1667L17.5 5.83333ZM10 15C10.6944 15 11.2847 14.7569 11.7708 14.2708C12.2569 13.7847 12.5 13.1944 12.5 12.5C12.5 11.8056 12.2569 11.2153 11.7708 10.7292C11.2847 10.2431 10.6944 10 10 10C9.30556 10 8.71528 10.2431 8.22917 10.7292C7.74306 11.2153 7.5 11.8056 7.5 12.5C7.5 13.1944 7.74306 13.7847 8.22917 14.2708C8.71528 14.7569 9.30556 15 10 15ZM5 8.33333H12.5V5H5V8.33333Z" fill="#022245"/>
          </svg>
          <span class='ml-2'>
            Salvar como empresa
          </span>
        </base-button>
      </section>
    </section>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import eventBus from '@/utils/eventBus'
import FilterAgentsLegalConsultation from '@/components/LegalSearch/Modal/FilterAgentsLegalConsultation.vue'
import { releaseCompanyMode } from '@/utils/releaseEnterpriseModeFunctionality'

export default {
  name: 'TabButtons',
  components: { FilterAgentsLegalConsultation },
  data() {
    return {
    }
  },
  computed: {
    summary() {
      return this.$store.getters['legalSearch/summary']
    },
    user() {
      return this.$store.getters['user/current']
    },
    tabView() {
      return this.$store.getters['legalSearch/tabView']
    },
    lookingForAgents() {
      return this.$store.getters['legalSearch/lookingForAgents']
    },
    ...mapGetters('breadcrumb', {
      breadcrumb: 'getBreadcrumb'
    }),
    ...mapGetters('legalSearch', {
      selectedAgents: 'selectedAgents'
    }),
    ...mapGetters('companyLegalConsultation', {
      importedCompany: 'importedCompany'
    }),
    ...mapGetters('productLegalConsultation', {
      disableProduct: 'disableProduct'
    }),
    selectedMoreThanOneProduct(){
      const agentes = this.summary.filter((x) => this.selectedAgents.includes(x.legalSearchId))
      const agentesVinculadoProduto = agentes.filter((x) => x.product && x.product._id)
      const agentesVinculadoProdutoAgrupadoPorProduto = agentesVinculadoProduto.reduce((group, agent) => {
        const product = agent.product._id
        group[product] = group[product] ? group[product] : []
        group[product].push(agent)
        return group
      }, {})
      return Object.keys(agentesVinculadoProdutoAgrupadoPorProduto).length >= 2
    },
    textBtn() {
      const agentes = this.summary.filter((x) => this.selectedAgents.includes(x.legalSearchId))
      const agentesVinculadoProduto = agentes.filter((x) => x.product && x.product._id && x.product.name)
      const agentesSemVinculosProduto = agentes.filter((x) => !x.product)
      const agentesVinculadoProdutoAgrupadoPorProduto = agentesVinculadoProduto.reduce((group, agent) => {
        const product = agent.product._id
        group[product] = group[product] ? group[product] : []
        group[product].push(agent)
        return group
      }, {})
      const keys = Object.keys(agentesVinculadoProdutoAgrupadoPorProduto)
      if (agentesVinculadoProduto.length > 0 && agentesSemVinculosProduto.length > 0 && keys.length === 1) {
        const product = agentesVinculadoProdutoAgrupadoPorProduto[keys[0]][0].product
        return `Adicionar ${agentesSemVinculosProduto.length > 1 ? 'agentes' : 'agente'} ao produto: ${product.name}`
      } else {
        return `Salvar ${this.selectedAgents.length > 1 ? 'agentes' : 'agente'} como produto `
      }
    },
  },
  props: { },
  methods: {
    releaseCompanyMode,
    ...mapActions('legalSearch', {
      exportingExcel: 'exportingExcel',
      setTabView: 'setTabView',
      setSlectedAgents: 'setSlectedAgents',
    }),
    ...mapActions('userActionLogs', {
      createUserActionLogs: 'createUserActionLogs'
    }),


    callGlobalCustomEvent(event) {
      eventBus.$emit(event)
    },

    showModalRemoveAgent(event) {
      this.$bvModal.show('rmconfirmation')
    },

    showModalAddCompany() {
      this.$bvModal.show('company')
    },

    async changeView(component, action, name) {
      if (this.$route.query.aba) {
        await this.$router.push('/web')
      }
      console.log(action)
      console.log(action)
      eventBus.$emit('updateTab', component)
      await this.setTabView(component)
      await this.setSlectedAgents([])
      if (this.user && this.user.role !== 'admin') {
        const log = {
          action
        }
        await this.createUserActionLogs(log)
      }
    }
  },
  mounted() {
  }
}
</script>

<style lang='scss' scoped>
.link {
  text-decoration: none;
}

.tabs-inventory {
  border: 2px solid #022245;
  border-radius: 8px;
  font-size: 2rem;
  background: #fafafa;
  min-height: 40px;
  flex-wrap: wrap;
}

.button-left {
  text-align: center;
  background-color: transparent !important;
  color: #022245;
  white-space: nowrap;
  border: none !important;
  outline: none !important;
  border-radius: 0 8px 8px 0 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  /*padding: 0 30px;*/
}

.button {
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  color: #022245;
  border: none;
  border-radius: 8px !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.button-right {
  text-align: center;
  white-space: nowrap;
  background-color: transparent !important;
  color: #022245;
  border: none !important;
  outline: none !important;
  border-radius: 8px 0 0 8px !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}


.active {
  text-align: center;
  white-space: nowrap;
  background-color: #022245 !important;
  color: #ECF0F3 !important;
  font-weight: 700 !important;
  outline: none !important;
  border: none !important;
  border-radius: 8px !important;
  font-size: 16px;
  font-style: normal;
  transition: opacity 0.3s ease-in!important;
}

.btn-summary {
  min-height: 40px;
  width: 220px!important;
  transition: opacity 0.3s ease-in!important;
}
.btn-insalubrity {
  min-height: 40px;
  width: 260px!important;
  transition: opacity 0.3s ease-in!important;
}
.btn-acgih-ltcat-pcmso {
  min-height: 40px;
  width: 120px!important;
  transition: opacity 0.3s ease-in!important;
}
.btn-quantitativeAssessment {
  min-height: 40px;
  width: 240px!important;
  transition: opacity 0.3s ease-in!important;
}
</style>
