export const optionsDegreeOfDust = [
  {
    key: 1,
    value: 'Baixo',
    description: ` Escamas grandes ou grânulos grossos - quando manipulados, produzem pouca poeira (exemplos: grânulos de PVC, flocos de cera, pellets que não se quebram)`
  },
  {
    key: 2,
    value: 'Médio',
    description: `Sólidos granulares e cristalinos - quando manipulados, vê-se a poeira que logo se deposita (exemplo: sabão em pó, granulares ou cristalinos)`
  },
  {
    key: 3,
    value: 'Alto',
    description: `Poeiras finas e leves - quando manipulados, observa-se formação de nuvens de poeira que ficam muitos minutos no ar (exemplos: cimento, pó de giz, carvão, sólidos finos ou poeiras muito leves)`
  }
]
