<template>
  <b-modal
    id='quantitativeAssessment-modal'
    size='md'
    header-bg-variant='info'
    header-text-variant='light'
    title='Selecione a forma de avaliação'
    ref='modal'
    hide-footer
    @show='showModal'
  >
    <form>
      <b-form-group>
        <b-form-radio-group
          id='checkbox-group-1'
          v-model='selectedAssessmentType'
          :options='activities'
          name='flavour-1'
          stacked
          value-field='key'
          text-field='evaluationForm'
        >
        </b-form-radio-group>
      </b-form-group>
      <br />

      <div>
        <b-button @click='save' class='btn w-100' :disabled='!selectedAssessmentType'>
          <i class='fa fa-save'></i> Salvar
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
export default {
  computed: {},
  data() {
    return {
      selectedAssessmentType: null,
      activities: []
    }
  },
  methods: {
    async showModal() {
      this.activities = [];
      this.selectedAssessmentType = null
      this.activities = this.agent[this.laboratory] ? this.agent[this.laboratory].map((x, index) => {
        return {
          key: (index + 1),
          evaluationForm: x.evaluationForm,
        }
      }) : []
      this.selectedAssessmentType = this.selectedEvaluationForm ? this.activities.find((x) => x.evaluationForm === this.selectedEvaluationForm).key : this.selectedEvaluationForm

    },
    save() {
      this.$emit('save', this.agent[this.laboratory].find((x) => x.evaluationForm === this.activities.find((y) => y.key === this.selectedAssessmentType).evaluationForm))
    }
  },
  props: ['agent', 'laboratory', 'selectedEvaluationForm'],
  watch: {}
}
</script>
<style lang='scss' scoped>
.btn {
  border: none;
  background-color: #CBD63E;
  color: #022245;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 24px;

  i {
    color: #022245;
    margin-right: 6px;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }

  &.active {
    border: none;
  }
}
</style>
