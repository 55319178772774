export default {
  getRowspan(object) {
    let rowspan = 0
    if (object.board_01) {
      rowspan++
    }
    if (object.board_02) {
      rowspan++
    }
    if (object.complementaryExamsAcgih) {
      rowspan++
    }
    return rowspan
  },

  getList(object) {
    let rowspan = []
    if (object.board_01) {
      rowspan.push(1)
    }
    if (object.board_02) {
      rowspan.push(2)
    }
    if (object.complementaryExamsAcgih) {
      rowspan.push(3)
    }
    return rowspan
  },
  getLists(object) {
    let rowspan = []
    if (object.board_01) {
      rowspan.push(1)
    }
    if (object.board_02) {
      rowspan.push(2)
    }
    if (object.complementaryExamsAcgih) {
      rowspan.push(3)
    }
    if(rowspan.length === 0){
      rowspan.push(1)
    }
    return rowspan
  },

  getRowspanSearchId(object) {
    let rowspan = 1
    if (object.board_01) {
      rowspan += object.board_01.indicators.length
    }
    if (object.board_02) {
      rowspan += object.board_02.indicators.length
    }
    if (object.complementaryExamsAcgih) {
      rowspan += object.complementaryExamsAcgih.indicators.length
    }
    return rowspan
  },

  getboard_01(agente) {
    if (agente.board_01 && agente.board_01.indicators && agente.board_01.indicators.length > 0) {
      return 'NR 7 - Quandro 1'
    }
    return ''
  },

  getValue(key, object, index) {
    const catalogInfo = 'acgihInfo'
    const catalog = 'acgih'
    if (!object) return ''

    switch (key) {
      case 'searchId':
        return ''
      case 'originInformation':
        return '-'
      case 'supplementaryExamData':
        return '-'
      default:
        return '-'
    }
  },
  getsearchId(object) {
    const casAgente = object.name || object.searchId || object.searchMatched
    const [cas, agente] = casAgente.split(':')

    if (!agente.trim() && object.synonyms && object.synonyms.length > 0) {
      return `${object.cas}: ${object.synonyms.find((x) => x.length > 0)}`
    }
    return casAgente
  },
  tableFields: [
    { key: 'searchId', value: 'Cas: Agente' },
    { key: 'originInformation', value: 'Origem da informação' },
    { key: 'supplementaryExamData', value: 'Dados do Exame Complementar' }
  ]
}
