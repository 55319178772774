<template>
  <div class='mt-3'>
    <b-modal
      ref='alert'
      scrollable
      hide-footer
      title='Atenção'
      header-bg-variant='info'
      header-text-variant='light'
    >
      <section class='modal-font-family'>
        <p>
          A impressão do arquivo em Excel esta sendo processada,
          clique no botão abaixo para acessar a página de downloads.
        </p>

        <router-link class='float-right router-link-text-decoration' :to="{ name: 'RelatoriosConsultaLegal' }">
          <base-button class='btn_export_modal'>
            <span class="label">Acessar</span>
          </base-button>
        </router-link>
      </section>
    </b-modal>

    <b-modal
      ref='quantitativeAssessmentInformation'
      id='quantitativeAssessmentInformation'
      scrollable
      hide-footer
      hide-header
      header-bg-variant='info'
      header-text-variant='light'
      size='lg'
    >
      <section class='modal-font-family p-1'>
        <section class='d-flex align-content-center justify-content-between mb-3'>
          <section>
            <p class='modal-title mt-1'>Informações da Avaliação Quantitativa</p>
          </section>

          <section>
            <b-icon
              style='cursor: pointer'
              @click='closeQuantitativeAssessmentInformation()'
              class='ml-1'
              font-scale='2.5'
              icon='x'
              color='#262626'
            >
            </b-icon>
          </section>
        </section>

        <section>
          <strong class='title'>
            Será realizado a seguir um planejamento de amostragem quantitativa, compreendendo ao menos
            ({{ getObjectivesAnalysis.journeyMinutes }}) minutos da
            jornada diária do
            trabalhador exposto aos agentes químicos da tabela abaixo. O objetivo do planejamento de amostragem será o
            atendimento ao ({{ objetivo() }}) {{getObjectivesAnalysis.considerSafetyMargin ? 'considerando a margem de segurança' : 'sem considerar a margem de segurança'}}.
          </strong>
        </section>

        <section class='obs my-4'>
          <img width='30' height='30' src='../../../assets/images/Obs.svg' alt=''>

          <section class='pl-2'>
            <p class='ml-2'>
              Os dados mostrados nesta aba para cada um dos agentes químicos são obtidos através daquilo que nos é oferecido pelo laboratório indicado.
              É possível que existam divergências com as informações mostradas aqui e o laboratório indicado e por isso reforçamos que em caso de
              interesse de trabalhar com o laboratório indicado, sempre busque validar essas informações diretamente com o contato técnico e comercial do laboratório.
            </p>

            <p class='ml-2'>
              O planejamento sugerido para a sua análise quantitativa não leva em conta possíveis ambientes ou situações em que possam ocorrer a saturação
              do amostrador e, portanto, é importante que você ajuste os dados de vazão e volume conforme as suas necessidades de avaliação.
            </p>

            <p class='ml-2'>
              O planejamento sugerido para a sua análise quantitativa é baseado em aproveitar o máximo do amostrador,
              ou seja, buscamos sempre calcular em cima do volume máximo que o amostrador suporta conforme metodologia para cada agente químico.
            </p>

            <p class='ml-2'>
              Buscamos facilitar o seu trabalho através de orientações práticas e técnicas. Nós não somos responsáveis pela sua avaliação em si.
              Siga as orientações e sempre busque alinhamento (desde o planejamento, durante a execução das avaliações e depois com os resultados
              analíticos em mãos) com o laboratório que ficará responsável por sua análise dos agentes químicos que forem quantificados.
            </p>
          </section>
        </section>

        <section class='d-flex justify-content-end w-100 text-right'>
          <h5 class='text-right'>
            <b-form-checkbox
              size="lg"
              v-model='iAgree'
              variant='info'
              @change='setIAgree()'
            >
              <strong>Eu concordo</strong>
            </b-form-checkbox>
          </h5>
        </section>
      </section>
    </b-modal>

    <div>
      <!--<a class="imprimir" :href="xlsxLink">
        <span class="icon"><i class="fas fa-print"></i></span>
        <span class="label">Versão para impressão Excel</span>
      </a>-->
      <section class='mb-2' v-if='!showResult'>
        <tab-buttons/>
      </section>
      <div class='w-100 component'>
        <component
          v-bind:is='component'
          @setComponent='setComponent'
        />

        <div class='text-left align-content-center d-flex' v-if='!component'>
          <h6 class='btn-outline-primary'>Buscando objetivos da análise ...</h6>
          <div class='px-2'>
            <b-spinner class='loading' variant='primary' label='Spinning'></b-spinner>
          </div>
        </div>
      </div>

      <div class='mt-3' v-if='showResult'>
        <laboratories />
        <quantitative-assessment-agents v-if='getObjectivesAnalysis && getObjectivesAnalysis.laboratory' />
      </div>
    </div>
  </div>
</template>

<script>
import Acigh from '@/models/Acgih'
import { mapActions, mapGetters } from 'vuex'
import eventBus from '../../../utils/eventBus'

import ObjectivesAnalysis from '@/components/LegalSearch/QuantitativeAssessment/components/ObjectivesAnalysis'
import QuantitativeAssessmentAgents
  from '@/components/LegalSearch/QuantitativeAssessment/components/QuantitativeAssessmentAgents'
import Laboratories from '@/components/LegalSearch/QuantitativeAssessment/components/Laboratories'
import SummaryObjectivesAnalysis
  from '@/components/LegalSearch/QuantitativeAssessment/components/SummaryObjectivesAnalysis'
import TabButtons from '@/components/LegalSearch/components/TabButtons.vue'

export default {
  name: 'QuantitativeAssessment',
  data() {
    return {
      iAgree: false,
      generateImpression: false,
      modalOpen: false,
      loading: false,
      showResult: false,
      allowed: false,
      dontHavePlan: false,
      model: Acigh,
      selectedAgent: {},
      scrollBarPosition: 0,
      component: '',
      objectiveAnalysisOptions: [
        { value: 'LAUDO_INSALUBRIDADE', text: 'Laudo de insalubridade' },
        { value: 'PGR', text: 'PGR' },
        { value: 'LTCAT', text: 'LTCAT' }
      ]
    }
  },
  components: {
    TabButtons,
    ObjectivesAnalysis,
    SummaryObjectivesAnalysis,
    Laboratories,
    QuantitativeAssessmentAgents,
  },
  computed: {
    xlsxLink() {
      return `${process.env.VUE_APP_BASE_URL}/xlsx/${this.user.id}/quantitativeEvaluation`
    },
    summary() {
      return this.$store.getters['legalSearch/summary']
    },
    user() {
      return this.$store.getters['user/current']
    },
    summaryMeta() {
      return this.$store.getters['legalSearch/summaryMeta']
    },
    ...mapGetters('objectivesAnalysis', {
      getObjectivesAnalysis: 'getObjectivesAnalysis'
    })
  },
  methods: {
    ...mapActions('reportsDownloads', {
      handleCreateReportDownloads: 'handleCreateReportDownloads'
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb'
    }),
    ...mapActions('event', {
      handlGetEventActive: 'handlGetEventActive'
    }),
    ...mapActions('legalSearch', {
      exportingExcel: 'exportingExcel'
    }),
    ...mapActions('objectivesAnalysis', {
      handleGetObjectivesAnalysis: 'handleGetObjectivesAnalysis',
      handleUpdateIAgreeObjectivesAnalysis: 'handleUpdateIAgreeObjectivesAnalysis',
    }),

    async setIAgree() {
      try {
        const { _id } = this.getObjectivesAnalysis
        const objectivesAnalysis = {
          iAgree: this.iAgree,
          _id
        }
        await this.handleUpdateIAgreeObjectivesAnalysis(objectivesAnalysis)
        await this.handleGetObjectivesAnalysis()
        this.$bvModal.hide('quantitativeAssessmentInformation')
      } catch (err) {

      }
    },

    objetivo() {
      return this.objectiveAnalysisOptions
        .filter((objective) => this.getObjectivesAnalysis.objectiveAnalysis.includes(objective.value))
        .map((x) => x.text)
        .join(', ')
    },
    closeQuantitativeAssessmentInformation(){
      this.$bvModal.hide('quantitativeAssessmentInformation')
    },
    setComponent(component) {
      this.component = component.component
      this.showResult = component.showResult
    },
    async generateReport() {
      await this.exportingExcel(true)
      const payload = {
        user: this.user.id,
        type: 'quantitativeEvaluation',
        format: 'xlsx',
        job: 'legalConsultationReports',
        parameters: {
          selectedFilters: this.summaryMeta,
        }
      }
      const { data, status } = await this.handleCreateReportDownloads(payload)

      let message = data.message
      let variant = 'danger'
      await this.exportingExcel(false)
      if (status === 201) {
        message = data.message
        variant = 'success'
        this.$refs['alert'].show()
        return
      }
      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })

    }
  },
  async mounted() {
    try {
      await this.handleGetObjectivesAnalysis()
      if (
        this.getObjectivesAnalysis && this.getObjectivesAnalysis.quantificationCriterion &&
        this.getObjectivesAnalysis.objectiveAnalysis && this.getObjectivesAnalysis.objectiveAnalysis.length > 0 &&
        !this.getObjectivesAnalysis.changedAgentsLegalConsultation
      ) {
        this.setComponent({ component: 'SummaryObjectivesAnalysis', showResult: true })
      } else {
        this.setComponent({ component: 'ObjectivesAnalysis', showResult: false })
      }
      eventBus.$on('generateReportQuantitativeAssessment', async () => {
        await this.generateReport()
      })
      await this.handlGetEventActive()
      const { iAgree } = this.getObjectivesAnalysis
      this.iAgree = iAgree
    } catch (err) {
      this.loading = false
      this.dontHavePlan = false
    }
  },
  beforeDestroy() {
    eventBus.$off('generateReportQuantitativeAssessment')
  },
  created() {
    const breadcrumb = {
      tab: 'quantitativeAssessment',
      page: 'Consulta Legal',
      subPage: {
        name: 'Consulta Legal',
        active: false
      },
      tabs: [
        {
          name: 'Avaliação Qualitativa',
          active: true
        }
      ]
    }
    this.setBreadcrumb(breadcrumb)
  }
}
</script>
<style>
.tooltip-inner {
  text-align: left;
  max-width: 400px !important;
  width: 400px !important;
}
</style>
<style lang='scss' scoped>
.pulse {
  width: max-content;
  text-transform: uppercase;
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  background-color: #ffa500;
  box-shadow: 0 0 0 0 #ffa500;
  color: var(--white);
  font-weight: 600;
  padding: 8.5px 7px;
  border-radius: 4px;
  font-size: 14px;
  letter-spacing: 1px;
  &:hover {
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    animation: none;
  }
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}

.component {
  background: #FFFFFF;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 10px;
  min-height: 90px;
  margin-top: -10px;
}

.btn_export {
  background-color: #CBD63E;
  color: #022245;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 24px;

  i {
    color: #022245;
    margin-right: 6px;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }
}

.headerTable {
  vertical-align: middle;
  background: #94D5F3;
  padding: 10px;
  position: sticky;
  top: 83px;
  z-index: 10;
}

.imprimir {
  color: var(--blue);
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
}

.imprimir:hover {
  text-decoration: underline
}

.loading {
  width: 1.5rem;
  height: 1.5rem;
}

.selectable-row {
  background-color: aliceblue;
  font-weight: bold;
  vertical-align: middle;
}

td {
  vertical-align: middle;
}

.field_ps {
  text-align: left;
  font-size: 12px;
}

.table tr:nth-child(2) th {
  top: 173px;
}
.obs {
  display: flex;
  align-items: center;
  justify-content: start;
  background: rgba(4, 168, 243, 0.4);
  border-radius: 6.25px;
  padding: 10px;
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
}
.modal-title {
  color: #262626;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.title {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}
</style>