<template>
  <b-modal
    id='edit-vase-modal'
    size='md'
    header-bg-variant='info'
    header-text-variant='light'
    title='Editar vazão'
    ref='modal'
    hide-footer
    @show='showModal'
    @hidden="hiddenModal"
  >
    <form>
      <b-alert show variant='danger' :class='{ hidden: !error }'>
        {{ error }}<br />
      </b-alert>

      <b-form-group>
        <label style='margin-bottom:-2px' class='mt-1'>Vazão máxima</label>
        <b-form-input
          placeholder='Digite a vazão máxima'
          v-model='selectedActivities.maximumLeak'
          id='samplingPercentage'
          style='height: 48px'
          class='w-100 mt-1'
          type='number'
        >
        </b-form-input>

        <label style='margin-bottom:-2px' class='mt-3'>Vazão mínima</label>
        <b-form-input
          placeholder='Digite a vazão mínima'
          v-model='selectedActivities.minimumFlow'
          id='samplingPercentage'
          style='height: 48px'
          class='w-100 mt-1'
          type='number'
        >
        </b-form-input>
      </b-form-group>
      <br />

      <b-input-group>
        <b-button class='btn' @click='save' block>
          <i class='fa fa-save'></i> Salvar
        </b-button>
      </b-input-group>
    </form>
  </b-modal>
</template>

<script>
export default {
  name: 'EditVaseModal',
  computed: {
    error() {
      return this.$store.getters['access/error']
    },
    user() {
      return this.$store.getters['user/current']
    },
  },
  data() {
    return {
      clickedSave: false,
      selectedActivities: {
        maximumLeak: null,
        minimumFlow: null
      }
    }
  },
  methods: {
    async hiddenModal() {
      if (!this.clickedSave) {
        await this.$store.dispatch('legalSearch/load', this.user.id)
      }
    },
    async showModal() {
      await this.$store.dispatch('access/updateError', '')
      this.selectedActivities = this.evaluationForm
      this.clickedSave = false;
    },
    async save() {
      await this.$store.dispatch('access/updateError', '')
      if (!this.selectedActivities.maximumLeak || !this.selectedActivities.minimumFlow) {
        await this.$store.dispatch('access/updateError', 'Todos os campos são obigatórios')
        return
      }
      if (Number(this.selectedActivities.maximumLeak) < this.selectedActivities.minimumFlow) {
        await this.$store.dispatch('access/updateError', 'A vazão mínima sempre deverá ser igual ou inferior à vazão máxima')
        return
      }
      this.selectedActivities.maximumLeak = Number(this.selectedActivities.maximumLeak)
      this.selectedActivities.minimumFlow = Number(this.selectedActivities.minimumFlow)
      this.clickedSave = true;
      this.$emit('save', this.selectedActivities)
    }
  },
  props: ['evaluationForm'],
  watch: {}
}
</script>
<style lang='scss' scoped>
.btn {
  border: none;
  background-color: #CBD63E;
  color: #022245;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 24px;

  i {
    color: #022245;
    margin-right: 6px;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }
}
</style>
