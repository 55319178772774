<template>
  <div class='container'>
    <div class='w-100 d-flex justify-content-end'>
      <a @click='close()' class='d-flex align-items-center btn_close'>
        <span>Fechar</span>
        <b-icon
          class='ml-1 '
          font-scale='1.5'
          icon='x'
          color='#022245'
        >
        </b-icon>
      </a>
    </div>

    <div>
      <b-alert class='mt-2' show variant="success" :class="{ hidden: !hasMessage() }">
        {{ message }}
      </b-alert>
      <b-alert class='mt-2' show variant="danger" :class="{ hidden: !hasErrors() }">
        {{ error }}<br />
      </b-alert>
    </div>
    <div class='d-flex align-items-start'>
      <img width='22' height='22' class='mr-2' src='../../../../assets/images/email.svg' alt='' />

      <p class='title'>
        Convide um laboratório
      </p>
    </div>

    <div class='d-flex align-items-center'>
      <p class='text_ pt-2'>
        Enviaremos um e-mail para o laboratório selecionado convidando-os a fazer parte da nossa plataforma.
      </p>
    </div>

    <div class='selected_laboratory_invitation'>
      <label>Qual laboratório você quer convidar para o HO Fácil Web?</label>
      <base-input-select
        v-model='laboratory'
        type='text'
        placeholder='Selecione um laboratório'
        :options='installmentsOptions'
      />
    </div>

    <div class='mt-5 mb-2 w-100'>
      <b-overlay
        :show='loading'
        rounded
        opacity='0.6'
        spinner-small
        spinner-variant='primary'
        class='d-inline-block w-100'
      >
        <button
          @click='sendLaboratoryInvitation()'
          class='btn_enviar_convite w-100'
        >
          <span>Enviar convite ao laboratório</span>
          <img width='22' height='22' class='mr-2' src='../../../../assets/images/send.svg' alt='' />
        </button>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core'

export default {
  name: 'InvitationLaboratory',
  directives: { mask },
  components: {},
  computed: {
    message() {
      return this.$store.getters['access/message']
    },
    error() {
      return this.$store.getters['access/error']
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      laboratory: null,
      loading: false,
      installmentsOptions: [
        { _id: 'contato@uniscientificgroup.com.br', value: 'Unianalysis' },
        { _id: 'solutech@solutechlab.com.br', value: 'Solutech' },
        { _id: 'lablatam@lablatam.com.br', value: 'Latam' },
        { _id: 'paularebellatto@eurofins.com', value: 'Eurofins Alac' }
      ]
    }
  },
  validations() {
    return {}
  },
  async mounted() {
    await this.$store.dispatch('access/updateMessage', '')
    await this.$store.dispatch('access/updateError', '')
  },
  methods: {
    ...mapActions('objectivesAnalysis', {
      handleSendLaboratoryInvitation: 'handleSendLaboratoryInvitation'
    }),
    hasErrors() {
      return !!this.error
    },
    hasMessage() {
      return !!this.message
    },
    close() {
      this.$emit('close')
    },
    async sendLaboratoryInvitation() {
      try {
        await this.$store.dispatch('access/updateMessage', '')
        await this.$store.dispatch('access/updateError', '')

        if(!this.laboratory){
          await this.$store.dispatch('access/updateError', 'Selecione o laboratório.')
          return
        }
        this.loading = true;
        const { _id, value } = this.installmentsOptions.find((x) => x._id === this.laboratory)

        const laboratoryInvitation = {
          email: _id,
          name: value,
        }

        const { data, status } = await this.handleSendLaboratoryInvitation(laboratoryInvitation)

        this.loading = false
        if (status === 200) {
          await this.$store.dispatch('access/updateMessage', data.message)
          return
        }
        await this.$store.dispatch('access/updateError', data.message)
      } catch (e) {

      }

    }
  },
  props: {},
  watch: {}
}
</script>
<style lang='scss' scoped>
.container {
  font-family: 'Montserrat', serif;
}

.placeholder {
  color: #808080;
}

.btn_close {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #022245;
  cursor: pointer;
  text-decoration: none;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #022245;

}

.text_ {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #808080;
}

.selected_laboratory_invitation {
  label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #262626;
  }

}

.btn_enviar_convite {
  background: #04A8F3;
  border: none;
  border-radius: 6.25px;
  padding: 12px;

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    margin-right: 10px;
  }
}
</style>
