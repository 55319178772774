export const optionsFrequencyUse = [
  {
    key: 1,
    value: 'Usado de forma sazonal: 1x a cada 2 ou três meses',
    valueHtml: 'Menos que <b/>1 a 2x por mês',
  },
  {
    key: 2,
    value: 'Possivelmente 1x por mês',
    valueHtml: 'Mais que <b/>2x por mês',
  },
  {
    key: 3,
    value: 'De 1 a 2x por semana',
    valueHtml: 'De 1 a 2x<br/> por semana',
  },
  {
    key: 4,
    value: 'Mais que 2x por semana',
    valueHtml: 'Mais que 2x <br/> por semana',
  },
  {
    key: 5,
    value: 'De 1 a 2x por dia',
    valueHtml: 'De 1 a 2x <br/> por dia',
  },
  {
    key: 6,
    value: 'Mais que 2x por dia',
    valueHtml: 'Mais que<br/> 2x por dia',
  },
]