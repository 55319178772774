<template>
  <section>
    <b-modal
      ref='orientationVideos'
      id='orientationVideos'
      hide-footer
      hide-header
      header-bg-variant='info'
      header-text-variant='light'
      size='lg'
      @show='showModal()'
    >
      <section class='modal-font-family'>
        <section>
          <section class='d-flex align-items-center'>
            <section>
              <svg width='26' height='26' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M11 16V21H5C4.49542 21.0002 4.00943 20.8096 3.63945 20.4665C3.26947 20.1234 3.04284 19.6532 3.005 19.15L3 19V16H11ZM21 16V19C21.0002 19.5046 20.8096 19.9906 20.4665 20.3605C20.1234 20.7305 19.6532 20.9572 19.15 20.995L19 21H13V16H21ZM11 10V14H3V10H11ZM21 10V14H13V10H21ZM11 3V8H3V5C2.99984 4.49542 3.19041 4.00943 3.5335 3.63945C3.87659 3.26947 4.34684 3.04284 4.85 3.005L5 3H11ZM19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V8H13V3H19Z'
                  fill='#262626' />
              </svg>
            </section>

            <section class='ml-2 '>
              <span class='header-text'>Orientações específicas</span>
            </section>
          </section>

          <section class='agent mt-3'>
            <span class='text-title'>Agente </span>
            <span class='text-agent-name'>{{ agent }}</span>
          </section>
        </section>

        <section>
          <hr class='line my-4' />
        </section>

        <section>
          <p class='text-btn'>Clique em um dos botões abaixo para abrir o vídeo em uma nova guia</p>
        </section>

        <section class='w-100'>
          <button
            class='btn mb-3 w-100'
            v-for='(video, index) in videos'
            :key='`${index}_${video}`'
            @click='goTo(video)'
          >
            {{ video.title }}
          </button>
        </section>
      </section>
    </b-modal>
  </section>

</template>

<script>
import axios from 'axios'

export default {
  name: 'OrientationVideos',
  components: {},
  props: {
    orientationVideos: { type: Array, default: [], required: true },
    agent: { type: String, default: null, required: true }
  },
  data() {
    return {
      videos: []
    }
  },
  mounted() {

  },
  beforeDestroy() {
  },
  computed: {
    summary() {
      return this.$store.getters['legalSearch/summary']
    },
    user() {
      return this.$store.getters['user/current']
    }
  },
  methods: {
    goTo(video){
      window.open(video.link, '_blank')
    },
    async showModal() {
      try {
        this.videos = []
        for (let index in this.orientationVideos) {
          const video = await this.getVideoYoutube(this.orientationVideos[index])
          video.link = this.orientationVideos[index]
          this.videos.push(video)
        }
      } catch (e) {

      }
    },
    async getVideoYoutube(link) {
      try {
        const { data } = await axios.get(`https://www.youtube.com/oembed?url=${link}&format=json`)
        return data
      } catch (e) {

      }
    }
  }
}
</script>

<style lang='scss' scoped>
.header-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: left;
  color: #262626;
}

.agent {
  border-radius: 5px;
  border: 1px solid #808080;
  width: fit-content;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;

  .text-title {
    color: #808080;
  }

  .text-agent-name {
    color: #262626;
  }
}

.line {
  background: #C0C0C0;
  height: 1px;
}

.text-btn {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #262626;
}

.btn {
  text-align: center;
  background-color: #022245 !important;
  color: #ECF0F3 !important;
  font-weight: 600 !important;
  outline: none !important;
  border: none !important;
  border-radius: 8px !important;
  font-size: 16px;
  font-style: normal;
  transition: opacity 0.3s ease-in !important;
  padding: 10px 0;
}
</style>
