export default {
  textDefault(){
    return `Clique aqui para responder nosso questionário e assim te auxiliarmos na análise da exposição permanente e proteção eficaz.`
  },

  getBase(object) {
    if (object.attachment11) return 'Anexo 11'
    else if (object.attachment12) return 'Anexo 12'
    else if (object.attachment13)
      return `Anexo 13 - ${object.attachment13.subgroup}`
    else return '-'
  },
  getActivity(object) {
    if(object.attachment11){
      return true
    } else if (
      (object.attachment1 &&
        object.attachment1.activities &&
        object.activities.length < 1) ||
      (object.attachment2 &&
        object.attachment2.activities &&
        object.activities.length < 1) ||
      (object.attachment3 &&
        object.attachment3.activities &&
        object.activities.length < 1) ||
      (object.attachment8 &&
        object.attachment8.activities &&
        object.activities.length < 1) ||
      (object.attachment12 &&
        object.attachment12.activities &&
        object.activities.length < 1) ||
      (object.attachment13 &&
        object.attachment13.activities &&
        object.activities.length < 1) ||
      (object.attachment14 &&
        object.attachment14.activities &&
        object.activities.length < 1)
    )
      return false
    else return true
  },
  getValue(key, object) {
    if (!object) return ''
    let catalog = undefined
    if (object.attachment1) {
      catalog = 'attachment1'
    } else if (object.attachment2) {
      catalog = 'attachment2'
    } else if (object.attachment3) {
      catalog = 'attachment3'
    } else if (object.attachment8) {
      catalog = 'attachment8'
    } else if (object.attachment11) {
      catalog = 'attachment11'
    } else if (object.attachment14) {
      catalog = 'attachment14'
    } else if (object.attachment12) {
      catalog = 'attachment12'
    } else if (object.attachment13) {
      catalog = 'attachment13'
    } else if (object.nr15Unclassified) {
      catalog = 'nr15Unclassified'
    }
    switch (key) {
      case 'ps':
        let mgm = '';
        let  ppm = ''
        if (object.limits && object.limits.limit1) {
          mgm = object.limits.limit1
        } else {
          mgm = object[catalog]
            ? object[catalog].limit1
              ? object[catalog].limit1
              : '-'
            : '-'
        }

        if (object.limits && object.limits.limit2) {
          ppm = object.limits.limit2
        } else {
          ppm =  object[catalog]
            ? object[catalog]
              ? object[catalog].limit2
                ? object[catalog].limit2
                : '-'
              : '-'
            : '-'
        }
        if(mgm.toUpperCase() === 'SIMPLES' && ppm.toUpperCase() === 'ASFIXIANTE'){
          return `Todos os valores fixados no Quadro nº 1 como "Asfixiantes Simples" 
                  determinam que nos ambientes de trabalho, em presença destas substâncias, a 
                  concentração mínima de oxigênio deverá ser 18 (dezoito) por cento em volume. 
                  As situações nas quais a concentração de oxigênio estiver abaixo deste valor 
                  serão consideradas de risco grave e iminente.`
        }

        if (object.attachment1 || object.attachment2 || object.attachment3 || object.attachment8 || object.attachment14) {
          if (object.limits.ps) {
            return object.limits.ps
          }
        }

        if (catalog == 'attachment11') {
          return `O agente ${object.name} está constatado na NR 15 em seu anexo 11 com insalubridade em grau ${object.limits
            ? object.limits.insalubrity
            : object[catalog].insalubrity
            }. Poderá ser considerada a possibilidade da condição insalubre e consequentemente o percebimento do adicional de insalubridade quando houver ao menos os seguintes requisitos constatados na análise do local de trabalho:
            <br/>1) Quando o trabalhador estiver exposto ao agente;
            <br/>2) Quando o limite de tolerância for ultrapassado;
            <br/>3) Quando não houver eficácia comprovada do EPC, EPI ou processo hermetizado.`
        }

        if (
          object.activities.includes('O trabalhador executa uma atividade diferente das opções previstas em norma para este agente químico') &&
          catalog == 'attachment12'
        ) {
          return `O agente ${object.name} está constatado na NR 15 em seu Anexo 12, porém a referida norma não expressa o limite de tolerância e o grau de insalubridade para a atividade selecionada.`
        }
        if (catalog == 'attachment12') {
          return `O agente ${object.name} está constatado na NR 15 em seu anexo 12 com insalubridade em grau ${object.limits
            ? object.limits.insalubrity
            : object[catalog].insalubrity
            }. Poderá ser considerada a possibilidade da condição insalubre e consequentemente o percebimento do adicional de insalubridade quando houver ao menos os seguintes requisitos constatados na análise do local de trabalho:
          <br/>1) Quando o trabalhador estiver exposto ao agente;
          <br/>2) Quando o limite de tolerância for ultrapassado;
          <br/>3) Quando não houver eficácia comprovada do EPC, EPI ou processo hermetizado.`
        }

        if (
          object.activities.includes('O trabalhador executa uma atividade diferente das opções previstas em norma para este agente químico') &&
          catalog == 'attachment13'
        )
          return `O agente ${object.name} está constatado na NR 15 em seu Anexo 13, porém a referida norma não expressa o grau de insalubridade para a atividade selecionada.`
        if (catalog == 'attachment13') {
          return `O agente ${object.name
            } está constatado na NR 15 em seu anexo 13 com insalubridade em grau ${object.limits
              ? object.limits.insalubrity
              : object[catalog].insalubrity
            }. Poderá ser considerada a possibilidade da condição insalubre e consequentemente o percebimento do adicional de insalubridade quando houver ao menos os seguintes requisitos constatados na análise do local de trabalho:
            <br/>1) Quando o trabalhador estiver exposto ao agente;
            <br/>2) Quando não houver eficácia comprovada do EPC, EPI ou processo hermetizado.`
        }
        if (catalog == 'nr15Unclassified') {
          return `O agente ${object.name} foi verificado por nosso serviço de classificação de agentes químicos e não apresentou margem para enquadramento na NR 15 em seus anexos 11, 12 e 13.`
        }
        return `O agente ${object.name} foi incluído em nosso banco de dados e está em processo de classificação para que possamos informar se ele apresentará ou não margem para o enquadramento na NR 15 em seus anexos 11, 12 ou 13.`
      case 'evaluation':
        return object[catalog]
          ? object[catalog].evaluation
            ? object[catalog].evaluation
            : '-'
          : '-'
      case 'ceilMgm':
        if (object.limits && object.limits.limit1Ceil) return object.limits.limit1Ceil
        return object[catalog]
          ? object[catalog].limit1Ceil
            ? object[catalog].limit1Ceil
            : '-'
          : '-'
      case 'ceilPpm':
        if (object.limits && object.limits.limit2Ceil) return object.limits.limit2Ceil
        return object[catalog]
          ? object[catalog].limit2Ceil
            ? object[catalog].limit2Ceil
            : '-'
          : '-'
      case 'insalubrity':
        // return object
        return (object && object.limits && object.limits.insalubrity )
          ? object.limits.insalubrity
            ? object.limits.insalubrity
            : '-'
          : object[catalog] && object[catalog].insalubrity
            ? object[catalog].insalubrity
            : '-'
      case 'activities':
        if (object.activities.length > 0) {
          const tooltip = object.activities.map((activity, index) => {
            return `${index + 1}) ${activity}`
          })
          return `${tooltip.join(';<br/> ')}.`
        } else {
          return ''
        }
      case 'esocial':
        return (object.limits && object.limits.code)
            ? object.limits.code
            : (object.table24 && object.table24.code)
              ? object.table24.code
              : '-'
        default:
        return undefined
    }
  },
  getsearchId(object){
    const casAgente = object.name || object.searchId || object.searchMatched
    const [cas, agente] = casAgente.split(':')

    if(!agente.trim() && object.synonyms && object.synonyms.length > 0){
      return `${object.cas}: ${object.synonyms.find((x) => x.length > 0)}`
    }
    return casAgente
  },
  tableFields: [
    { key: 'agentDetails' },
    { key: 'activities' },
    { key: 'evaluation' },
    { key: 'base' },
    { key: 'toleranceLimits' },
    { key: 'insalubrity' },
    /*{ key: 'esocial', value: 'ESOCIAL - Tabela 24' },*/
  ],
  tableFieldsPrint: [
    { key: 'searchId', value: 'CAS: Agente' },
    { key: 'base', value: 'Fundamentações' },
    { key: 'activities', value: 'Lista de Atividades' },
    {
      key: 'toleranceList',
      value: 'Lista de Tolerância',
      list: [
        { key: 'skinAbsorption', value: 'Pele' },
        { key: 'mgm', value: 'mg/m³' },
        { key: 'ppm', value: 'ppm' },
        { key: 'ceil', value: 'Teto' },
        { key: 'ceilMgm', value: 'Valor máximo mg/m³' },
        { key: 'ceilPpm', value: 'Valor máximo ppm' },
        { key: 'insalubrity', value: 'Insalubridade' }
      ]
    },
    { key: 'ps', value: 'Observações' }
  ]
}
