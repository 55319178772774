export default {
  getPremium(key, object) {
    switch (key) {
      case 'nr15-base':
        return object.attachment12 || object.attachment13 ? true : false
      case 'ceil':
        return object.attachment12 || object.attachment13 ? true : false
      case 'skinAbsorption':
        return object.attachment12 || object.attachment13 ? true : false
      case 'mgm':
        return object.attachment12 || object.attachment13 ? true : false
      case 'ppm':
        return object.attachment12 || object.attachment13 ? true : false
      default:
        return false
    }
  },
  getValue(key, object) {
    if (!object) return ''
    switch (key) {
      case 'searchId':
        return object.name || object.searchId || object.searchMatched
      case 'reference':
        return object.acgih ? 'Sim' : 'Não'
      case 'nr15-base':
        // return object.attachment11 ? 'Anexo 11' : 'Ver aba Laudo de Insalubridade'
        return object.attachment12 || object.attachment13
          ? 'Ver aba Laudo de Insalubridade'
          : object.attachment11
          ? 'Anexo 11'
          : '-'
      case 'ceil':
        return object.attachment12 || object.attachment13
          ? 'hidden'
          : object.attachment11
          ? object.attachment11.ceil
          : '-'
      case 'skinAbsorption':
        return object.attachment12 || object.attachment13
          ? 'hidden'
          : object.attachment11
          ? object.attachment11.skinAbsorption
          : '-'
      case 'mgm':
        return object.attachment12 || object.attachment13
          ? 'hidden'
          : object.attachment11
          ? object.attachment11.limit1
          : '-'
      case 'ppm':
        return object.attachment12 || object.attachment13
          ? 'hidden'
          : object.attachment11
          ? object.attachment11.limit2
          : '-'
      case 'insalubrity':
        return object.attachment12 || object.attachment13
          ? 'hidden'
          : object.attachment11
          ? object.attachment11.insalubrity
          : '-'
      case 'group':
        return object.linach
          ? object.linach.group
            ? object.linach.group
            : 'Não'
          : 'Não'
      case 'priority':
        return object.linach
          ? object.linach.priority
            ? object.linach.priority
            : 'Não'
          : 'Não'
      case 'esocial':
        return object.table24
          ? object.table24.code
            ? object.table24.code
            : 'Não'
          : 'Não'
      case 'decree3048':
        return object.decree3048
          ? object.decree3048.notation
            ? object.decree3048.notation
            : 'Não'
          : 'Não'
      case 'situation':
        return object.decree3048
          ? object.decree3048.activities
            ? object.decree3048.activities.join('<br/>')
            : '-'
          : '-'
      case 'condition':
        return object.decree3048
          ? 'O agente possui condições de enquadramento conforme expresso no Decreto 3048/99 - Anexo IV.'
          : '-'
      default:
        return '-'
    }
  },
  tableFields: [
    { key: 'searchId' },
    { key: 'reference' },
    { key: 'nr15-base' },
    { key: 'ceil' },
    { key: 'skinAbsorption' },
    { key: 'mgm' },
    { key: 'ppm' },
    { key: 'insalubrity' },
    { key: 'group' },
    { key: 'esocial' },
    { key: 'decree3048' }
  ]
}
