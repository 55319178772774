import { differenceInDays } from "date-fns";

const surveys = {
  download: {
    name: 'hofacil:survey:download',
    daysNext: 14,
  },
  login: {
    name: 'hofacil:survey:login',
    daysNext: 21,
  }
}

export function setDateLastSurveyLocal(name) {
  const survey = surveys[name];
  const now = new Date();

  window.localStorage.setItem(survey.name, now.toISOString());
}

export function isOpenSurvey(name) {
  /*const survey = surveys[name];
  const dateLastSurvey = window.localStorage.getItem(survey.name);

  if (!dateLastSurvey) {
    return true;
  } else {
    const now = new Date();

    const diffDays = differenceInDays(new Date(dateLastSurvey), now);

    if (diffDays >= 30) {
      return true;
    }
  }*/

  return false;
}
