export const optionsExposureTime = [
  {
    key: 1,
    value: 'Menos que 10 min',
  },
  {
    key: 2,
    value: '10-30 min',
  },
  {
    key: 3,
    value: '30-60 min',
  },
  {
    key: 4,
    value: '1-2h horas',
  },
  {
    key: 5,
    value: '2-4 horas',
  },
  {
    key: 6,
    value: 'Mais que 4 horas',
  }
]
