<template>
  <div class='mt-1'>
    <b-modal
      ref='products'
      id='products'
      hide-footer
      hide-header
      header-bg-variant='info'
      header-text-variant='light'
      size='xl'
      @show='showModal()'
    >
      <b-overlay
        :show='lookingForCompany'
        rounded
        opacity='0.6'
        spinner-small
        spinner-variant='primary'
        class='d-inline-block w-100'
        style='min-height: 150px'
      >
        <section class='modal-font-family'>
          <section class='mb-3'>
            <section class='d-flex align-content-center justify-content-between '>
              <section>
                <p class='products-title mt-1'>
                  Selecione os produtos para o Cargo
                </p>
              </section>

              <section>
                <b-icon
                  style='cursor: pointer'
                  @click='closeModal()'
                  class='ml-1'
                  font-scale='2.5'
                  icon='x'
                  color='#262626'
                >
                </b-icon>
              </section>
            </section>
            <section>
              <p class='products-sub-title'>
                Selecione os produtos que serão adicionados ao cargo. É possível adicionar mais de um.
              </p>
            </section>
          </section>

          <section
            style='display: grid;  grid-template-columns: repeat(auto-fill, minmax(25%, auto));'
          >
            <b-form-checkbox-group
              v-model='selected'
              v-for='(product, i) in products'
              :key='i'
              class='product'
              size="lg"
            >
              <b-form-checkbox
                class=''
                :value='product._id'
              >
                <p class='word-break product-name mt-2'>{{ product.name }}</p>
                <section
                  v-for='(legalSearch, l) in product.legalSearches'
                  :key='l._id'

                  class='mb-2'
                >
                  <span class='product-agent word-break'>{{ legalSearch.name }}</span>
                </section>
              </b-form-checkbox>
            </b-form-checkbox-group>
          </section>

          <section class='d-flex justify-content-between mt-4'>
            <base-button
              class='w-25 mr-3 btn-cancel'
              @click='closeModal()'
            >
              <span>Cancelar</span>
            </base-button>

            <b-overlay
              :show='loading'
              rounded
              opacity='0.6'
              spinner-small
              spinner-variant='primary'
              class='d-inline-block w-75'
            >
              <base-button
                class='w-100 btn-add d-flex align-items-center justify-content-center'
                @click='create()'
              >
                <span class='mr-2'> Finalizar adição de produtos </span>
              </base-button>
            </b-overlay>
          </section>
        </section>

        <template v-slot:overlay>
          <div class='text-center loading-spinner'>
            <b-spinner
              style='width: 2rem; height: 2rem'
              variant='primary'
              label='Large Spinner'
            ></b-spinner>
            <h6 class='text-primary'>Buscando setor...</h6>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Sector',
  components: {},
  props: {
    sectorId: {
      type: String,
      required: false,
      default: null
    },
    lisProductsId: {
      type: Array,
      required: false,
      default: null
    },
    unitId: {
      type: String,
      required: false,
      default: null
    },
    roleId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      lookingForCompany: false,
      products: [],
      selected: [],
      sector: {
        name: '',
        _id: null
      }
    }
  },
  async mounted() {
    try {
    } catch (e) {
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    },
    disabled() {
      return this.selected.length === 0
    },
    ...mapGetters('companyLegalConsultation', {
      importedCompany: 'importedCompany'
    })
  },
  methods: {
    ...mapActions('sector', {
      handleCreate: 'handleCreate',
      handleGet: 'handleGet',
      handleUpdade: 'handleUpdade'
    }),
    ...mapActions('productLegalConsultation', {
      handleGetCompanyUserProductLegalConsultation: 'handleGetCompanyUserProductLegalConsultation',
      handleAddUpdateRoleProductLegalConsultation: 'handleAddUpdateRoleProductLegalConsultation'
    }),
    async showModal() {
      try {
        await this.getProducts()
        this.selected = this.lisProductsId
      } catch (e) {

      }
    },

    async create() {
      try {
        const payload = {
          role: this.roleId,
          products: this.selected
        }
        this.loading = true
        const { data, status } = await this.handleAddUpdateRoleProductLegalConsultation(payload)
        this.loading = false
        let message = data.message
        let variant = 'success'
        if (status !== 200) {
          message = data.errors
          variant = 'danger'
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        await this.closeModal()
        this.$emit('update')
      } catch (e) {
      }
    },

    async update() {
      try {
        this.loading = true
        const { data, status } = await this.handleUpdade(this.sector)
        this.loading = false
        let message = data.message
        let variant = 'success'
        if (status !== 200) {
          message = data.errors
          variant = 'danger'
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        await this.closeModal()
        this.$emit('update')
      } catch (e) {
      }
    },

    async get() {
      try {
        this.lookingForCompany = true
        const { data, status } = await this.handleGet(this.sectorId)
        const { sector } = data
        this.sector = sector
        this.lookingForCompany = false
      } catch (e) {
      }
    },

    async getProducts() {
      try {
        this.lookingForCompany = true
        const params = {
          companyId: this.importedCompany._id,
          roleId: this.roleId
        }
        const { data, status } = await this.handleGetCompanyUserProductLegalConsultation(params)
        const { products } = data
        this.products = products
        this.lookingForCompany = false
      } catch (e) {
      }
    },

    closeModal() {
      this.$bvModal.hide('products')
    }
  },
  created() {
  }
}
</script>
<style lang='scss' scoped>
.products-title {
  color: #262626;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.products-sub-title {
  color: #808080;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: -10px
}

.product {
  border-radius: 10px;
  border: 1px solid var(--navy-blue);
  padding: 5px;
  margin: 12px 12px 0 0;
}
.product-name{
  color: var(--navy-blue);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.product-agent{
  color: #808080;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.btn-cancel {
  background: transparent !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: var(--navy-blue);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.btn-add {
  background: var(--navy-blue) !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}
</style>
