<template>
  <section>
    <b-modal
      id='unqualifiedCasInformation'
      size='lg'
      header-bg-variant='info'
      header-text-variant='light'
      title='Resumo da importação dos agentes'
      ref='unqualifiedCasInformation'
      hide-footer
      scrollable
    >
      <section class='modal-font-family'>
        <h6>Agentes importados: {{ amountImportedAagents }}</h6>
        <h6>Agentes não classificados, (Solicitação de classificação enviada): {{ amountAgentsClassificationRequest }} </h6>
        <h6>Agentes adicionados anteriormente: {{ amountAgentsPreviouslyAdded }}</h6>
        <h6>Agentes com número cas inválido: {{ amountAgentsCASInvalid }} </h6>
        <b-card-group
          deck
          v-for='(result, i) in agentImportSummaryByCas'
          :key='i'
        >
          <b-card class='mx-1 my-1' :header='`CAS: ${result.cas}`'>
            <b-list-group v-for='(message, x) in result.summary' :key='x'>
              <b-list-group-item>{{ message }}</b-list-group-item>
            </b-list-group>
          </b-card>
        </b-card-group>
      </section>
    </b-modal>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      id='uploadLisCas'
      size='lg'
      header-bg-variant='info'
      header-text-variant='light'
      title='Importação de agentes'
      ref='uploadLisCas'
      hide-footer
      @show='init'
    >
      <section class='modal-font-family'>
        <div v-if='showProgressBar' class='text-center mb-3'>
          <span><strong>{{ importedPrcentage }}% / 100%</strong></span>
          <b-progress :value='importedPrcentage' :max='100' class='mb-3' height='1.2rem'>
          </b-progress>
        </div>

        <div v-else class='mb-3'>
          <span class=''><strong class='text-danger'>Atenção: </strong>{{ message }}</span>
        </div>

        <div>
          <hr />
        </div>

        <div class='mb-3'>
          <label class='mt-1 label-functionality'>Importar arquivo contendo número CAS dos agentes</label>
          <div class='d-flex justify-content-between w-100 '>
            <b-button
              class='w-50 mr-2'
              href='https://files-hofacil.s3.sa-east-1.amazonaws.com/1663598170652'
              target='_blank' variant='outline-primary'>
              Arquivo de exemplo
            </b-button>

            <div class='d-flex justify-content-between w-50'>
              <div>
                <b-spinner
                  v-if='loading'
                  class='mx-3'
                  variant='primary'
                  label='Spinning'
                ></b-spinner>
              </div>
              <b-form-file
                v-model='files'
                placeholder='Selecione um arquivo'
                accept='.xlsx'
                @input='upload()'
              >
              </b-form-file>
            </div>
          </div>
        </div>

        <div v-if='releaseCompanyMode(user)'>
          <div v-if='companies.length > 0'>
            <div>
              <label class='mt-1 label-functionality'>Selecione o tipo de importação</label>
              <b-form-select
                v-model='importType'
                :options='importTypes'
                class='mb-4'
                style='height: 50px'
                value-field='key'
                text-field='text'
              >
                <template #first>
                  <b-form-select-option :value='null' disabled>Selecione a importação</b-form-select-option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div v-if="importType && importType !== 'file'">
            <div class=''>
              <label class='mt-1 label-functionality'>Selecione a empresa</label>
              <b-form-select
                v-model='company'
                :options='companies'
                class='mb-4'
                style='height: 50px'
                value-field='_id'
                text-field='name'
                @change='setCompany'
              >
                <template #first>
                  <b-form-select-option :value='null' disabled>Selecione a empresa</b-form-select-option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div class='mt-2' v-if="products.length > 0 && company && importType && importType === 'product'">
            <div class=''>
              <label class='mt-1 label-functionality'>Selecione o produto</label>
              <b-form-select
                v-model='product'
                :options='products'
                class='mb-4'
                style='height: 50px'
                value-field='_id'
                text-field='name'
                @change='setProduct'
              >
                <template #first>
                  <b-form-select-option :value='null' disabled>Selecione o produto</b-form-select-option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div v-if="company && products.length <= 0 && importType && importType === 'product'">
            <p>
              <strong class='text-danger'>Atenção: </strong>
              A empresa <strong>{{ this.companies.find((x) => x._id === company).name }}</strong> não possui produtos
              cadastrados.
            </p>
          </div>
        </div>

        <div>
          <b-alert
            class='mb-1 mt-2 text-left'
            :show='dismissCountDown'
            dismissible
            variant='danger'
            @dismissed='dismissCountDown = 0'
            @dismiss-count-down='countDownChanged'
          >
            <span class='text-left'>{{ errors }}</span>
          </b-alert>
        </div>

        <div class='pt-4 w-100 d-flex'>
          <b-button
            :class="(company && products.length <= 0 && importType && importType === 'product') ? 'w-100' : 'w-50'"
            @click='$refs.uploadLisCas.hide()'
            variant='danger'>
            Cancelar
          </b-button>

          <b-overlay
            :show='importing'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='w-50 ml-2'
            v-if="!(company && products.length <= 0 && importType && importType === 'product')"
          >
            <b-button
              class='w-100'
              @click='processFileXLSX()'
              variant='success'
              :disabled='!whichFunctionalityToImport'
            >
              Importar
            </b-button>
          </b-overlay>
        </div>
      </section>
    </b-modal>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      id='importCompany'
      size='lg'
      header-bg-variant='info'
      header-text-variant='light'
      title='Trabalho em modo empresa'
      ref='importCompany'
      hide-footer
    >
      <section class='modal-font-family'>
        <div v-if='showProgressBar' class='text-center mb-3'>
          <span><strong>{{ importedPrcentage }}% / 100%</strong></span>
          <b-progress :value='importedPrcentage' :max='100' class='mb-3' height='1.2rem'>
          </b-progress>
        </div>

        <div class='mt-2' v-if='companies.length > 0'>
          <div class=''>
            <label class='mt-1 label-functionality'>Selecione abaixo a empresa que você quer trabalhar</label>
            <b-form-select
              v-model='company'
              :options='companies'
              class='mb-4'
              style='height: 50px'
              value-field='_id'
              text-field='name'
              @change='setCompany'
            >
              <template #first>
                <b-form-select-option :value='null' disabled>Selecione a empresa</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>

        <div>
          <b-alert
            class='mb-1 mt-2 text-left'
            :show='dismissCountDown'
            dismissible
            variant='danger'
            @dismissed='dismissCountDown = 0'
            @dismiss-count-down='countDownChanged'
          >
            <span class='text-left'>{{ errors }}</span>
          </b-alert>
        </div>

        <div class='pt-4 w-100 d-flex'>
          <b-button
            class='w-50 mr-2'
            @click='$refs.importCompany.hide()'
            variant='danger'>
            Cancelar
          </b-button>

          <b-overlay
            :show='importing'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='w-50'
          >
            <b-button
              class='w-100'
              @click='processFileXLSX()'
              variant='success'
            >
              Importar
            </b-button>
          </b-overlay>
        </div>
      </section>
    </b-modal>

    <b-modal
      id='limitedDailySearches'
      size='lg'
      header-bg-variant='danger'
      header-text-variant='light'
      :title="`Atingido o limite de adições ${dailySearches.plan === 'TRIAL' ? 'diária' : 'mensal'}`"
      ref='limitedDailySearches'
      hide-footer
    >
      <section class='modal-font-family'>
        <p v-if="dailySearches.plan === 'TRIAL'">
          O limite do plano gratuito de adições em 24 horas foi atingido.
          Aguarde até {{ dailySearches.expireAt | moment('timezone', 'America/Sao_Paulo', 'DD/MM/YYYY HH:mm') }}
          para poder pesquisar mais agentes químicos ou tenha acesso aos recursos completos adquirindo um dos planos
          pagos.
          Clique sobre o texto a seguir para conferir os planos disponíveis:
          <strong><a href='/planos'> Meus Planos </a></strong>
        </p>
        <p v-else>
          O limite de agentes que podem ser adicionados dentro do período mensal da sua assinatura foi atingido.
          Assim que o uma nova fatura for gerada e estiver com status de paga na plataforma de pagamentos,
          os recursos de consulta de agentes químicos serão renovados. Para consultar agentes químicos sem limites,
          bem como ter acesso à todas as ferramentas da plataforma, clique no texto a seguir para
          <a href='/checkout?plano=AN1HO'> Adquirir um plano anual</a>.
        </p>
      </section>
    </b-modal>

    <b-modal
      id='addedAgentLimits'
      size='lg'
      header-bg-variant='danger'
      header-text-variant='light'
      :title='title'
      ref='addedAgentLimits'
      hide-footer
    >
      <section class='modal-font-family'>
        <p>
          {{ message }}
        </p>
        <b-row class='w-100 justify-content-end'>
          <b-button align='right' variant='success' class='px-3' @click="$bvModal.hide('addedAgentLimits')">Ok
          </b-button>
        </b-row>
      </section>
    </b-modal>

    <b-modal
      id='registerProductOrImport'
      ref='registerProductOrImport'
      header-bg-variant='info'
      header-text-variant='light'
      title='Trabalho em modo empresa'
      hide-footer
      size='lg'
    >
      <section class='modal-font-family m-0 p-0'>
        <section>
          <p><strong>Selecione a opção desejada:</strong></p>
        </section>

        <section class='w-100 d-flex'>
          <b-button
            variant='success'
            class='w-50 mr-1'
            @click='showModalImportCompany()'
            style='font-size: 14.1px'
          >
            Editar informações de uma empresa já cadastrada
          </b-button>

          <b-button
            variant='success'
            class='w-50'
            style='font-size: 14.1px'
            @click="$bvModal.show('company')"
          >
            Cadastrar empresa
          </b-button>
        </section>
      </section>
    </b-modal>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      id='duplicateProducts'
      ref='duplicateProducts'
      size='xl'
      header-bg-variant='info'
      header-text-variant='light'
      hide-footer
    >
      <template #modal-header='{ close }'>
        <h5>Produtos duplicados</h5>
      </template>
      <section class='modal-font-family w-100'>
        <b-list-group
          v-for='(product, i) in duplicateProducts'
          :key='i'
          class='w-100'
        >
          <section :class="i > 0 ? 'mt-4' : ''">
            <h6>Na Consulta Legal não é permitido que existam dois produtos com o mesmo nome. <br/><br/>
              Revise e edite um dos dois produtos apresentados a seguir:</h6>
          </section>

          <section  class='d-flex mt-2'>
            <b-list-group-item
              style='border: 1px solid rgba(0, 0, 0, 0.125);!important;'
              class='w-100 mr-2'
              v-for='(repeatedProduct, r) in product.repeatedProducts'
              :key='r'
            >
              <section class=' w-100'>
                <section class='d-flex justify-content-between'>
                  <section>
                    <p><strong>Nome: </strong>{{ repeatedProduct.product.name }}</p>
                    <p><strong>Descrição: </strong>{{ repeatedProduct.product.description }}</p>
                    <p>
                      <span v-if='repeatedProduct.product.urlFISPQ'><strong>FISPQ: </strong>
                        <a :href='repeatedProduct.product.urlFISPQ' target='_blank'>
                          Ver arquivo anexado
                        </a>
                      </span>
                      <span v-else><strong>FISPQ: </strong>
                        -
                      </span>
                    </p>
                  </section>

                  <section>
                    <b-button
                      variant='success'
                      @click="callGlobalCustomEvent('showModalAddProduct', repeatedProduct.product._id)"
                    >
                      Editar produto
                    </b-button>
                  </section>
                </section>

                <strong class='mb-3'>Composição</strong><br/>
                <section class='ml-1 mb-1' v-for='(agents, i) in repeatedProduct.product.legalSearches'>
                  <strong>Cas: Agente: </strong> {{agents.name}}<br/>
                </section>
              </section>
            </b-list-group-item>
          </section>
        </b-list-group>
      </section>
    </b-modal>
  </section>
</template>

<script>
import XLSX from 'xlsx'
import eventBus from '@/utils/eventBus'
import { mapActions, mapGetters } from 'vuex'
import { releaseCompanyMode } from '@/utils/releaseEnterpriseModeFunctionality'

export default {
  name: 'ImporterAgents',
  props: {},
  data() {
    return {
      LIMIT_AGENTS_ADDED_IN_AGENT_SELECTION: 1000,
      NUMBER_OF_AGENTS_PER_IMPORT: 30,
      loading: false,
      importing: false,
      showProgressBar: false,
      showFunctionalityKeepDeleteAgents: true,
      functionality: null,
      importFile: 1,
      importCompany: 2,
      importProduct: 3,
      message: '',
      title: '',
      files: null,
      casList: [],
      howManyAgentsCanAdd: 0,
      numberOfImportedAgents: 0,
      importedPrcentage: 0,
      chunks: [],
      agent: 'success',
      casSearch: '',
      dailySearches: {},
      itemsToRemove: [],
      amountImportedAagents: 0,
      amountAgentsClassificationRequest: 0,
      amountAgentsPreviouslyAdded: 0,
      amountAgentsCASInvalid: 0,
      agentImportSummaryByCas: [],
      companies: [],
      importType: null,
      whichFunctionalityToImport: null,
      importTypes: [],
      products: [],
      company: null,
      product: null,
      legalSearchesId: [],
      dismissCountDown: 0,
      dismissSecs: 10,
    }
  },
  async mounted() {
    eventBus.$on('showModalUploadLisCas', async () => {
      await this.showModalUploadLisCASNumber()
    })
    eventBus.$on('importAgentsCompany', async () => {
      await this.showModalImportCompany()
    })
  },
  beforeDestroy() {
    eventBus.$off('handleDownloadPdf')
    eventBus.$off('showModalUploadLisCas')
  },
  computed: {
    ...mapGetters('userPlans', {
      limitationOfAgentsAdded: 'limitationOfAgentsAdded'
    }),
    ...mapGetters('classificationRequests', {
      classificationRequest: 'getClassificationRequest'
    }),
    ...mapGetters('companyLegalConsultation', {
      importedCompany: 'importedCompany'
    }),
    ...mapGetters('legalSearch', {
      thereAreDuplicateProducts: 'thereAreDuplicateProducts',
      duplicateProducts: 'duplicateProducts'
    }),
    errors() {
      return this.$store.getters['access/error']
    },
    hasSelected() {
      return this.itemsToRemove.length > 0
    },
    selectedAll: {
      get: function() {
        return this.itemsToRemove.length === this.summary.length
      },
      set: function() {
      }
    },
    summary() {
      return this.$store.getters['legalSearch/summary']
    },
    summaryMeta() {
      return this.$store.getters['legalSearch/summaryMeta']
    },
    agentsPerPage() {
      return this.$store.getters['legalSearch/agentsPerPage']
    },
    user() {
      return this.$store.getters['user/current']
    },
    selectedAgent: {
      get: function() {
        return this.$store.getters['legalSearch/selectedAgent']
      },
      set: function(agent) {
        this.$store.dispatch('legalSearch/select', agent)
      }
    }
  },
  methods: {
    releaseCompanyMode,
    ...mapActions('companyLegalConsultation', {
      handleGetCompanyLegalConsultation: 'handleGetCompanyLegalConsultation',
      handleMarkAsLastImportedCompanyLegalConsultation: 'handleMarkAsLastImportedCompanyLegalConsultation',
      handleGetLastImportedCompanyLegalConsultation: 'handleGetLastImportedCompanyLegalConsultation',
      handleDeleteAgentCompanyLegalConsultation: 'handleDeleteAgentCompanyLegalConsultation',
      handleAddAgentCompanyLegalConsultation: 'handleAddAgentCompanyLegalConsultation',
      handleUnmarkAllAsLastImportedCompanyLegalConsultation: 'handleUnmarkAllAsLastImportedCompanyLegalConsultation'
    }),
    ...mapActions('productLegalConsultation', {
      handleGetCompanyProductLegalConsultation: 'handleGetCompanyProductLegalConsultation',
      handleAddAgentProductLegalConsultation: 'handleAddAgentProductLegalConsultation',
      handleCreatewithoutAgentsProductLegalConsultation: 'handleCreatewithoutAgentsProductLegalConsultation'
    }),
    ...mapActions('objectivesAnalysis', {
      handleGetObjectivesAnalysis: 'handleGetObjectivesAnalysis',
      handleUpdateChangedAgentsLegalConsultation: 'handleUpdateChangedAgentsLegalConsultation'
    }),
    ...mapActions('userPlans', {
      handleGetAddedAgentsLimitation: 'handleGetAddedAgentsLimitation'
    }),
    ...mapActions('legalSearch', {
    }),
    ...mapActions('freeModeAgents', {
      handleCreateFreeModeAgents: 'handleCreateFreeModeAgents',
      handleAddAgentsFreeModeAgents: 'handleAddAgentsFreeModeAgents',
    }),
    ...mapActions('userActionLogs', {
      createUserActionLogs: 'createUserActionLogs',
    }),
    callGlobalCustomEvent(event, productId) {
      eventBus.$emit(event, productId)
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert(dismissSecs) {
      this.dismissCountDown = dismissSecs
    },

    async init() {
      try {
        this.importType = null
        this.whichFunctionalityToImport = null
        await this.handleGetLastImportedCompanyLegalConsultation(this.user.id)
      } catch (e) {

      }
    },

    async upload() {
      try {
        this.functionality = this.importFile
        this.company = null
        this.product = null
        this.whichFunctionalityToImport = 'file'
        this.casList = []
        const fileReader = new FileReader()
        fileReader.onload = async (event) => {
          const data = event.target.result
          const workbook = XLSX.read(data, {
            type: 'binary'
          })
          for (const sheet of workbook.SheetNames) {
            let rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet])
            this.casList = JSON.parse(JSON.stringify(rowObject))
          }
        }
        await fileReader.readAsBinaryString(this.files)
      } catch (e) {

      }
    },

    setCompany(company) {
      this.files = null
      this.product = null
      this.functionality = this.importCompany
      this.casList = []
      const { legalSearches, products } = this.companies.find((x) => x._id === company)
      this.products = products && products.length > 0 ? products.filter((product) => product.legalSearches && product.legalSearches.length > 0) : []
      this.casList = legalSearches
      this.whichFunctionalityToImport = 'company'
    },

    setProduct(product) {
      this.files = null
      this.functionality = this.importProduct
      this.casList = []
      const { legalSearches } = this.products.find((x) => x._id === product)
      this.casList = legalSearches
      this.whichFunctionalityToImport = 'product'
    },

    async getCompanies() {
      try {
        const { data, status } = await this.handleGetCompanyLegalConsultation(this.user.id)
        this.companies = status === 200 && data && data.companies ? data.companies : []
      } catch (e) {

      }
    },

    async getProducts() {
      try {
        const { data, status } = await this.handleGetCompanyProductLegalConsultation(this.user.id)
        this.products = status === 200 && data && data.products ? data.products : []
      } catch (e) {

      }
    },

    async showModalImportCompany() {
      try {
        if (this.checkImportLimits()) {
          this.$refs.importCompany.show()
          await this.getCompanies()
        }
      } catch (e) {

      }
    },

    async showModalUploadLisCASNumber() {
      try {
        this.importTypes = [
          {
            key: 'company',
            text: 'Empresa'
          },
        ]

        if (this.importedCompany && this.importedCompany._id) {
          this.importTypes = [
            {
              key: 'product',
              text: 'Produto'
            }
          ]
        }

        if (this.checkImportLimits()) {
          this.$refs.uploadLisCas.show()
          await this.getCompanies()
        }
      } catch (e) {

      }
    },

    async processFileXLSX() {
      try {
        if (!this.whichFunctionalityToImport) {
          await this.$store.dispatch('access/updateError', `Selecione uma empresa ou produto para importar.`)
          this.showAlert(5)
          return
        } else if (this.importType === 'product' && !Boolean(this.product)) {
          await this.$store.dispatch('access/updateError', `Selecione o produto para importar.`)
          this.showAlert(5)
          return
        } else if (this.importType === 'company' && !Boolean(this.company)) {
          await this.$store.dispatch('access/updateError', `Selecione a empresa para importar.`)
          this.showAlert(5)
          return
        } else if (this.importType === 'file' && !Boolean(this.files)) {
          await this.$store.dispatch('access/updateError', `Faça upload do arquivo para importar.`)
          this.showAlert(5)
          return
        }

        this.loading = true
        if (this.limitationOfAgentsAdded) {
          if (this.casList.length > this.limitationOfAgentsAdded.limitation) {
            this.loading = false
            const title = `Limite de importação de agentes`
            const message = `O limite máximo de agentes que pode ser importado de uma única vez é de ${this.limitationOfAgentsAdded.limitation} agentes!`
            this.addedAgentLimits(title, message)
            return
          }

          this.howManyAgentsCanAdd = this.limitationOfAgentsAdded.limitation - this.limitationOfAgentsAdded.added
          if (this.casList.length > this.howManyAgentsCanAdd) {
            this.loading = false
            const title = `Limite de agentes adicionados`
            const message = `Seu limite mensal de adições de agentes químicos é de: ${this.limitationOfAgentsAdded.limitation} agentes. Você esta tentando importar uma lista com ${this.casList.length} agentes,
                  mas você pode adicionar apenas mais ${this.howManyAgentsCanAdd} agentes.`
            this.addedAgentLimits(title, message)
            return
          }
        }

        if (this.casList.length > this.LIMIT_AGENTS_ADDED_IN_AGENT_SELECTION) {
          this.loading = false
          const title = `O limite de importação`
          const message = `O limite máximo de agentes que pode ser importado de uma única vez é de ${this.LIMIT_AGENTS_ADDED_IN_AGENT_SELECTION} registros!`
          this.addedAgentLimits(title, message)
          return
        }

        const numberOfAgentsThatCanBeAdded = this.LIMIT_AGENTS_ADDED_IN_AGENT_SELECTION - this.summaryMeta.total
        if (this.casList.length > numberOfAgentsThatCanBeAdded) {
          this.loading = false
          const title = `Limite de agentes adicionados`
          const message = `O limite máximo de agentes que pode ter na 'Seleção de agentes' é de ${this.LIMIT_AGENTS_ADDED_IN_AGENT_SELECTION} agentes. Você esta tentando importar uma lista com ${this.casList.length} agentes,
              mas você pode adicionar apenas mais ${numberOfAgentsThatCanBeAdded} agentes. Precisa remover agentes da 'Seleção de agentes' ou importar apenas ${numberOfAgentsThatCanBeAdded} agentes.`
          this.addedAgentLimits(title, message)
          return
        }

        if (this.functionality === this.importFile) {
          if (this.casList.filter((x) => x.hasOwnProperty('cas')).length < 1) {
            this.$bvToast.toast('Arquivo incorreto, use o arquivo de exemplo!', {
              title: 'Aviso',
              variant: 'danger',
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000
            })
            this.loading = false
            this.files = null
            return
          }
          await this.importListCAS(this.casList)
        } else {
          await this.importAgentsCompany(this.casList)
        }
      } catch (e) {
        this.loading = false
      }
    },

    async importListCAS(casList) {
      try {
        this.callGlobalCustomEvent('clearItems', null)
        let index = 1
        this.importing = true;
        this.legalSearchesId = []
        this.summary.forEach((agent) => {
          const indexAgent = casList.findIndex((cas) => cas.cas === agent.cas)
          if (indexAgent >= 0) {
            casList.splice(indexAgent, 1)
            this.agentImportSummaryByCas.push({
              cas: agent.cas,
              summary: ['Agente tinha sido adicionado anteriormente.']
            })
            this.amountAgentsPreviouslyAdded++
          }
        })
        this.NUMBER_OF_AGENTS_PER_IMPORT = Math.ceil((casList.length / 100) * 12)

        this.chunks = casList.reduce((array, item, index) => {
          const chunkIndex = Math.floor(index / this.NUMBER_OF_AGENTS_PER_IMPORT)
          if (!array[chunkIndex]) {
            array[chunkIndex] = []
          }
          array[chunkIndex].push(item)
          return array
        }, [])

        this.showProgressBar = true
        for (const cas of this.chunks) {
          const { importSummary } = (await this.$store.dispatch('legalSearch/importList', {
              user: this.user.id,
              casList: cas,
              acgih_data: {}
            })
          ).data
          this.legalSearchesId.push(...importSummary.legalSearchesAddId)
          this.amountImportedAagents += importSummary.amountImportedAagents
          this.amountAgentsClassificationRequest += importSummary.amountAgentsClassificationRequest
          this.amountAgentsPreviouslyAdded += importSummary.amountAgentsPreviouslyAdded
          this.amountAgentsCASInvalid += importSummary.amountAgentsCASInvalid
          this.agentImportSummaryByCas.push(...importSummary.agentImportSummaryByCas)
          this.importedPrcentage = Math.floor((100 / this.chunks.length) * index)
          index++
        }
        await this.load()
        setTimeout(async () => {
          this.showProgressBar = false
          this.$refs.uploadLisCas.hide()
          this.$refs.unqualifiedCasInformation.show()
          this.loading = false
          await this.handleGetAddedAgentsLimitation(this.user)
          const sessionId = localStorage.getItem('sessionId')
          await this.$api.delete(`users/cache/${sessionId}`);
          const user = (await this.$api.get(`users/${sessionId}`)).data
          await this.$store.dispatch('user/updateUser', user)
          await this.handleUpdateChangedAgentsLegalConsultation(true)
        }, 1000)

        if (this.importedCompany && this.importedCompany._id) {
          const bodyAddAgentCompany = {
            user: this.user.id,
            companyId: this.importedCompany._id,
            legalSearchesId: this.legalSearchesId
          }
          await this.handleAddAgentCompanyLegalConsultation(bodyAddAgentCompany)

          this.$bvToast.toast(`As informações da empresa foram atualizadas.`, {
            title: 'Atenção',
            variant: 'success',
            toaster: 'b-toaster-top-center',
            autoHideDelay: 6000
          })

        } else {
          await this.handleAddAgentsFreeModeAgents(
            {
              user: this.user.id,
              agents: this.legalSearchesId
            }
          )
        }
        this.importing = false;
        if (this.user && this.user.role !== 'admin') {
          const log = {
            action: 'IMPORTOU_LISTA_AGENTES_CONSULTA_LEGAL'
          }
          await this.createUserActionLogs(log)
        }
      } catch (e) {
        console.error(e)
      }
    },

    async importAgentsCompany(casList) {
      try {
        this.callGlobalCustomEvent('clearItems', null)
        let index = 1
        this.NUMBER_OF_AGENTS_PER_IMPORT = Math.ceil((casList.length / 100) * 12)
        this.legalSearchesId = []
        let urlImport = 'importAgentsCompany'
        let productCreate = null
        this.importing = true;
        if (this.functionality === this.importProduct) {
          urlImport = 'importAgentsProduct'
          const agentProduct = casList.length > 0 ? casList[0] : null
          if (agentProduct) {
            const { data } = await this.handleCreatewithoutAgentsProductLegalConsultation(
              {
                productId: agentProduct.productId,
                companyId: this.importedCompany && this.importedCompany._id ? this.importedCompany._id : null
              }
            )
            const { productLegalConsultation } = data
            productCreate = productLegalConsultation
            casList.forEach((x) => {
              x.productId = productLegalConsultation._id
              x.product = productLegalConsultation._id
              x.companyId = productLegalConsultation.company ? productLegalConsultation.company : null
            })
          }
        }

        this.chunks = casList.reduce((array, item, index) => {
          const chunkIndex = Math.floor(index / this.NUMBER_OF_AGENTS_PER_IMPORT)
          if (!array[chunkIndex]) {
            array[chunkIndex] = []
          }
          array[chunkIndex].push(item)
          return array
        }, [])

        this.showProgressBar = true

        for (const legalSearches of this.chunks) {
          const { importSummary } = (await this.$store.dispatch(`legalSearch/${urlImport}`, {
              user: this.user.id,
              legalSearches
            })
          ).data
          this.legalSearchesId.push(...importSummary.legalSearchesAddId)
          this.amountImportedAagents += importSummary.amountImportedAagents
          this.amountAgentsClassificationRequest += importSummary.amountAgentsClassificationRequest
          this.amountAgentsPreviouslyAdded += importSummary.amountAgentsPreviouslyAdded
          this.amountAgentsCASInvalid += importSummary.amountAgentsCASInvalid
          this.agentImportSummaryByCas.push(...importSummary.agentImportSummaryByCas)
          this.importedPrcentage = Math.floor((100 / this.chunks.length) * index)
          index++
        }
        await this.load()

        setTimeout(async () => {
          this.showProgressBar = false
          this.$refs.uploadLisCas.hide()
          this.$refs.importCompany.hide()
          this.$refs.registerProductOrImport.hide()
          this.$refs.unqualifiedCasInformation.show()
          this.loading = false
          await this.handleGetAddedAgentsLimitation(this.user)
          const sessionId = localStorage.getItem('sessionId')
          const user = (await this.$api.get(`users/${sessionId}`)).data
          await this.$store.dispatch('user/updateUser', user)
          await this.handleUpdateChangedAgentsLegalConsultation(true)
        }, 500)

        if (this.functionality === this.importCompany) {
          await this.handleUnmarkAllAsLastImportedCompanyLegalConsultation(this.user.id)
          await this.handleMarkAsLastImportedCompanyLegalConsultation(
            {
              user: this.user.id,
              companyLegalConsultationId: this.company
            }
          )
          await this.handleGetLastImportedCompanyLegalConsultation(this.user.id)
          await this.handleCreateFreeModeAgents(
            {
              user: this.user.id,
              agents: this.legalSearchesId,
            }
          )
          await this.$store.dispatch('legalSearch/notRemove', this.legalSearchesId)
        } else if (this.functionality === this.importProduct && this.importedCompany && this.importedCompany._id) {
          await this.handleAddAgentProductLegalConsultation(
            {
              user: this.user.id,
              productId: productCreate._id,
              legalSearchesId: this.legalSearchesId,
              companyId: this.importedCompany._id
            }
          )

          await this.handleAddAgentCompanyLegalConsultation(
            {
              user: this.user.id,
              companyId: this.importedCompany._id,
              legalSearchesId: this.legalSearchesId
            }
          )
        }
        await this.load()
        this.importing = false;
      } catch (e) {
        console.log()
      }
    },

    async load() {
      await this.$store.dispatch('legalSearch/load', this.user.id)
    },

    userReachedLimitAddedAgents() {
      return this.limitationOfAgentsAdded && this.limitationOfAgentsAdded.limitation <= this.limitationOfAgentsAdded.added
    },

    checkImportLimits() {
      try {
        this.importedPrcentage = 0
        this.amountImportedAagents = 0
        this.amountAgentsClassificationRequest = 0
        this.amountAgentsPreviouslyAdded = 0
        this.amountAgentsCASInvalid = 0
        this.agentImportSummaryByCas = []
        this.showProgressBar = false
        this.loading = false
        this.casList = []
        this.files = null
        this.company = null
        this.product = null
        this.importType = null
        this.whichFunctionalityToImport = null
        this.showFunctionalityKeepDeleteAgents = !!(this.summary && this.summary.length > 0)
        this.message = `O limite máximo de agentes que pode ser importado de uma única vez é de ${this.user && this.user.plan && this.user.plan.plan === 'MO1HO' ? this.limitationOfAgentsAdded.limitation : this.LIMIT_AGENTS_ADDED_IN_AGENT_SELECTION} agentes!`

        const userReachedLimitAddedAgents = this.userReachedLimitAddedAgents()
        if (userReachedLimitAddedAgents) {
          this.handleDailySearches(this.limitationOfAgentsAdded)
          return false
        }

        if (this.summaryMeta.total >= this.LIMIT_AGENTS_ADDED_IN_AGENT_SELECTION) {
          const title = `Atingido o limite de agentes adicionados`
          const message = `Para adicionar mais agentes é necessário apagar agentes para adicionar outros, o limte de agentes adicionados é ${this.LIMIT_AGENTS_ADDED_IN_AGENT_SELECTION}!`
          this.addedAgentLimits(title, message)
          return false
        }
        return true
      } catch (e) {
        console.log(e)
      }
    },

    handleDailySearches(body) {
      this.dailySearches = body
      this.$refs.limitedDailySearches.show()
    },

    addedAgentLimits(title, message) {
      this.title = title
      this.message = message
      this.$refs.uploadLisCas.hide()
      this.$refs.importCompany.hide()
      this.$refs.addedAgentLimits.show()
    }
  },
  watch: {
    thereAreDuplicateProducts() {
      if (this.thereAreDuplicateProducts) {
        this.$refs.duplicateProducts.show()
      } else {
        this.$refs.duplicateProducts.hide()
      }
    }
  }
}
</script>

<style lang='scss' scoped>

</style>
