<template>
  <base-modal class="modal-request-agent" :isOpened="isOpen" @close="closeModal">
    <template v-slot:header>
      <h3>CAS NÃO CLASSIFICADO</h3>
    </template>
    <template v-slot:content>
      <span>
        {{informativeText}}
      </span>
    </template>
    <template v-slot:footer>
      <base-button
        @click="closeModal"
        danger
        block
      >
        {{buttonNot}}
      </base-button>
      <base-button
        @click="submitClassificationRequest"
        success
        block
      >
        {{buttonYes}}
      </base-button>
    </template>
  </base-modal>
</template>

<script>
export default {
  name: 'ModalRequestAgent',
  props: {
    isOpen: { type: Boolean, default: false },
    informativeText: { type: String, default: 'Este CAS ainda não foi classificado. Gostaria de solicitar uma prioridade para ele?' },
    buttonNot: { type: String, default: 'Não' },
    buttonYes: { type: String, default: 'Sim' },
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    submitClassificationRequest() {
      this.$emit('submit-request')
    },
  }
}
</script>

<style lang="scss" scoped>

.modal-request-agent {
  /deep/ header {
    padding: 0.5rem;
    h3 {
      font-weight: bold;
    }
  }

  /deep/ main {
    display: flex;
    align-items: center;
    padding: 1rem;

    span {
      width: 100%;
      font-size: 22px;
      text-align: center;
    }
  }

  /deep/ footer {
    display: flex;
    gap: 16px;
    padding: 1rem;
  }
}
</style>
