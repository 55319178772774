<template>
  <b-modal
    id='edit-volume-modal'
    size='lg'
    header-bg-variant='info'
    header-text-variant='light'
    title='Editar vazão e volume'
    ref='modal'
    hide-footer
    @show='showModal'
    @hidden='hiddenModal'
  >
    <form>
      <b-alert show variant='danger' :class='{ hidden: !error }'>
        {{ error }}<br />
      </b-alert>

      <b-form-group>
        <div class='d-flex align-items-end justify-content-between'>
          <div class='w-25 mr-2'>
            <label style='margin-bottom:-2px' class='mt-3'>Vazão mínima</label>
            <b-form-input
              :disabled='true'
              placeholder='Digite o volume mínima'
              v-model='selectedActivities.minimumFlow'
              id='samplingPercentage'
              style='height: 48px'
              class='w-100 mt-1'
              type='number'
            >
            </b-form-input>
          </div>

          <div class='w-50 mr-2'>
            <label style='margin-bottom:-2px' class='mt-3'>Digite sua vazão</label>
            <b-form-input
              placeholder='Digite sua vazão'
              :disabled='itsFlowDisabled'
              v-model='selectedActivities.itsFlow'
              id='samplingPercentage'
              style='height: 48px'
              class='w-100 mt-1'
              type='number'
              :max='selectedActivities.maximumLeak'
              :min='selectedActivities.minimumFlow'
            >
            </b-form-input>
          </div>

          <div class='w-25'>
            <label style='margin-bottom:-2px' class='mt-1'>Vazão máxima</label>
            <b-form-input
              :disabled='true'
              placeholder='Digite o volume máxima'
              v-model='selectedActivities.maximumLeak'
              id='samplingPercentage'
              style='height: 48px'
              class='w-100 mt-1'
              type='number'
            >
            </b-form-input>
          </div>
        </div>


        <div class='d-flex align-items-end justify-content-between'>
          <div class='w-25 mr-2'>
            <label style='margin-bottom:-2px' class='mt-3'>Volume mínimo</label>
            <b-form-input
              :disabled='true'
              placeholder='Digite o volume mínima'
              v-model='selectedActivities.minimumVolume'
              id='samplingPercentage'
              style='height: 48px'
              class='w-100 mt-1'
              type='number'
            >
            </b-form-input>
          </div>

          <div class='w-50 mr-2'>
            <label style='margin-bottom:-2px' class='mt-3'>Digite seu volume</label>
            <b-form-input
              placeholder='Digite seu volume'
              :disabled='itsVolumeDisabled'
              v-model='selectedActivities.itsVolume'
              id='samplingPercentage'
              style='height: 48px'
              class='w-100 mt-1'
              type='number'
              :max='selectedActivities.maximumVolume'
              :min='selectedActivities.minimumVolume'
            >
            </b-form-input>
          </div>

          <div class='w-25'>
            <label style='margin-bottom:-2px' class='mt-1'>Volume máximo</label>
            <b-form-input
              :disabled='true'
              placeholder='Digite o volume máxima'
              v-model='selectedActivities.maximumVolume'
              id='samplingPercentage'
              style='height: 48px'
              class='w-100 mt-1'
              type='number'
            >
            </b-form-input>
          </div>
        </div>
      </b-form-group>
      <br />

      <b-input-group>
        <b-button class='btn' @click='save' block>
          <i class='fa fa-save'></i> Salvar
        </b-button>
      </b-input-group>
    </form>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'EditVolumeModal',
  computed: {
    error() {
      return this.$store.getters['access/error']
    },
    user() {
      return this.$store.getters['user/current']
    },
    modalEditFlowVolumeIsOpen() {
      return this.$store.getters['legalSearch/modalEditFlowVolumeIsOpen']
    },
  },
  data() {
    return {
      clickedSave: false,
      itsFlowDisabled: false,
      itsVolumeDisabled: false,
      selectedActivities: {
        maximumVolume: null,
        minimumVolume: null,
        itsVolume: null,
        maximumLeak: null,
        minimumFlow: null,
        itsFlow: null
      }
    }
  },
  methods: {
    ...mapActions('legalSearch', {
      legalSearch: 'legalSearch',
    }),
    async hiddenModal() {
      if (!this.clickedSave) {
        await this.$store.dispatch('legalSearch/load', this.user.id)
      }

    },
    async showModal() {
      try {
        await this.$store.dispatch('access/updateError', '')
        this.selectedActivities = this.evaluationForm

        this.itsFlowDisabled = false
        if(this.selectedActivities.minimumFlow === this.selectedActivities.maximumLeak){
          this.selectedActivities.itsFlow = this.selectedActivities.maximumLeak
          this.itsFlowDisabled = true
        }

        this.itsVolumeDisabled = false
        if(this.selectedActivities.minimumVolume === this.selectedActivities.maximumVolume){
          this.selectedActivities.itsVolume = this.selectedActivities.minimumVolume
          this.itsVolumeDisabled = true
        }
        this.clickedSave = false
      } catch (e) {

      }
    },
    async save() {
      await this.$store.dispatch('access/updateError', '')

      if (this.selectedActivities.itsFlow && Number(this.selectedActivities.itsFlow) < Number(this.selectedActivities.minimumFlow)) {
        await this.$store.dispatch('access/updateError', 'A vazão informada não pode ser menor que a miníma.')
        return
      }

      if (this.selectedActivities.itsFlow && Number(this.selectedActivities.itsFlow) > Number(this.selectedActivities.maximumLeak)) {
        await this.$store.dispatch('access/updateError', 'A vazão informada não pode ser maior que a máxima.')
        return
      }

      if (this.selectedActivities.itsVolume && Number(this.selectedActivities.itsVolume) < this.selectedActivities.minimumVolume) {
        await this.$store.dispatch('access/updateError', 'O volume informada não pode ser menor que o minímo.')
        return
      }

      if (this.selectedActivities.itsVolume && Number(this.selectedActivities.itsVolume) > this.selectedActivities.maximumVolume) {
        await this.$store.dispatch('access/updateError', 'O volume informada não pode ser maior que o máximo.')
        return
      }
      this.selectedActivities.itsFlow = this.selectedActivities.itsFlow ? Number(this.selectedActivities.itsFlow) : null
      this.selectedActivities.itsVolume = this.selectedActivities.itsVolume ? Number(this.selectedActivities.itsVolume) :  null
      this.clickedSave = true
      this.$emit('save', this.selectedActivities)
    }
  },
  props: ['evaluationForm', 'laboratory'],
  watch: {}
}
</script>
<style lang='scss' scoped>
.btn {
  border: none;
  background-color: #CBD63E;
  color: #022245;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 24px;

  i {
    color: #022245;
    margin-right: 6px;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }
}
</style>
