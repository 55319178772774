<template>
  <base-modal class="modal-agent-not-found" :isOpened="isOpen" @close="closeModal">
    <template v-slot:header>
      <h3>AGENTE NÃO ENCONTRADO</h3>
    </template>
    <template v-slot:content>
      <span>Não encontramos esse agente pelo nome, tente novamente pesquisando pelo CAS</span>
    </template>
    <template v-slot:footer>
      <base-button
        @click="closeModal"
        block
      >
        OK
      </base-button>
    </template>
  </base-modal>
</template>

<script>
export default {
  name: 'ModalAgentNotFound',
  props: {
    isOpen: { type: Boolean, default: false },
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>

.modal-agent-not-found {
  /deep/ header {
    padding: 0.5rem;
    h3 {
      font-weight: bold;
    }
  }

  /deep/ main {
    display: flex;
    align-items: center;
    padding: 1rem;

    span {
      width: 100%;
      font-size: 22px;
      text-align: center;
    }
  }

  /deep/ footer {
    padding: 1rem;
  }
}
</style>