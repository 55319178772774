<template>
  <section>
    <b-modal
      ref='addProduct'
      id='addProduct'
      hide-footer
      header-bg-variant='info'
      header-text-variant='light'
      :title="`${productId ? 'Atualizar produto' : 'Cadastrar produto'}`"
      size='lg'
      @show='showModal()'
    >
      <Role
        :roleId='null'
        :sectorId='null'
        @update='getRoles'
      >
      </Role>

      <section class='modal-font-family'>
        <section class='d-flex align-items-center'>
          <section>
            <label class='label'>Cargo do trabalhador que usa esse produto</label>
            <b-form-select
              id='selectedCompany'
              v-model='roleId'
              :options='roles'
              :state="selectedRole"
              class='mb-3'
              style='height: 50px'
              value-field='_id'
              text-field='name'
              @change='setSelectedRole($event)'
            >
              <template #first>
                <b-form-select-option :value='null' disabled>Selecione o cargo do trabalhador que usa esse produto
                </b-form-select-option>
              </template>
            </b-form-select>
          </section>

          <section>
            <base-button
              @click='showModalAddRole()'
              class='btn-add-role btn-add'
            >
              Criar um novo cargo
            </base-button>
          </section>
        </section>

        <section class='mt-1'>
          <label class='label'>Nome do produto</label>
          <base-input-text
            v-model='name'
            placeholder='Digite o nome do produto'
            class='label-input-modal'
            type='text'
          />
        </section>

        <section class='mt-4'>
          <label class='label'>Selecione o estado físico do produto</label>
          <b-form-select
            id='selectedCompany'
            v-model='physicalState'
            :options='physicalStates'
            class='mb-3'
            style='height: 50px'
            value-field='key'
            text-field='value'
            @change="degreeOfDust = null"
          >
            <template #first>
              <b-form-select-option :value='null' disabled>Selecione o estado físico do produto
              </b-form-select-option>
            </template>
          </b-form-select>
        </section>

        <section v-if='physicalState && physicalState === 1' class='ventilationIdentified  '>
          <label class="label">Marque o grau de empoeiramento</label>
          <b-form-group class='degreeOfDust' v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              stacked :key="index"
              id="radio-group-2"
              class='d-flex'
              v-model="degreeOfDust"
              v-for="(item, index) in optionsDegreeOfDust"
            >
              <b-tooltip  :target="`degreeOfDust${item.key}`" triggers="hover" placement="top">
                {{ item.description }}
              </b-tooltip>

              <b-form-radio :value="item.key">
                <label :style="degreeOfDust === item.key ? 'font-weight: 700; color: var(--navy-blue)' : ''">{{item.value}}</label>&nbsp;
                <svg :id="`degreeOfDust${item.key}`" width="17" height="17" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.95 16C10.3 16 10.596 15.879 10.838 15.637C11.08 15.395 11.2007 15.0993 11.2 14.75C11.2 14.4 11.0793 14.104 10.838 13.862C10.5967 13.62 10.3007 13.4993 9.95 13.5C9.6 13.5 9.30433 13.621 9.063 13.863C8.82167 14.105 8.70067 14.4007 8.7 14.75C8.7 15.1 8.821 15.396 9.063 15.638C9.305 15.88 9.60067 16.0007 9.95 16ZM9.05 12.15H10.9C10.9 11.6 10.9627 11.1667 11.088 10.85C11.2133 10.5333 11.5673 10.1 12.15 9.55C12.5833 9.11667 12.925 8.704 13.175 8.312C13.425 7.92 13.55 7.44933 13.55 6.9C13.55 5.96667 13.2083 5.25 12.525 4.75C11.8417 4.25 11.0333 4 10.1 4C9.15 4 8.379 4.25 7.787 4.75C7.195 5.25 6.78267 5.85 6.55 6.55L8.2 7.2C8.28333 6.9 8.471 6.575 8.763 6.225C9.055 5.875 9.50067 5.7 10.1 5.7C10.6333 5.7 11.0333 5.846 11.3 6.138C11.5667 6.43 11.7 6.75067 11.7 7.1C11.7 7.43333 11.6 7.746 11.4 8.038C11.2 8.33 10.95 8.60067 10.65 8.85C9.91667 9.5 9.46667 9.99167 9.3 10.325C9.13333 10.6583 9.05 11.2667 9.05 12.15ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z" fill="#022245"/>
                </svg>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </section>

        <section class='mt-4'>
          <label class='label'>Descrição do produto</label>
          <base-text-area
            v-model='description'
            class='custom-textarea'
            placeholder='Descreva aqui como é utilizado este produto. Informações sobre como o trabalhador faz uso deste produto, se ele dilui/mistura/dissolve, se o produto está em forma líquida ou sólida são boas informações para que possamos te ajudar a reconhecer seus riscos.'
            type='text'
          />
        </section>

        <section>
          <a class='file-uploaded mt-3' v-if='urlFISPQ' :href='urlFISPQ' target='_blank'>
            Clique aqui para ver o arquivo anexado anteriormente
          </a>
        </section>

        <section class='mt-3'>
          <input type='file' id='files' @change='onChange' hidden />
          <label class='file-product d-flex align-items-center' for='files'>
            <strong class='ml-3' v-if='files && files.name'>{{ files.name }}</strong>

            <svg v-if='!files.name' class='ml-3 mr-2' width='18' height='18' viewBox='0 0 10 18' fill='none'
                 xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M3.12969 6.00003V12.743C3.13748 13.2361 3.33882 13.7063 3.69026 14.0523C4.0417 14.3982 4.51507 14.5921 5.0082 14.5921C5.50134 14.5921 5.9747 14.3982 6.32615 14.0523C6.67759 13.7063 6.87893 13.2361 6.88672 12.743L6.89281 3.90472C6.89792 3.48328 6.81933 3.06502 6.66159 2.67418C6.50385 2.28334 6.2701 1.9277 5.97389 1.62787C5.67768 1.32804 5.3249 1.09 4.93601 0.927526C4.54711 0.765056 4.12983 0.681396 3.70836 0.681396C3.28689 0.681396 2.86961 0.765056 2.48071 0.927526C2.09182 1.09 1.73904 1.32804 1.44283 1.62787C1.14661 1.9277 0.912864 2.28334 0.755124 2.67418C0.597384 3.06502 0.518793 3.48328 0.523906 3.90472V12.8025C0.51532 13.3959 0.624765 13.985 0.84588 14.5356C1.067 15.0863 1.39537 15.5875 1.81191 16.0101C2.22845 16.4327 2.72484 16.7683 3.27223 16.9973C3.81962 17.2264 4.40709 17.3443 5.00047 17.3443C5.59385 17.3443 6.18132 17.2264 6.72871 16.9973C7.2761 16.7683 7.77249 16.4327 8.18903 16.0101C8.60557 15.5875 8.93394 15.0863 9.15506 14.5356C9.37617 13.985 9.48562 13.3959 9.47703 12.8025V4.48785'
                stroke='#808080' stroke-miterlimit='10' stroke-linecap='round' />
            </svg>

            <span v-if='!files.name'>Clique aqui para anexar a FDS ou FISPQ desse produto</span>
          </label>
        </section>

        <section class='mt-4'>
          <b-alert show variant='danger' :class='{ hidden: !error }'>
            {{ error }}<br />
          </b-alert>
        </section>

        <section class='d-flex justify-content-between mt-4'>
          <base-button
            class='w-50 mr-3 btn-cancel'
            @click='hideModalAddProduct()'
          >
            <span>Cancelar</span>
          </base-button>

          <b-overlay
            :show='loading'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='d-inline-block w-50'
          >
            <base-button
              class='w-100 btn-add d-flex align-items-center justify-content-center'
              @click='add()'
            >
            <span class='mr-2'>
              {{ productId ? 'Atualizar' : 'Adicionar' }}
            </span>
            </base-button>
          </b-overlay>
        </section>
      </section>
    </b-modal>
  </section>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from '@/utils/validators'
import useVuelidate from '@vuelidate/core'
import eventBus from '@/utils/eventBus'
import { physicalProductStates } from '@/mocks/physicalProductStates'
import { optionsDegreeOfDust } from '@/mocks/optionsDegreeOfDust'
import Role from '@/components/LegalSearch/Gro/components/Role.vue'

export default {
  name: 'ProductAdd',
  components: { Role },
  props: {},
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      loading: false,
      roles: [],
      files: [],
      sectorId: null,
      name: "",
      roleId: null,
      description: "",
      physicalState: null,
      degreeOfDust: null,
      selectedRole: null,
      urlFISPQ: null,
      productId: null,
      physicalStates: [],
      optionsDegreeOfDust: [],
      errors: {
        roleId: 'Selecione o cargo do trabalhador que usa esse produto',
        name: 'Digite o nome do produto',
        physicalState: 'Selecione o estado físico do produto',
      },
    }
  },
  mounted() {
    eventBus.$on('showModalAddProduct', async (productId) => {
      this.productId = null
      if (productId) {
        this.productId = productId
        this.$bvModal.show('addProduct')
      } else {
        await this.analyzeAgents()
      }
    })
  },
  beforeDestroy() {
    eventBus.$off('showModalAddProduct')
  },
  computed: {
    error() {
      return this.$store.getters['access/error']
    },
    summaryMeta() {
      return this.$store.getters['legalSearch/summaryMeta']
    },
    summary() {
      return this.$store.getters['legalSearch/summary']
    },
    user() {
      return this.$store.getters['user/current']
    },
    disabled() {
      return !(this.name && this.description && this.roleId && this.physicalState && (this.physicalState === 2 || this.degreeOfDust))
    },
    ...mapGetters('legalSearch', {
      selectedAgents: 'selectedAgents'
    }),
    ...mapGetters('companyLegalConsultation', {
      importedCompany: 'importedCompany'
    })
  },
  methods: {
    ...mapActions('productLegalConsultation', {
      handleCreateProductLegalConsultation: 'handleCreateProductLegalConsultation',
      handleAddAgentProductLegalConsultation: 'handleAddAgentProductLegalConsultation',
      handleGetProductLegalConsultation: 'handleGetProductLegalConsultation',
      handleUpdateProductLegalConsultation: 'handleUpdateProductLegalConsultation',
      setDisableProduct: 'setDisableProduct',
    }),
    ...mapActions('companyLegalConsultation', {
      handleUpdateAgentsCompanyLegalConsultation: 'handleUpdateAgentsCompanyLegalConsultation'
    }),
    ...mapActions('legalSearch', {
    }),
    ...mapActions('role', {
      handleGetByCompany: 'handleGetByCompany'
    }),

    async showModal() {
      try {
        this.loading = false
        this.files = []
        this.urlFISPQ = null
        this.name = ""
        this.roleId = null
        this.description = ""
        this.physicalState = null
        this.selectedRole = null
        this.degreeOfDust = null
        Object.assign(this.physicalStates, physicalProductStates)
        Object.assign(this.optionsDegreeOfDust, optionsDegreeOfDust)
        await this.$store.dispatch('access/updateError', '')
        if (this.productId) {
          const { data } = await this.handleGetProductLegalConsultation(this.productId)
          const { urlFISPQ, name, description, physicalState, role, degreeOfDust } = data.product
          this.name = name
          this.description = description
          this.urlFISPQ = urlFISPQ
          this.physicalState = physicalState
          this.degreeOfDust = degreeOfDust
          this.roleId = role
        }
        await this.getRoles(true)
      } catch (e) {

      }
    },

    async showModalAddRole() {
      try {
        setTimeout(() => {
          this.$bvModal.show('role')
        }, 100)
      } catch (e) {
      }
    },

    setSelectedRole(event){
      this.selectedRole = true;
    },

    async getRoles(selectedRole) {
      const { data } = await this.handleGetByCompany(this.importedCompany._id)
      const { roles } = data
      this.roles = roles
      if(!selectedRole) {
        this.selectedRole = false
      }
    },

    onChange(event) {
      const files = Array.from(event.target.files)
      this.files = files[0]
    },

    async add() {
      await this.$store.dispatch('access/updateError', '')
      if (this.disabled){
        let message = ``;
        if (!this.roleId){
          message = `Selecione o cargo do trabalhador que usa esse produto`;
        } else if (!this.name){
          message = `Preencha o nome do produto`;
        } else if (!this.physicalState){
          message = `Selecione o estado físico do produto`;
        } else if (this.physicalState === 1 && !this.degreeOfDust ){
          message = `Selecione o o grau de empoeiramento`;
        } else {
          message = `Preencha a descrição do produto`;
        }
        await this.$store.dispatch('access/updateError', message)
        return
      }
      this.loading = true
      let response = null
      if (this.productId) {
        response = await this.handleUpdateProductLegalConsultation(
          {
            name: this.name,
            description: this.description,
            file: this.files,
            urlFISPQ: this.urlFISPQ,
            physicalState: this.physicalState,
            degreeOfDust: this.degreeOfDust,
            role: this.roleId,
            id: this.productId
          }
        )
        this.$bvToast.toast(`Produto atualizado com sucesso.`, {
          title: 'Atenção',
          variant: 'success',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 6000
        })
      } else {
        response = await this.handleCreateProductLegalConsultation(
          {
            companyId: this.importedCompany && this.importedCompany._id ? this.importedCompany._id : null,
            name: this.name,
            description: this.description,
            role: this.roleId,
            physicalState: this.physicalState,
            degreeOfDust: this.degreeOfDust,
            legalSearchesId: this.selectedAgents,
            file: this.files,
            user: this.user.id
          }
        )

        this.$bvToast.toast(`Produto cadastrado com sucesso.`, {
          title: 'Atenção',
          variant: 'success',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 6000
        })
      }
      this.loading = false
      this.hideModalAddProduct()
      await this.load()
      if (this.importedCompany && this.importedCompany._id) {
        const bodyUpdateAgents = {
          companyId: this.importedCompany._id,
          legalSearchesId: this.selectedAgents
        }
        await this.handleUpdateAgentsCompanyLegalConsultation(bodyUpdateAgents)
      }
      this.$emit('clear-items-remove')
    },


    hideModalAddProduct() {
      this.$bvModal.hide('addProduct')
    },

    async load() {
      await this.$store.dispatch('legalSearch/load', this.user.id)
    },

    async analyzeAgents() {
      const agentes = this.summary.filter((x) => this.selectedAgents.includes(x.legalSearchId))
      const agentesVinculadoProduto = agentes.filter((x) => x.product && x.product._id)
      const agentesSemVinculosProduto = agentes.filter((x) => !x.product)

      const agentesVinculadoProdutoAgrupadoPorProduto = agentesVinculadoProduto.reduce((group, agent) => {
        const product = agent.product._id
        group[product] = group[product] ? group[product] : []
        group[product].push(agent)
        return group
      }, {})
      const keys = Object.keys(agentesVinculadoProdutoAgrupadoPorProduto)

      if (agentesVinculadoProduto.length > 0 && agentesSemVinculosProduto.length > 0 && keys.length === 1) {
        await this.setDisableProduct(true)
        const product = agentesVinculadoProdutoAgrupadoPorProduto[keys[0]][0].product
        await this.handleAddAgentProductLegalConsultation(
          {
            user: this.user.id,
            productId: product._id,
            legalSearchesId: agentesSemVinculosProduto.map((x) => x.legalSearchId),
            companyId: this.importedCompany && this.importedCompany._id ? this.importedCompany._id : null
          }
        )

        await this.handleUpdateAgentsCompanyLegalConsultation(
          {
            companyId: this.importedCompany._id,
            legalSearchesId: agentesSemVinculosProduto.map((x) => x.legalSearchId)
          }
        )
        await this.load()
        this.$emit('clear-items-remove')
        await this.setDisableProduct(false)
      } else {
        this.$bvModal.show('addProduct')
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.custom-textarea {
}

.file-uploaded {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  color: var(--navy-blue);
  border: 1px solid var(--navy-blue);

  padding: 6px 12px;

  border-radius: 4px;

  transition: all 0.2s ease-in-out;

  text-decoration: none;

  cursor: pointer;
}

.file-product {
  border-radius: 6.25px;
  border: 1px dashed #808080;
  width: 100%;
  height: 40px;
  cursor: pointer;

  span {
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
}

.btn-cancel {
  background: transparent !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: var(--navy-blue);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.btn-add {
  background: var(--navy-blue) !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: #FFFFFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.label {
  margin-bottom: -8px !important;
  font-weight: 700 !important;
}
.btn-add-role{
  min-width: 200px;
  height: 50px;
  margin-bottom: -8px;
  margin-left: 10px;
}
.degreeOfDust{
  border: 1px solid var(--border-input);
  border-radius: 4px;
  padding:10px;
}
</style>
