<template>
  <div class='mt-1'>
    <OrientationVideos
      :orientationVideos="orientationVideos"
      :agent="agent"
      @clear="orientationVideos = []"
    />
    <table class='text-center table b-table table-bordered custom-table'>
      <thead class='sticky'>
      <tr>
        <th colspan='4' class='container-tab-buttons'>
          <tab-buttons />
        </th>
      </tr>
      <tr class='headerTable'>
        <th class='width-20-percent'>Produto</th>
        <th class='width-40-percent'>CAS: Agente</th>
        <th class='width-20-percent'>Origem da informação</th>
        <th class='width-20-percent'>Dados do Exame Complementar</th>
      </tr>
      </thead>

      <tbody>
      <template v-for='product in legalConsultationAgentsGroupedByProduct'>
        <template
          v-for='item in product.legalSearches'
        >
          <template v-if="product.product._id === '-'">
            <tr>
              <td
                :rowspan='item.board_01 || item.board_02 || item.complementaryExamsAcgih ? model.getRowspan(item) + 1 : 2'>
                <p class='word-break'>-</p>
              </td>
            </tr>

            <template v-if='item.board_01 || item.board_02 || item.complementaryExamsAcgih'>
              <tr v-for='(row, i) in model.getList(item)' :key='`row_${row}${item.cas}`'>
                <td v-if='i === 0' :rowspan='model.getList(item).length'>
                  <div>
                    <b-tooltip
                      :target='item.cas'
                      custom-class="custom-tooltip"
                      placement='auto'
                      triggers='hover'
                    >
                      <section
                        v-if='(item.attachment11 && item.attachment11.group) || item.nameAttachment11'
                      >
                      <span
                        class='p-2 text-white'
                        style='font-size: 15px'
                      >
                      Este agente consta na NR 15 com o seguinte nome:
                        {{ item.attachment11 && item.attachment11.group
                        ? item.attachment11.group
                        : item.nameAttachment11 ? item.nameAttachment11: '-'
                        }}
                      </span> <br/><br/>
                      </section>

                      <section
                        class='p-2'
                        v-if='item.acgih && item.agent'
                      >
                      <span
                        class='text-white'
                        style='font-size: 15px'
                      >
                        Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                        {{ item.agent ? item.agent : '-' }}
                      </span>
                        <br/>
                        <br/>
                      </section>

                      <a
                        :href='`https://pubchem.ncbi.nlm.nih.gov/#query=${item.cas}`'
                        target='_blank'
                        class='link-pubchem-cas ml-2'
                      >
                      <span>
                        Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{item.cas}}
                      </span>
                      </a>
                    </b-tooltip>

                    <a
                      href='#'
                      class='hover_links'
                      :id='item.cas'
                    >
                      <span class='word-break'>{{ model.getsearchId(item) }}</span>
                      <b-icon
                        style='margin-bottom: -1px'
                        class='ml-1'
                        font-scale="1.2"
                        icon="info-circle-fill"
                        variant="info"
                      >
                      </b-icon>
                    </a>

                    <section v-if='item.videosOrientacaoAbaPcmso && item.videosOrientacaoAbaPcmso.length > 0'>
                      <button
                        class='btn-orientations mt-2'
                        @click='showModalOrientationVideos(model.getsearchId(item), item.videosOrientacaoAbaLaudoInsalubridade)'
                      >
                      <span>
                        Ver orientações <br/> especificas
                      </span>
                      </button>
                    </section>
                  </div>
                </td>
                <td>
                  <div v-if='item.board_01 && row === board_01'>
                    NR 7 - Quadro 1
                  </div>

                  <div v-if='item.board_02 && row === board_02'>
                    NR 7 - Quadro 2
                  </div>

                  <div v-if='item.complementaryExamsAcgih && row === complementaryExamsAcgih'>
                    ACGIH 2024
                  </div>
                </td>
                <td>
                  <div v-if='item.board_01 && row === board_01'>
                    <div
                      class='mt-3'
                      :class="indicatorsIndex < item.board_01.indicators.length - 1 ? 'border-supplementaryExamData':''"
                      v-for='(indicator, indicatorsIndex) in item.board_01.indicators' :key='indicatorsIndex'
                    >
                      <p>
                        Indicador: {{ indicator[`indicator_0${(indicatorsIndex + 1)}`].join(', ') }} <br />
                        Momento da coleta: {{ indicator.timeOfCollection }}<br />
                        Valor do IBE/EE: {{ indicator.valueIbeEE }}<br />
                        Observações: {{ indicator.observation }}<br />
                      </p>
                    </div>
                  </div>

                  <div v-if='item.board_02  && row === board_02'>
                    <div
                      class='mt-3'
                      :class="indicatorsIndex < item.board_02.indicators.length - 1 ? 'border-supplementaryExamData':''"
                      v-for='(indicator, indicatorsIndex) in item.board_02.indicators' :key='indicatorsIndex'
                    >
                      <p>
                        Indicador: {{ indicator[`indicator_0${(indicatorsIndex + 1)}`].join(', ') }} <br />
                        Momento da coleta: {{ indicator.timeOfCollection }}<br />
                        Valor do IBE/SC: {{ indicator.valueIbeSC }}<br />
                        Observações: {{ indicator.observation }}<br />
                      </p>
                    </div>
                  </div>

                  <div v-if='item.complementaryExamsAcgih && row === complementaryExamsAcgih'>
                    <div
                      class='mt-3'
                      :class="indicatorsIndex < item.complementaryExamsAcgih.indicators.length - 1 ? 'border-supplementaryExamData':''"
                      v-for='(indicator, indicatorsIndex) in item.complementaryExamsAcgih.indicators'
                      :key='indicatorsIndex'>
                      <p>
                        Determinante: {{ indicator[`determining_0${(indicatorsIndex + 1)}`].join(', ') }} <br />
                        Horário da coleta: {{ indicator.timeOfCollection }}<br />
                        <span class='position-relative bei'>BEI<span
                          class='position-absolute mr-2 marca_registrada'> &reg; </span></span>:
                        {{ indicator.valueIbe }}<br />
                        Notações: {{ indicator.notations }}<br />
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </template>

            <tr v-else :key='item.legalSearchId'>
              <td>
                <b-tooltip
                  :target='item.cas'
                  custom-class="custom-tooltip"
                  placement='auto'
                  triggers='hover'
                >
                  <section
                    v-if='(item.attachment11 && item.attachment11.group) || item.nameAttachment11'
                  >
                      <span
                        class='p-2 text-white'
                        style='font-size: 15px'
                      >
                      Este agente consta na NR 15 com o seguinte nome:
                        {{ item.attachment11 && item.attachment11.group
                        ? item.attachment11.group
                        : item.nameAttachment11 ? item.nameAttachment11: '-'
                        }}
                      </span> <br/><br/>
                  </section>

                  <section
                    class='p-2'
                    v-if='item.acgih && item.agent'
                  >
                      <span
                        class='text-white'
                        style='font-size: 15px'
                      >
                        Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                        {{ item.agent ? item.agent : '-' }}
                      </span>
                    <br/>
                    <br/>
                  </section>

                  <a
                    :href='`https://pubchem.ncbi.nlm.nih.gov/#query=${item.cas}`'
                    target='_blank'
                    class='link-pubchem-cas ml-2'
                  >
                    <span>
                      Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{item.cas}}
                    </span>
                  </a>
                </b-tooltip>

                <a
                  href='#'
                  class='hover_links'
                  :id='item.cas'
                >
                  <span class='word-break'>{{ model.getsearchId(item) }}</span>
                  <b-icon
                    style='margin-bottom: -1px'
                    class='ml-1'
                    font-scale="1.2"
                    icon="info-circle-fill"
                    variant="info"
                  >
                  </b-icon>
                </a>

                <section v-if='item.videosOrientacaoAbaPcmso && item.videosOrientacaoAbaPcmso.length > 0'>
                  <button
                    class='btn-orientations mt-2'
                    @click='showModalOrientationVideos(model.getsearchId(item), item.videosOrientacaoAbaLaudoInsalubridade)'
                  >
                      <span>
                        Ver orientações <br/> especificas
                      </span>
                  </button>
                </section>
              </td>

              <td colspan='2'>
                É possível que este agente não tenha nenhuma relação com a NR 07 e com a ACGIH para fins de exames
                complementares.
                Nossa base de dados é constantemente atualizada e validada para a verificação da inclusão de novas
                substâncias.
                A exclusão analítica deste agente e seus possíveis exames no PCMSO fica a cargo do Médico responsável.
              </td>
            </tr>
          </template>

          <template v-else>
            <tr>
              <td :rowspan='rowspan(product)' v-if='item.index === 0'>
                <section>
                  <p class='word-break'>{{ product.product.name }}</p>
                </section>
              </td>
            </tr>
            <tr v-for='(row, i) in model.getLists(item)' :key='`row_${row}${item.cas}`'>
              <td v-if='i === 0' :rowspan='model.getLists(item).length'>
                <div>
                  <b-tooltip
                    :target='item.cas'
                    custom-class="custom-tooltip"
                    placement='auto'
                    triggers='hover'
                  >
                    <section
                      v-if='(item.attachment11 && item.attachment11.group) || item.nameAttachment11'
                    >
                      <span
                        class='p-2 text-white'
                        style='font-size: 15px'
                      >
                      Este agente consta na NR 15 com o seguinte nome:
                        {{ item.attachment11 && item.attachment11.group
                        ? item.attachment11.group
                        : item.nameAttachment11 ? item.nameAttachment11: '-'
                        }}
                      </span> <br/><br/>
                    </section>

                    <section
                      class='p-2'
                      v-if='item.acgih && item.agent'
                    >
                      <span
                        class='text-white'
                        style='font-size: 15px'
                      >
                        Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                        {{ item.agent ? item.agent : '-' }}
                      </span>
                      <br/>
                      <br/>
                    </section>

                    <a
                      :href='`https://pubchem.ncbi.nlm.nih.gov/#query=${item.cas}`'
                      target='_blank'
                      class='link-pubchem-cas ml-2'
                    >
                    <span>
                        Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{item.cas}}
                    </span>
                    </a>
                  </b-tooltip>

                  <a
                    href='#'
                    class='hover_links'
                    :id='item.cas'
                  >
                    <span class='word-break'>{{ model.getsearchId(item) }}</span>
                    <b-icon
                      style='margin-bottom: -1px'
                      class='ml-1'
                      font-scale="1.2"
                      icon="info-circle-fill"
                      variant="info"
                    >
                    </b-icon>
                  </a>

                  <section v-if='item.videosOrientacaoAbaPcmso && item.videosOrientacaoAbaPcmso.length > 0'>
                    <button
                      class='btn-orientations mt-2'
                      @click='showModalOrientationVideos(model.getsearchId(item), item.videosOrientacaoAbaLaudoInsalubridade)'
                    >
                      <span>
                        Ver orientações <br/> especificas
                      </span>
                    </button>
                  </section>
                </div>
              </td>

              <td v-if='item.board_01 || item.board_02 || item.complementaryExamsAcgih'>
                <div v-if='item.board_01 && row === board_01'>
                  NR 7 - Quadro 1
                </div>

                <div v-if='item.board_02 && row === board_02'>
                  NR 7 - Quadro 2
                </div>

                <div v-if='item.complementaryExamsAcgih && row === complementaryExamsAcgih'>
                  ACGIH 2024
                </div>
              </td>

              <td v-if='item.board_01 || item.board_02 || item.complementaryExamsAcgih'>
                <div v-if='item.board_01 && row === board_01'>
                  <div
                    class='mt-3'
                    :class="indicatorsIndex < item.board_01.indicators.length - 1 ? 'border-supplementaryExamData':''"
                    v-for='(indicator, indicatorsIndex) in item.board_01.indicators' :key='indicatorsIndex'
                  >
                    <p>
                      Indicador: {{ indicator[`indicator_0${(indicatorsIndex + 1)}`].join(', ') }} <br />
                      Momento da coleta: {{ indicator.timeOfCollection }}<br />
                      Valor do IBE/EE: {{ indicator.valueIbeEE }}<br />
                      Observações: {{ indicator.observation }}<br />
                    </p>
                  </div>
                </div>

                <div v-if='item.board_02  && row === board_02'>
                  <div
                    class='mt-3'
                    :class="indicatorsIndex < item.board_02.indicators.length - 1 ? 'border-supplementaryExamData':''"
                    v-for='(indicator, indicatorsIndex) in item.board_02.indicators' :key='indicatorsIndex'
                  >
                    <p>
                      Indicador: {{ indicator[`indicator_0${(indicatorsIndex + 1)}`].join(', ') }} <br />
                      Momento da coleta: {{ indicator.timeOfCollection }}<br />
                      Valor do IBE/SC: {{ indicator.valueIbeSC }}<br />
                      Observações: {{ indicator.observation }}<br />
                    </p>
                  </div>
                </div>

                <div v-if='item.complementaryExamsAcgih && row === complementaryExamsAcgih'>
                  <div
                    class='mt-3'
                    :class="indicatorsIndex < item.complementaryExamsAcgih.indicators.length - 1 ? 'border-supplementaryExamData':''"
                    v-for='(indicator, indicatorsIndex) in item.complementaryExamsAcgih.indicators'
                    :key='indicatorsIndex'>
                    <p>
                      Determinante: {{ indicator[`determining_0${(indicatorsIndex + 1)}`].join(', ') }} <br />
                      Horário da coleta: {{ indicator.timeOfCollection }}<br />
                      <span class='position-relative bei'>BEI<span
                        class='position-absolute mr-2 marca_registrada'> &reg; </span></span>:
                      {{ indicator.valueIbe }}<br />
                      Notações: {{ indicator.notations }}<br />
                    </p>
                  </div>
                </div>
              </td>


              <td colspan='2' v-if='!item.board_01 && !item.board_02 && !item.complementaryExamsAcgih'>
                É possível que este agente não tenha nenhuma relação com a NR 07 e com a ACGIH para fins de exames
                complementares.
                Nossa base de dados é constantemente atualizada e validada para a verificação da inclusão de novas
                substâncias.
                A exclusão analítica deste agente e seus possíveis exames no PCMSO fica a cargo do Médico responsável.
              </td>
            </tr>
          </template>
        </template>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import eventBus from '../../../utils/eventBus'
import Pcmso from '@/models/Pcmso'
import TabButtons from '@/components/LegalSearch/components/TabButtons.vue'
import OrientationVideos from '@/components/LegalSearch/Modal/OrientationVideos.vue'

export default {
  name: 'PcmsoTable',
  data() {
    return {
      model: Pcmso,
      board_01: 1,
      board_02: 2,
      complementaryExamsAcgih: 3,
      orientationVideos: [],
      agent: "",
    }
  },
  components: { OrientationVideos, TabButtons },
  computed: {
    xlsxLink() {
      return `${process.env.VUE_APP_BASE_URL}/xlsx/${this.user.id}/pcmso`
    },
    legalConsultationAgentsGroupedByProduct() {
      return this.$store.getters['legalSearch/legalConsultationAgentsGroupedByProduct']
    },
    summary() {
      return this.$store.getters['legalSearch/summary']
    },
    user() {
      return this.$store.getters['user/current']
    }
  },
  methods: {
    ...mapActions('reportsDownloads', {
      handleCreateReportDownloads: 'handleCreateReportDownloads'
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb'
    }),
    ...mapActions('event', {
      handlGetEventActive: 'handlGetEventActive'
    }),
    ...mapActions('legalSearch', {
      exportingExcel: 'exportingExcel'
    }),
    showModalOrientationVideos(agent, orientationVideos){
      this.orientationVideos = orientationVideos
      this.agent = agent
      setTimeout(() => {
        this.$bvModal.show('orientationVideos')
      },100)
    },

    rowspan(legalConsultationAgentsGroupedByProduct) {
      let total = 0
      legalConsultationAgentsGroupedByProduct.legalSearches.forEach((x) => {
        total += (this.model.getRowspan(x))
      })
      total += legalConsultationAgentsGroupedByProduct.legalSearches.filter((x) =>
        !x.board_01 && !x.board_02 && !x.complementaryExamsAcgih
      ).length

      if (legalConsultationAgentsGroupedByProduct.product._id !== '-') {
        return legalConsultationAgentsGroupedByProduct.legalSearches.length + total
      }
    }

  },
  async mounted() {
  },
  beforeDestroy() {
    eventBus.$off('generateReportInsalubrity')
  },
  created() {
  }
}
</script>

<style scoped>

.icon {
  cursor: pointer;
  margin-left: 7px;
}

.hover_links {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #04A8F3;
  cursor: pointer;
}

.hover_links:hover {
  text-decoration: underline;
}

.sticky {
  position: sticky;
  top: 10px;
  z-index: 10;
}

.container-tab-buttons {
  padding: 0 0 5px 0 !important;
}

.headerTable {
  vertical-align: middle;
  background: #94D5F3;
  padding: 10px;
  top: 50px;
}

.width-20-percent {
  width: 20% !important;
}
.width-40-percent {
  width: 40% !important;
}

.border-supplementaryExamData {
  border-bottom: 1px solid #ccc;
}

td {
  vertical-align: middle;
}

.field_ps {
  text-align: left;
  font-size: 12px;
}

.marca_registrada {
  font-size: 14px;
}

.bei {
  margin-right: 12px;
}
</style>
