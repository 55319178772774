<template>
  <div class='mt-1'>
    <b-modal
      ref='ventilationIdentifiedModal'
      id='ventilationIdentifiedModal'
      hide-footer
      hide-header
      header-bg-variant='info'
      header-text-variant='light'
      size='xl'
      @show='showModal()'
      :no-close-on-esc='loading'
      :no-close-on-backdrop='loading'
    >
      <b-overlay
        :show='lookingTermsOfUse'
        rounded
        opacity='0.6'
        spinner-small
        spinner-variant='primary'
        class='d-inline-block w-100'
      >
        <section class='modal-font-family'>
          <section class='d-flex justify-content-between align-items-start  w-100 mb-5 mt-1'>
            <section>
              <span class='title'>{{titles[ventilation]}}</span><br/>
              <span class='sub-title'>Selecione uma das opções abaixo</span>
            </section>
            <b-icon
              @click='closeModal()'
              class='cursor-pointer'
              font-scale='2.2'
              icon='x'
              color='#022245'
            >
            </b-icon>
          </section>

          <section class='w-100'>
            <b-form-radio-group
              v-if='ventilation === 1'
              class='container-level'
              v-model='termsOfUse.ventilationWorkEnvironmentGeneral'
            >
              <section
                class=' level'
                :class="termsOfUse.ventilationWorkEnvironmentGeneral === 1 ? 'ventilation-level-selected': 'ventilation-level-not-selected'"
              >
                <b-form-radio :value='1'>
                  <span class='level-text-radio'>
                    Nível de Ventilação Baixo
                  </span>
                </b-form-radio>

                <hr />

                <section class='level-img'>
                  <img width='25' class='mr-3' height='25' src='../../../../../assets/images/filledVentilation.svg'
                       alt='' />
                  <img width='25' class='mr-3' height='25' src='../../../../../assets/images/ventilation.svg' alt='' />
                  <img width='25' height='25' src='../../../../../assets/images/ventilation.svg' alt='' />
                </section>

                <section class='d-flex justify-content-between align-items-start'>
                  <p class='level-text-orintation mr-2'>
                    Ambiente aberto com muitas paredes e poucas janelas e ventilação natural
                  </p>

                  <b-tooltip
                    target='nivelVentilacaoBaixo'
                    placement='top'
                    triggers='hover'
                  >
                    Um exemplo de ambiente de trabalho assim seria uma oficina mecânica com
                    pouca iluminação e ventilação natural, limitada a uma grande porta de
                    entrada do estabelecimento.
                  </b-tooltip>

                  <b-icon
                    class='ml-1 '
                    font-scale='1.5'
                    icon='question-circle-fill'
                    id='nivelVentilacaoBaixo'
                    variant='info'
                  >
                  </b-icon>
                </section>
              </section>

              <section
                class=' level'
                :class="termsOfUse.ventilationWorkEnvironmentGeneral === 2 ? 'ventilation-level-selected': 'ventilation-level-not-selected'"
              >
                <b-form-radio :value='2'>
                  <span class='level-text-radio'>
                    Nível de Ventilação Médio
                  </span>
                </b-form-radio>

                <hr />

                <section class='level-img'>
                  <img width='25' class='mr-3' height='25' src='../../../../../assets/images/filledVentilation.svg'  alt='' />
                  <img width='25' class='mr-3' height='25' src='../../../../../assets/images/filledVentilation.svg' alt='' />
                  <img width='25' height='25' src='../../../../../assets/images/ventilation.svg' alt='' />
                </section>

                <section class='d-flex justify-content-between align-items-start'>
                  <p class='level-text-orintation mr-2'>
                    Ambiente aberto com muitas paredes, mas com muitas janelas e ventilação natural
                  </p>

                  <b-tooltip
                    target='nivelVentilacaoMedio'
                    placement='top'
                    triggers='hover'
                  >
                    Um exemplo de ambiente de trabalho assim seria um
                    galpão industrial com várias janelas grandes que
                    por sua vez promovem uma boa ventilação em todos os
                    setores e direções.
                  </b-tooltip>

                  <b-icon
                    class='ml-1 '
                    font-scale='1.5'
                    icon='question-circle-fill'
                    id='nivelVentilacaoMedio'
                    variant='info'
                  >
                  </b-icon>
                </section>
              </section>

              <section
                class=' level'
                :class="termsOfUse.ventilationWorkEnvironmentGeneral === 3 ? 'ventilation-level-selected': 'ventilation-level-not-selected'"
              >
                <b-form-radio :value='3'>
                  <span class='level-text-radio'>
                    Nível de Ventilação Alto
                  </span>
                </b-form-radio>

                <hr />

                <section class='level-img'>
                  <img width='25' class='mr-3' height='25' src='../../../../../assets/images/filledVentilation.svg'  alt='' />
                  <img width='25' class='mr-3' height='25' src='../../../../../assets/images/filledVentilation.svg' alt='' />
                  <img width='25' height='25' src='../../../../../assets/images/filledVentilation.svg' alt='' />
                </section>

                <section class='d-flex justify-content-between align-items-start'>
                  <p class='level-text-orintation mr-2'>
                    Ambiente aberto com poucas paredes e ventilação natural
                  </p>

                  <b-tooltip
                    target='nivelVentilacaoAlto'
                    placement='top'
                    triggers='hover'
                  >
                    Um exemplo de ambiente de trabalho assim é um pátio de posto de
                    gasolina ou ambientes abertos com possibilidade de circulação
                    de ar e ventos por todas as direções.
                  </b-tooltip>

                  <b-icon
                    class='ml-1 '
                    font-scale='1.5'
                    icon='question-circle-fill'
                    id='nivelVentilacaoAlto'
                    variant='info'
                  >
                  </b-icon>
                </section>
              </section>

            </b-form-radio-group>

            <b-form-radio-group
              v-else
              class='container-level'
              v-model='termsOfUse.ventilationInstalledSpecificallyForTheProductionProcess'
            >
              <section
                class=' level'
                :class="termsOfUse.ventilationInstalledSpecificallyForTheProductionProcess === 2 ? 'ventilation-level-selected': 'ventilation-level-not-selected'"
              >
                <b-form-radio :value='2'>
                  <span class='level-text-radio'>
                    Nível de Controle 1
                  </span>
                </b-form-radio>
                <hr />
                <section class='level-img'>
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />

                </section>

                <section class='d-flex justify-content-between align-items-start'>
                  <p class='level-text-orintation mr-2'>
                    Ventilação geral com menos de 3 a 6 trocas de ar por hora
                  </p>
                </section>
              </section>


              <section
                class=' level'
                :class="termsOfUse.ventilationInstalledSpecificallyForTheProductionProcess === 3 ? 'ventilation-level-selected': 'ventilation-level-not-selected'"
              >
                <b-form-radio :value='3'>
                  <span class='level-text-radio'>
                    Nível de Controle 2
                  </span>
                </b-form-radio>
                <hr />
                <section class='level-img'>
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />

                </section>

                <section class='d-flex justify-content-between align-items-start'>
                  <p class='level-text-orintation mr-2'>
                    Boa ventilação geral com menos de 6 a 12 trocas de ar por hora
                  </p>
                </section>
              </section>


              <section
                class=' level'
                :class="termsOfUse.ventilationInstalledSpecificallyForTheProductionProcess === 4 ? 'ventilation-level-selected': 'ventilation-level-not-selected'"
              >
                <b-form-radio :value='4'>
                  <span class='level-text-radio'>
                    Nível de Controle 3
                  </span>
                </b-form-radio>
                <hr />
                <section class='level-img'>
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />

                </section>

                <section class='d-flex justify-content-between align-items-start'>
                  <p class='level-text-orintation mr-2'>
                    Boa ventilação geral com exaustão local nos pontos de emissão
                  </p>
                </section>
              </section>


              <section
                class=' level'
                :class="termsOfUse.ventilationInstalledSpecificallyForTheProductionProcess === 5 ? 'ventilation-level-selected': 'ventilation-level-not-selected'"
              >
                <b-form-radio :value='5'>
                  <span class='level-text-radio'>
                    Nível de Controle 4
                  </span>
                </b-form-radio>
                <hr />
                <section class='level-img'>
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />

                </section>

                <section class='d-flex justify-content-between align-items-start'>
                  <p class='level-text-orintation mr-2'>
                    Captura no ponto de emissão com contenção
                  </p>
                </section>
              </section>


              <section
                class=' level'
                :class="termsOfUse.ventilationInstalledSpecificallyForTheProductionProcess === 6 ? 'ventilation-level-selected': 'ventilation-level-not-selected'"
              >
                <b-form-radio :value='6'>
                  <span class='level-text-radio'>
                    Nível de Controle 5
                  </span>
                </b-form-radio>
                <hr />
                <section class='level-img'>
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />

                </section>

                <section class='d-flex justify-content-between align-items-start'>
                  <p class='level-text-orintation mr-2'>
                    Enclausuramento do agente (uma barreira de proteção)
                  </p>
                </section>
              </section>


              <section
                class=' level'
                :class="termsOfUse.ventilationInstalledSpecificallyForTheProductionProcess === 7 ? 'ventilation-level-selected': 'ventilation-level-not-selected'"
              >
                <b-form-radio :value='7'>
                  <span class='level-text-radio'>
                    Nível de Controle 6
                  </span>
                </b-form-radio>
                <hr />
                <section class='level-img'>
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/filledVentilation.svg'
                    alt=''
                  />

                </section>

                <section class='d-flex justify-content-between align-items-start'>
                  <p class='level-text-orintation mr-2'>
                    Enclausuramento primário e secundário (duas barreiras de proteção)
                  </p>
                </section>
              </section>


              <section
                class=' level'
                :class="termsOfUse.ventilationInstalledSpecificallyForTheProductionProcess === 1 ? 'ventilation-level-selected': 'ventilation-level-not-selected'"
              >
                <b-form-radio :value='1'>
                  <span class='level-text-radio'>
                    Nenhuma das opções anteriores
                  </span>
                </b-form-radio>
                <hr />
                <section class='level-img'>
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />
                  <img
                    class='mr-3'
                    width='25'
                    height='25'
                    src='../../../../../assets/images/ventilation.svg'
                    alt=''
                  />

                </section>

                <section class='d-flex justify-content-between align-items-start'>
                  <p class='level-text-orintation mr-2'>
                    Caso nenhuma das outras opções seja condizente com sua condição de ventilação instalada
                  </p>
                </section>
              </section>
            </b-form-radio-group>
          </section>

          <section class='mt-4'>
            <b-alert show variant='danger' :class='{ hidden: !error }'>
              {{ error }}<br />
            </b-alert>
          </section>

          <section class='d-flex justify-content-between mt-4 w-100'>
            <button
              class='btn-cancel'
              @click='closeModal()'
            >
              <span>Cancelar</span>
            </button>

            <button
              v-if='ventilation === 1'
              class='btn-add'
              @click='next()'
            >
              <span > Seguir para próxima etapa </span>
            </button>

            <b-overlay
              v-else
              :show='loading'
              rounded
              opacity='0.6'
              spinner-small
              spinner-variant='primary'
              class=''
              style='width: 66%'
            >
              <button
                v-if='termsOfUseId'
                class='w-100 btn-add'
                style='width: 100%'
                @click='update()'
              >
                <span> Atualizar </span>
              </button>

              <button
                v-else
                class='w-100 btn-add'
                style='width: 100%'
                @click='create()'
              >
                <span> Cadastrar </span>
              </button>
            </b-overlay>
          </section>
        </section>

        <template v-slot:overlay>
          <div class='text-center loading-spinner'>
            <b-spinner
              style='width: 2rem; height: 2rem'
              variant='primary'
              label='Large Spinner'
            ></b-spinner>
            <h6 class='text-primary'>Buscando cargo...</h6>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { optionsVentilationIdentified } from '@/mocks/optionsVentilationIdentified'

export default {
  name: 'VentilationIdentifiedModal',
  components: {},
  props: {
    termsOfUseId: {
      type: String,
      required: false,
      default: null
    },
    productId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      lookingTermsOfUse: false,
      titles: {
        1: `Condição de ventilação do ambiente de trabalho de forma geral`,
        2: `Condição de ventilação instalada e específica para o processo produtivo`
      },
      ventilation: 2,
      ventilationConditionsEnvironmentWorkGeneralForm: 1,
      specificInstalledVentilationConditionsProductionProcess: 3,
      termsOfUse: {
        ventilationWorkEnvironmentGeneral: null,
        ventilationInstalledSpecificallyForTheProductionProcess: null,
      },
      optionsVentilationIdentified: []
    }
  },
  async mounted() {
    try {

    } catch (e) {
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    },
    disabled() {
      return !(this.termsOfUse.ventilationIdentified)
    },
    error() {
      return this.$store.getters['access/error']
    },
  },
  methods: {
    ...mapActions('termsOfUse', {
      handleCreate: 'handleCreate',
      handleGet: 'handleGet',
      handleUpdade: 'handleUpdade'
    }),
    async closeModalVentilationIdentified() {
      try {
        this.$bvModal.hide('ventilationIdentified')
      } catch (e) {

      }
    },

    async showModal() {
      try {
        await this.$store.dispatch('access/updateError', '')
        Object.assign(this.optionsVentilationIdentified, optionsVentilationIdentified)
        this.optionsVentilationIdentified = optionsVentilationIdentified
        this.termsOfUse = {
          ventilationWorkEnvironmentGeneral: null,
          ventilationInstalledSpecificallyForTheProductionProcess: null,
        }
        this.ventilation = 1
        if (this.termsOfUseId) {
          await this.get()
        }
      } catch (e) {

      }
    },

    async next() {
      try {
        await this.$store.dispatch('access/updateError', '')
        if(!this.termsOfUse.ventilationWorkEnvironmentGeneral){
          await this.$store.dispatch('access/updateError', 'Selecione o nível de Ventilação')
          return
        }
        this.ventilation = 2
      } catch (e) {
      }
    },

    async create() {
      try {
        if(!this.termsOfUse.ventilationInstalledSpecificallyForTheProductionProcess){
          await this.$store.dispatch('access/updateError', 'Selecione o nível de Controle')
          return
        }
        this.loading = true
        this.termsOfUse.product = this.productId
        this.termsOfUse.fields = ['Condição de ventilação do ambiente de trabalho de forma geral', 'Condição de ventilação instalada e específica para o processo produtivo']
        const { data, status } = await this.handleCreate(this.termsOfUse)
        this.loading = false
        let message = data.message
        let variant = 'success'
        if (status !== 201) {
          message = data.errors
          variant = 'danger'
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.closeModal()
        this.$emit('update')
      } catch (e) {
        this.$bvToast.toast('Não foi possível cadastrar a condição de ventilação do ambiente de trabalho de forma geral e a condição de ventilação instalada e específica para o processo produtivo, verifique os dados e tente novamente.', {
          title: 'Atenção',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 10000
        })
        this.loading = false
        this.closeModal()
        this.$emit('update')
      }
    },

    async update() {
      try {
        if(!this.termsOfUse.ventilationInstalledSpecificallyForTheProductionProcess){
          await this.$store.dispatch('access/updateError', 'Selecione o nível de Controle')
          return
        }
        this.loading = true
        this.termsOfUse.fields = ['Condição de ventilação do ambiente de trabalho de forma geral', 'Condição de ventilação instalada e específica para o processo produtivo']
        const { data, status } = await this.handleUpdade(this.termsOfUse)
        this.loading = false
        let message = data.message
        let variant = 'success'
        if (status !== 200) {
          message = data.errors
          variant = 'danger'
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.closeModal()
        this.$emit('update')
      } catch (e) {
        this.$bvToast.toast('Não foi possível atualizar a condição de ventilação do ambiente de trabalho de forma geral e a condição de ventilação instalada e específica para o processo produtivo, verifique os dados e tente novamente.', {
          title: 'Atenção',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 10000
        })
        this.loading = false
        this.closeModal()
        this.$emit('update')
      }
    },

    async get() {
      try {
        this.lookingTermsOfUse = true
        const { data, status } = await this.handleGet(this.termsOfUseId)
        const { ventilationWorkEnvironmentGeneral, ventilationInstalledSpecificallyForTheProductionProcess,  _id } = data.termsOfUse
        this.termsOfUse.ventilationWorkEnvironmentGeneral = ventilationWorkEnvironmentGeneral
        this.termsOfUse.ventilationInstalledSpecificallyForTheProductionProcess = ventilationInstalledSpecificallyForTheProductionProcess
        this.termsOfUse._id = _id
        this.lookingTermsOfUse = false
      } catch (e) {
      }
    },

    closeModal() {
      if (this.loading) {
        return
      }
      this.$bvModal.hide('ventilationIdentifiedModal')
    }
  },
  created() {
  }
}
</script>
<style lang='scss' scoped>
.btn-cancel {
  background: transparent !important;
  border-radius: 4px !important;
  border: 1px solid var(--navy-blue) !important;
  width: 32%!important;
  padding: 6px 0;
  span {
    color: var(--navy-blue);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.btn-add {
  background: var(--navy-blue) !important;
  border-radius: 4px !important;
  border: 1px solid var(--navy-blue) !important;
  width: 66%;
  padding: 6px 0;
  span {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.modalVentilacao {
  display: inline-block;
  margin: 10px;
  justify-content: center;
  cursor: pointer;

  .img-caption {
    text-align: center;

    .caption {
      font-size: 13px;
      text-align: center;
      max-width: 230px;
    }
  }
}

.ventilationIdentified {
  .label {
    font-size: 18px;
    font-weight: 700;
  }

  .btn {
    width: 100%;
    min-height: 48px;
    border: 1px solid var(--border-input);
    border-radius: 4px;
    outline: none !important;
    text-align: left;
  }

}

.title{
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: var(--navy-blue)
}
.sub-title{
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #808080;
}

.ventilation-level-not-selected {
  border: 1px solid #D9D9D9;
  .level-text-radio {
    font-size: 14px;
    font-weight: 700;
    color: #808080;
  }
}
.ventilation-level-selected {
  border: 1px solid #022245;
  .level-text-radio {
    font-size: 14px;
    font-weight: 700;
    color: #022245;
  }
}

.level {
  border-radius: 4px;
  padding: 10px;
  margin: 5px;
  //width: 32%;
  .level-img {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  .level-text-orintation {
    font-size: 14px;
    font-weight: 400;

  }
}

hr {
  background-color: #D9D9D9;
}
.container-level{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32%, auto));
}
</style>
