<template>
  <div class='mt-1'>
    <b-modal
      ref='sector'
      id='sector'
      hide-footer
      header-bg-variant='info'
      header-text-variant='light'
      :title="`${sectorId ? 'Atualizar setor' : 'Cadastrar setor'}`"
      size='lg'
      @show='showModal()'
    >
      <b-overlay
        :show='lookingForCompany'
        rounded
        opacity='0.6'
        spinner-small
        spinner-variant='primary'
        class='d-inline-block w-100'
        style='min-height: 150px'
      >
        <section class='modal-font-family'>
          <section>
            <label>Nome</label>
            <base-input-text
              v-model='sector.name'
              placeholder='Digite o nome do setor'
              class='label-input-modal'
              type='text'
            />
          </section>

          <section class='d-flex justify-content-between mt-4'>
            <base-button
              class='w-50 mr-3 btn-cancel'
              @click='closeModal()'
            >
              <span>Cancelar</span>
            </base-button>

            <b-overlay
              :show='loading'
              rounded
              opacity='0.6'
              spinner-small
              spinner-variant='primary'
              class='d-inline-block w-50'
            >
              <base-button
                v-if='sectorId'
                :disabled='disabled'
                class='w-100 btn-add d-flex align-items-center justify-content-center'
                @click='update()'
              >
                <span class='mr-2'> Atualizar </span>
              </base-button>

              <base-button
                v-else
                :disabled='disabled'
                class='w-100 btn-add d-flex align-items-center justify-content-center'
                @click='create()'
              >
                <span class='mr-2'> Cadastrar </span>
              </base-button>
            </b-overlay>
          </section>
        </section>

        <template v-slot:overlay>
          <div class='text-center loading-spinner'>
            <b-spinner
              style='width: 2rem; height: 2rem'
              variant='primary'
              label='Large Spinner'
            ></b-spinner>
            <h6 class='text-primary'>Buscando setor...</h6>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Sector',
  components: {},
  props: {
    sectorId: {
      type: String,
      required: false,
      default: null
    },
    unitId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      lookingForCompany: false,
      sector: {
        name: '',
        _id: null
      }
    }
  },
  async mounted() {
    try {
    } catch (e) {
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    },
    disabled() {
      return !(this.sector.name)
    }
  },
  methods: {
    ...mapActions('sector', {
      handleCreate: 'handleCreate',
      handleGet: 'handleGet',
      handleUpdade: 'handleUpdade'
    }),
    async showModal() {
      try {
        this.sector = {
          name: '',
          _id: null
        }
        if (this.sectorId) {
          await this.get()
        }
      } catch (e) {

      }
    },

    async create() {
      try {
        this.loading = true
        this.sector.unit = this.unitId
        const { data, status } = await this.handleCreate(this.sector)
        this.loading = false
        let message = data.message
        let variant = 'success'
        if (status !== 201) {
          message = data.errors
          variant = 'danger'
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        await this.closeModal()
        this.$emit('update')
      } catch (e) {
      }
    },

    async update() {
      try {
        this.loading = true
        const { data, status } = await this.handleUpdade(this.sector)
        this.loading = false
        let message = data.message
        let variant = 'success'
        if (status !== 200) {
          message = data.errors
          variant = 'danger'
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        await this.closeModal()
        this.$emit('update')
      } catch (e) {
      }
    },

    async get() {
      try {
        this.lookingForCompany = true
        const { data, status } = await this.handleGet(this.sectorId)
        const { sector } = data
        this.sector = sector
        this.lookingForCompany = false
      } catch (e) {
      }
    },

    closeModal() {
      this.$bvModal.hide('sector')
    }
  },
  created() {
  }
}
</script>
<style lang='scss' scoped>
.btn-cancel {
  background: transparent !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: var(--navy-blue);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.btn-add {
  background: var(--navy-blue) !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}
</style>
