export const ventilationLevels = [
  {
    key: 1,
    value: 'Ambiente aberto com poucas paredes e ventilação natural'
  },
  {
    key: 2,
    value: 'Ambiente aberto com muitas paredes, mas com muitas janelas e ventilação natural'
  },
  {
    key: 3,
    value: 'Ambiente aberto com muitas paredes e poucas janelas e ventilação natural'
  }
]