export function agentCASNumberIsValid(cas) {
  return new RegExp(/\b[1-9]{1}[0-9]{1,10}-\d{2}-\d\b|\bCAS-[1-9]{1}[0-9]{1,10}-\d{2}-\d\b/).test(cas)
}
export function extractString(text) {
  return text ? text.replace(new RegExp(/\b[1-9]{1}[0-9]{1,10}-\d{2}-\d\b|\bCAS-[1-9]{1}[0-9]{1,10}-\d{2}-\d\b/), '') : text
}
export function extractCASNumber(text) {
  const textToExtractTheCas = text.replaceAll(' ', '').trim()
  const regexCheckIfItsNumber = /^[0-9]+$/;
  for (let i = 0; i < textToExtractTheCas.length; i++) {
    const textSubstring = textToExtractTheCas.substring(i, textToExtractTheCas.length);
    const firstCharacter = textSubstring.substring(0, 1)
    if(firstCharacter.match(regexCheckIfItsNumber)){
      for (let i = textSubstring.length; i >= 0 ; i--) {
        const cas = textSubstring.substring(0, i).match(new RegExp(/\b[1-9]{1}[0-9]{1,10}-\d{2}-\d\b|\bCAS-[1-9]{1}[0-9]{1,10}-\d{2}-\d\b/))
        if(cas){
          return cas ? cas.join('') : cas;
        }
      }
    }
  }
  return null
}
