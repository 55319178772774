<template>
  <b-modal
    id="interactions-modal"
    size="md"
    header-bg-variant="primary"
    header-text-variant="light"
    title="Escolha as atividades realizadas"
    ref="modal"
    hide-footer
  >
    <form>
      <b-form-group>
        <b-form-radio-group
          id="radio-group-1"
          v-model="selectedInteractions"
          :options="interactions"
          name="radio-options"
          stacked
        ></b-form-radio-group>
      </b-form-group>
      <br />
      <b-input-group>
        <b-button variant="success" @click="save" block>
          <i class="fa fa-save"></i> Salvar
        </b-button>
      </b-input-group>
    </form>
  </b-modal>
</template>

<script>
export default {
  computed: {
    interactions() {
      const catalog = 'acgih'
      if (this.agent[catalog] && this.agent[catalog].interactions) {
        let interactions = []
        this.agent[catalog].interactions.forEach((group) => {
          interactions = interactions.concat(group.interactions)
        })
        interactions = interactions.concat(['Todas as opções anteriores'])
        return interactions
      } else return ['Todas as opções anteriores']
    },
  },
  data() {
    return {
      selectedInteractions: [],
    }
  },
  methods: {
    save() {
      this.$emit(
        'save',
        Object.assign(this.agent, {
          selectedInteractions: this.selectedInteractions,
        })
      )
    },
  },
  props: ['agent', 'prevInteractions'],
  watch: {
    agent() {
      this.selectedInteractions = this.agent.acgihInfo.interactions
        ? this.agent.acgihInfo.interactions
        : []
    },
  },
}
</script>
