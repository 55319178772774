<template>
  <div class='mt-1'>
    <b-modal
      ref='quantityUsedFrequencyExposureTimeUseModal'
      id='quantityUsedFrequencyExposureTimeUseModal'
      hide-footer
      hide-header
      header-bg-variant='info'
      header-text-variant='light'
      size='lg'
      @show='showModal()'
      :no-close-on-esc='loading'
      :no-close-on-backdrop='loading'
    >
      <b-overlay
        :show='lookingTermsOfUse'
        rounded
        opacity='0.6'
        spinner-small
        spinner-variant='primary'
        class='d-inline-block w-100'
        style='min-height: 150px'
      >
        <section class='modal-font-family'>
          <section class='d-flex justify-content-end w-100 mb-1'>
            <b-icon
              @click='closeModal()'
              class='cursor-pointer'
              font-scale='2.2'
              icon='x'
              color='#022245'
            >
            </b-icon>
          </section>

          <section>
            <b-tooltip target='quantityUsed' triggers='hover' placement='top'>
              Selecione a quantidade diária utilizada deste produto. Esta informação se refere a quantidade diária que o
              trabalhador usa durante sua jornada de trabalho, ou seja, durante o dia quando ele executa a atividade ele
              usa quantos
              quilos ou litros desse produto?
            </b-tooltip>

            <label class='d-flex align-items-center label'>
              Quantidade diária utilizada deste produto (em kg ou L)

              <svg class='ml-2' id='quantityUsed' width='18' height='18' viewBox='0 0 20 20' fill='none'
                   xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M9.95 16C10.3 16 10.596 15.879 10.838 15.637C11.08 15.395 11.2007 15.0993 11.2 14.75C11.2 14.4 11.0793 14.104 10.838 13.862C10.5967 13.62 10.3007 13.4993 9.95 13.5C9.6 13.5 9.30433 13.621 9.063 13.863C8.82167 14.105 8.70067 14.4007 8.7 14.75C8.7 15.1 8.821 15.396 9.063 15.638C9.305 15.88 9.60067 16.0007 9.95 16ZM9.05 12.15H10.9C10.9 11.6 10.9627 11.1667 11.088 10.85C11.2133 10.5333 11.5673 10.1 12.15 9.55C12.5833 9.11667 12.925 8.704 13.175 8.312C13.425 7.92 13.55 7.44933 13.55 6.9C13.55 5.96667 13.2083 5.25 12.525 4.75C11.8417 4.25 11.0333 4 10.1 4C9.15 4 8.379 4.25 7.787 4.75C7.195 5.25 6.78267 5.85 6.55 6.55L8.2 7.2C8.28333 6.9 8.471 6.575 8.763 6.225C9.055 5.875 9.50067 5.7 10.1 5.7C10.6333 5.7 11.0333 5.846 11.3 6.138C11.5667 6.43 11.7 6.75067 11.7 7.1C11.7 7.43333 11.6 7.746 11.4 8.038C11.2 8.33 10.95 8.60067 10.65 8.85C9.91667 9.5 9.46667 9.99167 9.3 10.325C9.13333 10.6583 9.05 11.2667 9.05 12.15ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z'
                  fill='#262626' />
              </svg>
            </label>

            <p v-if='!termsOfUse.quantityUsed' class='text-orientation'>Selecione uma das opções da escala abaixo</p>

            <section class='w-100 mt-3 d-flex align-items-end'>
              <section class='w-100 text-center container-option'>
                <section class='options'>
                  <section
                    class='position-relative cursor-pointer'
                    v-for='option in optionsQuantityUsed'
                    :key="`${option.key}_quantityUsed`"
                    @click='setQuantityUsed(option.key)'
                  >
                    <p :class="option.key === termsOfUse.quantityUsed ? 'selected' : 'not-selected'">{{ option.value }}</p>
                    <section :class="option.key === termsOfUse.quantityUsed ? 'circule' : 'trace'"></section>
                  </section>
                </section>
              </section>

              <section class='arrow'></section>
            </section>

            <section class='w-100 d-flex justify-content-between mt-3 selected'>
              <p>Pouco</p>
              <p>Muito</p>
            </section>
          </section>

          <section class='mt-3'>
            <b-tooltip target='frequencyUse' triggers='hover' placement='top'>
              Selecione a frequência utilizada deste produto. Esta informação está relacionada a
              quantidade aproximada de quantas vezes o trabalhador utiliza este produto por dia.
              Caso o produto seja utilizado em dias alternados ou com frequência reduzida,
              selecione a opção mais próxima dentre as ofertadas abaixo. Dica para facilitar
              a seleção da opção: pense em quantidade de uso por dia, senão pense em quantidade
              de usos por semana ou mês.
            </b-tooltip>

            <label class='d-flex align-items-center label '>
              Frequência de utilização deste produto


              <svg class='ml-2' id='frequencyUse' width='18' height='18' viewBox='0 0 20 20' fill='none'
                   xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M9.95 16C10.3 16 10.596 15.879 10.838 15.637C11.08 15.395 11.2007 15.0993 11.2 14.75C11.2 14.4 11.0793 14.104 10.838 13.862C10.5967 13.62 10.3007 13.4993 9.95 13.5C9.6 13.5 9.30433 13.621 9.063 13.863C8.82167 14.105 8.70067 14.4007 8.7 14.75C8.7 15.1 8.821 15.396 9.063 15.638C9.305 15.88 9.60067 16.0007 9.95 16ZM9.05 12.15H10.9C10.9 11.6 10.9627 11.1667 11.088 10.85C11.2133 10.5333 11.5673 10.1 12.15 9.55C12.5833 9.11667 12.925 8.704 13.175 8.312C13.425 7.92 13.55 7.44933 13.55 6.9C13.55 5.96667 13.2083 5.25 12.525 4.75C11.8417 4.25 11.0333 4 10.1 4C9.15 4 8.379 4.25 7.787 4.75C7.195 5.25 6.78267 5.85 6.55 6.55L8.2 7.2C8.28333 6.9 8.471 6.575 8.763 6.225C9.055 5.875 9.50067 5.7 10.1 5.7C10.6333 5.7 11.0333 5.846 11.3 6.138C11.5667 6.43 11.7 6.75067 11.7 7.1C11.7 7.43333 11.6 7.746 11.4 8.038C11.2 8.33 10.95 8.60067 10.65 8.85C9.91667 9.5 9.46667 9.99167 9.3 10.325C9.13333 10.6583 9.05 11.2667 9.05 12.15ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z'
                  fill='#262626' />
              </svg>
            </label>
            <p v-if='!termsOfUse.frequencyUse' class='text-orientation'>Selecione uma das opções da escala abaixo</p>

            <section class='w-100 mt-3 d-flex align-items-end'>
              <section class='w-100 text-center container-option' style='height: 135px'>
                <section class='options'>
                  <section
                    class='position-relative cursor-pointer'
                    v-for='option in optionsFrequencyUse'
                    :key="`${option.key}_frequencyUse`"
                    @click='setFrequencyUse(option.key)'
                  >
                    <p
                      :class="option.key === termsOfUse.frequencyUse ? 'selected' : 'not-selected'"
                      :style="option.key !== 1 ? 'padding-bottom: 48px' : ' '"
                    >
                      {{ option.value }}
                    </p>
                    <section :class="option.key === termsOfUse.frequencyUse ? 'circule' : 'trace'"></section>
                  </section>
                </section>
              </section>

              <section class='arrow'></section>
            </section>

            <section class='w-100 d-flex justify-content-between mt-3 selected'>
              <p>Pouco</p>
              <p>Muito</p>
            </section>
          </section>

          <section class='mt-3'>
            <b-tooltip target='exposureTime' triggers='hover' placement='top'>
              Selecione o tempo total diário estimado em que este trabalhador fica exposto a este produto.
              Esta informação está relacionada com o tempo aproximado de quantas horas ou minutos o trabalhador utiliza
              este produto por dia.
              Caso o produto seja utilizado em dias alternados ou com frequência reduzida, selecione a opção mais
              próxima dentre as ofertadas abaixo.
              Dica para facilitar a seleção da opção: pense em quantidade de uso por dia, senão pense em quantidade de
              usos por semana ou mês.
            </b-tooltip>

            <label class='d-flex align-items-center label '>
              Tempo de exposição do trabalhador com o uso do produto (por dia)

              <svg class='ml-2' id='exposureTime' width='18' height='18' viewBox='0 0 20 20' fill='none'
                   xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M9.95 16C10.3 16 10.596 15.879 10.838 15.637C11.08 15.395 11.2007 15.0993 11.2 14.75C11.2 14.4 11.0793 14.104 10.838 13.862C10.5967 13.62 10.3007 13.4993 9.95 13.5C9.6 13.5 9.30433 13.621 9.063 13.863C8.82167 14.105 8.70067 14.4007 8.7 14.75C8.7 15.1 8.821 15.396 9.063 15.638C9.305 15.88 9.60067 16.0007 9.95 16ZM9.05 12.15H10.9C10.9 11.6 10.9627 11.1667 11.088 10.85C11.2133 10.5333 11.5673 10.1 12.15 9.55C12.5833 9.11667 12.925 8.704 13.175 8.312C13.425 7.92 13.55 7.44933 13.55 6.9C13.55 5.96667 13.2083 5.25 12.525 4.75C11.8417 4.25 11.0333 4 10.1 4C9.15 4 8.379 4.25 7.787 4.75C7.195 5.25 6.78267 5.85 6.55 6.55L8.2 7.2C8.28333 6.9 8.471 6.575 8.763 6.225C9.055 5.875 9.50067 5.7 10.1 5.7C10.6333 5.7 11.0333 5.846 11.3 6.138C11.5667 6.43 11.7 6.75067 11.7 7.1C11.7 7.43333 11.6 7.746 11.4 8.038C11.2 8.33 10.95 8.60067 10.65 8.85C9.91667 9.5 9.46667 9.99167 9.3 10.325C9.13333 10.6583 9.05 11.2667 9.05 12.15ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z'
                  fill='#262626' />
              </svg>
            </label>

            <p v-if='!termsOfUse.exposureTime' class='text-orientation'>Selecione uma das opções da escala abaixo</p>

            <section class='w-100 mt-3 d-flex align-items-end'>
              <section class='w-100 text-center container-option' style='height: 87px'>
                <section class='options'>
                  <section
                    class='position-relative cursor-pointer'
                    v-for='option in optionsExposureTime'
                    :key="`${option.key}_exposureTime`"
                    @click='setExposureTime(option.key)'
                  >
                    <p
                      :class="option.key === termsOfUse.exposureTime ? 'selected' : 'not-selected'"
                      :style="option.key !== 1 && option.key !== 6 ? 'padding-bottom: 24px' : ' '"
                    >
                      {{ option.value }}
                    </p>
                    <section :class="option.key === termsOfUse.exposureTime ? 'circule' : 'trace'"></section>
                  </section>
                </section>
              </section>

              <section class='arrow'></section>
            </section>

            <section class='w-100 d-flex justify-content-between mt-3 selected'>
              <p>Pouco</p>
              <p>Muito</p>
            </section>
          </section>

          <section class='d-flex justify-content-between mt-4'>
            <base-button
              class='w-50 mr-3 btn-cancel'
              @click='closeModal()'
            >
              <span>Cancelar</span>
            </base-button>

            <b-overlay
              :show='loading'
              rounded
              opacity='0.6'
              spinner-small
              spinner-variant='primary'
              class='d-inline-block w-50'
            >
              <base-button
                v-if='termsOfUseId'
                :disabled='disabled'
                class='w-100 btn-add d-flex align-items-center justify-content-center'
                @click='update()'
              >
                <span class='mr-2'> Atualizar </span>
              </base-button>

              <base-button
                v-else
                :disabled='disabled'
                class='w-100 btn-add d-flex align-items-center justify-content-center'
                @click='create()'
              >
                <span class='mr-2'> Cadastrar </span>
              </base-button>
            </b-overlay>
          </section>
        </section>

        <template v-slot:overlay>
          <div class='text-center loading-spinner'>
            <b-spinner
              style='width: 2rem; height: 2rem'
              variant='primary'
              label='Large Spinner'
            ></b-spinner>
            <h6 class='text-primary'>Buscando...</h6>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { optionsQuantityUsed } from '@/mocks/optionsQuantityUsed'
import { optionsFrequencyUse } from '@/mocks/optionsFrequencyUse'
import { optionsExposureTime } from '@/mocks/optionsExposureTime'

export default {
  name: 'QuantityUsedFrequencyExposureTimeUseModal',
  components: {},
  props: {
    termsOfUseId: {
      type: String,
      required: false,
      default: null
    },
    productId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      visibleFrequencyUse: false,
      visibleQuantityUsed: false,
      visibleExposureTime: false,
      lookingTermsOfUse: false,
      termsOfUse: {
        quantityUsed: null,
        frequencyUse: null,
        exposureTime: null
      },
      optionsExposureTime: [],
      optionsFrequencyUse: [],
      optionsQuantityUsed: []
    }
  },
  async mounted() {
    try {

    } catch (e) {
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    },
    disabled() {
      return !(this.termsOfUse.quantityUsed && this.termsOfUse.frequencyUse && this.termsOfUse.exposureTime)
    }
  },
  methods: {
    ...mapActions('termsOfUse', {
      handleCreate: 'handleCreate',
      handleGet: 'handleGet',
      handleUpdade: 'handleUpdade'
    }),
    setQuantityUsed(quantityUsed) {
      this.termsOfUse.quantityUsed = quantityUsed
      this.visibleQuantityUsed = false
    },

    setFrequencyUse(frequencyUse) {
      this.termsOfUse.frequencyUse = frequencyUse
      this.visibleFrequencyUse = false
    },

    setExposureTime(exposureTime) {
      this.termsOfUse.exposureTime = exposureTime
      this.visibleExposureTime = false
    },
    async showModal() {
      try {
        Object.assign(this.optionsExposureTime, optionsExposureTime)
        this.optionsExposureTime = optionsExposureTime
        Object.assign(this.optionsFrequencyUse, optionsFrequencyUse)
        this.optionsFrequencyUse = optionsFrequencyUse
        Object.assign(this.optionsQuantityUsed, optionsQuantityUsed)
        this.optionsQuantityUsed = optionsQuantityUsed
        this.termsOfUse = {
          quantityUsed: null,
          frequencyUse: null,
          exposureTime: null,
        }
        if (this.termsOfUseId) {
          await this.get()
        }
      } catch (e) {

      }
    },

    async create() {
      try {
        this.loading = true
        this.termsOfUse.product = this.productId
        this.termsOfUse.fields = ['Quantidade diária utilizada deste produto (em kg ou L)', 'Frequência de utilização deste produto', 'Tempo de exposição do trabalhador com o uso do produto (por dia)'];
        const { data, status } = await this.handleCreate(this.termsOfUse)
        this.loading = false
        let message = data.message
        let variant = 'success'
        if (status !== 201) {
          message = data.errors
          variant = 'danger'
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.closeModal()
        this.$emit('update')
      } catch (e) {
        this.$bvToast.toast('Não foi possível cadastrar a auantidade diária utilizada deste produto, frequência de utilização deste produto e o tempo de exposição do trabalhador com o uso do produto, verifique os dados e tente novamente.', {
          title: 'Atenção',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 10000
        })
        this.loading = false
        this.closeModal()
        this.$emit('update')
      }
    },

    async update() {
      try {
        this.loading = true
        this.termsOfUse.fields = ['Quantidade diária utilizada deste produto (em kg ou L)', 'Frequência de utilização deste produto', 'Tempo de exposição do trabalhador com o uso do produto (por dia)'];
        const { data, status } = await this.handleUpdade(this.termsOfUse)
        this.loading = false
        let message = data.message
        let variant = 'success'
        if (status !== 200) {
          message = data.errors
          variant = 'danger'
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.closeModal()
        this.$emit('update')
      } catch (e) {
        this.$bvToast.toast('Não foi possível atualizar a auantidade diária utilizada deste produto, frequência de utilização deste produto e o tempo de exposição do trabalhador com o uso do produto, verifique os dados e tente novamente.', {
          title: 'Atenção',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 10000
        })
        this.loading = false
        this.closeModal()
        this.$emit('update')
      }
    },

    async get() {
      try {
        this.lookingTermsOfUse = true
        const { data, status } = await this.handleGet(this.termsOfUseId)
        const { quantityUsed, frequencyUse, exposureTime, _id } = data.termsOfUse
        this.termsOfUse.quantityUsed = quantityUsed ? quantityUsed : null
        this.termsOfUse.frequencyUse = frequencyUse ? frequencyUse : null
        this.termsOfUse.exposureTime = exposureTime ? exposureTime : null
        this.termsOfUse._id = _id
        this.lookingTermsOfUse = false
      } catch (e) {
      }
    },

    closeModal() {
      if (this.loading) {
        return
      }
      this.$bvModal.hide('quantityUsedFrequencyExposureTimeUseModal')
    }
  },
  created() {
  }
}
</script>
<style lang='scss' scoped>
.btn-cancel {
  background: transparent !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: var(--navy-blue);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.btn-add {
  background: var(--navy-blue) !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.container-option {
  border-bottom: 8px solid #04A8F366;
  height: 63px;

  .options {
    text-align: center !important;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16.66%, auto)) !important;
    justify-items: center !important;
    section{
      padding-bottom: 10px!important;
    }
  }
}

.arrow {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-left: 30px solid #04A8F366;
  border-bottom: 15px solid transparent;
  margin-bottom: -10px;
}

.trace {
  width: 4px !important;
  height: 15px !important;
  background-color: #04A8F366 !important;
  margin: 0 auto !important;
  transition: all 0.1s ease-in-out;

}

.circule {
  position: absolute;
  width: 22px !important;
  height: 22px !important;
  background: #04A8F3;
  justify-content: center!important;
  border-radius: 50%;
  bottom: -5px !important;
  left: 0;
  right: 0;
  transition: all 0.1s ease-in-out;
  text-align: center;
  margin: auto !important;
}

.selected {
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  color: #04A8F3;
  text-align: center;
  padding: 0 3px;
}
.not-selected {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #262626;
  padding: 0 3px;
}

.label {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #262626;

}
.text-orientation {
  margin-top: -3px!important;
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #808080;
}
</style>
