export const optionsQuantityUsed = [
  {
    key: 1,
    value: 'Menos que 1'
  },
  {
    key: 2,
    value: '1-10'
  },
  {
    key: 3,
    value: '10-50'
  },
  {
    key: 4,
    value: '50-100'
  },
  {
    key: 5,
    value: '1-1000'
  },
  {
    key: 6,
    value: 'Mais que 1000'
  }
]