<template>
  <div>
    <router-link :to="{ name: 'PrintReport' }" v-if="allowed">
      <span class="icon"><i class="fas fa-print"></i></span>
      <span class="label">Versão para impressão</span>
    </router-link>

    <table
      class="text-center table b-table table-bordered custom-table"
      v-if="allowed"
    >
      <thead>
        <tr>
          <th rowspan="2">CAS: Agente</th>
          <th>ACGIH 2024</th>
          <th colspan="6">NR 15</th>
          <th>LINACH</th>
          <th rowspan="2">ESOCIAL - Tabela 24</th>
          <th rowspan="2">Decreto 3048/99 - Anexo IV</th>
        </tr>
        <tr>
          <th>Referência</th>
          <th>Fundamentação</th>
          <th>Teto</th>
          <th>Pele</th>
          <th>mg/m³</th>
          <th>ppm</th>
          <th>Insalubridade</th>
          <th>Grupo</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in summary" :key="item.legalSearchId">
          <template v-for="field in model.tableFields">
            <td
              :key="`field_${field.key}`"
              v-if="
                field.key == 'nr15-base' && model.getPremium(field.key, item)
              "
              colspan="6"
            >
              {{ model.getValue(field.key, item) }}
            </td>
            <td
              :key="`field_${field.key}`"
              v-else-if="model.getValue(field.key, item) !== 'hidden'"
            >
              <template v-if="field.key === 'decree3048'">
                {{ model.getValue(field.key, item) }}
                <template v-if="item.decree3048 && item.decree3048.notation">
                  :
                  <a
                    href="#"
                    v-b-tooltip.lefttop.hover.html="
                      model.getValue('situation', item)
                    "
                  >
                    (Condições exemplificativas)
                  </a>
                </template>
              </template>
              <template v-else>{{ model.getValue(field.key, item) }}</template>
            </td>
          </template>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="12"></td>
        </tr>
      </tfoot>
    </table>

    <div class="text-left align-content-center d-flex" v-if='loading'>
      <p class='btn-outline-primary'>Carregando...</p>
      <div class='px-2'>
        <b-spinner class='loading' variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>

    <b-alert v-if='dontHavePlan' show variant="danger">
      Sua conta não possui um plano adequado para visualizar esse relatório.
      Verifique
      <router-link :to="{ name: 'ChangePlan' }">seu plano </router-link>
    </b-alert>
  </div>
</template>

<script>
import LegalSearchReport from '@/models/LegalSearchReport'
export default {
  data() {
    return {
      model: LegalSearchReport,
      hidden: true,
      disabled: true,
      removeAll: '',
      allowed: true,
      removeItems: [],
      maxMatchesSelect: 100,
      agent: '',
      agents: [],
      removeItem: '',
      hitted: false,
      inventoryMessage: '',
      inventory: [],
      itemsToRemove: [],
      message: '',
      options: [],
      alertvariant: 'success',
      loading: false,
      dontHavePlan: false,
    }
  },
  async mounted() {
    try {
      this.loading = true;
      await this.$api.post('/users/checkplan')
        .then((response) => {
          this.loading = false;
          this.allowed = !!response.data
          this.dontHavePlan = !response.data;
        }).catch((error) => {
          this.loading = false;
          this.dontHavePlan = false;
          console.log(error)
        })
    } catch (err) {
      this.loading = false;
      this.dontHavePlan = false;
      console.log(err)
    }
  },
  computed: {
    selectedAgent: {
      get: function () {
        return this.$store.getters['legalSearch/selectedAgent']
      },
      set: function (agent) {
        this.$store.dispatch('legalSearch/select', agent)
      },
    },
    hasSelected() {
      return this.itemsToRemove.length > 0
    },
    selectedAll: {
      get: function () {
        return this.itemsToRemove.length === this.summary.length
      },
      set: function () {},
    },
    summary() {
      return this.$store.getters['legalSearch/summary']
    },
    user() {
      return this.$store.getters['user/current']
    },
  },
}
</script>

<style>
/* .table th {
  background-color: var(--sapphire-blue);
  color: var(--white);
  padding: 15px;
}

.table td {
  padding: 5px;
}

.table {
  margin: 10px 0 50px;
}

.table #search-cell {
  text-align: left;
}
.table thead tr:last-child {
  display: none;
}
.fa-check {
  color: green;
  font-size: 23px;
}
.fa-times {
  color: red;
  font-size: 23px;
} */
.table tr:nth-child(2) th {
  top: 173px;
  z-index: 10;
}
</style>
<style scoped>
.loading{
  width: 1.5rem;
  height: 1.5rem;
}
</style>
