<template>
  <div>
    <component
      :is="modal.current"
      :isOpen="modal.open"
      :informativeText="informativeText"
      @close="modal.open = false"
      @submit-request="submitClassificationRequest"
    />

    <ProductAdd @clear-items-remove='clearItemsremove'></ProductAdd>

    <Company @clear-items-remove='clearItemsremove'></Company>

    <b-modal
      id="limitedDailySearches"
      size="lg"
      header-bg-variant="danger"
      header-text-variant="light"
      :title="`Atingido o limite de adições ${dailySearches.plan === 'TRIAL' ? 'diária' : 'mensal'}`"
      ref="limitedDailySearches"
      hide-footer
    >
      <section class='modal-font-family'>
        <p v-if="dailySearches.plan === 'TRIAL'">
          O limite do plano gratuito de adições em 24 horas foi atingido.
          Aguarde até {{ dailySearches.expireAt | moment('timezone', 'America/Sao_Paulo', 'DD/MM/YYYY HH:mm')}}
          para poder pesquisar mais agentes químicos ou tenha acesso aos recursos completos adquirindo um dos planos pagos.
          Clique sobre o texto a seguir para conferir os planos disponíveis:
          <strong><a href="/planos"> Meus Planos </a></strong>
        </p>
        <p v-else >
          O limite de agentes que podem ser adicionados dentro do período mensal da sua assinatura foi atingido.
          Assim que o uma nova fatura for gerada e estiver com status de paga na plataforma de pagamentos,
          os recursos de consulta de agentes químicos serão renovados. Para consultar agentes químicos sem limites,
          bem como ter acesso à todas as ferramentas da plataforma, clique no texto a seguir para
          <a href="/checkout?plano=AN1HO"> Adquirir um plano anual</a>.
        </p>
      </section>
    </b-modal>

    <b-modal
      id="rmconfirmation"
      size="lg"
      header-bg-variant="danger"
      header-text-variant="light"
      title="Confirmar ação?"
      ref="rmconfirmation"
      hide-footer
    >
      <section class='modal-font-family'>
        <p>
          Deseja confirmar a ação? Uma vez confirmada a ação não pode ser desfeita
        </p>
        <b-button variant="danger" @click="remove(true)" class="mr-3">Remover</b-button>
        <b-button @click="$bvModal.hide('rmconfirmation')">Cancelar</b-button>
      </section>
    </b-modal>

    <b-modal
      id="changeQueryMode"
      ref="changeQueryMode"
      size="lg"
      header-bg-variant="danger"
      header-text-variant="light"
      title="Confirmar ação?"
      hide-footer
    >
      <section class='modal-font-family'>
        <section>
          <p>
            <strong>A consulta está em modo empresa.</strong>
          </p>
          <p v-if='importedCompany && importedCompany.name'>
            {{itemsToRemove.length > 1 ? 'Os agentes serão excluídos ' : 'O agente será excluído '}} permanentemente da empresa <strong>{{importedCompany.name}}</strong>.
          </p>
        </section>

        <section>
          <label>Selecione a opção desejada para essa ação:</label>
        </section>

        <section class='mt-1'>
          <b-overlay
            :show='loading'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='d-inline-block'
          >
            <b-button
              variant="danger"
              @click="deleteSelectedAgents()"
              class='mt-2'
            >
              Excluir agentes selecionados
            </b-button>
          </b-overlay>

          <b-overlay
            :show='loadingChangeToFreeMode'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='d-inline-block'
          >
            <b-button
              variant="danger"
              @click="changeToFreeMode()"
              class='mx-1 mt-2'
            >
              Mudar para modo livre
            </b-button>
          </b-overlay>

          <b-overlay
            :show='loadingDeleteEntireProduct'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='d-inline-block'
            v-if='checkSelectedAgentsWithProducts()'
          >
            <b-button
              variant="danger"
              @click="deleteEntireProduct()"
              class='mt-2'
            >
              Excluir todo o produto
            </b-button>
          </b-overlay>

          <b-button
            @click="$bvModal.hide('changeQueryMode')"
            class='mt-2'
          >
            Cancelar
          </b-button>
        </section>
      </section>
    </b-modal>

    <b-modal
      id="selectDeleteAgentOrProduct"
      ref="selectDeleteAgentOrProduct"
      size="lg"
      header-bg-variant="danger"
      header-text-variant="light"
      title="Confirmar ação?"
      hide-footer
    >
      <section class='modal-font-family'>
        <section >
          <p v-if='product && product.name'>
            A exclusão de um ou mais agentes do <strong>{{ product.name}}</strong> irá alterar a composição do produto.
          </p>
        </section>

        <section class='my-3'>
          <label><strong>Selecione a opção desejada para essa ação:</strong></label>
        </section>

        <section>
          <b-overlay
            :show='loadingChangeToFreeMode'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='d-inline-block'
          >
            <b-button
              variant="danger"
              @click="seleteSelectedAgentsInFreeMode()"
              class='mx-1 mt-2'
            >
              Excluir somente os agentes selecionados
            </b-button>
          </b-overlay>

          <b-overlay
            :show='loadingDeleteEntireProduct'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='d-inline-block'
            v-if='checkSelectedAgentsWithProducts()'
          >
            <b-button
              variant="danger"
              @click="deleteEntireProduct()"
              class='mt-2'
            >
              Excluir todo o produto
            </b-button>
          </b-overlay>

          <b-button
            class='mt-2 ml-1'
            @click="$bvModal.hide('selectDeleteAgentOrProduct')"
          >
            Cancelar
          </b-button>
        </section>
      </section>
    </b-modal>

    <b-modal
      id="addedAgentLimits"
      size="lg"
      header-bg-variant="danger"
      header-text-variant="light"
      :title="title"
      ref="addedAgentLimits"
      hide-footer
    >
      <section class='modal-font-family'>
        <p>
          {{message}}
        </p>
        <b-row class='w-100 justify-content-end'>
          <b-button align='right'  variant="success" class="px-3" @click="$bvModal.hide('addedAgentLimits')">Ok</b-button>
        </b-row>
      </section>
    </b-modal>

    <SummaryTable
      ref="tableAgents"
      :items-to-remove="itemsToRemove"
      :selected-all="selectedAll"
      :limitAgentsAddedInAgentSelection="LIMIT_AGENTS_ADDED_IN_AGENT_SELECTION"
      @select-all="selectAll"
      @select="select"
      @remove-confirmation="removeConfirmation"
      @daily-searches="handleDailySearches"
      @addedAgentLimits="addedAgentLimits"
      @open-modal="openModal"
    />

    <base-button
      v-if="hasSelected"
      class='my-2'
      outlineDanger
      @click="deleteAgents()"
    >
      <i class="fa fa-trash"></i>
      <span class='ml-2'>Apagar selecionados</span>
    </base-button>
  </div>
</template>

<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import ModalAgentNotFound from '@/components/LegalSearch/Modal/AgentNotFound'
import ModalRequestAgent from '@/components/LegalSearch/Modal/RequestAgent'
import ModalRequestSent from '@/components/LegalSearch/Modal/RequestSent'
import SummaryTable from '@/components/LegalSearch/SummaryTable'
import XLSX from 'xlsx'
import VueHtml2pdf from 'vue-html2pdf'
import { mapActions, mapGetters } from 'vuex'
import eventBus from '../../utils/eventBus'
import ProductAdd from '@/components/LegalSearch/Modal/ProductAdd.vue'
import Company from '@/components/LegalSearch/Modal/Company.vue'

export default {
  components: {
    Company,
    ProductAdd,
    VueBootstrapTypeahead,
    ModalAgentNotFound,
    ModalRequestAgent,
    ModalRequestSent,
    VueHtml2pdf,
    SummaryTable,
  },
  data() {
    return {
      LIMIT_AGENTS_ADDED_IN_AGENT_SELECTION: 1000,
      NUMBER_OF_AGENTS_PER_IMPORT: 30,
      loading: false,
      loadingChangeToFreeMode: false,
      loadingDeleteEntireProduct: false,
      message: '',
      title: '',
      files: null,
      casList: [],
      howManyAgentsCanAdd: 0,
      numberOfImportedAgents: 0,
      importedPrcentage: 0,
      chunks: [],
      agent: 'success',
      casSearch: '',
      dailySearches: {},
      itemsToRemove: [],
      modal: {
        current: 'ModalRequestAgent',
        open: false,
      },
      informativeText: '',
      amountImportedAagents: 0,
      amountAgentsClassificationRequest: 0,
      amountAgentsPreviouslyAdded: 0,
      amountAgentsCASInvalid: 0,
      agentImportSummaryByCas: [],
      companies: [],
      products: [],
      company: null,
      product: null,
      deleteOption: null,
      legalSearchesId: [],
    }
  },

  async mounted() {
    eventBus.$on('handleDownloadPdf', async () => {
      await this.handleDownloadPdf()
    })
    eventBus.$on('analyzeAgentsDelete', async () => {
      await this.deleteAgents()
    })
    eventBus.$on('clearItems', async () => {
      console.log('clearItems')
      await this.clearItemsremove()
    })
    await this.handleGetLastImportedCompanyLegalConsultation(this.user.id)
  },

  beforeDestroy() {
    eventBus.$off('handleDownloadPdf')
    eventBus.$off('analyzeAgentsDelete')
    eventBus.$off('clearItems')
  },

  created() {
    const breadcrumb = {
      tab: 'legalSearch',
      page: 'Consulta Legal',
      subPage: {
        name: 'Consulta Legal',
        active: false
      },
      tabs: [
        {
          name: 'Seleção de agentes',
          active: true
        }
      ]
    }
    this.setBreadcrumb(breadcrumb)
  },
  methods: {
    ...mapActions('objectivesAnalysis', {
      handleGetObjectivesAnalysis: 'handleGetObjectivesAnalysis',
      handleUpdateChangedAgentsLegalConsultation: 'handleUpdateChangedAgentsLegalConsultation',
    }),
    ...mapActions('classificationRequests', {
      createClassificationRequest: 'handleCreateClassificationRequest',
    }),
    ...mapActions('userActionLogs', {
      createUserActionLogs: 'createUserActionLogs',
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    ...mapActions('event', {
      handlGetEventActive: 'handlGetEventActive',
    }),
    ...mapActions('legalSearch', {
      setSkip: 'setSkip',
      setSlectedAgents: 'setSlectedAgents',
      importFreeModeAgents: 'importFreeModeAgents',
    }),
    ...mapActions('companyLegalConsultation', {
      handleDeleteAgentCompanyLegalConsultation: 'handleDeleteAgentCompanyLegalConsultation',
      handleRemoveAgentByProductCompanyLegalConsultation: 'handleRemoveAgentByProductCompanyLegalConsultation',
      handleUnmarkAllAsLastImportedCompanyLegalConsultation: 'handleUnmarkAllAsLastImportedCompanyLegalConsultation',
      handleGetLastImportedCompanyLegalConsultation: 'handleGetLastImportedCompanyLegalConsultation',
    }),
    ...mapActions('productLegalConsultation', {
      handleGetCompanyProductLegalConsultation: 'handleGetCompanyProductLegalConsultation',
      handleDeleteProductLegalConsultation: 'handleDeleteProductLegalConsultation',
      handleDeleteAgentProductLegalConsultation: 'handleDeleteAgentProductLegalConsultation',
      handleDeleteAgentsProductLegalConsultation: 'handleDeleteAgentsProductLegalConsultation',
      handleGetProductLegalConsultation: 'handleGetProductLegalConsultation',
    }),
    ...mapActions('freeModeAgents', {
      handleRemovegentsFreeModeAgents: 'handleRemovegentsFreeModeAgents',
    }),
    ...mapActions('measurement', {
      handleDeleteByAgents: 'handleDeleteByAgents',
    }),
    addedAgentLimits(title, message){
      this.title = title
      this.message = message
      this.$refs.addedAgentLimits.show()
    },

    async deleteEntireProduct() {
      try {
        this.loadingDeleteEntireProduct = true;

        const { data } = await this.handleGetProductLegalConsultation(this.product._id)
        let agentsRemove = []
        if (data && data.product){
          const {product} = data
          agentsRemove = product.legalSearches.map((x) => x._id);
          const products = [
            {
              userId: this.user.id,
              productId: product._id,
              agents: product.legalSearches.map((x) => x.name),
            }
          ]
          await this.handleDeleteByAgents(products)
        }

        await this.handleDeleteProductLegalConsultation(this.product._id)
        if (this.importedCompany && this.importedCompany._id) {
          await this.handleDeleteAgentCompanyLegalConsultation({
              companyId: this.importedCompany._id,
              legalSearchesId: agentsRemove
          })
        }
        await this.remove()

        this.loadingDeleteEntireProduct = false;
        this.$refs.changeQueryMode.hide()
        this.$refs.selectDeleteAgentOrProduct.hide()
        this.itemsToRemove = []
        this.$bvToast.toast(`As informações da empresa foram atualizadas.`, {
          title: 'Atenção',
          variant: 'success',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 6000,
        });
      } catch (e) {

      }
    },

    async deleteMeasurementsByAgent(){
      try {
        const agentes = this.summary.filter((x) => this.itemsToRemove.includes(x.legalSearchId))
        const agentesVinculadoProduto = agentes.filter((x) => x.product && x.product._id)

        const agentesVinculadoProdutoAgrupadoPorProduto = agentesVinculadoProduto.reduce((group, agent) => {
          const product = agent.product._id;
          group[product] = group[product] ? group[product] : [];
          group[product].push(agent);
          return group;
        }, {})

        const keys = Object.keys(agentesVinculadoProdutoAgrupadoPorProduto)
        if (keys.length > 0) {
          const products = []
          keys.forEach((x) => {
            products.push(
              {
                userId: this.user.id,
                productId: x,
                agents: agentesVinculadoProdutoAgrupadoPorProduto[x].map((x) => x.name),
              }
            )
          })
          await this.handleDeleteByAgents(products)
        }

      } catch (e) {

      }
    },

    async changeToFreeMode() {
      try {
        this.loadingChangeToFreeMode = true;
        await this.remove()
        await this.handleUnmarkAllAsLastImportedCompanyLegalConsultation(this.user.id)
        await this.handleGetLastImportedCompanyLegalConsultation(this.user.id)
        await this.importFreeModeAgents(this.user.id)
        await this.load()
        this.itemsToRemove = []
        this.loadingChangeToFreeMode = false;
        this.$refs.changeQueryMode.hide()

        this.$bvToast.toast(`As informações da empresa foram atualizadas.`, {
          title: 'Atenção',
          variant: 'success',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 6000,
        });
      } catch (e) {

      }
    },

    async load() {
      await this.$store.dispatch('legalSearch/load', this.user.id)
    },

    async deleteSelectedAgents() {
      try {
        this.loading = true;
        this.legalSearchesId = [];

        await this.handleDeleteAgentCompanyLegalConsultation(
          {
            companyId: this.importedCompany._id,
            legalSearchesId: this.itemsToRemove
          }
        )

        const agentes = this.summary.filter((x) => this.itemsToRemove.includes(x.legalSearchId))
        const agentesVinculadoProduto = agentes.filter((x) => x.product && x.product._id)

        const agentesVinculadoProdutoAgrupadoPorProduto = agentesVinculadoProduto.reduce((group, agent) => {
          const product = agent.product._id;
          group[product] = group[product] ? group[product] : [];
          group[product].push(agent);
          return group;
        }, {})

        const keys = Object.keys(agentesVinculadoProdutoAgrupadoPorProduto)
        if (keys.length > 0) {
          const products = []
          keys.forEach((x) => {
            products.push(
              {
                productId: x,
                legalSearches: agentesVinculadoProdutoAgrupadoPorProduto[x].map((x) => x.legalSearchId),
              }
            )
          })

          await this.handleDeleteAgentsProductLegalConsultation({ products })
        }
        await this.deleteMeasurementsByAgent()
        await this.remove()
        this.loading = false;
        this.$refs.changeQueryMode.hide()
        this.itemsToRemove = []

        this.$bvToast.toast(`As informações da empresa foram atualizadas.`, {
          title: 'Atenção',
          variant: 'success',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 6000,
        });
      } catch (e) {

      }
    },

    async seleteSelectedAgentsInFreeMode() {
      try {
        this.loadingChangeToFreeMode = true;
        await this.remove()
        this.loadingChangeToFreeMode = false;
        this.$refs.selectDeleteAgentOrProduct.hide()
        this.itemsToRemove = []
      } catch (e) {

      }
    },

    async remove(clear) {
      await this.handleRemovegentsFreeModeAgents(
        {
          user: this.user.id,
          agents: this.itemsToRemove
        }
      )
      await this.$store.dispatch('legalSearch/remove', this.itemsToRemove)
      this.verifyRemovedheLastPage()
      await this.$refs.tableAgents.load()
      this.$refs.rmconfirmation.hide()
      this.$refs.selectDeleteAgentOrProduct.hide()
      await this.handleUpdateChangedAgentsLegalConsultation(true)
      if(clear){
        this.itemsToRemove = []
      }
    },

    verifyRemovedheLastPage(){
      if(this.summaryMeta && this.summaryMeta.total && this.summaryMeta.total > 0){
        const numberOfPages = Math.ceil(this.summaryMeta.total /  this.agentsPerPage)
        if(numberOfPages === this.summaryMeta.skip && this.itemsToRemove.length === this.summary.length) {
          this.setSkip(numberOfPages - 1)
        }
      }
    },

    async removeConfirmation(i) {
      this.itemsToRemove.push(i)
      this.deleteAgents()
    },

    select(id) {
      let index = this.itemsToRemove.indexOf(id)
      if (index >= 0) {
        this.itemsToRemove.splice(index, 1)
      } else {
        this.itemsToRemove.push(id)
      }
    },

    selectAll() {
      if (this.hitted) {
        this.hitted = false
        return
      }
      if (this.selectedAll) {
        this.itemsToRemove = []
      } else {
        this.itemsToRemove = this.summary.map((item) => item.legalSearchId)
      }
    },

    async handleDownloadPdf() {
      localStorage.setItem('summaryMeta', JSON.stringify(this.summaryMeta));
      if (this.user && this.user.role !== 'admin') {
        const log = {
          action: 'EXPORTOU_EM_PDF',
        }
        await this.createUserActionLogs(log)
      }
      const path = this.$router.resolve({ name: 'PrintLegalSearch' })
      window.open(path.href, '_blank')
    },

    deleteAgents(){
      this.deleteOption = null;
      if (this.importedCompany && this.importedCompany._id) {
        this.$bvModal.show('changeQueryMode')
      } else {
        this.analyzeAgentsToDelete()
      }
    },

    checkSelectedAgentsWithProducts(){
      const agentes = this.summary.filter((x) => this.itemsToRemove.includes(x.legalSearchId))
      const agentesVinculadoProduto = agentes.filter((x) => x.product && x.product._id)
      const agentesSemVinculosProduto = agentes.filter((x) => !x.product)

      const agentesVinculadoProdutoAgrupadoPorProduto = agentesVinculadoProduto.reduce((group, agent) => {
        const product = agent.product._id;
        group[product] = group[product] ? group[product] : [];
        group[product].push(agent);
        return group;
      }, {})
      const keys = Object.keys(agentesVinculadoProdutoAgrupadoPorProduto)

      if (agentesVinculadoProduto.length > 0 && agentesSemVinculosProduto.length <= 0 && keys.length === 1) {
        this.product = agentesVinculadoProdutoAgrupadoPorProduto[keys[0]][0].product
        return true
      } else {
        return false
      }
    },

    async analyzeAgentsToDelete() {
      this.deleteOption = null;
      if (this.checkSelectedAgentsWithProducts() && this.importedCompany && this.importedCompany._id) {
        this.$bvModal.show('selectDeleteAgentOrProduct')
      } else {
        this.$bvModal.show('rmconfirmation')
      }
    },

    handleDailySearches(body) {
      this.dailySearches = body
      this.$refs.limitedDailySearches.show()
    },

    async submitClassificationRequest() {
      this.modal.open = false
      this.classificationRequest.cas = this.agent

      const { status, data } = await this.$api.post(`agents/addChemicalAgent`, {
        cas: this.casSearch,
      })
      if (status === 200 || status === 201) {
        const { chemicalAgent } = data

        const chemicalAgentAdd = {
          _id: chemicalAgent._id,
          searchMatched: `${chemicalAgent.cas}:`,
        }

        this.selectedAgent = chemicalAgentAdd
        this.$emit('addChemicalAgentLegalConsultation')
      }
      const response = await this.createClassificationRequest()

      if (response.message === 'Classification request created successfully') {
        this.modal.current = 'ModalRequestSent'
        this.modal.open = true
        this.informativeText = `Informamos que os agentes que ainda não entraram em nossa base de dados ou que estão em processo de classificação não serão considerados para efeitos de análise no relatório`
        this.$refs.tableAgents.$refs.agenBaseAutocomplete.inputValue = ''
      }
    },

    openModal(payload) {
      this.modal = payload.modal
      this.agent = payload.agent
      this.casSearch = payload.casSearch
      this.informativeText = `Este agente não estava em nossa base de dados, porém classificaremos para você o mais rápido possível. As informações relativas a este agente, como exemplo do seu nome e sinônimo, serão atualizadas automaticamente. Por hora somente o CAS deste agente será necessário. Você gostaria de adicionar este agente com estas condições?`
    },

    clearItemsremove(){
      this.itemsToRemove = []
    }
  },
  computed: {
    ...mapGetters('classificationRequests', {
      classificationRequest: 'getClassificationRequest',
    }),
    ...mapGetters('companyLegalConsultation', {
      importedCompany: 'importedCompany'
    }),
    hasSelected() {
      return this.itemsToRemove.length > 0
    },
    selectedAll: {
      get: function () {
        return this.itemsToRemove.length === this.summary.length
      },
      set: function () {},
    },
    summary() {
      return this.$store.getters['legalSearch/summary']
    },
    summaryMeta() {
      return this.$store.getters['legalSearch/summaryMeta']
    },
    agentsPerPage() {
      return this.$store.getters['legalSearch/agentsPerPage']
    },
    user() {
      return this.$store.getters['user/current']
    },
    selectedAgent: {
      get: function () {
        return this.$store.getters['legalSearch/selectedAgent']
      },
      set: function (agent) {
        this.$store.dispatch('legalSearch/select', agent)
      },
    }
  },
  watch: {
    itemsToRemove(){
      this.setSlectedAgents(this.itemsToRemove)
    }
  }
}
</script>
<style lang="scss">
.label-functionality{
  font-weight: 700;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Arquivo';
}
.buttons {
  display: flex;
  justify-content: space-between;
}
.title-legend-summary{
  color: #ECF0F3;
  font-style: normal;
  font-weight: 700;
  margin-right: 10px;
}
.legends-summary {
  display: flex;
  align-items: start;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 8px;
  span {
    display: flex;
    align-items: center;
    gap: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #ECF0F3;
  }
}
</style>
