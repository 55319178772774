<template>
  <div>
    <div class='obs'>
      <img width='30' height='30' src='../../../../assets/images/Obs.svg'>

      <span class='ml-2'>Obs: Sempre que houverem atualizações de agentes (Aba Seleção de Agentes), os dados abaixo terão de ser preenchidos novamente.</span>
    </div>
    <div class='divider my-4'></div>
    <div>
      <p class='title'>Objetivos da Análise</p>
    </div>

    <div class='inputs pb-5'>
      <div class='w-100'>
        <label class='label_input'>
          Informe, em minutos, a duração da jornada de trabalho que o trabalhador está exposto aos agentes químicos
          desta consulta:
        </label>
        <div class='d-flex align-items-center'>
          <b-form-input
            placeholder='Ex: 120'
            v-model='v$.getObjectivesAnalysis.journeyMinutes.$model'
            style='height: 48px'
            class='w-25'
            v-mask="['###########']"
          >
          </b-form-input>
          <span class='ml-3'>
            (em minutos)
          </span>
        </div>
      </div>



      <div class='w-50 mt-4'>
        <label class='label_input'>
          Critérios de quantificação:
        </label>
        <div class='w-100 objetivos'>
          <div
            v-b-toggle.quantificationCriterion
            class='btn-objetivos'
            @click='showQuantificationCriterion = !showQuantificationCriterion'
          >
            <span class='title-btn'>
              Selecione um critério
            </span>

            <img
              :class="showQuantificationCriterion ? 'rotate ': ''"
              class='ml-3 transition'
              width='20'
              height='15'
              src='../../../../assets/images/Botton.svg'
              alt=''
            />
          </div>

          <b-collapse id='quantificationCriterion'>
            <b-form-group
              class='ml-2 objectiveAnalysisSelected'
              v-slot='{ ariaDescribedby }'>
              <b-form-radio-group
                id='checkbox-group-3'
                class='pr-2'
                v-model='v$.getObjectivesAnalysis.quantificationCriterion.$model'
                :aria-describedby='ariaDescribedby'
                name='flavour-3'
                stacked
              >
                <b-form-radio
                  class='item py-2 text'
                  value='75'
                  :uncheckable='true'
                >
                  <strong>
                    Amostrar 75% da jornada (recomendado)
                  </strong>

                  <b-popover target='popover-target-1' triggers='hover' placement='top'>
                    <template #title>Atenção</template>
                    <strong>
                      A NIOSH recomenda a coleta entre 70 e 80% da jornada diária conforme um estudo estatístico
                      realizado,
                      publicado e intitulado: Manual de Estratégia de Amostragem (1977). No caso dos limites serem do
                      tipo
                      STEL o critério de tempo de amostragem não será em função da jornada, mas sim em relação à 15
                      minutos
                      conforme o limite.
                    </strong>
                  </b-popover>

                  <b-icon
                    class='ml-1 '
                    font-scale='1.5'
                    icon='question-circle-fill'
                    id='popover-target-1'
                    variant='info'
                  >
                  </b-icon>
                </b-form-radio>

                <b-form-radio
                  class='py-2 text'
                  value='other'>
                  <strong>
                    Outro
                  </strong>
                </b-form-radio>

                <div class='mt-3' v-if="getObjectivesAnalysis.quantificationCriterion === 'other'">
                  <span class='text'>
                     Amostrar porcentagem da jornada:
                  </span>

                  <b-form-input
                    placeholder='Digite a porcentagem amostragem'
                    v-model='samplingPercentage'
                    id='samplingPercentage'
                    style='height: 48px'
                    class='w-100 mt-1'
                    :state='samplingIsValid'
                    v-mask="['###']"
                    @keyup='validateSamplingPercentage()'
                  >
                  </b-form-input>
                  <b-form-invalid-feedback id='samplingPercentage' :state='samplingIsValid'>
                    A porcentagem da amostragem deve ser entre 0 e 100.
                  </b-form-invalid-feedback>
                </div>
              </b-form-radio-group>
            </b-form-group>
          </b-collapse>
        </div>
      </div>

      <div class='mt-4'>
        <div class='d-flex align-items-start'>
          <div>
            <label class='label_input'>
              Considerar margem de segurança?
            </label>
          </div>

          <div class='ml-3 '>
            <b-popover target='considerSafetyMargin_' triggers='hover' placement='top'>
              <template #title>Atenção</template>
              <strong>
                A margem de segurança é aplicada sobre a vazão e o volume de forma a se manter sempre dentro dos limites
                do
                método.
                Ao assinalar esta opção você estará considerando a (vazão e volumes mínimos) do método aumentados em 10%
                e
                a
                (vazão e volumes máximos) diminuídos em 10%. Marcando esta opção o seu planejamento pode ficar um pouco
                mais
                caro tendo em vista o possível aumento de amostradores necessários para cobrir a jornada representativa.
              </strong>
            </b-popover>

            <b-icon
              class='mr-2 '
              font-scale='1.7'
              icon='question-circle-fill'
              id='considerSafetyMargin_'
              variant='info'
            >
            </b-icon>
          </div>
        </div>

        <div class='d-flex align-items-end'>
          <b-form-radio-group
            id='checkbox-group-2'
            class='pr-2 d-flex justify-content-between'
            v-model='v$.getObjectivesAnalysis.considerSafetyMargin.$model'
            name='flavour-2'
          >
            <b-form-radio
              class='py-2 mr-5'
              :value='true'
            >
              <strong
                :class="getObjectivesAnalysis && getObjectivesAnalysis.considerSafetyMargin ? 'margem_seguranca_selecionada': 'margem_seguranca_nao_selecionada'">
                Sim
              </strong>
            </b-form-radio>

            <b-form-radio
              class='py-2'

              :value='false'>
              <strong
                :class="getObjectivesAnalysis && getObjectivesAnalysis.considerSafetyMargin ? 'margem_seguranca_nao_selecionada': 'margem_seguranca_selecionada'"
              >
                Não
              </strong>
            </b-form-radio>
          </b-form-radio-group>
        </div>
      </div>

      <div class='w-100 mt-4'>
        <b-overlay
          :show='loading'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class='d-inline-block'
        >
          <base-button
            class='btn_ver_avaliacao d-flex justify-content-start align-items-center'
            @click='seeEvaluationResult()'
          >
            <strong>Ver resultado da avaliação</strong>
            <img class='ml-3' width='20' height='15' src='../../../../assets/images/Right.svg'>
          </base-button>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core'

export default {
  name: 'ObjectivesAnalysis',
  directives: { mask },
  components: {},
  computed: {
    ...mapGetters('objectivesAnalysis', {
      getObjectivesAnalysis: 'getObjectivesAnalysis'
    })

  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      objectiveAnalysisOptions: [
        { value: 'LAUDO_INSALUBRIDADE', text: 'Laudo de insalubridade' },
        { value: 'PGR', text: 'PGR' },
        { value: 'LTCAT', text: 'LTCAT' }
      ],
      loading: false,
      samplingIsValid: null,
      samplingPercentage: null,
      maximumSamplingPercentage: 100,
      recommendedSampling: 75,
      minimumSamplingPercentage: 0,
      objectiveAnalysisSelected: [],
      showObjectiveAnalysis: false,
      showQuantificationCriterion: false,
      selectedInteractions: []
    }
  },
  validations() {
    return {
      getObjectivesAnalysis: {
        journeyMinutes: {},
        objectiveAnalysis: {},
        quantificationCriterion: {},
        considerSafetyMargin: {}
      }
    }
  },
  async mounted() {
    try {
      await this.handleGetObjectivesAnalysis()
      if (this.recommendedSampling != this.getObjectivesAnalysis.quantificationCriterion) {
        this.samplingPercentage = this.getObjectivesAnalysis.quantificationCriterion
        this.getObjectivesAnalysis.quantificationCriterion = 'other'
        this.samplingIsValid = true
      }

      if (this.getObjectivesAnalysis && this.getObjectivesAnalysis.changedAgentsLegalConsultation) {
        this.getObjectivesAnalysis.changedAgentsLegalConsultation = false
      }
    } catch (e) {

    }
  },
  methods: {
    ...mapActions('objectivesAnalysis', {
      handleGetObjectivesAnalysis: 'handleGetObjectivesAnalysis',
      handleCreateObjectivesAnalysis: 'handleCreateObjectivesAnalysis',
      handleUpdateObjectivesAnalysis: 'handleUpdateObjectivesAnalysis'
    }),
    validateSamplingPercentage() {
      this.samplingIsValid = !!(this.samplingPercentage && Number(this.samplingPercentage) >= this.minimumSamplingPercentage && Number(this.samplingPercentage) <= this.maximumSamplingPercentage)
    },
    displayObjectiveAnalysis() {
      this.$bvModal.show('objective-analysis-modal')
    },
    validate() {
      if (this.getObjectivesAnalysis.quantificationCriterion === 'other') {
        if (this.samplingIsValid) {
          this.getObjectivesAnalysis.quantificationCriterion = this.samplingPercentage
        } else {
          return { valid: false, message: 'A porcentagem da amostragem deve ser entre 0 e 100!' }
        }
      }
      const valid = !!(this.getObjectivesAnalysis.journeyMinutes &&
        parseInt(this.getObjectivesAnalysis.journeyMinutes) > 0 &&
        this.getObjectivesAnalysis.quantificationCriterion
      )
      return { valid, message: `Todos os campos precisa serem preenchidos!` }
    },
    async seeEvaluationResult() {
      try {
        const validate = this.validate()
        if (!validate.valid) {
          this.$bvToast.toast(validate.message, {
            title: 'Aviso',
            variant: 'danger',
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000
          })
          return
        }
        this.loading = true
        this.getObjectivesAnalysis.objectiveAnalysis = [
          "LAUDO_INSALUBRIDADE",
          "PGR",
          "LTCAT"
        ]
        if (this.getObjectivesAnalysis._id) {
          await this.handleUpdateObjectivesAnalysis()
        } else {
          await this.handleCreateObjectivesAnalysis()
        }
        await this.handleGetObjectivesAnalysis()
        this.loading = false
        this.$emit('setComponent', { component: 'SummaryObjectivesAnalysis', showResult: true })
      } catch (e) {

      }
    }
  },
  props: ['agent', 'prevInteractions'],
  watch: {}
}
</script>
<style lang='scss' scoped>
.objetivos {
  border: 1px solid #808080;
  border-radius: 4px;

  .objectiveAnalysisSelected {
    .item {
      border-bottom: 1px solid #C0C0C0;
    }

    .text {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #022245;
    }
  }
}

.btn-objetivos {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  cursor: pointer;

  &:hover {
    background: transparent !important;
  }

  .title-btn {
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #808080;
    line-height: 10px;
    transition: all 0.5s ease;
  }
}

.rotate {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);

}

.transition {
  -webkit-transition: -webkit-transform .5s ease-in-out;
  -ms-transition: -ms-transform .5s ease-in-out;
  transition: transform .5s ease-in-out;
}

.obs {
  display: flex;
  align-items: center;
  justify-content: start;
  background: rgba(4, 168, 243, 0.4);
  border-radius: 6.25px;
  padding: 10px;
  font-style: normal;
  font-weight: 500;
}

.divider {
  height: 1px;
  background: #C0C0C0;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.inputs {
  .label_input {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
  }

  .btn_ver_avaliacao {
    width: 280px;
    height: 48px;
  }
}

.margem_seguranca_selecionada {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #04A8F3;
}

.margem_seguranca_nao_selecionada {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #808080;
}
</style>
