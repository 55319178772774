<template>
  <div class="mt-1">
    <b-modal
      ref='alert'
      scrollable
      hide-footer
      title='Atenção'
      header-bg-variant='info'
      header-text-variant='light'
    >
      <section class='modal-font-family'>
        <p>
          A impressão do arquivo em Excel esta sendo processada,
          clique no botão abaixo para acessar a página de downloads.
        </p>

        <router-link class='float-right router-link-text-decoration' :to="{ name: 'RelatoriosConsultaLegal' }">
          <base-button class='btn_export_modal'>
            <span class="label">Acessar</span>
          </base-button>
        </router-link>
      </section>
    </b-modal>

    <AcighModel
      :agent="selectedAgent"
      @save="saveSelectedInteractions"
    ></AcighModel>

    <OrientationVideos
      :orientationVideos="orientationVideos"
      :agent="agent"
      @clear="orientationVideos = []"
    />
    <!-- <router-link :to="{ name: 'PrintAcgih' }" v-if="allowed">
      <span class="icon"><i class="fas fa-print"></i></span>
      <span class="label">Versão para impressão beta</span>
    </router-link> -->
    {{ '\t' }}
    <!--<div class="imprimir" @click="$emit('open-survey'), generateReport()" v-if="allowed">
      <span class="icon"><i class="fas fa-print"></i></span>
      <span class="label">Versão para impressão Excel</span>
    </div>-->

    <table class="text-center table b-table table-bordered custom-table acgih">
      <thead class='sticky'>
        <tr class='headerTable' >
          <th colspan='9' class='container-tab-buttons' >
            <tab-buttons/>
          </th>
        </tr>
        <tr class='headerTable' style='background: #94D5F3;'>
          <th class='width-10-percent' rowspan="2">Produto</th>
          <th class='width-20-percent' rowspan="2">CAS: Agente</th>
          <th class='width-60-percent' colspan="6">ACGIH 2024</th>
        </tr>
        <tr class='headerTable'>
          <th class='width-10-percent min-width-160-px'>Fração de Coleta</th>
          <th class='width-10-percent'>TWA</th>
          <th class='width-10-percent'>STEL</th>
          <th class='width-10-percent'>Teto</th>
          <th class='width-10-percent'>Notações</th>
          <th class='width-10-percent'>Base de TLV</th>
        </tr>
      </thead>
      <tbody>
      <template v-for="item in legalConsultationAgentsGroupedByProduct">
        <template v-for="legalSearch in item.legalSearches">
          <template
            v-if='item.product._id === `-`'
          >
            <template v-if="!legalSearch.acgih" >
              <tr :key="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-not-acgih`">
                <td>
                  <span>-</span>
                </td>

                <td>
                  <b-tooltip
                    :target="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-not-acgih-searchId`"
                    custom-class="custom-tooltip"
                    placement="auto"
                    triggers="hover"
                  >
                    <section
                      v-if='(legalSearch.attachment11 && legalSearch.attachment11.group) || legalSearch.nameAttachment11'
                    >
                      <span
                        class='p-2 text-white'
                        style='font-size: 15px'
                      >
                      Este agente consta na NR 15 com o seguinte nome:
                        {{ legalSearch.attachment11 && legalSearch.attachment11.group
                        ? legalSearch.attachment11.group
                        : legalSearch.nameAttachment11 ? legalSearch.nameAttachment11: '-'
                        }}
                      </span> <br/><br/>
                    </section>

                    <section
                      class='p-2'
                      v-if='legalSearch.acgih && legalSearch.agent'
                    >
                      <span
                        class='text-white'
                        style='font-size: 15px'
                      >
                        Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                        {{ legalSearch.agent ? legalSearch.agent : '-' }}
                      </span>
                      <br/>
                      <br/>
                    </section>

                    <a
                      :href="`https://pubchem.ncbi.nlm.nih.gov/#query=${legalSearch.cas}`"
                      target='_blank'
                      class='link-pubchem-cas ml-2'
                    >
                      <span>
                        Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{legalSearch.cas}}
                      </span>
                    </a>
                  </b-tooltip>

                  <a
                    href="#" class='hover_links'
                    :id="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-not-acgih-searchId`"
                  >
                    <span class='word-break'>{{ model.getsearchId(legalSearch) }}</span>

                    <b-icon
                      style='margin-bottom: -1px'
                      class='ml-1'
                      font-scale="1.2"
                      icon="info-circle-fill"
                      variant="info"
                    >
                    </b-icon>
                  </a>

                  <section v-if='legalSearch.videosOrientacaoAbaAcgih && legalSearch.videosOrientacaoAbaAcgih.length > 0'>
                    <button
                      class='btn-orientations mt-2'
                      @click='showModalOrientationVideos(model.getsearchId(legalSearch), legalSearch.videosOrientacaoAbaLaudoInsalubridade)'
                    >
                      <span>
                        Ver orientações <br/> especificas
                      </span>
                    </button>
                  </section>
                </td>

                <td colspan="7">
                  O agente foi classificado, porém não apresentou enquadramento para a ACGIH.
                </td>
              </tr>
            </template>

            <template v-else-if="model.getInteractions(legalSearch) === 'all-interactions'">
              <tr :key="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-all-interactions`">
                <td :rowspan="legalSearch.acgihInfo.limits.length + 1">
                  <span>-</span>
                </td>

                <td :rowspan="legalSearch.acgihInfo.limits.length + 1">
                  <b-tooltip
                    :target="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-all-interactions-searchId`"
                    custom-class="custom-tooltip"
                    placement="auto"
                    triggers="hover"
                  >
                    <section
                      v-if='(legalSearch.attachment11 && legalSearch.attachment11.group) || legalSearch.nameAttachment11'
                    >
                      <span
                        class='p-2 text-white'
                        style='font-size: 15px'
                      >
                      Este agente consta na NR 15 com o seguinte nome:
                        {{ legalSearch.attachment11 && legalSearch.attachment11.group
                        ? legalSearch.attachment11.group
                        : legalSearch.nameAttachment11 ? legalSearch.nameAttachment11: '-'
                        }}
                      </span> <br/><br/>
                    </section>

                    <section
                      class='p-2'
                      v-if='legalSearch.acgih && legalSearch.agent'
                    >
                      <span
                        class='text-white'
                        style='font-size: 15px'
                      >
                        Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                        {{ legalSearch.agent ? legalSearch.agent : '-' }}
                      </span>
                      <br/>
                      <br/>
                    </section>

                    <a
                      :href="`https://pubchem.ncbi.nlm.nih.gov/#query=${legalSearch.cas}`"
                      target='_blank'
                      class='link-pubchem-cas ml-2'
                    >
                      <span>
                        Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{legalSearch.cas}}
                      </span>
                    </a>
                  </b-tooltip>

                  <a
                    href="#"
                    class='hover_links word-break'
                    :id="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-all-interactions-searchId`"
                  >
                    <span class='word-break'>{{ model.getsearchId(legalSearch) }}</span>

                    <b-icon
                      style='margin-bottom: -1px'
                      class='ml-1'
                      font-scale="1.2"
                      icon="info-circle-fill"
                      variant="info"
                    >
                    </b-icon>
                  </a>

                  <section v-if='legalSearch.videosOrientacaoAbaAcgih && legalSearch.videosOrientacaoAbaAcgih.length > 0'>
                    <button
                      class='btn-orientations mt-2'
                      @click='showModalOrientationVideos(model.getsearchId(legalSearch), legalSearch.videosOrientacaoAbaLaudoInsalubridade)'
                    >
                      <span>
                        Ver orientações <br/> especificas
                      </span>
                    </button>
                  </section>
                </td>
              </tr>

              <tr
                v-for="row in legalSearch.acgihInfo.limits.length"
                :key="`row_${row}-${item.product._id}-${legalSearch.cas}-${legalSearch.index}-all-interactions`"
              >
                <template v-for="(field, index) in model.tableFields">
                  <td
                    v-if="field.key !== 'searchId' && field.key !== 'product'"
                    :key="`field_${field.key}`"
                    :class="`field_${field.key}`"
                    :col="index"
                  >
                    <template v-if="field.key === 'interactions'">
                      <a
                        href="#"
                        v-b-tooltip.lefttop.hover.html
                        :title="model.getValue(field.key, legalSearch, row - 1)"
                        v-if="model.getValue(field.key, legalSearch, row - 1) != '-'"
                        @click="displayInteractions(legalSearch)"
                      >
                        <span style='' class='d-flex justify-content-center'>
                          Especificações
                          <b-icon
                            class='ml-1'
                            font-scale="1.2"
                            icon="info-circle-fill"
                            variant="info"
                          >
                          </b-icon>
                        </span>
                      </a>
                      <a href="#" v-else>-</a>
                    </template>

                    <template
                      v-else-if="field.key === 'base_do_tlv' || field.key === 'notations'"
                    >
                      <div
                        v-html="model.getValue(field.key, legalSearch, row - 1)"
                      ></div>
                    </template>

                    <template v-else>
                      {{ model.getValue(field.key, legalSearch, row - 1) }}
                    </template>
                  </td>
                </template>
              </tr>
            </template>

            <template v-else-if="model.getInteractions(legalSearch) === 'one-interaction' || model.getInteractions(legalSearch) === 'none-interaction'">
              <tr :key="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-one-interaction-none-interaction`">
                <td
                  v-for="field in model.tableFields"
                  :key="`field_${field.key}`"
                  :class="`field_${field.key}`"
                >
                  <template v-if="field.key === 'interactions'">
                    <a
                      href="#"
                      v-b-tooltip.lefttop.hover.html
                      :title="model.getValue(field.key, legalSearch)"
                      v-if="model.getValue(field.key, legalSearch) != '-'"
                      @click="displayInteractions(legalSearch)"
                    >
                      <span style='' class='d-flex justify-content-center'>
                        Especificações
                        <b-icon
                          class='ml-1'
                          font-scale="1.2"
                          icon="info-circle-fill"
                          variant="info"
                        >
                        </b-icon>
                      </span>
                    </a>
                    <a href="#" v-else>-</a>
                  </template>

                  <template
                    v-else-if="field.key === 'base_do_tlv' || field.key === 'notations'"
                  >
                    <div v-html="model.getValue(field.key, legalSearch)"></div>
                  </template>
                  <template v-else>
                    {{ model.getValue(field.key, legalSearch) }}
                  </template>

                  <template v-if="field.key === 'searchId'">
                    <b-tooltip
                      :target="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-one-interaction-none-interaction-searchId`"
                      custom-class="custom-tooltip"
                      placement="auto"
                      triggers="hover"
                    >
                      <section
                        v-if='(legalSearch.attachment11 && legalSearch.attachment11.group) || legalSearch.nameAttachment11'
                      >
                      <span
                        class='p-2 text-white'
                        style='font-size: 15px'
                      >
                      Este agente consta na NR 15 com o seguinte nome:
                        {{ legalSearch.attachment11 && legalSearch.attachment11.group
                        ? legalSearch.attachment11.group
                        : legalSearch.nameAttachment11 ? legalSearch.nameAttachment11: '-'
                        }}
                      </span> <br/><br/>
                      </section>

                      <section
                        class='p-2'
                        v-if='legalSearch.acgih && legalSearch.agent'
                      >
                      <span
                        class='text-white'
                        style='font-size: 15px'
                      >
                        Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                        {{ legalSearch.agent ? legalSearch.agent : '-' }}
                      </span>
                        <br/>
                        <br/>
                      </section>

                      <a
                        :href="`https://pubchem.ncbi.nlm.nih.gov/#query=${legalSearch.cas}`"
                        target='_blank'
                        class='link-pubchem-cas ml-2'
                      >
                        <span>
                          Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{legalSearch.cas}}
                        </span>
                      </a>
                    </b-tooltip>

                    <a
                      href="#"
                      class='hover_links word-break'
                      :id="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-one-interaction-none-interaction-searchId`"
                    >
                      <span class='word-break'>{{ model.getsearchId(legalSearch) }}</span>

                      <b-icon
                        style='margin-bottom: -1px'
                        class='ml-1'
                        font-scale="1.2"
                        icon="info-circle-fill"
                        variant="info"
                      >
                      </b-icon>
                    </a>

                    <section v-if='legalSearch.videosOrientacaoAbaAcgih && legalSearch.videosOrientacaoAbaAcgih.length > 0'>
                      <button
                        class='btn-orientations mt-2'
                        @click='showModalOrientationVideos(model.getsearchId(legalSearch), legalSearch.videosOrientacaoAbaLaudoInsalubridade)'
                      >
                      <span>
                        Ver orientações <br/> especificas
                      </span>
                      </button>
                    </section>
                  </template>
                </td>
              </tr>
            </template>

            <template v-else-if="model.getInteractions(legalSearch) === 'select-interaction'">
              <tr :key="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-select-interaction`">
                <td>
                  <span>-</span>
                </td>

                <td>
                  <b-tooltip
                    :target="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-select-interaction-searchId`"
                    custom-class="custom-tooltip"
                    placement="auto"
                    triggers="hover"
                  >
                    <section
                      v-if='(legalSearch.attachment11 && legalSearch.attachment11.group) || legalSearch.nameAttachment11'
                    >
                      <span
                        class='p-2 text-white'
                        style='font-size: 15px'
                      >
                      Este agente consta na NR 15 com o seguinte nome:
                        {{ legalSearch.attachment11 && legalSearch.attachment11.group
                        ? legalSearch.attachment11.group
                        : legalSearch.nameAttachment11 ? legalSearch.nameAttachment11: '-'
                        }}
                      </span> <br/><br/>
                    </section>

                    <section
                      class='p-2'
                      v-if='legalSearch.acgih && legalSearch.agent'
                    >
                      <span
                        class='text-white'
                        style='font-size: 15px'
                      >
                        Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                        {{ legalSearch.agent ? legalSearch.agent : '-' }}
                      </span>
                      <br/>
                      <br/>
                    </section>

                    <a
                      :href="`https://pubchem.ncbi.nlm.nih.gov/#query=${legalSearch.cas}`"
                      target='_blank'
                      class='link-pubchem-cas ml-2'
                    >
                      <span>
                        Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{legalSearch.cas}}
                      </span>
                    </a>
                  </b-tooltip>

                  <a
                    href="#"
                    class='hover_links word-break'
                    :id="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-select-interaction-searchId`"
                  >
                    <span class='word-break'>{{ model.getsearchId(legalSearch) }}</span>

                    <b-icon
                      style='margin-bottom: -1px'
                      class='ml-1'
                      font-scale="1.2"
                      icon="info-circle-fill"
                      variant="info"
                    >
                    </b-icon>
                  </a>

                  <section v-if='legalSearch.videosOrientacaoAbaAcgih && legalSearch.videosOrientacaoAbaAcgih.length > 0'>
                    <button
                      class='btn-orientations mt-2'
                      @click='showModalOrientationVideos(model.getsearchId(legalSearch), legalSearch.videosOrientacaoAbaLaudoInsalubridade)'
                    >
                      <span>
                        Ver orientações <br/> especificas
                      </span>
                    </button>
                  </section>
                </td>

                <td colspan="7" class="selectable-row">
                  <a href="#" @click="displayInteractions(legalSearch)">
                    Agente necessita de determinação de fração ou forma de
                    exposição, selecione aqui
                  </a>
                </td>
              </tr>
            </template>
          </template>

          <template
            v-else
          >
            <template>
              <tr >
                <template v-if='legalSearch.index < 1' >
                  <td :rowspan='rowspanProduct(item, legalSearch)'>
                    <span>{{ item.product.name }}</span>

                    <section
                      class='mt-2'
                      v-if="synergisticRiskProducts && synergisticRiskProducts.length > 0 && !!synergisticRiskProducts.find((x) => x.product === item.product._id)"
                    >
                      <b-tooltip
                        :target="`${legalSearch.cas}_${legalSearch.legalSearchId}_${item.product._id}_synergisticRiskProducts`"
                        custom-class="custom-tooltip"
                        placement="auto"
                        triggers="hover"
                      >
                        <strong>Este produto contém agentes químicos que afetam de forma acumulativa o mesmo órgão ou sistema alvo:</strong> <br/>
                        <ul class='phrases mt-2'>
                          <li
                            v-for='phrase in synergisticRiskProducts.find((x) => x.product === legalSearch.product._id).phrases'
                            :key='phrase'
                          >
                            {{phrase}}
                          </li>
                        </ul>
                      </b-tooltip>

                      <button
                        v-if="synergisticRiskProducts && synergisticRiskProducts.length > 0 && !!synergisticRiskProducts.find((x) => x.product === legalSearch.product._id)"
                        class='btn-synergistic-risk'
                        :id="`${legalSearch.cas}_${legalSearch.legalSearchId}_${legalSearch.product._id}_synergisticRiskProducts`"
                      >
                        <svg width="20" height="20" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M18.034 8.5645L10.6878 1.2175C10.4057 0.93712 10.0242 0.779739 9.62651 0.779739C9.22882 0.779739 8.8473 0.93712 8.56526 1.2175L1.21901 8.5645C0.93863 8.84654 0.78125 9.22806 0.78125 9.62575C0.78125 10.0234 0.93863 10.405 1.21901 10.687L8.56601 18.0333C9.14952 18.6168 10.1035 18.6168 10.6885 18.0333L18.0355 10.6863C18.3151 10.4041 18.4719 10.023 18.4719 9.62575C18.4719 9.22855 18.3151 8.84741 18.0355 8.56525L18.034 8.5645ZM9.02876 4.735C9.19376 4.55725 9.40376 4.468 9.66176 4.468C9.91976 4.468 10.1298 4.5565 10.2948 4.73275C10.4598 4.909 10.5423 5.13025 10.5423 5.3965C10.5423 5.626 10.4673 6.39625 10.315 7.708C10.165 9.0205 10.03 10.288 9.91751 11.512H9.37976C9.27851 10.2873 9.15476 9.01975 9.00476 7.708C8.85626 6.3955 8.78126 5.626 8.78126 5.3965C8.78126 5.134 8.86376 4.915 9.02876 4.7365V4.735ZM10.3263 14.35C10.1403 14.53 9.91901 14.62 9.66176 14.62C9.40451 14.62 9.18326 14.53 8.99801 14.35C8.81276 14.1685 8.72051 13.9495 8.72051 13.6923C8.72051 13.435 8.81276 13.2138 8.99801 13.0285C9.18401 12.8425 9.40526 12.7495 9.66251 12.7495C9.91976 12.7495 10.141 12.8425 10.3263 13.0285C10.5138 13.2138 10.606 13.435 10.606 13.6923C10.606 13.9495 10.513 14.1685 10.327 14.3493L10.3263 14.35Z" fill="#022245"/>
                        </svg>
                      </button>
                    </section>
                  </td>
                </template>
              </tr>
            </template>

            <template v-if="!legalSearch.acgih" >
              <tr :key="legalSearch.legalSearchId">
                <td>
                  <b-tooltip
                    :target="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-not-acgih-searchId`"
                    custom-class="custom-tooltip"
                    placement="auto"
                    triggers="hover"
                  >
                    <section
                      v-if='(legalSearch.attachment11 && legalSearch.attachment11.group) || legalSearch.nameAttachment11'
                    >
                      <span
                        class='p-2 text-white'
                        style='font-size: 15px'
                      >
                      Este agente consta na NR 15 com o seguinte nome:
                        {{ legalSearch.attachment11 && legalSearch.attachment11.group
                        ? legalSearch.attachment11.group
                        : legalSearch.nameAttachment11 ? legalSearch.nameAttachment11: '-'
                        }}
                      </span> <br/><br/>
                    </section>

                    <section
                      class='p-2'
                      v-if='legalSearch.acgih && legalSearch.agent'
                    >
                      <span
                        class='text-white'
                        style='font-size: 15px'
                      >
                        Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                        {{ legalSearch.agent ? legalSearch.agent : '-' }}
                      </span>
                      <br/>
                      <br/>
                    </section>

                    <a
                      :href="`https://pubchem.ncbi.nlm.nih.gov/#query=${legalSearch.cas}`"
                      target='_blank'
                      class='link-pubchem-cas ml-2'
                    >
                      <span>
                        Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{legalSearch.cas}}
                      </span>
                    </a>
                  </b-tooltip>

                  <a
                    href="#"
                    class='hover_links word-break'
                    :id="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-not-acgih-searchId`"
                  >
                    <span class='word-break'>{{ model.getsearchId(legalSearch) }}</span>

                    <b-icon
                      style='margin-bottom: -1px'
                      class='ml-1'
                      font-scale="1.2"
                      icon="info-circle-fill"
                      variant="info"
                    >
                    </b-icon>
                  </a>

                  <section v-if='legalSearch.videosOrientacaoAbaAcgih && legalSearch.videosOrientacaoAbaAcgih.length > 0'>
                    <button
                      class='btn-orientations mt-2'
                      @click='showModalOrientationVideos(model.getsearchId(legalSearch), legalSearch.videosOrientacaoAbaLaudoInsalubridade)'
                    >
                      <span>
                        Ver orientações <br/> especificas
                      </span>
                    </button>
                  </section>
                </td>

                <td colspan="7">
                  O agente foi classificado, porém não apresentou enquadramento para a ACGIH.
                </td>
              </tr>
            </template>

            <template v-else-if="model.getInteractions(legalSearch) === 'all-interactions'">
              <tr v-for="row in legalSearch.acgihInfo.limits.length" :key="`row_${row}-${item.product._id}-${legalSearch.cas}-${legalSearch.index}-all-interactions`">
                <template v-for="(field, index) in model.tableFields.filter((x) => x.key !== 'product')">
                  <td
                    v-if="fieldInteractions(field.key, row)"
                    :key="`field_${field.key}`"
                    :class="`field_${field.key}`"
                    :col="index"
                    :rowspan="field.key === 'searchId' ? legalSearch.acgihInfo.limits.length : 1"
                  >
                    <template v-if="field.key === 'interactions'">
                      <a
                        href="#"
                        v-b-tooltip.lefttop.hover.html
                        :title="model.getValue(field.key, legalSearch, row - 1)"
                        v-if="model.getValue(field.key, legalSearch, row - 1) != '-'"
                        @click="displayInteractions(legalSearch)"
                      >
                        <span style='' class='d-flex justify-content-center'>
                          Especificações
                          <b-icon
                            class='ml-1'
                            font-scale="1.2"
                            icon="info-circle-fill"
                            variant="info"
                          >
                          </b-icon>
                        </span>
                      </a>
                      <a href="#" v-else>-</a>
                    </template>

                    <template v-else-if="field.key === 'searchId'">
                      <b-tooltip
                        :target="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-all-interactions-searchId`"
                        custom-class="custom-tooltip"
                        placement="auto"
                        triggers="hover"
                      >
                        <section
                          v-if='(legalSearch.attachment11 && legalSearch.attachment11.group) || legalSearch.nameAttachment11'
                        >
                      <span
                        class='p-2 text-white'
                        style='font-size: 15px'
                      >
                      Este agente consta na NR 15 com o seguinte nome:
                        {{ legalSearch.attachment11 && legalSearch.attachment11.group
                        ? legalSearch.attachment11.group
                        : legalSearch.nameAttachment11 ? legalSearch.nameAttachment11: '-'
                        }}
                      </span> <br/><br/>
                        </section>

                        <section
                          class='p-2'
                          v-if='legalSearch.acgih && legalSearch.agent'
                        >
                      <span
                        class='text-white'
                        style='font-size: 15px'
                      >
                        Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                        {{ legalSearch.agent ? legalSearch.agent : '-' }}
                      </span>
                          <br/>
                          <br/>
                        </section>

                        <a
                          :href="`https://pubchem.ncbi.nlm.nih.gov/#query=${legalSearch.cas}`"
                          target='_blank'
                          class='link-pubchem-cas ml-2'
                        >
                          <span>
                            Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{legalSearch.cas}}
                          </span>
                        </a>
                      </b-tooltip>

                      <a
                        href="#"
                        class='hover_links word-break'
                        :id="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-all-interactions-searchId`"
                      >
                        <span class='word-break'>{{ model.getsearchId(legalSearch) }}</span>

                        <b-icon
                          style='margin-bottom: -1px'
                          class='ml-1'
                          font-scale="1.2"
                          icon="info-circle-fill"
                          variant="info"
                        >
                        </b-icon>
                      </a>

                      <section v-if='legalSearch.videosOrientacaoAbaAcgih && legalSearch.videosOrientacaoAbaAcgih.length > 0'>
                        <button
                          class='btn-orientations mt-2'
                          @click='showModalOrientationVideos(model.getsearchId(legalSearch), legalSearch.videosOrientacaoAbaLaudoInsalubridade)'
                        >
                      <span>
                        Ver orientações <br/> especificas
                      </span>
                        </button>
                      </section>
                    </template>

                    <template
                      v-else-if="field.key === 'base_do_tlv' || field.key === 'notations'"
                    >
                      <div
                        v-html="model.getValue(field.key, legalSearch, row - 1)"
                      ></div>
                    </template>

                    <template v-else>
                      {{ model.getValue(field.key, legalSearch, row - 1) }}
                    </template>
                  </td>
                </template>
              </tr>
            </template>

            <template v-else-if="model.getInteractions(legalSearch) === 'one-interaction' || model.getInteractions(legalSearch) === 'none-interaction'">
              <tr :key="legalSearch.legalSearchId">
                <td
                  v-for="field in model.tableFields.filter((x) => x.key !== 'product')"
                  :key="`field_${field.key}`"
                  :class="`field_${field.key}`"
                >
                  <template v-if="field.key === 'interactions'">
                    <a
                      href="#"
                      v-b-tooltip.lefttop.hover.html
                      :title="model.getValue(field.key, legalSearch)"
                      v-if="model.getValue(field.key, legalSearch) != '-'"
                      @click="displayInteractions(legalSearch)"
                    >
                      <span style='' class='d-flex justify-content-center'>
                        Especificações
                        <b-icon
                          class='ml-1'
                          font-scale="1.2"
                          icon="info-circle-fill"
                          variant="info"
                        >
                        </b-icon>
                      </span>
                    </a>
                    <a href="#" v-else>-</a>
                  </template>

                  <template v-else-if="field.key === 'searchId'">
                    <b-tooltip
                      :target="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-one-interaction-none-interaction-searchId`"
                      custom-class="custom-tooltip"
                      placement="auto"
                      triggers="hover"
                    >
                      <section
                        v-if='(legalSearch.attachment11 && legalSearch.attachment11.group) || legalSearch.nameAttachment11'
                      >
                      <span
                        class='p-2 text-white'
                        style='font-size: 15px'
                      >
                      Este agente consta na NR 15 com o seguinte nome:
                        {{ legalSearch.attachment11 && legalSearch.attachment11.group
                        ? legalSearch.attachment11.group
                        : legalSearch.nameAttachment11 ? legalSearch.nameAttachment11: '-'
                        }}
                      </span> <br/><br/>
                      </section>

                      <section
                        class='p-2'
                        v-if='legalSearch.acgih && legalSearch.agent'
                      >
                      <span
                        class='text-white'
                        style='font-size: 15px'
                      >
                        Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                        {{ legalSearch.agent ? legalSearch.agent : '-' }}
                      </span>
                        <br/>
                        <br/>
                      </section>

                      <a
                        :href="`https://pubchem.ncbi.nlm.nih.gov/#query=${legalSearch.cas}`"
                        target='_blank'
                        class='link-pubchem-cas ml-2'
                      >
                        <span>
                          Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{legalSearch.cas}}
                        </span>
                      </a>
                    </b-tooltip>

                    <a
                      href="#"
                      class='hover_links word-break'
                      :id="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-one-interaction-none-interaction-searchId`"
                    >
                      <span class='word-break'>{{ model.getsearchId(legalSearch) }}</span>

                      <b-icon
                        style='margin-bottom: -1px'
                        class='ml-1'
                        font-scale="1.2"
                        icon="info-circle-fill"
                        variant="info"
                      >
                      </b-icon>
                    </a>

                    <section v-if='legalSearch.videosOrientacaoAbaAcgih && legalSearch.videosOrientacaoAbaAcgih.length > 0'>
                      <button
                        class='btn-orientations mt-2'
                        @click='showModalOrientationVideos(model.getsearchId(legalSearch), legalSearch.videosOrientacaoAbaLaudoInsalubridade)'
                      >
                      <span>
                        Ver orientações <br/> especificas
                      </span>
                      </button>
                    </section>
                  </template>

                  <template
                    v-else-if="field.key === 'base_do_tlv' || field.key === 'notations'"
                  >
                    <div v-html="model.getValue(field.key, legalSearch)"></div>
                  </template>
                  <template v-else>
                    {{ model.getValue(field.key, legalSearch) }}
                  </template>
                </td>
              </tr>
            </template>

            <template v-else-if="model.getInteractions(legalSearch) === 'select-interaction'">
              <tr :key="legalSearch.legalSearchId">
                <td>
                  <b-tooltip
                    :target="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-select-interaction-searchId`"
                    custom-class="custom-tooltip"
                    placement="auto"
                    triggers="hover"
                  >
                    <section
                      v-if='(legalSearch.attachment11 && legalSearch.attachment11.group) || legalSearch.nameAttachment11'
                    >
                      <span
                        class='p-2 text-white'
                        style='font-size: 15px'
                      >
                      Este agente consta na NR 15 com o seguinte nome:
                        {{ legalSearch.attachment11 && legalSearch.attachment11.group
                        ? legalSearch.attachment11.group
                        : legalSearch.nameAttachment11 ? legalSearch.nameAttachment11: '-'
                        }}
                      </span> <br/><br/>
                    </section>

                    <section
                      class='p-2'
                      v-if='legalSearch.acgih && legalSearch.agent'
                    >
                      <span
                        class='text-white'
                        style='font-size: 15px'
                      >
                        Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                        {{ legalSearch.agent ? legalSearch.agent : '-' }}
                      </span>
                      <br/>
                      <br/>
                    </section>

                    <a
                      :href="`https://pubchem.ncbi.nlm.nih.gov/#query=${legalSearch.cas}`"
                      target='_blank'
                      class='link-pubchem-cas ml-2'
                    >
                      <span>
                        Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{legalSearch.cas}}
                      </span>
                    </a>
                  </b-tooltip>

                  <a
                    href="#"
                    class='hover_links word-break'
                    :id="`${item.product._id}-${legalSearch.cas}-${legalSearch.index}-select-interaction-searchId`"
                  >
                    <span class='word-break'>{{ model.getsearchId(legalSearch) }}</span>

                    <b-icon
                      style='margin-bottom: -1px'
                      class='ml-1'
                      font-scale="1.2"
                      icon="info-circle-fill"
                      variant="info"
                    >
                    </b-icon>
                  </a>

                  <section v-if='legalSearch.videosOrientacaoAbaAcgih && legalSearch.videosOrientacaoAbaAcgih.length > 0'>
                    <button
                      class='btn-orientations mt-2'
                      @click='showModalOrientationVideos(model.getsearchId(legalSearch), legalSearch.videosOrientacaoAbaLaudoInsalubridade)'
                    >
                      <span>
                        Ver orientações <br/> especificas
                      </span>
                    </button>
                  </section>
                </td>

                <td colspan="7" class="selectable-row">
                  <a href="#" @click="displayInteractions(legalSearch)">
                    Agente necessita de determinação de fração ou forma de
                    exposição, selecione aqui
                  </a>
                </td>
              </tr>
            </template>
          </template>
        </template>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import Acigh from '@/models/Acgih'
import AcighModel from '@/components/LegalSearch/AcighModal'
import { mapActions } from 'vuex'
import eventBus from '../../utils/eventBus'
import TabButtons from '@/components/LegalSearch/components/TabButtons.vue'
import OrientationVideos from '@/components/LegalSearch/Modal/OrientationVideos.vue'

export default {
  data() {
    return {
      generateImpression: false,
      loading: false,
      allowed: true,
      dontHavePlan: false,
      model: Acigh,
      selectedAgent: {},
      scrollBarPosition: 0,
      orientationVideos: [],
      agent: "",
    }
  },
  components: {
    OrientationVideos,
    TabButtons,
    AcighModel,
  },
  computed: {
    xlsxLink() {
      return `${process.env.VUE_APP_BASE_URL}/xlsx/${this.user.id}/acgih`
    },
    summary() {
      return this.$store.getters['legalSearch/summary']
    },
    synergisticRiskProducts() {
      return this.$store.getters['legalSearch/synergisticRiskProducts']
    },
    user() {
      return this.$store.getters['user/current']
    },
    summaryMeta() {
      return this.$store.getters['legalSearch/summaryMeta']
    },
    legalConsultationAgentsGroupedByProduct() {
      return this.$store.getters['legalSearch/legalConsultationAgentsGroupedByProduct']
    },
    importedCompany() {
      return this.$store.getters['companyLegalConsultation/importedCompany']
    },
  },
  methods: {
    ...mapActions('reportsDownloads', {
      handleCreateReportDownloads: 'handleCreateReportDownloads',
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    ...mapActions('event', {
      handlGetEventActive: 'handlGetEventActive',
    }),
    ...mapActions('legalSearch', {
      exportingExcel: 'exportingExcel',
    }),
    showModalOrientationVideos(agent, orientationVideos){
      this.orientationVideos = orientationVideos
      this.agent = agent
      setTimeout(() => {
        this.$bvModal.show('orientationVideos')
      },100)
    },

    rowspanProduct(legalConsultationAgentsGroupedByProduct, legalSearch){
      let countOneInteractionAndNoneInteraction = legalConsultationAgentsGroupedByProduct.legalSearches
        .filter((x) => this.model.getInteractions(x) === 'one-interaction' || this.model.getInteractions(x) === 'none-interaction').length

      let allInteractions = legalConsultationAgentsGroupedByProduct.legalSearches
        .filter((x) => this.model.getInteractions(x) === 'all-interactions')

      let countAllInteractions = 0
      allInteractions.forEach((x) => {
        const { acgihInfo } = x
        if (acgihInfo && acgihInfo.limits){
          const limit = acgihInfo.limits.length
          countAllInteractions += limit
        }

      })
      countOneInteractionAndNoneInteraction += countAllInteractions

      countOneInteractionAndNoneInteraction += legalConsultationAgentsGroupedByProduct.legalSearches
        .filter((x) => this.model.getInteractions(x) === 'select-interaction').length
      return countOneInteractionAndNoneInteraction + legalConsultationAgentsGroupedByProduct.legalSearches.length
    },
    fieldInteractions(field, row){
      return !(field === 'searchId' && row >= 2);
    },
    async generateReport(){
      await this.exportingExcel(true);
      const payload = {
        user: this.user.id,
        type: "acgih",
        format: 'xlsx',
        job: 'legalConsultationReports',
        parameters: {
          selectedFilters: this.summaryMeta,
        }
      }
      const { data, status } = await this.handleCreateReportDownloads(payload)

      let message = data.message
      let variant = 'danger'
      this.exportingExcel(false);
      if(status === 201){
        message = data.message;
        variant = 'success';
        this.$refs['alert'].show()
        return
      }
      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
      });
    },
    displayInteractions(item) {
      if (
        item.acgih &&
        item.acgih.interactions &&
        item.acgih.interactions.length > 1
      ) {
        this.selectedAgent = item
        this.$bvModal.show('interactions-modal')
        this.scrollBarPosition = document.documentElement.scrollTop;
      }
    },

    async saveSelectedInteractions(agent) {
      await this.$store.dispatch('legalSearch/saveInteractions', agent)
      await this.$store.dispatch('legalSearch/load', this.user.id)
      this.$bvModal.hide('interactions-modal')
      window.scroll(0, this.scrollBarPosition)

      if (this.importedCompany && this.importedCompany._id) {
        this.$bvToast.toast(`As informações da empresa foram atualizadas.`, {
          title: 'Atenção',
          variant: 'success',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 6000,
        });
      }
    },
  },
  async mounted() {
    try {
      eventBus.$on('generateReportACGIH', async () => {
        await this.generateReport()
      })
      await this.handlGetEventActive();
    } catch (err) {
      this.loading = false;
      this.dontHavePlan = false;
      console.log(err)
    }
  },
  beforeDestroy() {
    eventBus.$off('generateReportACGIH')
  },
  created() {
    const breadcrumb = {
      tab: 'acgih',
      page: 'Consulta Legal',
      subPage: {
        name: 'Consulta Legal',
        active: false
      },
      tabs: [
        {
          name: 'ACGIH',
          active: true
        }
      ]
    }
    this.setBreadcrumb(breadcrumb)
  },
}
</script>
<style>
.tooltip-inner {
  text-align: left;
  max-width: 400px !important;
  width: 400px !important;
}
</style>
<style lang="scss" scoped>
.width-10-percent{
  width: 10%!important;
}
.width-60-percent{
  width: 60%!important;
}
.min-width-160-px{
  min-width: 160px!important;
}
.width-20-percent{
  width: 20%!important;
}
.hover_links{
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #04A8F3;
}
.sticky {
  position: sticky;
  top: 10px;
  z-index: 10;
}
.container-tab-buttons{
  padding: 0 0 5px 0!important;
}
.headerTable{
  background: #94D5F3;
  vertical-align: middle;
  padding: 10px;
  top: 83px;
}
.imprimir {
  color: var(--blue);
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
}
.imprimir:hover {
  text-decoration: underline
}
.loading{
  width: 1.5rem;
  height: 1.5rem;
}
.selectable-row {
  background-color: aliceblue;
  font-weight: bold;
  vertical-align: middle;
}
td {
  vertical-align: middle;
}
.field_ps {
  text-align: left;
  font-size: 12px;
}

.table tr:nth-child(2) th {
  top: 173px;
}
.btn_export {
  background-color: #CBD63E;
  color: #022245;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 24px;

i {
  color: #022245;
  margin-right: 6px;
}

img {
  width: 15px;
  height: 15px;
  margin-right: 6px;
}
}
.btn-synergistic-risk{
  width: 32px!important;
  height: 32px!important;
  background: #CBD63E!important;
  border: 1px solid #022245;
  border-radius: 2px;
}
.phrases{
  list-style: inside;
  margin-left: 20px;
  li{
    list-style-position: outside;
  }
}
</style>
