<template>
  <section>
    <modal-confirm-action
      refModal='changeMode'
      idModal='changeMode'
      :loading='loading'
      :description='description'
      :title='title'
      @confirmed='setImportedToFalseCompanyLegalConsultation'
    />

    <b-modal
      ref='filter'
      id='filter'
      hide-footer
      hide-header
      header-bg-variant='info'
      header-text-variant='light'
      title='Pesquisar meus agentes adicionados'
      size='xl'
      @show='showModal()'
    >
      <section class='modal-font-family'>
        <section class='header d-flex justify-content-between align-items-center mb-4'>
          <span class='title'>Pesquisar meus agentes adicionados</span>
          <b-icon
            @click='closeModal()'
            class='cursor-pointer'
            font-scale='2.2'
            icon='x'
            color='#022245'
          >
          </b-icon>
        </section>

        <section class='warning d-flex justify-content-between align-items-center'>
          <section>
            <svg width="26" height="25" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.5 15H11.5V9H9.5V15ZM10.5 7C10.7833 7 11.021 6.904 11.213 6.712C11.405 6.52 11.5007 6.28267 11.5 6C11.5 5.71667 11.404 5.479 11.212 5.287C11.02 5.095 10.7827 4.99933 10.5 5C10.2167 5 9.979 5.096 9.787 5.288C9.595 5.48 9.49933 5.71733 9.5 6C9.5 6.28333 9.596 6.521 9.788 6.713C9.98 6.905 10.2173 7.00067 10.5 7ZM10.5 20C9.11667 20 7.81667 19.7373 6.6 19.212C5.38333 18.6867 4.325 17.9743 3.425 17.075C2.525 16.175 1.81267 15.1167 1.288 13.9C0.763333 12.6833 0.500667 11.3833 0.5 10C0.5 8.61667 0.762667 7.31667 1.288 6.1C1.81333 4.88333 2.52567 3.825 3.425 2.925C4.325 2.025 5.38333 1.31267 6.6 0.788C7.81667 0.263333 9.11667 0.000666667 10.5 0C11.8833 0 13.1833 0.262667 14.4 0.788C15.6167 1.31333 16.675 2.02567 17.575 2.925C18.475 3.825 19.1877 4.88333 19.713 6.1C20.2383 7.31667 20.5007 8.61667 20.5 10C20.5 11.3833 20.2373 12.6833 19.712 13.9C19.1867 15.1167 18.4743 16.175 17.575 17.075C16.675 17.975 15.6167 18.6877 14.4 19.213C13.1833 19.7383 11.8833 20.0007 10.5 20Z" fill="#022245"/>
            </svg>
          </section>

          <section>
            <p v-if='products.length > 0'>Os filtros são independentes. Você poderá selecionar separadamente filtros de produto, normativos ou ambos!</p>
            <p>O resultado do(s) filtro(s) aplicado refletirá em todas abas!</p>
          </section>
        </section>

        <section v-if='products.length > 0' class='mt-4'>
          <p class='title-product'><strong>Filtro de Produto</strong></p>
          <section
            style='display: grid;  grid-template-columns: repeat(auto-fill, minmax(25%, auto));'
          >
            <b-form-checkbox-group
              v-for='(product, i) in products'
              v-model='selectedProducts'
              :key='i'
              class='mr-3'
              stacked
            >
              <section
                class='product'
                :style="selectedProducts.includes(product._id) ? 'border: 1.5px solid #022245': 'border: 1.5px solid #C0C0C0;'"
              >
                <b-form-checkbox
                  :value='product._id'
                >
                  <strong
                    class='text'
                    :style="selectedProducts.includes(product._id) ? 'color: #022245;font-weight: 700;': 'color: #262626; font-weight: 400;'"
                  >
                    {{ product.name }}
                  </strong>
                </b-form-checkbox>
              </section>
            </b-form-checkbox-group>
          </section>
        </section>
        <section class='mt-4'>
          <hr/>
        </section>

        <p class='title-product'><strong>Filtro de Normativas</strong></p>

        <section class='modalFilter d-flex flex-wrap'>
          <section class='mr-5 mt-2'>
            <h6>ACGIH</h6>
            <b-form-group
              class='objectiveAnalysisSelected'
              v-slot='{ ariaDescribedby }'
            >
              <b-form-checkbox-group
                id='acgih'
                class='pr-2'
                v-model='acgih'
                :aria-describedby='ariaDescribedby'
                name='acgih'
                stacked
              >
                <b-form-checkbox
                  class='py-2 text'
                  value='consta'
                  @change="updateSelectedFilter('acgih', 'consta')"
                >
                  <section class='d-flex align-items-center'>
                    <strong class='text-opcoes-relavao-legal'>
                      Consta
                    </strong>
                    <img class='ml-2' width='18' height='20' src='@/assets/images/consta.svg' alt='Consta' />
                  </section>
                </b-form-checkbox>

                <b-form-checkbox
                  class='py-2 text'
                  value='naoConsta'
                  @change="updateSelectedFilter('acgih', 'naoConsta')"
                >
                  <section class='d-flex align-items-center'>
                    <strong class='text-opcoes-relavao-legal'>
                      Não consta
                    </strong>
                    <img class='ml-2' width='16' height='15' src='@/assets/images/naoConsta.svg' alt='Consta' />
                  </section>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </section>

          <section class='mr-5 mt-2'>
            <h6>NR 15</h6>
            <b-form-group
              class='objectiveAnalysisSelected'
              v-slot='{ ariaDescribedby }'
            >
              <b-form-checkbox-group
                id='nr15'
                class='pr-2'
                v-model='nr15'
                :aria-describedby='ariaDescribedby'
                name='nr15'
                stacked
              >
                <b-form-checkbox
                  class='py-2 text'
                  value='consta'
                  @change="updateSelectedFilter('nr15', 'consta')"
                >
                  <section class='d-flex align-items-center'>
                    <strong class='text-opcoes-relavao-legal'>
                      Consta
                    </strong>
                    <img class='ml-2' width='18' height='20' src='@/assets/images/consta.svg' alt='Consta' />
                  </section>
                </b-form-checkbox>

                <b-form-checkbox
                  class='py-2 text'
                  value='naoConsta'
                  @change="updateSelectedFilter('nr15', 'naoConsta')"
                >
                  <section class='d-flex align-items-center'>
                    <strong class='text-opcoes-relavao-legal'>
                      Não consta
                    </strong>
                    <img class='ml-2' width='16' height='15' src='@/assets/images/naoConsta.svg' alt='Consta' />
                  </section>
                </b-form-checkbox>

                <b-form-checkbox
                  class='py-2 text'
                  value='classificacao'
                  @change="updateSelectedFilter('nr15', 'classificacao')"
                >
                  <section class='d-flex align-items-center'>
                    <strong class='text-opcoes-relavao-legal'>
                      Em classificação
                    </strong>
                    <img class='ml-2' width='16' height='15' src='@/assets/images/classificacao.svg' alt='Consta' />
                  </section>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </section>

          <section class='mr-5 mt-2'>
            <h6>LINACH</h6>
            <b-form-group
              class='objectiveAnalysisSelected'
              v-slot='{ ariaDescribedby }'
            >
              <b-form-checkbox-group
                id='linach'
                class='pr-2'
                v-model='linach'
                :aria-describedby='ariaDescribedby'
                name='linach'
                stacked
              >
                <b-form-checkbox
                  class='py-2 text'
                  value='consta'
                  @change="updateSelectedFilter('linach', 'consta')"
                >
                  <section class='d-flex align-items-center'>
                    <strong class='text-opcoes-relavao-legal'>
                      Consta
                    </strong>
                    <img class='ml-2' width='18' height='20' src='@/assets/images/consta.svg' alt='Consta' />
                  </section>
                </b-form-checkbox>

                <b-form-checkbox
                  class='py-2 text'
                  value='naoConsta'
                  @change="updateSelectedFilter('linach', 'naoConsta')"
                >
                  <section class='d-flex align-items-center'>
                    <strong class='text-opcoes-relavao-legal'>
                      Não consta
                    </strong>
                    <img class='ml-2' width='16' height='15' src='@/assets/images/naoConsta.svg' alt='Consta' />
                  </section>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </section>

          <section class='mr-5 mt-2'>
            <h6>ESOCIAL - Tabela 24</h6>
            <b-form-group
              class='objectiveAnalysisSelected'
              v-slot='{ ariaDescribedby }'
            >
              <b-form-checkbox-group
                id='esocial'
                class='pr-2'
                v-model='esocial'
                :aria-describedby='ariaDescribedby'
                name='esocial'
                stacked
              >
                <b-form-checkbox
                  class='py-2 text'
                  value='consta'
                  @change="updateSelectedFilter('esocial', 'consta')"
                >
                  <section class='d-flex align-items-center'>
                    <strong class='text-opcoes-relavao-legal'>
                      Consta
                    </strong>
                    <img class='ml-2' width='18' height='20' src='@/assets/images/consta.svg' alt='Consta' />
                  </section>
                </b-form-checkbox>

                <b-form-checkbox
                  class='py-2 text'
                  value='naoConsta'
                  @change="updateSelectedFilter('esocial', 'naoConsta')"
                >
                  <section class='d-flex align-items-center'>
                    <strong class='text-opcoes-relavao-legal'>
                      Não consta
                    </strong>
                    <img class='ml-2' width='16' height='15' src='@/assets/images/naoConsta.svg' alt='Consta' />
                  </section>
                </b-form-checkbox>

                <b-form-checkbox
                  class='py-2 text'
                  value='classificacao'
                  @change="updateSelectedFilter('esocial', 'classificacao')"
                >
                  <section class='d-flex align-items-center'>
                    <strong class='text-opcoes-relavao-legal'>
                      Em classificação
                    </strong>
                    <img class='ml-2' width='16' height='15' src='@/assets/images/classificacao.svg' alt='Consta' />
                  </section>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </section>

          <section class='mr-5 mt-2'>
            <h6>DECRETO 3048/99 <br /> - Anexo IV</h6>
            <b-form-group
              class='objectiveAnalysisSelected'
              v-slot='{ ariaDescribedby }'
            >
              <b-form-checkbox-group
                id='checkbox-group-3'
                class='pr-2'
                v-model='decreto'
                :aria-describedby='ariaDescribedby'
                name='decreto'
                stacked
              >
                <b-form-checkbox
                  class='py-2 text'
                  value='consta'
                  @change="updateSelectedFilter('decreto', 'consta')"
                >
                  <section class='d-flex align-items-center'>
                    <strong class='text-opcoes-relavao-legal'>
                      Consta
                    </strong>
                    <img class='ml-2' width='18' height='20' src='@/assets/images/consta.svg' alt='Consta' />
                  </section>
                </b-form-checkbox>

                <b-form-checkbox
                  class='py-2 text'
                  value='naoConsta'
                  @change="updateSelectedFilter('decreto', 'naoConsta')"
                >
                  <section class='d-flex align-items-center'>
                    <strong class='text-opcoes-relavao-legal'>
                      Não consta
                    </strong>
                    <img class='ml-2' width='16' height='15' src='@/assets/images/naoConsta.svg' alt='Consta' />
                  </section>
                </b-form-checkbox>

                <b-form-checkbox
                  class='py-2 text'
                  value='classificacao'
                  @change="updateSelectedFilter('decreto', 'classificacao')"
                >
                  <section class='d-flex align-items-center'>
                    <strong class='text-opcoes-relavao-legal'>
                      Em classificação
                    </strong>
                    <img class='ml-2' width='16' height='15' src='@/assets/images/classificacao.svg' alt='Consta' />
                  </section>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </section>
        </section>

        <section class='d-flex justify-content-end mt-3 w-100'>
          <base-button
            class='btn-clear-filter w-25 mr-3'
            @click='clearSelectedFilters()'
          >
            <span>Limpar filtros</span>
          </base-button>

          <base-button
            class='btn_filter w-75'
            @click='searchAgents()'
          >
            <span>Pesquisar agentes</span>
          </base-button>
        </section>
      </section>
    </b-modal>

    <section class='w-100 d-flex justify-content-between'>
      <section
        v-if='appliedFilters'
        class='filter-content mt-2 d-flex align-items-center flex-wrap justify-content-between'
      >
        <section class='d-flex flex-wrap'>
          <section class='d-flex align-items-center'>
            <img class='ml-1 mr-2' width='18' height='20' src='@/assets/images/filter.svg' alt='Filtro' />

            <span class='text-filter'>Filtros Aplicados:</span>
          </section>

          <section class='d-flex ml-2 flex-wrap'>
            <section v-if='summaryMeta.acgih' class='d-flex'>
              <section class='mt-1'>
                <span class='text-filter-applied'>ACGIH</span>
                <img
                  class='ml-1 mb-1'
                  width='18'
                  height='16'
                  v-bind:src='require(`../../../assets/images/${summaryMeta.acgih}.svg`)' alt=''
                />
              </section>
              <section class='divider mx-2'></section>
            </section>


            <section v-if='summaryMeta.nr15' class='d-flex'>
              <section class='mt-1'>
                <span class='text-filter-applied'>NR 15</span>
                <img
                  class='ml-1 mb-1'
                  width='18'
                  height='16'
                  v-bind:src='require(`../../../assets/images/${summaryMeta.nr15}.svg`)' alt=''
                />
              </section>
              <section class='divider mx-2'></section>
            </section>


            <section v-if='summaryMeta.linach' class='d-flex'>
              <section class='mt-1'>
                <span class='text-filter-applied'>LINACH</span>
                <img
                  class='ml-1 mb-1'
                  width='18'
                  height='16'
                  v-bind:src='require(`../../../assets/images/${summaryMeta.linach}.svg`)' alt=''
                />
              </section>
              <section class='divider mx-2'></section>
            </section>


            <section v-if='summaryMeta.esocial' class='d-flex'>
              <section class='mt-1'>
                <span class='text-filter-applied'>ESOCIAL - Tabela 24</span>
                <img
                  class='ml-1 mb-1'
                  width='18'
                  height='16'
                  v-bind:src='require(`../../../assets/images/${summaryMeta.esocial}.svg`)' alt=''
                />
              </section>
              <section class='divider mx-2'></section>
            </section>


            <section v-if='summaryMeta.decreto' class='d-flex'>
              <section class='mt-1'>
                <span class='text-filter-applied'>DECRETO 3048/99 - Anexo IV</span>
                <img
                  class='ml-1 mb-1'
                  width='18'
                  height='16'
                  v-bind:src='require(`../../../assets/images/${summaryMeta.decreto}.svg`)' alt=''
                />
              </section>
            </section>

            <section v-if='summaryMeta.products && summaryMeta.products.length >0' class='d-flex mr-2 mb-1'>
              <section class='mt-1'>
                <span class='text-filter-applied'>
                  <strong>Produtos:</strong> {{products.filter((x) => selectedProducts.includes(x._id)).map((y) => y.name).join(' | ')}}
                </span>
              </section>
            </section>
          </section>
        </section>

        <section class='mr-2 d-flex flex-nowrap'>
          <a
            class='btn-filtros mr-2'
            @click='clearSelectedFilters()'
          >
            <img
              class='ml-1 mr-2'
              width='18'
              height='20'
              src='@/assets/images/limparFiltros.svg'
              alt='Limpar filtros'
            />
            <span
              class='text-btn-filtros'
            >
              Limpar filtros
            </span>
          </a>

          <a
            class='btn-filtros'
            @click='showModalFilter()'
          >
            <img
              class='ml-1 mr-1'
              width='21'
              height='21'
              src='@/assets/images/editarFiltros.svg'
              alt='Editar filtros'
            />
            <span
              class='text-btn-filtros'
            >
              Editar
            </span>
          </a>
        </section>
      </section>

      <section v-else class='mt-2 float-left'>
        <button class='btn-new-filter' @click='showModalFilter()'>
          <img class='ml-1 mr-2' width='18' height='20' src='@/assets/images/filter.svg' alt='Filtro' />

          <span class='text-filter pr-2'>Aplicar novo filtro</span>
        </button>
      </section>

      <section v-if="releaseCompanyMode(user)" class='d-flex'>
        <!--<section class='mt-2 mx-1'>
          <router-link
            :to="{ name: 'ManageCompanies' }"
          >
            <button
              class='btn-new-filter'
            >
              <span  class='text-filter pr-2 px-2'>Gerenciar empresas</span>
            </button>
          </router-link>
        </section>-->

        <section
          v-if='importedCompany'
          class='mt-2'
        >
          <b-overlay
            :show='loading'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='d-inline-block'
          >
            <button
              class='btn-mode'
              @click="showModalChangeMode()"
            >
              <span  class='text-filter pr-2 px-2'>Consulta em modo empresa: {{importedCompany.name}}</span>
            </button>
          </b-overlay>
        </section>

        <section v-else class='mt-2'>
          <b-overlay
            :show='loading'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='d-inline-block'
          >
            <button
              class='btn-mode'
              @click="switchToBusinessMode()"
            >
              <span class='text-filter pr-2 px-2'>Consulta em modo: <strong>Livre</strong></span>
            </button>
          </b-overlay>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from '@/utils/validators'
import useVuelidate from '@vuelidate/core'
import eventBus from '@/utils/eventBus'
import { releaseCompanyMode } from '@/utils/releaseEnterpriseModeFunctionality'
import ModalConfirmAction from '@/components/ModalConfirmAction.vue'

export default {
  name: 'FilterAgentsLegalConsultation',
  components: { ModalConfirmAction },
  props: {},
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      description: '',
      products: [],
      selectedProducts: [],
      title: '',
      loading: false,
      acgih: [],
      nr15: [],
      linach: [],
      esocial: [],
      decreto: [],
      filters: {
        acgih: [],
        nr15: '',
        linach: '',
        esocial: '',
        decreto: ''
      }
    }
  },
  computed: {
    appliedFilters() {
      return this.$store.getters['legalSearch/appliedFilters']
    },
    summaryMeta() {
      return this.$store.getters['legalSearch/summaryMeta']
    },
    user() {
      return this.$store.getters['user/current']
    },
    ...mapGetters('companyLegalConsultation', {
      importedCompany: 'importedCompany'
    }),
    ...mapGetters('breadcrumb', {
      breadcrumb: 'getBreadcrumb'
    }),
  },
  methods: {
    releaseCompanyMode,
    ...mapActions('legalSearch', {
      handleResetSummaryMeta: 'handleResetSummaryMeta',
      setSkip: 'setSkip',
      summaryClear: 'summaryClear',
      handleSetSummaryMeta: 'handleSetSummaryMeta',
      importFreeModeAgents: 'importFreeModeAgents',
    }),
    ...mapActions('companyLegalConsultation', {
      handleGetNumberRegisteredCompanies: 'handleGetNumberRegisteredCompanies',
      handleUnmarkAllAsLastImportedCompanyLegalConsultation: 'handleUnmarkAllAsLastImportedCompanyLegalConsultation',
      handleGetLastImportedCompanyLegalConsultation: 'handleGetLastImportedCompanyLegalConsultation',
    }),
    ...mapActions('productLegalConsultation', {
      handleGetProductLegalConsultationByCompany: 'handleGetProductLegalConsultationByCompany',
    }),
    async showModalChangeMode() {
      try {
        this.$bvModal.show('changeMode')
        this.title = 'Alterar modo de consulta';
        this.description = 'Deseja realmente alterar para o modo de consulta livre?';
      } catch (e) {

      }
    },

    async setImportedToFalseCompanyLegalConsultation() {
      try {
        this.loading = true;
        await this.handleUnmarkAllAsLastImportedCompanyLegalConsultation(this.user.id)
        await this.handleGetLastImportedCompanyLegalConsultation(this.user.id)
        await this.importFreeModeAgents(this.user.id)
        this.loading = false;
        await this.load()
        this.$bvToast.toast(`Modo de consulta alterado para modo livre.`, {
          title: 'Atenção',
          variant: 'success',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 6000,
        });
        this.$bvModal.hide('changeMode')
      } catch (e) {

      }
    },

    async switchToBusinessMode() {
      try {
        this.loading = true;
        const amount = await this.handleGetNumberRegisteredCompanies(this.user.id)
        if (amount > 0) {
          this.$bvModal.show('registerProductOrImport')
        } else{
          this.$bvModal.show('company')
        }
        this.loading = false;
      } catch (e) {

      }
    },

    callGlobalCustomEvent(event) {
      eventBus.$emit(event)
    },

    updateSelectedFilter(variable, value) {
      const length = this[variable].length
      if (length > 1) {
        this[variable] = [value]
      }
    },

    async showModal() {
      const { acgih, nr15, linach, esocial, decreto, products } = this.summaryMeta
      this.acgih = acgih ? [acgih] : []
      this.nr15 = nr15 ? [nr15] : []
      this.linach = linach ? [linach] : []
      this.esocial = esocial ? [esocial] : []
      this.decreto = decreto ? [decreto] : []
      this.selectedProducts = products ? products : []

      if (this.importedCompany && this.importedCompany._id) {
        const { data } = await this.handleGetProductLegalConsultationByCompany(this.importedCompany._id)
        const {products} = data
        this.products = products
        console.log(this.products)
      }
    },

    showModalFilter() {
      this.$refs.filter.show()
    },
    closeModal() {
      this.$refs.filter.hide()
    },

    async searchAgents() {
      await this.setSkip(1)
      await this.summaryClear()
      this.$refs.filter.hide()
      await this.handleSetSummaryMeta(
        {
          acgih: this.acgih.toString(),
          nr15: this.nr15.toString(),
          linach: this.linach.toString(),
          esocial: this.esocial.toString(),
          decreto: this.decreto.toString(),
          products: this.selectedProducts
        }
      )
      await this.load()
    },

    async load() {
      this.loading = false
      await this.$store.dispatch('legalSearch/load', this.user.id)
    },

    async clearSelectedFilters() {
      await this.summaryClear()
      await this.setSkip(1)
      this.$refs.filter.hide()
      await this.handleResetSummaryMeta()
      await this.load()
    }
  }
}
</script>

<style lang='scss' scoped>
.modalFilter {
  font-family: 'Montserrat', serif;
}
.header{
  .title{
    font-size: 20px;
    font-weight: 700;
    line-height: 29.26px;
    text-align: left;
    color: #262626;

  }
}
.warning{
  background: #04A8F366;
  border-radius: 8px;
  padding: 10px 30px 1px 30px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: #022245;
}
.title-product{
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
  text-align: left;
  color: #262626;
}
.product{
  border-radius: 12px;
  padding: 10px;
}
.text{
  font-size: 15px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
}
h6{
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
  color: #262626;
}
.text-opcoes-relavao-legal{
  font-size: 15px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #262626;
}

.filter-content {
  border-radius: 4px;
  border: 1px solid #85D1F3;
  background: #DBECF4;
  min-height: 40px !important;


  .btn-filtros {
    text-decoration: none;
    cursor: pointer;
  }

  .text-btn-filtros {
    color: #022245;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.btn-clear-filter {
  background: transparent !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: var(--navy-blue);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}
.text-filter {
  color: #022245;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: normal;
}

.btn-new-filter {
  border-radius: 4px;
  border: 1px solid #85D1F3;
  background: #DBECF4;
  min-height: 40px !important;
}
.btn-mode {
  border-radius: 4px;
  border: 1px solid  #CBD63E;
  background: rgba(203, 214, 62, 0.40);
  min-height: 40px !important;
  margin-left: 10px;
}

.btn_filter {
  background: var(--navy-blue) !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.divider {
  width: 1px !important;
  background: #FFFFFF !important;
  height: 100% !important;
}

.text-filter-applied {
  color: #022245;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 15px;
}
</style>
