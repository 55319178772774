export default {
  textDefault(){
    return `Clique aqui para responder nosso questionário e assim te auxiliarmos na análise da exposição permanente e proteção eficaz.`
  },
  protecaoEficaz(){
    return {
      pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico: null,
      pergunta_1_1_OEPCFuncionandoAdequadamente: null,
      pergunta_1_2_EPCTemLaudoDocumentoAtestandoEficacia: null,
      pergunta_1_3_LaudoDocumentoDentroPrazoPrevistoCronogramaManutencao: null,
      pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico: null,
      pergunta_2_1_todosEPIsEmpresaAdquiriuPossuemCA: null,
      pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI: null,
      pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco: null,
      pergunta_2_4_fornecimentoEPISempreRegistrado: null,
      pergunta_2_5_empresaCaraterFiscalizacaoConstanteJuntoSeusEmpregadosEstaExigindoUsoEPI: null,
      pergunta_2_6_empresaExecutaProcedimentosInternosParaRealizarHigienizacaoManutencaoPeriodicaEPIs: null,
      pergunta_2_7_empresaSubstituiImediatamenteEPICasoDeleEstarDanificadoExtraviado: null,
      pergunta_2_8_empresaExecutaProcedimentosInternosParaCasoEPIApresenteIrregularidades: null,
      pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66: null,
      pergunta_3_1_EPITrabalhadorUsaSomenteAqueleFornecidoPelaEmpresa: null,
      pergunta_3_2_EPIUtilizadoPeloTrabalhadorEstaAlinhadoSuaNecessidadeFinalidadeRisco: null,
      pergunta_3_3_trabalhadorEstaSendoResponsavelPelaLimpezaGuardaConservacaoEPI: null,
      pergunta_3_4_trabalhadorMatemComunicaaoComEmpresaParaInformarSituacoesEPIEstiverExtraviadoDanificadoQualquerAlteracaoTorneImproprioParaUso: null,
      pergunta_3_5_trabalhadorCumpreDeterminacoeEmpresaQuantoUsoEPI: null
    }
  },

  exposicaoPermanente(){
    return {
      frequenciaTrabalhadorRealizarAtividadefrequqnciaTrabalhadorRealizarAtividade: null,
      trabalhadorRealizeAtividadeSemPresencaSemNecessidadeEmpregoagentesQuimicos: null,
      trabalhadorexecutaAtividadeAgentesQuimicosAnalisados: null
    }
  },
  getValue(key, object) {
    if (!object) return ''
    let catalog = undefined
    if (object.attachment1) {
      catalog = 'attachment1'
    } else if (object.attachment2) {
      catalog = 'attachment2'
    } else if (object.attachment3) {
      catalog = 'attachment3'
    } else if (object.attachment8) {
      catalog = 'attachment8'
    } else if (object.attachment11) {
      catalog = 'attachment11'
    } else if (object.attachment12) {
      catalog = 'attachment12'
    } else if (object.attachment13) {
      catalog = 'attachment13'
    } else if (object.attachment14) {
      catalog = 'attachment14'
    } else if (object.nr15Unclassified) {
      catalog = 'nr15Unclassified'
    }
    switch (key) {
      case 'ps':
        // eslint-disable-next-line no-case-declarations
        let sentenceObservation = this.generatePhraseAgentProcessFramingCondition_00(object)
        if (sentenceObservation) {
          return sentenceObservation
        }
        let exposicaoPermanente = this.exposicaoPermanente();
        let protecaoEficaz = this.protecaoEficaz();
        if(
          object.product && object.product._id &&
          object.product.permanentExhibition && this.interactedPermanentExposureQuestions(object.product.permanentExhibition) &&
          object.product.effectiveProtection && this.interactedEffectiveProtectionQuestions(object.product.effectiveProtection)
        ) {
          exposicaoPermanente = object.product.permanentExhibition;
          protecaoEficaz = object.product.effectiveProtection;
        }

        sentenceObservation = this.generatePhraseBasedOnLinachCondition_01(object, exposicaoPermanente, protecaoEficaz)
        if (sentenceObservation) {
          return sentenceObservation
        }

        sentenceObservation = this.generatePhraseAgentNotFramedCondition_02(object, exposicaoPermanente, protecaoEficaz)
        if (sentenceObservation) {
          return sentenceObservation
        }

        return '-'
      default:
        return undefined
    }
  },

  elaborarFrasePersonalizadaReconhecimentoExposicaoPermamente(agente, exposicaoPermanente){
    let sentence_01 = ``;
    let sentence_02 = ``;
    let sentence_03 = ``;
    let sentence_peso = ``;
    let peso_01 = 0;
    let peso_02 = 0;
    let peso_03 = 0;
    let peso_total = 0;

    if (exposicaoPermanente.frequenciaTrabalhadorRealizarAtividadefrequqnciaTrabalhadorRealizarAtividade) {
      sentence_01 = `O trabalhador executa a atividade de forma habitual/frequente.`;
      peso_01 = 1;
    } else {
      sentence_01 = `O trabalhador não executa a atividade de forma habitual ou rotineira, pois não há uma frequência previsível da execução da atividade.`;
      peso_01 = 0;
    }


    if (exposicaoPermanente.trabalhadorRealizeAtividadeSemPresencaSemNecessidadeEmpregoagentesQuimicos) {
      sentence_02 = `<br/>Como o trabalhador pode executar a atividade sem a presença ou a utilização dos agentes químicos, então é 
                     possível afirmar que quando a atividade é executada não é sempre que ele terá uma contato com os agentes nocivos. 
                     Desse modo é possivel dizer que a atividade que o trabalhador executa é dissociável dos agentes nocivos 
                     (dissociável: é dispensável ou não é obrigatória para a execução da atividade ou produção do bem ou serviço).`;

      peso_02 = 0;

    } else {
      sentence_02 = `<br/>É possivel dizer que a atividade que o trabalhador executa é indissociável dos agentes químicos nocivos, 
                    pois o trabalhador sempre executa a atividade utilizando os produtos químicos que contém os agentes nocivos.`;
      peso_02 = 1;
    }


    if (exposicaoPermanente.trabalhadorexecutaAtividadeAgentesQuimicosAnalisados) {
      sentence_03 = `<br/>O trabalhador está em uma condição em que ocorre a exposição via dermal ou via respiração/inalação/ingestão dos agentes químicos.`;
      peso_03 = 1;
    } else {
      sentence_03 = `<br/>O trabalhador não está em uma condição em que ocorre a exposição nociva. Entretanto é recomendada cautela nessa afirmação, 
                    pois muitos agentes podem apresentar uma dispersão no ambiente de forma que não seja algo visível a olho nu ou ainda sensível 
                    ao olfato e ainda assim estar presente no ambiente expondo o trabalhador.`;
      peso_03 = 0;
    }


    peso_total = peso_01 + peso_02 + peso_03;
    if(peso_total <= 1){
      sentence_peso = `Resumo sobre a exposição permamente: A chance dessa exposição ser reconhecida como permanente é BAIXA.`;
    } else if(peso_total > 1){
      sentence_peso = `Resumo sobre a exposição permamente: A chance dessa exposição ser reconhecida como permamente é ALTA.`;
    }
    return `<br/>Análise sobre a exposição permanente: ${sentence_01} ${sentence_02} ${sentence_03} <br/> ${sentence_peso}`
  },

  analizarPergunta_1_protecaoEficaz(protecaoEficaz){
    if(protecaoEficaz.pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico){
      return !(protecaoEficaz.pergunta_1_1_OEPCFuncionandoAdequadamente === false ||
        protecaoEficaz.pergunta_1_2_EPCTemLaudoDocumentoAtestandoEficacia === false ||
        protecaoEficaz.pergunta_1_3_LaudoDocumentoDentroPrazoPrevistoCronogramaManutencao === false);
    } else {
      return false
    }
  },

  analizarPergunta_2_protecaoEficaz(protecaoEficaz){
    if(protecaoEficaz.pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico){
      return !(protecaoEficaz.pergunta_2_1_todosEPIsEmpresaAdquiriuPossuemCA === false ||
        protecaoEficaz.pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI === false ||
        protecaoEficaz.pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco === false ||
        protecaoEficaz.pergunta_2_4_fornecimentoEPISempreRegistrado === false ||
        protecaoEficaz.pergunta_2_5_empresaCaraterFiscalizacaoConstanteJuntoSeusEmpregadosEstaExigindoUsoEPI === false ||
        protecaoEficaz.pergunta_2_6_empresaExecutaProcedimentosInternosParaRealizarHigienizacaoManutencaoPeriodicaEPIs === false ||
        protecaoEficaz.pergunta_2_7_empresaSubstituiImediatamenteEPICasoDeleEstarDanificadoExtraviado === false ||
        protecaoEficaz.pergunta_2_8_empresaExecutaProcedimentosInternosParaCasoEPIApresenteIrregularidades === false
      );
    } else {
      return false
    }
  },

  analizarPergunta_3_protecaoEficaz(protecaoEficaz){
    if(protecaoEficaz.pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66){
      return !(protecaoEficaz.pergunta_3_1_EPITrabalhadorUsaSomenteAqueleFornecidoPelaEmpresa === false ||
        protecaoEficaz.pergunta_3_2_EPIUtilizadoPeloTrabalhadorEstaAlinhadoSuaNecessidadeFinalidadeRisco === false ||
        protecaoEficaz.pergunta_3_3_trabalhadorEstaSendoResponsavelPelaLimpezaGuardaConservacaoEPI === false ||
        protecaoEficaz.pergunta_3_4_trabalhadorMatemComunicaaoComEmpresaParaInformarSituacoesEPIEstiverExtraviadoDanificadoQualquerAlteracaoTorneImproprioParaUso === false ||
        protecaoEficaz.pergunta_3_5_trabalhadorCumpreDeterminacoeEmpresaQuantoUsoEPI === false
      );
    } else {
      return false
    }
  },

  elaboracaoFrasePersonalizadaReconhecimentoProtecao(agente, protecaoEficaz){
    const pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico = this.analizarPergunta_1_protecaoEficaz(protecaoEficaz)
    const pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico = this.analizarPergunta_2_protecaoEficaz(protecaoEficaz)
    const pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66 = this.analizarPergunta_3_protecaoEficaz(protecaoEficaz)

    let sentence_01 = ``;
    let sentence_02 = ``;
    let sentence_03 = ``;
    let protecao_eficaz_resumo = ``;

    if(pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico){
      sentence_01 = `É provável que o EPC esteja controlando as exposições e desse modo garantindo que os agentes químicos envolvidos no processo produtivo não estejam no ambiente de trabalho expondo os trabalhadores, entretanto é muito importante que a afirmação de que o EPC é eficaz seja fundamentada em evidências documentais e técnicas.`
    } else {
      sentence_01 = `Tendo em vista a falta da instalação de um EPC ou ainda de evidências documentais referentes à sua eficácia, então não é possível afirmar que o EPC é eficaz.`
    }

    if(pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico){
      if(pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66){
        sentence_02 = `<br/>É possível afirmar que exista um EPI eficaz, pois o empregador e o empregado estão cumprindo com as suas obrigações conforme os itens 6.5 e 6.6 da NR 06.`
        sentence_03 = ``
      }
      if(!pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66){
        sentence_02 = `<br/>Como o empregador está cumprindo com suas obrigações conforme os itens 6.5 da NR 06, então é possível dizer que a empresa ou organização está trabalhando em prol da implementação de uma gestão de proteção de forma eficiente. Apesar deste trabalho ser importante ele não é suficiente para afirmar que o EPI é eficaz, pois também é necessário analisar se o empregado está cumprindo com as suas obrigações conforme os itens 6.6 da NR 06. Desse modo analisamos as respostas referentes às obrigações do empregado e vimos que não é possível afirmar que exista um EPI eficaz, pois o empregado não está cumprindo suas obrigações conforme os itens 6.6 da NR 06.`
        sentence_03 = `<br/>É comum que se tente afirmar a existência de um EPI eficaz somente olhando as obrigações da empresa/empregador, mas isso é um erro muito comum. Essa análise unilateral deve ser evitada para que os enquadramentos nos laudos e seus reflexos tributários, previdenciários e de saúde do trabalhador sejam sempre coerentes com a realidade do trabalhador e da empresa.`
      }
    } else {
      sentence_02 = `<br/>Não é possível afirmar que exista um EPI eficaz, pois o empregador não está cumprindo com as suas obrigações conforme os itens 6.5 da NR 06.`
      sentence_03 = ``
    }

    if(pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico){
      if(pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico && pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66){
        protecao_eficaz_resumo = `<br/>Resumo sobre a proteção eficaz: Considerando a existência de um EPC considerado como eficaz, bem como o pleno atendimento aos itens 6.5 e 6.6 da NR 06 é possível afirmar que a proteção atribuída ao ambiente e ao trabalhador durante a execução das atividades é eficaz.`
      } else {
        protecao_eficaz_resumo = `<br/>Resumo sobre a proteção eficaz: É provável que o EPC esteja controlando as exposições na fonte do risco devido às informações de que o equipamento está adequado e em pleno funcionamento, bem como a existência de evidências que comprovam a eliminição ou diminuição das concentrações abaixo dos níveis de ação dos agentes químicos no ambiente de trabalho. A análise de um EPI nesse ambiente pode ser relevante para fortalecer a tese de que o trabalhador não está exposto aos agentes nocivos, entretanto é importante destacar que muitas vezes a implementação do EPI não se faz necessária quando se há um EPC eficaz.`
      }
    } else if(!pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico) {
      if(pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico && pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66){
        protecao_eficaz_resumo = `<br/>Resumo sobre a proteção eficaz: A gestão da proteção através do EPI pode ser considerada eficaz tendo em vista o pleno atendimento aos itens 6.5 e 6.6 da NR 06. Apesar disso é importante ressaltar que outras medidas de proteção também devem ser estudadas, implementadas e observadas conforme a hierarquia das medidas de prevenção a fim de atender as situações previstas no subitem 1.5.5.1.2 da NR 01.`
      } else {
        protecao_eficaz_resumo = `<br/>Resumo sobre a proteção eficaz: Não foram apontadas evidências ou medidas de controle suficientes para garantir que a proteção ao trabalhador seja eficaz.`
      }
    }
    return `<br/>Análise sobre a proteção eficaz: ${sentence_01}${sentence_02}${sentence_03}${protecao_eficaz_resumo}`
  },

  interactedPermanentExposureQuestions(exposicaoPermanente){
    return exposicaoPermanente.frequenciaTrabalhadorRealizarAtividadefrequqnciaTrabalhadorRealizarAtividade !== null &&
      exposicaoPermanente.trabalhadorRealizeAtividadeSemPresencaSemNecessidadeEmpregoagentesQuimicos !== null &&
      exposicaoPermanente.trabalhadorexecutaAtividadeAgentesQuimicosAnalisados !== null;
  },

  interactedEffectiveProtectionQuestions(protecaoEficaz){
    if(
      protecaoEficaz.pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico &&
      (
        protecaoEficaz.pergunta_1_1_OEPCFuncionandoAdequadamente === null ||
        protecaoEficaz.pergunta_1_2_EPCTemLaudoDocumentoAtestandoEficacia === null ||
        protecaoEficaz.pergunta_1_3_LaudoDocumentoDentroPrazoPrevistoCronogramaManutencao === null
      )
      ){
      return false;
    }

    if(
      protecaoEficaz.pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico &&
      (
        protecaoEficaz.pergunta_2_1_todosEPIsEmpresaAdquiriuPossuemCA === null ||
        protecaoEficaz.pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI === null ||
        protecaoEficaz.pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco === null ||
        protecaoEficaz.pergunta_2_4_fornecimentoEPISempreRegistrado === null ||
        protecaoEficaz.pergunta_2_5_empresaCaraterFiscalizacaoConstanteJuntoSeusEmpregadosEstaExigindoUsoEPI === null ||
        protecaoEficaz.pergunta_2_6_empresaExecutaProcedimentosInternosParaRealizarHigienizacaoManutencaoPeriodicaEPIs === null ||
        protecaoEficaz.pergunta_2_7_empresaSubstituiImediatamenteEPICasoDeleEstarDanificadoExtraviado === null ||
        protecaoEficaz.pergunta_2_8_empresaExecutaProcedimentosInternosParaCasoEPIApresenteIrregularidades === null
      )
    ){
      return false;
    }

    if(
      protecaoEficaz.pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66 &&
      (
        protecaoEficaz.pergunta_3_1_EPITrabalhadorUsaSomenteAqueleFornecidoPelaEmpresa === null ||
        protecaoEficaz.pergunta_3_2_EPIUtilizadoPeloTrabalhadorEstaAlinhadoSuaNecessidadeFinalidadeRisco === null ||
        protecaoEficaz.pergunta_3_3_trabalhadorEstaSendoResponsavelPelaLimpezaGuardaConservacaoEPI === null ||
        protecaoEficaz.pergunta_3_4_trabalhadorMatemComunicaaoComEmpresaParaInformarSituacoesEPIEstiverExtraviadoDanificadoQualquerAlteracaoTorneImproprioParaUso === null ||
        protecaoEficaz.pergunta_3_5_trabalhadorCumpreDeterminacoeEmpresaQuantoUsoEPI === null
      )
    ){
      return false;
    }

    return protecaoEficaz.pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico !== null &&
      protecaoEficaz.pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico !== null &&
      protecaoEficaz.pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66 !== null;
  },

  generatePhraseAgentProcessFramingCondition_00(object){
    if (object.attachment1 || object.attachment2 || object.attachment3 || object.attachment8 || object.attachment14) {
      return false
    }
    if (object.decree3048) {
      if (object.linach && object.linach.group === 'Grupo 1') {
        return false
      }
      if (object.attachment11) {
        if (object.linach && object.linach.group === 'Grupo 1') {
          return false
        } else {
          return false
        }
      }
      if (object.attachment12 && object.activities.includes('Nenhuma das opções anteriores')) {
        if (object.linach && object.linach.group === 'Grupo 1') {
          return false
        } else {
          return false
        }
      } else if (object.attachment12) {
        if (object.linach && object.linach.group === 'Grupo 1') {
          return false
        } else {
          return false
        }
      }
      if (object.attachment13 && object.activities.includes('Nenhuma das opções anteriores')) {
        if (object.linach && object.linach.group === 'Grupo 1') {
          return false
        } else {
          return false
        }
      } else if (object.attachment13) {
        if (object.linach && object.linach.group === 'Grupo 1') {
          return false
        } else {
          return false
        }
      }
      return false
    } else if (object.attachment11 && object.linach && object.linach.group === 'Grupo 1') {
      return false
    } else if (object.decreeUnclassified) {
      return false
    }
    return `O agente ${this.getsearchId(object)} foi incluído em nosso banco de dados e está em processo de classificação 
            para que possamos informar se ele apresentará margem para o enquadramento no decreto 3048/99 - anexo IV. 
            Se você precisa dessa informação com urgência, por gentileza, entre em contato conosco através do botão
            'Envie seu feedback' informando qual o CAS que você precisa de ajuda.`
  },

  generatePhraseBasedOnLinachCondition_01(object, exposicaoPermanente, protecaoEficaz){
    if (object.linach && object.linach.group === 'Grupo 1') {
      if (object.decreeUnclassified) {
        return `O agente ${this.getsearchId(object)} está no grupo 1 da lista LINACH e possui número CAS, porém ele foi verificado por 
                nosso serviço de classificação de agentes químicos e não apresentou margem para enquadramento por não estar 
                presente em nenhum dos grupos químicos do decreto 3048/99 - anexo IV.`

      } else if(object.decree3048){
        let observationSentence_01 = `O agente ${this.getsearchId(object)} consta no decreto 3048/99 - anexo IV e na lista LINACH dentro do grupo 1.
                                                <br/>A análise sobre o seu enquadramento será feita de forma QUALITATIVA.
                                                <br/>Além disso é importante analisar se a exposição ocorre de forma nociva, 
                                                permamente e sem a eficácia comprovada do EPC ou processo hermetizado.
                                                <br/>A análise de um agente comprovadamente carcinogênico considera somente 
                                                medidas de controle que eliminem a exposição e, portanto, o EPI não é considerado 
                                                proteção eficaz conforme explica o artigo 64 do Decreto 10410/20.`

        const observationSentence_02 = `<br/>A relação legal para o enquadramento se faz ao menos através do artigo 68 do Decreto 3048/99, artigo 268 da 
                                                  Instrução Normativa PRES/INSS Nº 128 e artigo 64 e 68 do Decreto 10410/20.`

        let observationSentence_03 = `<br/>`

        let observationSentence_04 = ``
        if(!this.interactedPermanentExposureQuestions(exposicaoPermanente) || !this.interactedEffectiveProtectionQuestions(protecaoEficaz)){
          observationSentence_03 = `<br/>`

          return `${observationSentence_01}${observationSentence_02}${observationSentence_03}`
        } else {
          observationSentence_01 = `O agente ${this.getsearchId(object)} consta no decreto 3048/99 - anexo IV e na lista LINACH dentro do grupo 1.
                                    <br/>A análise sobre o seu enquadramento será feita de forma QUALITATIVA. 
                                    <br/>Além disso é importante analisar se a exposição ocorre de forma nociva, permanente e sem a eficácia comprovada do EPC ou processo hermetizado.
                                    <br/>A análise de um agente comprovadamente carcinogênico considera somente medidas de controle que eliminem a exposição e, 
                                    portanto, o EPI não é considerado proteção eficaz conforme explica o artigo 64 do Decreto 10410/20.`

          observationSentence_03 = this.elaborarFrasePersonalizadaReconhecimentoExposicaoPermamente(object, exposicaoPermanente)

          observationSentence_04 = this.elaboracaoFrasePersonalizadaReconhecimentoProtecao(object, protecaoEficaz)
          return `${observationSentence_01}${observationSentence_02}<br/>${observationSentence_03}<br/>${observationSentence_04}`
        }
      }
    } else if (object.linach && (object.linach.group === 'Grupo 2A' || object.linach.group === 'Grupo 2B')) {
      let observationSentence_01 = `O agente ${this.getsearchId(object)} consta no decreto 3048/99 - anexo IV e na lista LINACH dentro do grupo 2.`;

      let observationSentence_02 = `<br/>Apesar deste agente estar na lista LINACH, a análise do enquadramento para os agentes que não 
                                              são comprovadamente carcinogênicos (grupo 2a e 2b) não seguem o mesmo procedimento dos agentes que estão no grupo 1.`;

      let observationSentence_03 = `<br/>A relação legal para o enquadramento se faz ao menos através do artigo 68 do Decreto 3048/99, artigo 268 
                                              da Instrução Normativa PRES/INSS Nº 128 e artigo 64 e 68 do Decreto 10410/20.`;

      if (object.decree3048) {
        let observationSentence_04 = ``;
        let observationSentence_05 = ``;
        let observationSentence_06 = ``;
        let observationSentence_07 = ``;
        if(object.attachment11){
          observationSentence_04 = `<br/>Além das informações citadas anteriormente é importante mencionar que este agente está presente no anexo 11 da NR 15 e por isso a 
                                    sua avaliação para fins de enquadramento deverá ser feita de forma QUANTITATIVA.`

          observationSentence_05 = ` Os principais pontos de destaque que fundamentarão o enquadramento serão:
                                    <br/>1) Quando o trabalhador estiver exposto ao agente de maneira permanente;
                                    <br/>2) Quando o limite de tolerância previsto no anexo 11 da NR 15 for ultrapassado;
                                    <br/>3) Quando não houver eficácia comprovada do EPC, EPI ou processo hermetizado.`

          if(!this.interactedPermanentExposureQuestions(exposicaoPermanente) || !this.interactedEffectiveProtectionQuestions(protecaoEficaz)){
            observationSentence_06 = `<br/>`
            return `${observationSentence_01}${observationSentence_02}${observationSentence_03}${observationSentence_04}${observationSentence_05}${observationSentence_06}`
          } else {
            observationSentence_06 = this.elaborarFrasePersonalizadaReconhecimentoExposicaoPermamente(object, exposicaoPermanente);
            observationSentence_07 = this.elaboracaoFrasePersonalizadaReconhecimentoProtecao(object, protecaoEficaz);

            return `${observationSentence_01}${observationSentence_02}${observationSentence_03}${observationSentence_04}${observationSentence_05}<br/>${observationSentence_06}<br/>${observationSentence_07}`
          }
        } else if(object.attachment12){
          observationSentence_04 = `<br/>Além das informações citadas anteriormente é importante mencionar que este agente está presente no anexo 12 da NR 15 e 
                                    por isso a sua avaliação para fins de enquadramento deverá ser feita de forma QUANTITATIVA.`

          observationSentence_05 = ` Os principais pontos de destaque que fundamentarão o enquadramento serão:
                                    <br/>1) Quando o trabalhador estiver exposto ao agente de maneira permanente;
                                    <br/>2) Quando o limite de tolerância previsto no anexo 12 da NR 15 for ultrapassado;
                                    <br/>3) Quando não houver eficácia comprovada do EPC, EPI ou processo hermetizado.`

          if(!this.interactedPermanentExposureQuestions(exposicaoPermanente) || !this.interactedEffectiveProtectionQuestions(protecaoEficaz)){
            observationSentence_06 = `<br/>`
            return `${observationSentence_01}${observationSentence_02}${observationSentence_03}${observationSentence_04}${observationSentence_05}${observationSentence_06}`
          } else {
            observationSentence_06 = this.elaborarFrasePersonalizadaReconhecimentoExposicaoPermamente(object, exposicaoPermanente);
            observationSentence_07 = this.elaboracaoFrasePersonalizadaReconhecimentoProtecao(object, protecaoEficaz);

            return `${observationSentence_01}${observationSentence_02}${observationSentence_03}${observationSentence_04}${observationSentence_05}<br/>${observationSentence_06}<br/>${observationSentence_07}`
          }
        } else if(object.attachment13){
          observationSentence_04 = `<br/>Além das informações citadas anteriormente é importante mencionar que este agente está presente no anexo 13 da NR 15 e por isso a sua 
                                    avaliação para fins de enquadramento deverá ser feita de forma QUALITATIVA.`

          observationSentence_05 = ` Os principais pontos de destaque que fundamentarão o enquadramento serão:
                                    <br/>1) Quando o trabalhador estiver exposto ao agente de maneira permanente;
                                    <br/>2) Quando não houver eficácia comprovada do EPC, EPI ou processo hermetizado.`

          if(!this.interactedPermanentExposureQuestions(exposicaoPermanente) || !this.interactedEffectiveProtectionQuestions(protecaoEficaz)){
            observationSentence_06 = `<br/>`
            return `${observationSentence_01}${observationSentence_02}${observationSentence_03}${observationSentence_04}${observationSentence_05}${observationSentence_06}`
          } else {
            observationSentence_06 = this.elaborarFrasePersonalizadaReconhecimentoExposicaoPermamente(object, exposicaoPermanente);
            observationSentence_07 = this.elaboracaoFrasePersonalizadaReconhecimentoProtecao(object, protecaoEficaz);

            return `${observationSentence_01}${observationSentence_02}${observationSentence_03}${observationSentence_04}${observationSentence_05}<br/>${observationSentence_06}<br/>${observationSentence_07}`
          }
        } else if(!object.attachment11 && !object.attachment12 && !object.attachment13){
          observationSentence_04 = `<br/>Além das informações citadas anteriormente é importante mencionar que este agente não apresenta uma relação 
                                    clara com a NR 15, entretanto isso não significa que ele não terá margem para o enquadramento. Desse modo a sua avaliação para fins de enquadramento deverá ser feita de forma QUALITATIVA.`

          observationSentence_05 = ` Os principais pontos de destaque que fundamentarão o enquadramento serão:
                                    <br/>1) Quando o trabalhador estiver exposto ao agente de maneira permanente;
                                    <br/>2) Quando não houver eficácia comprovada do EPC, EPI ou processo hermetizado.`

          if(!this.interactedPermanentExposureQuestions(exposicaoPermanente) || !this.interactedEffectiveProtectionQuestions(protecaoEficaz)){
            observationSentence_06 = `<br/>`
            return `${observationSentence_01}${observationSentence_02}${observationSentence_03}${observationSentence_04}${observationSentence_05}${observationSentence_06}`
          } else {
            observationSentence_06 = this.elaborarFrasePersonalizadaReconhecimentoExposicaoPermamente(object, exposicaoPermanente);
            observationSentence_07 = this.elaboracaoFrasePersonalizadaReconhecimentoProtecao(object, protecaoEficaz);

            return `${observationSentence_01}${observationSentence_02}${observationSentence_03}${observationSentence_04}${observationSentence_05}<br/>${observationSentence_06}<br/>${observationSentence_07}`
          }
        }
      } else if(!object.decree3048){
        observationSentence_01 = `O agente ${this.getsearchId(object)} consta na lista LINACH dentro do grupo 2.`;
        observationSentence_02 = `<br/>Apesar deste estar na lista LINACH, este foi verificado por nosso serviço de classificação de agentes 
                                  químicos e não apresentou margem para enquadramento por não estar presente em nenhum dos grupos químicos do Decreto 3048/99 - anexo IV.`;

        return `${observationSentence_01}${observationSentence_02}`
      }
    }
  },

  generatePhraseAgentNotFramedCondition_02(object, exposicaoPermanente, protecaoEficaz){
    let observationSentence_01 = `O agente ${this.getsearchId(object)} consta no decreto 3048/99 - anexo IV.`
    let observationSentence_02 = `<br/> A relação legal para o enquadramento se faz ao menos através do artigo 68 do Decreto 3048/99, artigo 268 da Instrução Normativa PRES/INSS Nº 128 e artigo 64 e 68 do Decreto 10410/20.`
    let observationSentence_03 = ``;
    let observationSentence_04 = ``;
    let observationSentence_05 = ``;
    let observationSentence_06 = ``;

    if (object.decree3048) {
      if(object.attachment11){
        observationSentence_03 = `<br/>Além das informações citadas anteriormente é importante mencionar que este agente está presente no anexo 11 da NR 15 e por 
                                  isso a sua avaliação para fins de enquadramento deverá ser feita de forma QUANTITATIVA.`

        observationSentence_04 = ` Os principais pontos de destaque que fundamentarão o enquadramento serão:
                                  <br/>1) Quando o trabalhador estiver exposto ao agente de maneira permanente;
                                  <br/>2) Quando o limite de tolerância previsto no anexo 11 da NR 15 for ultrapassado;
                                  <br/>3) Quando não houver eficácia comprovada do EPC, EPI ou processo hermetizado.`

        if(!this.interactedPermanentExposureQuestions(exposicaoPermanente) || !this.interactedEffectiveProtectionQuestions(protecaoEficaz)){
          observationSentence_05 = `<br/>`
          return `${observationSentence_01}${observationSentence_02}${observationSentence_03}${observationSentence_04}${observationSentence_05}`
        } else {
          observationSentence_05 = this.elaborarFrasePersonalizadaReconhecimentoExposicaoPermamente(object, exposicaoPermanente);
          observationSentence_06 = this.elaboracaoFrasePersonalizadaReconhecimentoProtecao(object, protecaoEficaz);

          return `${observationSentence_01}${observationSentence_02}${observationSentence_03}${observationSentence_04}<br/>${observationSentence_05}<br/>${observationSentence_06}`
        }
      } else if(object.attachment12){
        observationSentence_03 = `<br/>Além das informações citadas anteriormente é importante mencionar que este agente está presente no anexo 12 da 
                                  NR 15 e por isso a sua avaliação para fins de enquadramento deverá ser feita de forma QUANTITATIVA.`

        observationSentence_04 = ` Os principais pontos de destaque que fundamentarão o enquadramento serão:
                                  <br/>1) Quando o trabalhador estiver exposto ao agente de maneira permanente;
                                  <br/>2) Quando o limite de tolerância previsto no anexo 12 da NR 15 for ultrapassado;
                                  <br/>3) Quando não houver eficácia comprovada do EPC, EPI ou processo hermetizado.`

        if(!this.interactedPermanentExposureQuestions(exposicaoPermanente) || !this.interactedEffectiveProtectionQuestions(protecaoEficaz)){
          observationSentence_05 = `<br/>`
          return `${observationSentence_01}${observationSentence_02}${observationSentence_03}${observationSentence_04}${observationSentence_05}`
        } else {
          observationSentence_05 = this.elaborarFrasePersonalizadaReconhecimentoExposicaoPermamente(object, exposicaoPermanente);
          observationSentence_06 = this.elaboracaoFrasePersonalizadaReconhecimentoProtecao(object, protecaoEficaz);

          return `${observationSentence_01}${observationSentence_02}${observationSentence_03}${observationSentence_04}<br/>${observationSentence_05}<br/>${observationSentence_06}`
        }
      } else if(object.attachment13){
        observationSentence_03 = `<br/>Além das informações citadas anteriormente é importante mencionar que este agente está presente no anexo 13 
                                  da NR 15 e por isso a sua avaliação para fins de enquadramento deverá ser feita de forma QUALITATIVA.`

        observationSentence_04 = ` Os principais pontos de destaque que fundamentarão o enquadramento serão:
                                  <br/>1) Quando o trabalhador estiver exposto ao agente de maneira permanente;
                                  <br/>2) Quando não houver eficácia comprovada do EPC, EPI ou processo hermetizado.`

        if(!this.interactedPermanentExposureQuestions(exposicaoPermanente) || !this.interactedEffectiveProtectionQuestions(protecaoEficaz)){
          observationSentence_05 = `<br/>`
          return `${observationSentence_01}${observationSentence_02}${observationSentence_03}${observationSentence_04}${observationSentence_05}`
        } else {
          observationSentence_05 = this.elaborarFrasePersonalizadaReconhecimentoExposicaoPermamente(object, exposicaoPermanente);
          observationSentence_06 = this.elaboracaoFrasePersonalizadaReconhecimentoProtecao(object, protecaoEficaz);

          return `${observationSentence_01}${observationSentence_02}${observationSentence_03}${observationSentence_04}<br/>${observationSentence_05}<br/>${observationSentence_06}`
        }
      } else if(!object.attachment11 && !object.attachment12 && !object.attachment13){
        observationSentence_03 = `<br/>Além das informações citadas anteriormente é importante mencionar que este agente não apresenta uma relação 
                                  clara com a NR 15, entretanto isso não significa que ele não terá margem para o enquadramento. Desse modo a sua avaliação para 
                                  fins de enquadramento deverá ser feita de forma QUALITATIVA.`

        observationSentence_04 = ` Os principais pontos de destaque que fundamentarão o enquadramento serão:
                                  <br/>1) Quando o trabalhador estiver exposto ao agente de maneira permanente;
                                  <br/>2) Quando não houver eficácia comprovada do EPC, EPI ou processo hermetizado.`

        if(!this.interactedPermanentExposureQuestions(exposicaoPermanente) || !this.interactedEffectiveProtectionQuestions(protecaoEficaz)){
          observationSentence_05 = `<br/>`
          return `${observationSentence_01}${observationSentence_02}${observationSentence_03}${observationSentence_04}${observationSentence_05}`
        } else {
          observationSentence_05 = this.elaborarFrasePersonalizadaReconhecimentoExposicaoPermamente(object, exposicaoPermanente);
          observationSentence_06 = this.elaboracaoFrasePersonalizadaReconhecimentoProtecao(object, protecaoEficaz);

          return `${observationSentence_01}${observationSentence_02}${observationSentence_03}${observationSentence_04}<br/>${observationSentence_05}<br/>${observationSentence_06}`
        }
      }
    } else if(!object.decree3048 && object.decreeUnclassified){
      return `O agente ${this.getsearchId(object)} foi verificado por nosso serviço de classificação de agentes químicos e 
              não apresentou margem para enquadramento por não estar presente em nenhum dos grupos químicos do Decreto 3048/99 - anexo IV.`
    }
  },

  getsearchId(object){
    const casAgente = object.name || object.searchId || object.searchMatched
    const [cas, agente] = casAgente.split(':')

    if(!agente.trim() && object.synonyms && object.synonyms.length > 0){
      return `${object.cas}: ${object.synonyms.find((x) => x.length > 0)}`
    }
    return casAgente
  },

  necessaryToFillOutAnalysisTool(agent){
    if (this.generatePhraseAgentProcessFramingCondition_00(agent)) {
      return false
    }
    return !(agent.product && agent.product._id &&
      agent.product.permanentExhibition && this.interactedPermanentExposureQuestions(agent.product.permanentExhibition) &&
      agent.product.effectiveProtection && this.interactedEffectiveProtectionQuestions(agent.product.effectiveProtection));
  },

  frasePersonalizadaFerramentaAnalise(agent){
    let protecaoEficaz = this.protecaoEficaz();
    if(
      agent.product && agent.product._id &&
      agent.product.effectiveProtection && this.interactedEffectiveProtectionQuestions(agent.product.effectiveProtection)
    ) {
      protecaoEficaz = agent.product.effectiveProtection;
    }
    if (this.interactedEffectiveProtectionQuestions(protecaoEficaz)){
      return `<br/>${this.elaboracaoFrasePersonalizadaReconhecimentoProtecao(agent, protecaoEficaz)}`;
    }
    return ``
  }
}
