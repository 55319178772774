<template>
  <b-modal
    id="activities-modal"
    size="md"
    header-bg-variant="primary"
    header-text-variant="light"
    title="Escolha as atividades realizadas"
    ref="modal"
    hide-footer
  >
    <form>
      <b-form-group>
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="selectedActivities"
          :options="activities"
          name="flavour-1"
          stacked
        ></b-form-checkbox-group> </b-form-group
      ><br />
      <b-input-group>
        <b-button variant="success" @click="save" block>
          <i class="fa fa-save"></i> Salvar
        </b-button>
      </b-input-group>
    </form>
  </b-modal>
</template>

<script>
export default {
  computed: {
    activities() {
      let catalog = ''

      if(this.agent.attachment1){
        catalog = 'attachment1'
      } else if(this.agent.attachment2){
        catalog = 'attachment2'
      } else if(this.agent.attachment3){
        catalog = 'attachment3'
      } else if(this.agent.attachment8){
        catalog = 'attachment8'
      } else if(this.agent.attachment12){
        catalog = 'attachment12'
      } else if(this.agent.attachment13){
        catalog = 'attachment13'
      } else if(this.agent.attachment14){
        catalog = 'attachment14'
      }
      if (this.agent[catalog] && this.agent[catalog].activities) {
        let activities = []
        this.agent[catalog].activities.forEach((group) => {
          activities = activities.concat(group.activities)
        })
        activities = activities.concat(['O trabalhador executa uma atividade diferente das opções previstas em norma para este agente químico'])
        return activities
      } else return ['O trabalhador executa uma atividade diferente das opções previstas em norma para este agente químico']

    },
  },
  data() {
    return {
      selectedActivities: [],
    }
  },
  methods: {
    save() {
      this.$emit(
        'save',
        Object.assign(this.agent, {
          selectedActivities: this.selectedActivities,
        })
      )
    },
  },
  props: ['agent', 'prevActivities'],
  watch: {
    agent() {
      this.selectedActivities = this.agent.activities
        ? this.agent.activities
        : []
    },
  },
}
</script>
