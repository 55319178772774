export default {
  verifyAgentHasMultipleRatings(object, laboratory) {
    const analise = object[laboratory]
    return analise && analise.length > 1
  },

  situacao_1(
    tempo_de_amostragem_minimo,
    vazao_minima,
    vazao_maxima,
    volume_maximo,
    inputs_do_usuario,
    vazao_manual,
    volume_manual,
  ) {
    let vazao_minima_recalculada = 0
    let tempo_por_amostrador = 0
    let volume_coletado = 0
    if (inputs_do_usuario) {
      tempo_por_amostrador = Number((volume_manual / vazao_manual).toFixed(4));

      if (tempo_por_amostrador >= tempo_de_amostragem_minimo){
        tempo_por_amostrador = tempo_de_amostragem_minimo;
      }
      volume_coletado = Number((tempo_por_amostrador * vazao_manual).toFixed(4))

      return { vazao_manual, tempo_por_amostrador, volume_coletado }
    } else {
      vazao_minima_recalculada = Number((volume_maximo / tempo_de_amostragem_minimo).toFixed(4))
      vazao_minima_recalculada = this.limitacao_de_vazao(vazao_minima, vazao_maxima, vazao_minima_recalculada)
      tempo_por_amostrador = Number((volume_maximo / vazao_minima_recalculada).toFixed(1));

      if (tempo_por_amostrador >= tempo_de_amostragem_minimo){
        tempo_por_amostrador = tempo_de_amostragem_minimo
      }

      volume_coletado = Number((tempo_por_amostrador * vazao_minima_recalculada).toFixed(4))

      return { vazao_minima_recalculada, tempo_por_amostrador, volume_coletado }
    }
  },

  situacao_2(
    quantidade_de_amostradores,
    tempo_de_amostragem_minimo,
    vazao_minima,
    vazao_maxima,
    volume_maximo,
    inputs_do_usuario,
    vazao_manual,
    volume_manual,
  ) {
    let tempo_por_amostrador = 0;
    let tempo_por_amostrador_recalculado = 0;
    let vazao_minima_recalculada = 0;
    let volume_coletado = 0;
    if (inputs_do_usuario) {
      tempo_por_amostrador = Number((volume_manual / vazao_manual).toFixed(4))
      tempo_por_amostrador_recalculado = tempo_de_amostragem_minimo / quantidade_de_amostradores

      if (tempo_por_amostrador_recalculado <= tempo_por_amostrador) {
        tempo_por_amostrador = tempo_por_amostrador_recalculado
      }

      volume_coletado = Number((tempo_por_amostrador * vazao_manual).toFixed(4))

      return { vazao_manual, tempo_por_amostrador, volume_coletado }
    } else {
      tempo_por_amostrador = Number((volume_maximo / vazao_minima).toFixed(4))
      tempo_por_amostrador_recalculado = tempo_de_amostragem_minimo / quantidade_de_amostradores

      if (tempo_por_amostrador_recalculado <= tempo_por_amostrador) {
        tempo_por_amostrador = tempo_por_amostrador_recalculado
      }

      vazao_minima_recalculada = Number((volume_maximo / tempo_por_amostrador).toFixed(4))
      vazao_minima_recalculada = this.limitacao_de_vazao(vazao_minima, vazao_maxima, vazao_minima_recalculada)
      volume_coletado = Number((tempo_por_amostrador * vazao_minima_recalculada).toFixed(4))

      return { vazao_minima_recalculada, tempo_por_amostrador, volume_coletado }
    }
  },

  planejamento_de_amostragem(forma_avaliacao_objeto, objectivesAnalysis) {
    const jornada = Number(objectivesAnalysis['journeyMinutes'])
    const criterio_de_quantificacao = Number(objectivesAnalysis['quantificationCriterion']) / 100

    const forma_avaliacao = forma_avaliacao_objeto['evaluationForm']
    let vazao_minima = Number(forma_avaliacao_objeto['minimumFlow'].toString().replace(',', '.'))
    let vazao_minima_recomendada = Number(forma_avaliacao_objeto['minimumRecommendedFlow'].toString().replace(',', '.'))
    let vazao_maxima = Number(forma_avaliacao_objeto['maximumLeak'].toString().replace(',', '.'))
    let vazao_maxima_recomendada = Number(forma_avaliacao_objeto['recommendedMaximumFlow'].toString().replace(',', '.'))
    let volume_maximo = Number(forma_avaliacao_objeto['maximumVolume'])
    let volume_minimo = Number(forma_avaliacao_objeto['minimumVolume'])
    let volume_maximo_recomendado = Number(forma_avaliacao_objeto['recommendedMaximumVolume'])
    const tempo_de_amostragem_minimo = criterio_de_quantificacao * jornada
    const margem_de_seguranca = objectivesAnalysis['considerSafetyMargin']
    let inputs_do_usuario = !!(forma_avaliacao_objeto && forma_avaliacao_objeto.itsFlow) && !!(forma_avaliacao_objeto && forma_avaliacao_objeto.itsVolume)
    let vazao_manual = 0;
    let volume_manual = 0;

    if (inputs_do_usuario) {
      vazao_manual = forma_avaliacao_objeto.itsFlow
      volume_manual = forma_avaliacao_objeto.itsVolume
    }

    return this.calcular_planejamento_de_amostragem(
      forma_avaliacao,
      vazao_minima,
      vazao_minima_recomendada,
      vazao_maxima,
      vazao_maxima_recomendada,
      volume_maximo,
      volume_maximo_recomendado,
      tempo_de_amostragem_minimo,
      margem_de_seguranca,
      inputs_do_usuario,
      vazao_manual,
      volume_manual,
      volume_minimo
    )
  },

  calcular_planejamento_de_amostragem(
    forma_avaliacao,
    vazao_minima,
    vazao_minima_recomendada,
    vazao_maxima,
    vazao_maxima_recomendada,
    volume_maximo,
    volume_maximo_recomendado,
    tempo_de_amostragem_minimo,
    margem_de_seguranca,
    inputs_do_usuario,
    vazao_manual,
    volume_manual,
    volume_minimo
  ) {
    let tempo_por_amostrador_sugerido = 0
    let frase_sugerida = ``
    let frase_de_orientacao = ``
    let quantidade_de_amostradores = 1
    let volume = 0
    let volume_limitado = 0
    let vazao_recalculada_sem_limitacao = 0
    let vazao_recalculada = 0
    let vazao = 0

    if (forma_avaliacao.includes('STEL')) {
      quantidade_de_amostradores = 1
      tempo_de_amostragem_minimo = 15

      volume = Number((vazao_maxima * tempo_de_amostragem_minimo).toFixed(4))

      if (volume >= volume_maximo){
        volume_limitado = volume_maximo
        vazao_recalculada_sem_limitacao = Number((volume_limitado / tempo_de_amostragem_minimo).toFixed(4))
        vazao_recalculada = this.limitacao_de_vazao(vazao_minima, vazao_maxima, vazao_recalculada_sem_limitacao)

        if (vazao_recalculada_sem_limitacao < vazao_minima || vazao_recalculada_sem_limitacao > vazao_maxima){
          frase_de_orientacao = `A quantificação deste agente para a finalidade de comparação com o limite STEL da ACGIH precisa ser dialogada e analisada junto ao laboratório, pois o volume ou a vazão calculados divergem dos limites permitidos pelo método. Verifique junto ao laboratório qual será o melhor caminho para que você possa quantificar o agente visando o limite STEL.`
        }
      } else {
        vazao_recalculada = vazao_maxima;
        frase_sugerida = `${frase_de_orientacao}<br/>Será necessário ${quantidade_de_amostradores} amostrador coletando a uma vazão de ${vazao_recalculada} L/min durante ${this.decimalPlaceLimitation(tempo_de_amostragem_minimo.toFixed(4))} minutos para avaliar este agente para seu limite do tipo STEL`
      }
      return frase_sugerida
    }

    if (forma_avaliacao.includes('passiva')) {
      return `<br/><br/><br/>Será necessário 1 (um) amostrador passivo para cobrir a jornada de ${this.decimalPlaceLimitation(tempo_de_amostragem_minimo.toFixed(4))} minutos`
    }

    if (forma_avaliacao.includes('TETO')) {
      quantidade_de_amostradores = 1
      vazao = vazao_maxima
      volume = volume_minimo
      tempo_por_amostrador_sugerido = Number((volume_minimo / vazao_maxima).toFixed(4))
      return `<br/><br/><br/>Será necessário ${quantidade_de_amostradores} amostrador com tempo de ${this.decimalPlaceLimitation(tempo_por_amostrador_sugerido.toFixed(4))} minutos com uma vazao de ${this.decimalPlaceLimitation(vazao_maxima.toFixed(4))} L/min para avaliar este agente para seu limite do tipo TETO`
    }

    let vazao_sugerida = 0
    let retorno = {}
    let tempo_por_amostrador = 0

    if (!margem_de_seguranca) {
      tempo_por_amostrador = Number((volume_maximo / vazao_minima).toFixed(4))

      if (inputs_do_usuario) {
        tempo_por_amostrador = Number((volume_manual / vazao_manual).toFixed(4))
      }

      for (let i = 1; i < 50; i++) {
        if (quantidade_de_amostradores === 1 && tempo_por_amostrador >= tempo_de_amostragem_minimo) {
          retorno = this.situacao_1(
            tempo_de_amostragem_minimo,
            vazao_minima,
            vazao_maxima,
            volume_maximo,
            inputs_do_usuario,
            vazao_manual,
            volume_manual
          )

          vazao_sugerida = inputs_do_usuario ? retorno.vazao_manual : retorno.vazao_minima_recalculada
          tempo_por_amostrador_sugerido = retorno.tempo_por_amostrador
          break
        } else if ((tempo_por_amostrador * quantidade_de_amostradores) >= tempo_de_amostragem_minimo) {
          break
        } else {
          quantidade_de_amostradores += 1
          retorno = this.situacao_2(
            quantidade_de_amostradores,
            tempo_de_amostragem_minimo,
            vazao_minima,
            vazao_maxima,
            volume_maximo,
            inputs_do_usuario,
            vazao_manual,
            volume_manual,
          )
          vazao_sugerida = inputs_do_usuario ? retorno.vazao_manual : retorno.vazao_minima_recalculada
          tempo_por_amostrador_sugerido = retorno.tempo_por_amostrador
        }
      }
    } else if (margem_de_seguranca) {
      tempo_por_amostrador = Number((volume_maximo_recomendado / vazao_minima_recomendada).toFixed(4))

      for (let i = 1; i < 50; i++) {
        if (quantidade_de_amostradores === 1 && tempo_por_amostrador >= tempo_de_amostragem_minimo) {
          retorno = this.situacao_1(
            tempo_de_amostragem_minimo,
            vazao_minima_recomendada,
            vazao_maxima_recomendada,
            volume_maximo_recomendado,
            inputs_do_usuario,
            vazao_manual,
            volume_manual
          )

          vazao_sugerida = inputs_do_usuario ? retorno.vazao_manual : retorno.vazao_minima_recalculada
          tempo_por_amostrador_sugerido = retorno.tempo_por_amostrador
          break
        } else if ((tempo_por_amostrador * quantidade_de_amostradores) >= tempo_de_amostragem_minimo) {
          break
        } else {
          quantidade_de_amostradores += 1
          retorno = this.situacao_2(
            quantidade_de_amostradores,
            tempo_de_amostragem_minimo,
            vazao_minima_recomendada,
            vazao_maxima_recomendada,
            volume_maximo_recomendado,
            inputs_do_usuario,
            vazao_manual,
            volume_manual
          )

          vazao_sugerida = inputs_do_usuario ? retorno.vazao_manual : retorno.vazao_minima_recalculada
          tempo_por_amostrador_sugerido = retorno.tempo_por_amostrador
        }
      }
    }

    if (quantidade_de_amostradores === 1) {
      return `<br/>Será necessário ${quantidade_de_amostradores} amostrador com tempo de ${this.decimalPlaceLimitation(tempo_por_amostrador_sugerido.toFixed(4))} minutos com uma vazão de ${this.decimalPlaceLimitation(vazao_sugerida.toFixed(4))} L/min para cobrir a jornada de ${this.decimalPlaceLimitation(tempo_de_amostragem_minimo.toFixed(4))} minutos"`
    } else {
      return `<br/>Serão necessários ${quantidade_de_amostradores} amostradores com tempo de ${this.decimalPlaceLimitation(tempo_por_amostrador_sugerido.toFixed(4))} minutos cada a uma vazao de ${this.decimalPlaceLimitation(vazao_sugerida.toFixed(4))} L/min para cobrir a jornada de ${this.decimalPlaceLimitation(tempo_de_amostragem_minimo.toFixed(4))} minutos`
    }
  },

  limitacao_de_vazao(vazao_minima, vazao_maxima, vazao_calculada) {
    if (vazao_calculada <= vazao_minima) {
      vazao_calculada = vazao_minima
    }
    if (vazao_calculada >= vazao_maxima) {
      vazao_calculada = vazao_maxima
    }
    return vazao_calculada
  },

  decimalPlaceLimitation(value) {
    try {
      const pointOrComma = value.toString().includes('.') ? '.' : ','
      const [integer, decimal] = value.toString().split(pointOrComma)

      if (!decimal) {
        return value
      }
      for (let i = 0; i <= 9; i++) {
        if (decimal === `${i}${i}${i}${i}`) {
          return `${integer},0`
        }
        if (decimal === `${i}${i}${i}${i}`) {
          return Number(value).toFixed(3).replace('.', ',')
        }
      }
      return Number(value).toFixed(3).replace('.', ',')
    } catch (e) {

    }
  },

  justAformEvaluation(object, laboratory) {
    const analise = object[laboratory]
    return analise && analise.length === 1
  },

  returnFlowEnteredByUser(object, objectivesAnalysis){
    let formEvaluation = object[`evaluationFormUsed${objectivesAnalysis.laboratory.name}`]

    if(!formEvaluation) {
      formEvaluation = object[objectivesAnalysis.laboratory.name][0]
    }

    if(formEvaluation && formEvaluation.itsFlow && formEvaluation.itsFlow.toString().length > 0){
      return `Vazão: ${formEvaluation.itsFlow} L/min`
    }

    return null
  },

  returnMaximumVolume(object, objectivesAnalysis){
    let formEvaluation = object[`evaluationFormUsed${objectivesAnalysis.laboratory.name}`]

    if(!formEvaluation) {
      formEvaluation = object[objectivesAnalysis.laboratory.name][0]
    }

    if(formEvaluation && formEvaluation.maximumVolume){
      return `Volume: ${formEvaluation.maximumVolume} L`
    }

    return null
  },

  returnVolumeEnteredByUser(object, objectivesAnalysis){
    let formEvaluation = object[`evaluationFormUsed${objectivesAnalysis.laboratory.name}`]

    if(!formEvaluation) {
      formEvaluation = object[objectivesAnalysis.laboratory.name][0]
    }

    if(formEvaluation && formEvaluation.itsVolume && formEvaluation.itsVolume.toString().length > 0){
      return `Volume: ${formEvaluation.itsVolume} L`
    }

    return null
  },

  seekFirstEvaluationForm(key, object, objectivesAnalysis) {
    const [formEvaluation] = object[objectivesAnalysis.laboratory.name]
    switch (key) {
      case 'necessarioParaAtender':
        return this.getNecessarioParaAtender(object, objectivesAnalysis)
      case 'methodologyCode':
        return `${formEvaluation.methodologyCode} - ${formEvaluation.methodologyDescription}`
      case 'evaluationForm':
        return formEvaluation.evaluationForm
      case 'minimumRecommendedFlow':
        return `Mínima: ${formEvaluation.minimumFlow} <br/> Máxima: ${formEvaluation.maximumLeak}`
      case 'recommendedMinimumVolume':
        return `Mínimo: ${formEvaluation.minimumVolume} <br/>  Máximo: ${formEvaluation.maximumVolume}`
      case 'suggestedPlanning':
        return 'Em desenvolvimento'
      default:
        return undefined
    }
  },

  seekEvaluationForm(key, object, objectivesAnalysis) {
    const evaluationFormUsed = object[`evaluationFormUsed${objectivesAnalysis.laboratory.name}`]
    switch (key) {
      case 'necessarioParaAtender':
        return this.getNecessarioParaAtender(object, objectivesAnalysis)
      case 'methodologyCode':
        return `${evaluationFormUsed.methodologyCode} - ${evaluationFormUsed.methodologyDescription}`
      case 'evaluationForm':
        return evaluationFormUsed.evaluationForm
      case 'minimumRecommendedFlow':
        return `Mínima: ${evaluationFormUsed.minimumFlow} <br/>  Máxima: ${evaluationFormUsed.maximumLeak}`
      case 'recommendedMinimumVolume':
        return `Mínimo: ${evaluationFormUsed.minimumVolume} <br/>  Máximo: ${evaluationFormUsed.maximumVolume}`
      case 'suggestedPlanning':
        return 'Em desenvolvimento'
      default:
        return undefined
    }
  },

  checkAgentIsInEceptionLTCAT(object, objectivesAnalysis) {
    if (
      object[`evaluationFormUsed${objectivesAnalysis.laboratory.name}`] &&
      object[`evaluationFormUsed${objectivesAnalysis.laboratory.name}`].enabledAgentInformationLTCAT
    ) {
      return false
    } else {
      if (objectivesAnalysis.objectiveAnalysis.includes('LTCAT')) {
        return !!(object && object.linach && object.linach.group && object.linach.group.trim() === 'Grupo 1' && object.decree3048)
      } else {
        return false
      }
    }
  },

  getEvaluationType(object, objectivesAnalysis) {
    let notNeeded = []
    let response = ''
    if (objectivesAnalysis.objectiveAnalysis.includes('LAUDO_INSALUBRIDADE') &&this.checkAgentIsInReportInsalubrity(object)) {
      notNeeded.push('Laudo de insalubridade')
    }

    if (objectivesAnalysis.objectiveAnalysis.includes('PGR') && this.checkAgentIsInACGIH(object)) {
      notNeeded.push('PGR')
    }

    if (objectivesAnalysis.objectiveAnalysis.includes('LTCAT') && this.checkAgentIsInLTCAT(object)) {
      notNeeded.push('LTCAT')
    }

    const index = notNeeded.join(', ').lastIndexOf(',')
    response = notNeeded.join(', ')
    if (index >= 0){
      response = `${notNeeded.join(', ').substring(0, index)} e ${notNeeded.join(', ').substring(index+1)}`;
    }

    return response
  },

  generateEvaluationObjectiveSentence(object, objectivesAnalysis) {
    let response = false
    if (
      object[`evaluationFormUsed${objectivesAnalysis.laboratory.name}`] &&
      object[`evaluationFormUsed${objectivesAnalysis.laboratory.name}`].enabledAgentInformationLTCAT
    ) {
      response = false
    } else {
      if (objectivesAnalysis.objectiveAnalysis.includes('LTCAT')) {
        response = this.checkAgentIsInLTCAT(object)
      } else {
        response = false
      }
    }

    if (response) {
      return response
    }

    if (
      object[`evaluationFormUsed${objectivesAnalysis.laboratory.name}`] &&
      object[`evaluationFormUsed${objectivesAnalysis.laboratory.name}`].enabledAgentInformationReportInsalubrity
    ) {
      response = false
    } else {
      if (objectivesAnalysis.objectiveAnalysis.includes('LAUDO_INSALUBRIDADE')) {
        response = this.checkAgentIsInReportInsalubrity(object)
      } else {
        response = false
      }
    }

    if (response) {
      return response
    }

    if (
      object[`evaluationFormUsed${objectivesAnalysis.laboratory.name}`] &&
      object[`evaluationFormUsed${objectivesAnalysis.laboratory.name}`].enabledAgentInformationACGIH
    ) {
      response = false
    } else {
      if (objectivesAnalysis.objectiveAnalysis.includes('PGR')) {
        response = this.checkAgentIsInACGIH(object)
      } else {
        response = false
      }
    }

    return response
  },

  checkAgentIsInLTCAT(object) {
    return !((object && object.decree3048 && object.attachment11) || (object && object.decree3048 && object.attachment12))
  },

  checkAgentIsInReportInsalubrity(object) {
    return !(object && (object.attachment11 || object.attachment12))
  },

  checkAgentIsInACGIH(object) {
    return !(object && object.acgih)
  },

  getNecessarioParaAtender(object, objectivesAnalysis) {
    const objectiveAnalysis = []
    if(!(objectiveAnalysis.find((objective) => objective.text === 'Laudo de insalubridade'))) {
      objectiveAnalysis.push(
        {
          agent: object.searchId,
          id: 0,
          img: !!((object && object.attachment11 || object.attachment12)) ? 'consta' : 'naoConsta',
          text: 'Laudo de insalubridade'
        }
      )
    }
    if(!(objectiveAnalysis.find((objective) => objective.text === 'LTCAT'))) {
      objectiveAnalysis.push(
        {
          agent: object.searchId,
          id: 1,
          img: !!(((object && object.decree3048 && object.attachment11) || (object && object.decree3048 && object.attachment12))) ? 'consta' : 'naoConsta',
          text: 'LTCAT'
        }
      )
    }
    if(!(objectiveAnalysis.find((objective) => objective.text === 'PGR'))) {
      objectiveAnalysis.push(
        {
          agent: object.searchId,
          id: 2,
          img: !!(object && object.acgih) ? 'consta' : 'naoConsta',
          text: 'PGR'
        }
      )
    }
    return objectiveAnalysis
  },
  getsearchId(object){
    const casAgente = object.name || object.searchId || object.searchMatched
    const [cas, agente] = casAgente.split(':')

    if(!agente.trim() && object.synonyms && object.synonyms.length > 0){
      return `${object.cas}: ${object.synonyms.find((x) => x.length > 0)}`
    }
    return casAgente
  },
  tableFields: [
    { key: 'necessarioParaAtender' },
    { key: 'methodologyCode' },
    { key: 'evaluationForm' },
    { key: 'minimumRecommendedFlow' },
    { key: 'recommendedMinimumVolume' },
    { key: 'defineVolumeFlow' },
    { key: 'suggestedPlanning' }
  ]
}
