export default {
  getExist(key, object) {
    if (!object) return ''
    switch (key) {
      case 'acgih':
        /*if (object.synonyms.length < 1 || !object.agent)
          return 'classificacao'*/
        return object.acgih ? 'consta' : 'naoConsta'
      case 'nr15':
        if (object.attachment1 ||
          object.attachment2 ||
          object.attachment3 ||
          object.attachment8 ||
          object.attachment14 ||
          object.attachment11 ||
          object.attachment12 ||
          object.attachment13
        )
          return 'consta'
        return object.nr15Unclassified
          ? 'naoConsta'
          : 'classificacao'
      case 'linach':
        /*if (object.synonyms.length < 1 || !object.agent)
          return 'classificacao'*/
        return object.linach ? 'consta' : 'naoConsta'
      case 'esocial':
        if (object.table24)
          return 'consta'
        return object.decreeUnclassified ? 'naoConsta' : 'classificacao'
      case 'decree3048':
        if (object.decree3048)
          return 'consta'
        return object.decreeUnclassified ? 'naoConsta' : 'classificacao'
      default:
        return undefined
    }
  },
  getsearchId(key, object){
    const casAgente = object.name || object.searchId || object.searchMatched
    const [cas, agente] = casAgente.split(':')

    if(!agente.trim() && object.synonyms && object.synonyms.length > 0){
      return `${object.cas}: ${object.synonyms.find((x) => x.length > 0)}`
    }
    return casAgente
  },
  getValue(key, object) {
    if (!object) return ''
    switch (key) {
      case 'searchId':
        return object.name || object.searchId || object.searchMatched
      default:
        return undefined
    }
  },
  tableFields: [
    /*{
      key: 'selectedToDelete',
      label: 'Selecionar'
    },
    {
      key: 'product',
      label: 'Produto'
    },
    {
      key: 'searchId',
      label: 'Agente'
    },*/
    {
      key: 'acgih',
      label: 'acgih'
    },
    {
      key: 'nr15',
      label: 'nr15'
    },
    {
      key: 'linach',
      label: 'linach'
    },
    {
      key: 'esocial',
      label: 'esocial'
    },
    {
      key: 'decree3048',
      label: 'decree3048'
    },
    {
      key: 'actions',
      label: ''
    }
  ],
  tableFieldsModal: [
    {
      key: 'searchId',
      label: 'Agente'
    },
    {
      key: 'acgih',
      label: 'acgih'
    },
    {
      key: 'nr15',
      label: 'nr15'
    },
    {
      key: 'linach',
      label: 'linach'
    },
    {
      key: 'esocial',
      label: 'esocial'
    },
    {
      key: 'decree3048',
      label: 'decree3048'
    }
  ],
  tableFieldsPrint: [
    {
      key: 'searchId',
      label: 'Agente'
    },
    {
      key: 'acgih',
      label: 'acgih'
    },
    {
      key: 'nr15',
      label: 'nr15'
    },
    {
      key: 'linach',
      label: 'linach'
    },
    {
      key: 'esocial',
      label: 'esocial'
    },
    {
      key: 'decree3048',
      label: 'decree3048'
    },
  ]
}
