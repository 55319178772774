<template>
  <div class='w-100'>
    <SurveySatisfaction/>

    <SurveyRecommendation/>

    <importer-agents>

    </importer-agents>

    <component v-bind:is="tabView" @open-survey="openDownloadSurvey" />

    <section class="d-flex justify-content-center" v-if="lookingForAgents && tabView !== 'Gro'">
      <p class='mt-2 btn-outline-primary text-loading'>Carregando agentes...</p>
      <section class='px-3'>
        <b-spinner class='loadingLookingForAgents' variant="primary" label="Spinning"></b-spinner>
      </section>
    </section>

    <div class='w-100 mb-5 mt-3' v-if="tabView !== 'Gro'">
      <b-pagination
        v-if='summaryMeta.total > agentsPerPage'
        align="center"
        :per-page='agentsPerPage'
        v-model="summaryMeta.skip"
        :total-rows="summaryMeta.total"
        pills
        first-text="⏮"
        prev-text="⏪"
        next-text="⏩"
        last-text="⏭"
        @page-click='setPage'
        size="lg"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import Acigh from '@/components/LegalSearch/Acgih'
import ImporterAgents from '@/components/LegalSearch/Modal/ImporterAgents.vue'
import Insalubrity from '@/components/LegalSearch/Insalubrity'
import Ltcat from '@/components/LegalSearch/Ltcat'
import Report from '@/components/LegalSearch/Report'
import Summary from '@/components/LegalSearch/Summary'
import QuantitativeAssessment from '@/components/LegalSearch/QuantitativeAssessment/QuantitativeAssessment'
import Pcmso from '@/components/LegalSearch/Pcmso/Pcmso'
import Gro from '@/components/LegalSearch/Gro/Gro'
import { isOpenSurvey } from '../utils/surveys'
import SurveySatisfaction from '@/components/SurveySatisfaction.vue'
import SurveyRecommendation from '@/components/SurveyRecommendation.vue'
import FilterAgentsLegalConsultation from '@/components/LegalSearch/Modal/FilterAgentsLegalConsultation.vue'
import { mapActions } from 'vuex'
import eventBus from '@/utils/eventBus'
export default {
  data() {
    return {
      tabView: 'Summary',
      openSurvey: {
        download: false,
        login: false,
        currentBtn: '',
      },
    }
  },
  created() {
    this.$store.state.pagetitle = '(Resumo)'
    this.changeView('Summary', 'CLICOU_ABA_SELECAO_DE_AGENTES')
    this.openSurvey.login = isOpenSurvey('login')
  },
  async mounted() {
    if(this.user.showPopupSurveyRecommendation) {
      this.$bvModal.show('surveyRecommendation')
    }
    if(this.user.showSatisfactionSurveyPopup) {
      this.$bvModal.show('surveySatisfaction')
    }
    await this.load()

    await this.setTabView('Summary')
    eventBus.$on('updateTab', async (component) => {
      this.tabView = component
    })

    if (this.$route.query.aba) {
      await this.changeView('Gro', 'CLICOU_ABA_SELECAO_DE_AGENTES')
      await this.setTabView('Gro')
    }
    await this.handleGetAddedAgentsLimitation(this.user)
    await this.loadRationaleGroups()
    await this.loadListOfPhrases()
    if (this.user && this.user.role !== 'admin') {
      const log = {
        action: 'ACESSOU_PAGINA_CONSULTA_LEGAL',
      }
      await this.createUserActionLogs(log)
    }
    await this.handleGetObjectivesAnalysis()
  },
  components: {
    Acigh,
    Insalubrity,
    Ltcat,
    Report,
    Summary,
    SurveySatisfaction,
    QuantitativeAssessment,
    Pcmso,
    Gro,
    FilterAgentsLegalConsultation,
    ImporterAgents,
    SurveyRecommendation,
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    },
    renew() {
      return this.$store.getters['user/renewable']
    },
    summaryMeta() {
      return this.$store.getters['legalSearch/summaryMeta']
    },
    agentsPerPage() {
      return this.$store.getters['legalSearch/agentsPerPage']
    },
    lookingForAgents() {
      return this.$store.getters['legalSearch/lookingForAgents']
    },
  },
  methods: {
    ...mapActions('userActionLogs', {
      createUserActionLogs: 'createUserActionLogs',
    }),
    ...mapActions('rationaleGroups', {
      loadRationaleGroups: 'loadRationaleGroups',
    }),
    ...mapActions('phraseListH', {
      loadListOfPhrases: 'loadListOfPhrases',
    }),
    ...mapActions('userPlans', {
      handleGetAddedAgentsLimitation: 'handleGetAddedAgentsLimitation',
    }),
    ...mapActions('legalSearch', {
      setSkip: 'setSkip',
      summaryClear: 'summaryClear',
      setTabView: 'setTabView',
    }),
    ...mapActions('objectivesAnalysis', {
      handleGetObjectivesAnalysis: 'handleGetObjectivesAnalysis',
    }),

    async load() {
      await this.$store.dispatch('legalSearch/load', this.user.id)
    },

    buttonActive(view) {
      return view === this.tabView
    },

    async changeView(component, action, name) {
      this.$route.meta.subtitle = name
      this.currentBtn = component
      this.tabView = component
      if (this.user && this.user.role !== 'admin') {
        const log = {
          action,
        }
        await this.createUserActionLogs(log)
      }
    },

    openDownloadSurvey() {
      this.openSurvey.download = isOpenSurvey('download')
    },

    async setPage(event, page) {
      await this.summaryClear()
      await this.setSkip(page)
      await this.load()
    },
  },
  beforeDestroy: function () {
    this.$store.state.pagetitle = ''
    eventBus.$off('updateTab')
  },
}
</script>
<style>
.btn-orientations{
  padding: 5px 8px;
  border-radius: 6px;
  background: var(--navy-blue);
  font-weight: 700;
  font-size: 14px;
  color: #FFFFFF;
}
.loadingLookingForAgents{
  width: 2rem;
  height: 2rem;
}
.custom-tooltip > .tooltip-inner {
  background: #000000;
  max-width: 800px !important;
  width: 800px !important;
}
</style>
<style scoped>

.link {
  text-decoration: none;
}
.tabs-inventory {
  border-radius: 10px;
}
table.sticky th {
  position: sticky;
  top: 0px;
}

.active {
  background-color: #022245 !important;
  color: var(--white) !important;
  font-weight: 700 !important;
  outline: none !important;
  border: none !important;
}

button.btn.btn.btn-outline-primary.btn-secondary {
  border-radius: 0;
  background: var(--white);
}

button.btn.btn.btn-outline-primary.btn-secondary.active,
button.btn.btn.btn-outline-primary.btn-secondary:hover {
  background-color: var(--blue);
}
</style>
